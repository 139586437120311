import {Tip} from '@renderer/js/components/dashboard/redux/types';
import {GET_TIP, SET_TIP} from '@renderer/js/components/dashboard/redux/constants';

export const setTip = (payload: Tip) => ({
  type: SET_TIP,
  payload,
});

export const getTip = () => ({
  type: GET_TIP,
  payload: {},
});
