import {connect} from 'react-redux';

import {AppState} from '@renderer/js/redux/rootReducer';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {selectIsPpiFunnelShown} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {PublicRoute} from './public-route.component';

const mapStateToProps = (state: AppState) => ({
  appInfo: getAppInfo(state),
  isPpiFunnelShown: selectIsPpiFunnelShown(state),
});

export const PublicRouteContainer = connect(mapStateToProps)(PublicRoute);
