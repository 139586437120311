import {TGetTipAction, TipsType, TSetTipAction} from '../types';
import {GET_TIP, SET_TIP} from '../constants';

const initialState: TipsType = {
  isLoading: false,
  tip: null,
};

type TActionsType = TSetTipAction | TGetTipAction;

export const tipsReducer = (state = initialState, action: TActionsType): TipsType => {
  switch (action.type) {
    case SET_TIP:
      return {
        ...state,
        isLoading: false,
        tip: action.payload,
      };
    case GET_TIP:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};
