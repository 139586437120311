import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './_paywall-modal.scss';
import {PaywallModalProps} from '@renderer/js/components/paywall-modal/paywall-modal.types';
import MonitorFeature from '@renderer/images/paywall-modal/monitor-feature.svg';
import MaskedEmailFeature from '@renderer/images/paywall-modal/masked-email-feature.svg';
import VPNFeature from '@renderer/images/paywall-modal/vpn-feature.svg';
import {ButtonComponent, ModalComponent} from '@figleafteam/components';
import {EVENTS} from '@common/events';
import DiamondImage from './images/diamond.svg';

export const PaywallModalComponent: FC<PaywallModalProps> = props => {
  const {t} = useTranslation();
  const [isFirstRender, setIsRendered] = useState<boolean>(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsRendered(false);
      return;
    }
    if (props.paywallModalState.isOpen) {
      props.sendTelemetryEvent({name: EVENTS.PAYWALL_POPUP.SHOW, source: 'app'});
    } else {
      props.sendTelemetryEvent({name: EVENTS.PAYWALL_POPUP.CLOSE, source: 'app'});
    }
  }, [props.paywallModalState.isOpen]);

  const handleClose = () => {
    props.resetPaywallModalState();
  };

  const handleOpenBuyNow = () => {
    props.sendTelemetryEvent({name: EVENTS.PAYWALL_POPUP.BUYNOW_BUTTON_CLICK, source: 'user'});
    props.openBuyNow();
  };

  const appFeatures = [
    {
      key: 'MonitorFeature',
      img: <MonitorFeature />,
      description: t('Get breach alerts for all your emails'),
    },
    {
      key: 'MaskedEmailFeature',
      img: <MaskedEmailFeature />,
      description: t('Create unlimited masked emails'),
    },
    {
      key: 'VPNFeature',
      img: <VPNFeature />,
      description: t('Connect privately with VPN'),
    },
  ];

  return (
    <ModalComponent
      width={600}
      autoFocus
      isOpen={props.paywallModalState.isOpen}
      showCloseButton
      handleClose={handleClose}
    >
      <section className="paywall-modal">
        <header className="paywall-modal__header">
          <DiamondImage className="paywall-modal__image" />
          <p className="paywall-modal__title">
            {t('Get {{partner}} Premium to take back control of your privacy', {partner: 'FigLeaf'})}
          </p>
        </header>
        <main className="paywall-modal__body">
          {appFeatures.map(feature => (
            <div key={feature.key} className="paywall-modal__feature-item">
              {feature.img}
              <p className="paywall-modal__feature-item-text">{feature.description}</p>
            </div>
          ))}
        </main>
        <footer className="paywall-modal__footer">
          <ButtonComponent type="primary" fullWidth text={t('Subscribe now')} onClick={handleOpenBuyNow} />
        </footer>
      </section>
    </ModalComponent>
  );
};
