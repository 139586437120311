import React from 'react';
import {AuthOnboardingContainer} from '@renderer/js/components/auth-onboarding/auth-onboarding.container';

import UnlockScreenImage from '@renderer/images/illustrations/unlock-image.svg';
import ImageMigration from '@renderer/images/illustrations/image-migration.svg';
import {getFromState, HISTORY_STATE_FROM} from '@renderer/js/router/history';

import {useCheckAuth} from '@renderer/js/utils/use-check-auth';

import {RecoveryWrapperProps} from './recovery-wrapper.types';
import './_recovery-wrapper.scss';

export const RecoveryWrapperComponent: React.FC<RecoveryWrapperProps> = props => {
  const from = getFromState();

  if (!from) {
    return <AuthOnboardingContainer>{props.children}</AuthOnboardingContainer>;
  }

  useCheckAuth(props.appInfo);

  return (
    <div className="recovery-wrapper">
      <div className="recovery-wrapper__col">
        <div className="recovery-wrapper__content">
          {from === HISTORY_STATE_FROM.MIGRATION && <ImageMigration />}
          {from === HISTORY_STATE_FROM.UNLOCK && <UnlockScreenImage />}
        </div>
      </div>
      <div className="recovery-wrapper__col">{props.children}</div>
    </div>
  );
};
