import {TPpiLeaksState, TSetPpiLeaksStateAction} from '../types';
import {SET_PPI_LEAKS_STATE, PPI_LOCAL_STORAGE_KEY} from '../constants';

const storedFunnelState = localStorage.getItem(PPI_LOCAL_STORAGE_KEY);

const initialFunnelState: TPpiLeaksState = {
  isAddEmailModalOpen: false,
  isVerifyModalOpen: false,
  isDeleteEmailModalOpen: false,
  isVerifyInProgress: false,
  currentEmailToVerify: 0,
  emailsToRescan: [],
  profiles: [],
  profilesToVerify: [],
  rawLeaksReport: null,
  nextModal: 'add',
};

type TActionType = TSetPpiLeaksStateAction;

export const leaksStateReducer = (
  state: TPpiLeaksState = storedFunnelState ? JSON.parse(storedFunnelState).leaks : initialFunnelState,
  action: TActionType,
): TPpiLeaksState => {
  switch (action.type) {
    case SET_PPI_LEAKS_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
