const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

export class TimeUnit {
  readonly milliseconds: number;

  private constructor(milliseconds: number) {
    this.milliseconds = milliseconds;
  }

  get seconds(): number {
    return this.milliseconds / MILLISECONDS_IN_SECOND;
  }

  get minutes(): number {
    return this.seconds / SECONDS_IN_MINUTE;
  }

  get hours(): number {
    return this.minutes / MINUTES_IN_HOUR;
  }

  get days(): number {
    return this.hours / HOURS_IN_DAY;
  }

  static milliseconds(value: number): TimeUnit {
    return new TimeUnit(value);
  }

  static seconds(value: number): TimeUnit {
    return this.milliseconds(value * MILLISECONDS_IN_SECOND);
  }

  static minutes(value: number): TimeUnit {
    return this.seconds(value * SECONDS_IN_MINUTE);
  }

  static hours(value: number): TimeUnit {
    return this.minutes(value * MINUTES_IN_HOUR);
  }

  static days(value: number): TimeUnit {
    return this.hours(value * HOURS_IN_DAY);
  }
}
