import React, {FC, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import {
  ButtonComponent,
  ButtonIconComponent,
  TooltipComponent,
  IconComponent,
  ConfirmModalComponent,
} from '@figleafteam/components';
import {history} from '@renderer/js/router/history';
import {PPIRisksReportContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report/ppi-risks-report.container';
import {EVENTS} from '@common/events';
import {PPIVerifyEmailModalContainer} from '@renderer/js/components/ppi-funnel/components/ppi-verify-email-modal/ppi-verify-email-modal.container';
import {PPIEmailsListModalContainer} from '@renderer/js/components/ppi-funnel/components/ppi-add-email-modal/ppi-add-email-modal.container';
import {PPIRisksScannerRunScanBlockContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-scanner-run-scan-block/ppi-risks-scanner-run-scan-block.container';
import {PPIRisksScannerProgressBlockContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-scanner-progress-block/ppi-risks-scanner-progress-block.container';
import {PrivacyIndicatorComponent} from '@renderer/js/components/ppi-funnel/components/ppi-privacy-indicator/privacy-indicator.component';
import {RiskTypes, TScannerStep} from '@renderer/js/components/ppi-funnel/redux/types';
import IconInfo from './images/info.svg';
import {TPPIRisksScannerProps} from './ppi-risks-scanner.types';
import './_ppi-risks-scanner.scss';

const IS_INDICATOR_ANIMATED = 'PPI:IS_INDICATOR_ANIMATED';

export const PPIRisksScannerComponent: FC<TPPIRisksScannerProps> = ({
  scannerStep,
  globalRiskType,
  appInfo,
  ppiLeaksState,
  setPpiFunnelState,
  setPpiLeaksState,
  setAuthPopupOpened,
  sendTelemetryEvent,
  getVerifiedEmails,
  deleteEmailFromReport,
}) => {
  const {t} = useTranslation();

  const [isAnimated, setIsAnimated] = useState(Boolean(localStorage.getItem(IS_INDICATOR_ANIMATED)));
  const scannerBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sendTelemetryEvent({name: EVENTS.PPI.RISK_SCANNER_OPEN, source: 'user'});
  }, []);

  useEffect(() => {
    if (appInfo?.isAuthorized) {
      getVerifiedEmails();
    }
  }, [appInfo?.isAuthorized]);

  const renderScannerStep = (step: TScannerStep) => {
    switch (step) {
      case 'initial':
        sendTelemetryEvent({name: EVENTS.PPI.RISK_SCANNER_START_SCAN_OPEN, source: 'app'});
        return <PPIRisksScannerRunScanBlockContainer />;
      case 'scanning':
        sendTelemetryEvent({name: EVENTS.PPI.RISK_SCANNER_PROGRESS_OPEN, source: 'app'});
        return <PPIRisksScannerProgressBlockContainer />;
      case 'report':
        sendTelemetryEvent({name: EVENTS.PPI.RISK_SCANNER_REPORT_OPEN, source: 'app'});
        return <PPIRisksReportContainer scannerBodyRef={scannerBodyRef} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (scannerStep === 'report' && !isAnimated) {
      setTimeout(() => {
        setIsAnimated(true);
        localStorage.setItem(IS_INDICATOR_ANIMATED, 'true');
      }, 1000);
    }
  }, [scannerStep]);

  const email = ppiLeaksState.profilesToVerify[ppiLeaksState.currentEmailToVerify]?.email;

  return (
    <div className="risks-scanner">
      <section className="risks-scanner__body" ref={scannerBodyRef}>
        <div className="risks-scanner__content">
          <div className="risks-scanner__close">
            <ButtonIconComponent
              onClick={() => {
                sendTelemetryEvent({name: EVENTS.PPI.RISK_SCANNER_HIDE, source: 'user'});
                setPpiFunnelState({isRisksScannerOpened: false});
              }}
            >
              <IconComponent name="close" size={24} />
            </ButtonIconComponent>
          </div>
          <div className="risks-scanner__how-it-works-wrapper">
            <TooltipComponent
              text={t(
                'We scan your computer and check if your private data was exposed. Please follow our instructions to lower them and protect yourself.',
              )}
              placement="top"
              modifier="dark"
            >
              <div className="risks-scanner__how-it-works">
                <span className="risks-scanner__how-it-works-icon">
                  <IconInfo />
                </span>
                {t('How it works')}
              </div>
            </TooltipComponent>
          </div>
          <header className="risks-scanner__header">
            <div
              className={classNames('risks-scanner__indicator', {
                'risks-scanner__indicator--small': isAnimated && scannerStep === 'report',
                'risks-scanner__indicator--small-animate': !isAnimated && scannerStep === 'report',
              })}
            >
              <PrivacyIndicatorComponent
                riskType={scannerStep === 'report' ? globalRiskType : RiskTypes.medium}
                status={scannerStep}
                size="small"
              />
            </div>
            {scannerStep === 'report' && (
              <h1
                className={classNames('risks-scanner__title', {
                  'risks-scanner__title--visible': isAnimated,
                  'risks-scanner__title--animate': !isAnimated,
                })}
              >
                {t('Your privacy risks report')}
              </h1>
            )}
          </header>
          {renderScannerStep(scannerStep)}
        </div>

        {scannerStep === 'report' && (
          <div className="risks-scanner__bottom">
            <div className="risks-scanner__bottom-control">
              {appInfo?.isAuthorized ? (
                <ButtonComponent
                  text={t('Decrease privacy risks')}
                  fullWidth
                  buttonType="button"
                  type="primary-green"
                  onClick={() =>
                    history.push({
                      pathname: '/dashboard/ppi',
                      state: {from: 'report'},
                    })
                  }
                />
              ) : (
                <ButtonComponent
                  text={t('Access full report for free')}
                  fullWidth
                  buttonType="button"
                  type="primary"
                  onClick={() => setAuthPopupOpened(true)}
                />
              )}
            </div>
          </div>
        )}
      </section>

      <PPIVerifyEmailModalContainer />
      <PPIEmailsListModalContainer />
      <ConfirmModalComponent
        isOpen={ppiLeaksState.isDeleteEmailModalOpen}
        title={t('Remove {{email}}', {email})}
        actionButtonText={t('Remove')}
        actionCallback={() => {
          deleteEmailFromReport(email);
        }}
        resetConfirmModalState={() => {
          setPpiLeaksState({
            isDeleteEmailModalOpen: false,
            isAddEmailModalOpen: true,
            isVerifyModalOpen: false,
          });
        }}
        text={t('By removing this email address, you will no longer receive content or alerts on it')}
      />
    </div>
  );
};
