import {createHashHistory} from 'history';

export enum HISTORY_STATE_FROM {
  MIGRATION = 'migration',
  UNLOCK = 'unlock',
  REPORT = 'report',
}

export const history = createHashHistory();
export type HistoryType = typeof history;

export const getFromState = (): HISTORY_STATE_FROM | undefined => {
  const state = history.location.state as {from: HISTORY_STATE_FROM};

  return state ? state.from : undefined;
};
