import React, {FC, useEffect} from 'react';
import {useTranslation, Trans} from 'react-i18next';

import {ButtonComponent, HeadingComponent} from '@figleafteam/components';
import {EVENTS} from '@common/events';
import BlockTrackers from '@renderer/images/illustrations/onboarding/browse/block-trackers.webp';
import BlockTrackers2x from '@renderer/images/illustrations/onboarding/browse/block-trackers@2x.webp';
import BlockTrackers3x from '@renderer/images/illustrations/onboarding/browse/block-trackers@3x.webp';

import ConnectPrivately from '@renderer/images/illustrations/onboarding/browse/connect-privately.webp';
import ConnectPrivately2x from '@renderer/images/illustrations/onboarding/browse/connect-privately@2x.webp';
import ConnectPrivately3x from '@renderer/images/illustrations/onboarding/browse/connect-privately@3x.webp';

import {BrowsingProps} from '@renderer/js/components/private-browsing/private-browsing.types';
import {AntitrackingContainer} from './components/antitracking/antitracking.container';
import {VpnContainer} from './components/vpn/vpn.container';
import {DetailsContainer} from '../details/components/details-component/details-control.container';
import './_private-browsing.scss';

export const PrivateConnectionComponent: FC<BrowsingProps> = props => {
  const {t} = useTranslation();

  useEffect(() => {
    props.sendTelemetryEvent({name: EVENTS.CONTROLS.CONTROLS_SCREEN_SHOW, source: 'app'});

    return () => {
      props.resetDetailsState();
    };
  }, []);

  const isExtensionInstalled =
    props.browsersReport &&
    props.browsersReport.filter(browser => browser.isExtensionSupported).some(browser => browser.extensions.length);

  const onboardingSteps = [];
  if (ENABLED_FEATURES.UiFeatures.Vpn) {
    onboardingSteps.push({
      title: t('Connect privately'),
      description: (
        <Trans i18nKey="Use our VPN to hide your location and encrypt your online traffic...">
          Use our VPN to <strong>hide your location and encrypt your online traffic</strong> while browsing the
          internet, protecting you from hackers and other online threats. We also have a{' '}
          <strong>strict no logs policy</strong>, ensuring your connection is truly private.
        </Trans>
      ),
      img: (
        <img
          src={ConnectPrivately}
          srcSet={`${ConnectPrivately2x} 2x, ${ConnectPrivately3x} 3x`}
          alt={t('Connect privately')}
        />
      ),
    });
  }
  onboardingSteps.push({
    title: t('Block ad-trackers'),
    description: (
      <Trans i18nKey="Add our Anti-tracker extension to Chrome, FireFox or Edge to...">
        Add our Anti-tracker extension to Chrome, FireFox or Edge to <strong>eliminate advertising trackers</strong>{' '}
        from sites you visit. You can even customize anti-tracking features by site.
      </Trans>
    ),
    img: <img src={BlockTrackers} srcSet={`${BlockTrackers2x} 2x, ${BlockTrackers3x} 3x`} alt="Block trackers" />,
  });

  return (
    <section className="private-browsing">
      <HeadingComponent
        title={t('Controls')}
        details={
          <DetailsContainer
            onboardingSteps={onboardingSteps}
            detailsKey="controls"
            onboardingFinishButton={
              !props.isAuthorized || isExtensionInstalled ? null : (
                <ButtonComponent
                  onClick={() => props.setDetailsState({isExtensionsModalOpened: true, isOnboardingOpened: false})}
                  text={t('Install browser extension')}
                  type="primary"
                  fullWidth
                />
              )
            }
          />
        }
      />
      <div className="private-browsing__content-wrapper">
        {Boolean(ENABLED_FEATURES.UiFeatures.Vpn) && (
          <div className="private-browsing__content private-browsing__content--bordered">
            <VpnContainer />
          </div>
        )}
        <div className="private-browsing__content">
          <AntitrackingContainer />
        </div>
      </div>
    </section>
  );
};
