import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getInstalledBrowsersReport} from '@renderer/js/components/settings/redux/selectors';
import {getBrowsersReport, openWebExtensionStore} from '@renderer/js/components/settings/redux/actions';
import {PrivateConnectionComponent} from '@renderer/js/components/private-browsing/private-browsing.component';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {resetDetailsState, setDetailsState} from '@renderer/js/components/details/redux/actions';
import {selectIsAuthorized} from '../app/redux/selectors';

const mapStateToProps = (state: AppState) => ({
  browsersReport: getInstalledBrowsersReport(state),
  isAuthorized: selectIsAuthorized(state),
});
const mapDispatchToProps = {
  getBrowsersReport,
  openWebExtensionStore,
  setDetailsState,
  resetDetailsState,
  sendTelemetryEvent,
};

export const PrivateBrowsingContainer = connect(mapStateToProps, mapDispatchToProps)(PrivateConnectionComponent);
