import {connect} from 'react-redux';

import {AppState} from '@renderer/js/redux/rootReducer';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';

import {RecoveryWrapperComponent} from './recovery-wrapper.component';

const mapStateToProps = (state: AppState) => ({
  appInfo: getAppInfo(state),
});

export const RecoveryWrapperContainer = connect(mapStateToProps, {})(RecoveryWrapperComponent);
