import {i18n} from '@figleafteam/helpers';

import {Tip} from '@renderer/js/components/dashboard/redux/types';

export const tips: Tip[] = [
  /*
    Monitor tips
   */
  {
    get title() {
      return i18n.t('46% of customers feel they’ve lost control over their own data');
    },
    get describe() {
      return i18n.t('Add your email for FigLeaf to monitor, and we’ll alert you about new breaches');
    },
  },
  {
    get title() {
      return i18n.t('A hacker attack occurs every 39 seconds');
    },
    get describe() {
      return i18n.t('Add your email for FigLeaf to monitor, and we’ll alert you about new breaches');
    },
  },
  {
    get title() {
      return i18n.t('The average time to identify a breach in 2019 was 206 days');
    },
    get describe() {
      return i18n.t('Add your email for FigLeaf to monitor, and we’ll alert you about new breaches');
    },
  },
  {
    get title() {
      return i18n.t('45,737 breaches reported so far');
    },
    get describe() {
      return i18n.t('Add your email for FigLeaf to monitor, and we’ll alert you about new breaches');
    },
  },
  {
    get title() {
      return i18n.t('3,366,253,764 is a number of compromised emails');
    },
    get describe() {
      return i18n.t('Add your email for FigLeaf to monitor, and we’ll alert you about new breaches');
    },
  },
  /*
    Controls tips
   */
  {
    get title() {
      return i18n.t(
        '62% of Americans believe it is not possible to go through daily life without companies collecting their data',
      );
    },
    get describe() {
      return i18n.t('Take control of your online data by using FigLeaf Anti-tracker and VPN');
    },
  },
  {
    get title() {
      return i18n.t(
        'On average, there are nine trackers on each website and 33 tracking requests per page spying on you',
      );
    },
    get describe() {
      return i18n.t('Use FigLeaf Anti-tracker to stop advertisers from collecting and using your browsing data');
    },
  },
  {
    get title() {
      return i18n.t('Nearly 83 percent of all traffic to the top 500 websites contains tracking');
    },
    get describe() {
      return i18n.t('Use FigLeaf Anti-tracker to stop advertisers from collecting and using your browsing data');
    },
  },
  {
    get title() {
      return i18n.t(
        '81% of Americans think the potential risks of data collection by companies about them outweigh the benefits',
      );
    },
    get describe() {
      return i18n.t('Take control of your online data by using FigLeaf Anti-tracker and VPN');
    },
  },
  {
    get title() {
      return i18n.t(
        'Hackers may steal all the personal information you enter while using a public WiFi network, including phone number, banking information, SSN, etc',
      );
    },
    get describe() {
      return i18n.t('Connect to FigLeaf VPN to protect from hackers, malware and other online threats.');
    },
  },
  /*
    Vault tips
   */
  {
    get title() {
      return i18n.t('81% of respondents feel as if they have little or no control over the data collected');
    },
    get describe() {
      return i18n.t('Avoid data breaches by using a FigLeaf masked email when signing up for online accounts');
    },
  },
  {
    get title() {
      return i18n.t('Only 2% of firms that have reported a breach to a supervisory authority have been fined');
    },
    get describe() {
      return i18n.t('Avoid data breaches by using a FigLeaf masked email when signing up for online accounts');
    },
  },
  {
    get title() {
      return i18n.t(
        '58% of the total breaches in 2019 were the result of hacking incidents, impacting 36.9 million patient records.',
      );
    },
    get describe() {
      return i18n.t('Avoid data breaches by using a FigLeaf masked email when signing up for online accounts');
    },
  },
  {
    get title() {
      return i18n.t('The average email address is worth $89 to a brand over time');
    },
    get describe() {
      return i18n.t(
        'Protect your email account by creating FigLeaf masked email for it. Your real email and data are protected, even if your masked email is part of a breach',
      );
    },
  },
  {
    get title() {
      return i18n.t(
        'The average time to identify a breach in 2019 was 206 days and the average time to contain a breach was 73 days, for a total of 279 days',
      );
    },
    get describe() {
      return i18n.t('Avoid data breaches by using a FigLeaf masked email when signing up for online accounts');
    },
  },
  {
    get title() {
      return i18n.t('3,813 breaches were reported through June 30, exposing over 4.1 billion records.');
    },
    get describe() {
      return i18n.t('Avoid data breaches by using a FigLeaf masked email when signing up for online accounts');
    },
  },
];
