import {
  TPaywallModalState,
  TResetPaywallModalStateAction,
  TSetPaywallModalStateAction,
} from '@renderer/js/components/paywall-modal/redux/types';
import {RESET_PAYWALL_MODAL_STATE, SET_PAYWALL_MODAL_STATE} from '../constants';

const initialState: TPaywallModalState = {
  isOpen: false,
};

type TActions = TSetPaywallModalStateAction | TResetPaywallModalStateAction;

export const paywallModalStateReducer = (state = initialState, action: TActions): TPaywallModalState => {
  switch (action.type) {
    case SET_PAYWALL_MODAL_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_PAYWALL_MODAL_STATE:
      return initialState;
    default:
      return state;
  }
};
