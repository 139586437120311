import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  signupSubmit,
  resetSignupEmailValidationStatus,
  resetSignupPasswordsValidationStatus,
  setAuthInfo,
  setAuthPopupContent,
} from '@renderer/js/components/auth/redux/actions';
import {selectisRisksScannerOpened} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {
  getEmail,
  getSignupPassword,
  getSignupRePassword,
  getSignUpErrors,
} from '@renderer/js/components/auth/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {AuthPopupSignupContentComponent} from './auth-popup-signup-content.component';

const mapStateToProps = (state: AppState) => ({
  email: getEmail(state),
  signupPassword: getSignupPassword(state),
  signupRePassword: getSignupRePassword(state),
  signUpErrors: getSignUpErrors(state),
  isRisksScannerOpened: selectisRisksScannerOpened(state),
});

export const AuthPopupSignUpContentContainer = connect(mapStateToProps, {
  setAuthInfo,
  signupSubmit,
  resetSignupEmailValidationStatus,
  resetSignupPasswordsValidationStatus,
  setAuthPopupContent,
  sendTelemetryEvent,
})(AuthPopupSignupContentComponent);
