import React from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, FigleafGradientLogoComponent} from '@figleafteam/components';
import {openURL} from '@common/compatibility/openURL';
import {EVENTS} from '@common/events';

import {AboutProps} from './about.types';
import './_about.scss';

export const AboutComponent: React.FC<AboutProps> = props => {
  const {t} = useTranslation();

  const handleUpdate = () => {
    props.sendTelemetryEvent({name: EVENTS.SETTINGS.UPDATE_APP_BUTTON_CLICK, source: 'user'});
    props.performUpdate();
  };

  return (
    <div className="about">
      <div className="about__content">
        <div className="about__logo">
          <FigleafGradientLogoComponent />
        </div>
        <h2 className="about__title">{t('{{partner}}', {partner: 'FigLeaf'})}</h2>
        <p className="about__version">{t('Version {{version}}', {version: VERSION})}</p>
        <div className="about__version-status">
          {props.updateInfo.isUpdateAvailable ? (
            <>
              <h5 className="about__version-update-text">{t('New version is available')}</h5>
              <ButtonComponent type="primary" onClick={handleUpdate} text={t('Update now')} />
            </>
          ) : (
            <p className="about__version-text">{t('You’re using the latest FigLeaf version.')}</p>
          )}
        </div>
      </div>
      <div className="about__footer">
        <p className="about__copy">{t('© {{year}} Aura Company All Rights Reserved', {year: '2021'})}</p>
        <div className="about__policy-links">
          <div className="about__link">
            <ButtonComponent
              type="link"
              onClick={() => openURL('https://figleaf.com/privacy-policy/')}
              text={t('Privacy Policy')}
            />
          </div>
          <div className="about__link">
            <ButtonComponent
              type="link"
              onClick={() => openURL('https://figleaf.com/terms-of-service/')}
              text={t('Terms of Service')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
