import {RESET_DETAILS_STATE, SET_DETAILS_STATE} from './constants';
import {TDetailsState} from './types';

export const setDetailsState = (payload: Partial<TDetailsState>) => ({
  type: SET_DETAILS_STATE,
  payload,
});

export const resetDetailsState = () => ({
  type: RESET_DETAILS_STATE,
  payload: {},
});
