import {connect} from 'react-redux';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {setDetailsState} from '@renderer/js/components/details/redux/actions';
import {AppState} from '@renderer/js/redux/rootReducer';
import {selectIsPpiFlowFinished} from '@renderer/js/components/settings/redux/selectors';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {DashboardComponent} from './dashboard.component';

const mapStateToProps = (state: AppState) => ({
  appInfo: getAppInfo(state),
  isPpiFlowFinished: selectIsPpiFlowFinished(state),
});
const mapDispatchToProps = {
  sendTelemetryEvent,
  setDetailsState,
};

export const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
