import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  setConfirmDeviceCode,
  requestConfirmDeviceCode,
  setResendCodeTime,
  setAuthPopupContent,
} from '@renderer/js/components/auth/redux/actions';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {getEmail, getConfirmDeviceCode, getResendCodeTime} from '@renderer/js/components/auth/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {AuthPopupConfirmDeviceComponent} from './auth-popup-confirm-device.component';

const mapStateToProps = (state: AppState) => ({
  email: getEmail(state),
  code: getConfirmDeviceCode(state),
  resendTime: getResendCodeTime(state),
  appInfo: getAppInfo(state),
});

export const AuthPopupConfirmDeviceContainer = connect(mapStateToProps, {
  setConfirmDeviceCode,
  requestConfirmDeviceCode,
  setResendCodeTime,
  setAuthPopupContent,
  sendTelemetryEvent,
})(AuthPopupConfirmDeviceComponent);
