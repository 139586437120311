import {connect} from 'react-redux';
import {getLicenseInfoAction} from '@figleafteam/store';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  getBrowsersConfigState,
  getTrackersBlockedInBrowserState,
} from '@renderer/js/components/private-browsing/redux/selectors';
import {getInstalledBrowsersReport} from '@renderer/js/components/settings/redux/selectors';
import {
  getBrowserConfig,
  updateBrowserConfig,
  getTrackersBlockedInBrowser,
} from '@renderer/js/components/private-browsing/redux/actions';
import {openWebExtensionStore, getBrowsersReport} from '@renderer/js/components/settings/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {BrowserItemComponent} from './browser-item.component';

const mapStateToProps = (state: AppState) => ({
  browsersReport: getInstalledBrowsersReport(state),
  browsersConfig: getBrowsersConfigState(state),
  trackersBlockedInBrowser: getTrackersBlockedInBrowserState(state),
});
const mapDispatchToProps = {
  getBrowsersReport,
  openWebExtensionStore,
  getLicenseInfoAction,
  sendTelemetryEvent,
  getBrowserConfig,
  updateBrowserConfig,
  getTrackersBlockedInBrowser,
};

export const BrowserItemContainer = connect(mapStateToProps, mapDispatchToProps)(BrowserItemComponent);
