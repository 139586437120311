import React, {memo, useEffect, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import {FlagIconComponent, IconComponent, DashboardWidgetComponent} from '@figleafteam/components';
import {EVENTS} from '@common/events';
import {history} from '@renderer/js/router/history';
import {useAuthorization} from '@renderer/js/components/auth/hooks/use-authorization';
import {BrowseWidgetProps} from './browse-widget.types';
import './_browse-widget.scss';
import {formatTrackersAmount} from '../antitracking/components/browser-item/browser-item.component';

export const BrowseWidgetComponent = memo<BrowseWidgetProps>(props => {
  const {
    browsersReport,
    browsersConfig,
    isVpnInfoFetched,
    isVpnEnabled,
    isCountryListFetched,
    isLicenseExpired,
    currentCountryName,
    currentCountryCode,
    currentCountryIp,
    antitrackingStatistics,
    getAntitrackingStatistics,
    getLicenseInfoAction,
    getBrowsersReport,
    getVpnInfo,
    getVpnConfig,
    setPaywallModalState,
    sendTelemetryEvent,
    setDetailsState,
    getBrowserConfig,
  } = props;
  const {t} = useTranslation();
  const {isAuthorized, runIfAuthorized} = useAuthorization();
  const [widgetState, setWidgetState] = useState<'standard' | 'expired' | 'extensions-not-installed'>('standard');

  const browsersWithExtensionsReport = browsersReport
    ? browsersReport.filter(report => report && report.isExtensionSupported && report.extensions.length)
    : [];

  const fetchBrowsersConfig = () => {
    if (browsersReport) {
      browsersWithExtensionsReport.forEach(report => {
        const browserName = report.name;
        if (!browsersConfig || !browsersConfig[browserName]) {
          getBrowserConfig(browserName);
        }
      });
    }
  };

  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    if (!browsersReport) {
      getBrowsersReport();
    }
    if (!isVpnInfoFetched) {
      getVpnInfo();
    }
    if (!isCountryListFetched) {
      getVpnConfig();
    }
    if (!isLicenseExpired) {
      getLicenseInfoAction();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (isAuthorized) {
      getAntitrackingStatistics();
      fetchBrowsersConfig();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (isAuthorized) {
      fetchBrowsersConfig();
    }
  }, [browsersReport, isAuthorized]);

  const browsersWithEnabledAntitracker = browsersWithExtensionsReport.filter(
    report => browsersConfig[report.name]?.isAntiTrackingEnabled,
  );

  const isExtensionInstalled = Boolean(browsersWithExtensionsReport.length);
  const isAntitrackingEnabled = Boolean(browsersWithEnabledAntitracker.length);

  const isDataFetched = !isAuthorized || (isVpnInfoFetched && isLicenseExpired !== null);

  const antitrackingStatsData = [
    {
      title: t('Trackers blocked'),
      value: antitrackingStatistics.total,
    },
    {
      title: t('This month'),
      value: antitrackingStatistics.month,
    },
    {
      title: t('This week'),
      value: antitrackingStatistics.week,
    },
    {
      title: t('Today'),
      value: antitrackingStatistics.today,
    },
  ];

  const config = {
    expired: {
      buttonText: t('Enable privacy controls'),
      onButtonClick: () => setPaywallModalState({isOpen: true}),
    },
    standard: {
      buttonText: t('Open Controls'),
      onButtonClick: () => {
        sendTelemetryEvent({
          name: EVENTS.DASHBOARD.CONTROLS_OPEN_CONTROLS_BUTTON_CLICK,
          source: 'user',
        });
        history.push('/browse');
      },
    },
    'extensions-not-installed': {
      buttonText: t('Add extension now'),
      onButtonClick: () =>
        runIfAuthorized(() => {
          sendTelemetryEvent({
            name: EVENTS.DASHBOARD.CONTROLS_INSTALL_EXTENSION_BUTTON_CLICK,
            source: 'user',
          });
          history.push('/browse');
          setDetailsState({isOnboardingOpened: true});
        }),
    },
  };

  useEffect(() => {
    if (isLicenseExpired) {
      setWidgetState('expired');
      return;
    }
    setWidgetState(isExtensionInstalled ? 'standard' : 'extensions-not-installed');
  }, [isLicenseExpired, isExtensionInstalled]);

  return (
    <DashboardWidgetComponent
      headerTitle={t('Controls')}
      headerIcon={<IconComponent name="control" size={24} />}
      tooltipText={
        ENABLED_FEATURES.UiFeatures.Vpn
          ? t(
              'Hide your location and encrypt traffic with a VPN connection, and eliminate advertising trackers in browsers with Anti-tracking',
            )
          : t('Make it hard for sites to spy on you by eliminating advertising trackers in your browsers')
      }
      isLoading={!isDataFetched}
      buttonText={config[widgetState].buttonText}
      onButtonClick={config[widgetState].onButtonClick}
    >
      <div
        className={classNames('browse-widget__content', {
          'browse-widget__content--single-column': !isDataFetched || !ENABLED_FEATURES.UiFeatures.Vpn,
        })}
      >
        {ENABLED_FEATURES.UiFeatures.Vpn ? (
          <>
            <div className="browse-widget__col">
              <h4 className="browse-widget__title">
                {t('Anti-tracking')}
                <br />
                {isAntitrackingEnabled ? (
                  <span className="browse-widget__feature-status">{t('enabled')}</span>
                ) : (
                  <span className="browse-widget__feature-status browse-widget__feature-status--disabled">
                    {t('disabled')}
                  </span>
                )}
              </h4>
              {isLicenseExpired && (
                <p className="browse-widget__text">
                  {t('Eliminate advertising trackers to make it hard to spy on you')}
                </p>
              )}
              {isExtensionInstalled && !isLicenseExpired && (
                <div className="browse-widget__anti-tracking-status">
                  <IconComponent name="check" size={20} />
                  <p className="browse-widget__trackers-blocked">
                    {t('{{count}} tracker blocked', {count: antitrackingStatistics.total})}
                  </p>
                </div>
              )}
              {!isExtensionInstalled && !isLicenseExpired && (
                <p className="browse-widget__text">
                  {t('Install browser extension to hide from trackers and advertisers')}
                </p>
              )}
            </div>
            <div className="browse-widget__col">
              <h4 className="browse-widget__title">
                {t('VPN')} <br />
                {isVpnEnabled ? (
                  <span className="browse-widget__feature-status">{t('connected')}</span>
                ) : (
                  <span className="browse-widget__feature-status browse-widget__feature-status--disabled">
                    {t('disconnected')}
                  </span>
                )}
              </h4>
              {isLicenseExpired && (
                <p className="browse-widget__text">{t('Hide your location and encrypt data with VPN.')}</p>
              )}
              {isVpnEnabled && !isLicenseExpired && (
                <div className="browse-widget__vpn-status">
                  <FlagIconComponent countryCode={currentCountryCode || ''} />
                  <p className="browse-widget__vpn-country">{currentCountryName || t('Unknown')}:</p>
                  <p className="browse-widget__vpn-ip">{currentCountryIp || ''}</p>
                </div>
              )}
              {!isVpnEnabled && !isLicenseExpired && (
                <p className="browse-widget__text">{t('Connect to VPN to hide your location and encrypt your data')}</p>
              )}
            </div>
          </>
        ) : (
          <div className="browse-widget__antitracking-control">
            <h4 className="browse-widget__title">
              {isExtensionInstalled ? (
                <>
                  <span>{t('Tracking protection')}</span>{' '}
                  <span className="browse-widget__feature-status">
                    {isAntitrackingEnabled ? t('enabled') : t('disabled')}
                  </span>
                </>
              ) : (
                t('Stop online tracking')
              )}
            </h4>
            {isExtensionInstalled ? (
              <div className="browse-widget__antitracking-statistics">
                {antitrackingStatsData.map((item, index) => (
                  <div className="browse-widget__antitracking-statistics-block" key={index}>
                    <span
                      className={classNames('browse-widget__antitracking-statistics-value', {
                        'browse-widget__antitracking-statistics-value--disabled': !isAntitrackingEnabled,
                      })}
                    >
                      {formatTrackersAmount(item.value)}
                    </span>
                    <div
                      className={classNames('browse-widget__antitracking-statistics-title', {
                        'browse-widget__antitracking-statistics-title--disabled': !isAntitrackingEnabled,
                      })}
                    >
                      {item.title}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="browse-widget__extensions-not-installed">
                {t(
                  'Add our Anti-Tracker extension to Chrome, Firefox or Edge to eliminate advertising trackers from sites you visit.',
                )}
              </p>
            )}
          </div>
        )}
      </div>
    </DashboardWidgetComponent>
  );
});

BrowseWidgetComponent.displayName = 'BrowseWidgetComponent';
