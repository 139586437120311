import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';
import {TUserProfileState, TUpdateUserProfileAction} from '../types';
import {UPDATE_USER_PROFILE} from '../constants';

const initialState: TUserProfileState = {
  profile: null,
};

type TActionsType = TUpdateUserProfileAction | TLogOutSuccessAction;

export const userProfileReducer = (state = initialState, action: TActionsType) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE:
      return {
        profile: action.payload.userProfile,
      };
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
