import {TSaveRecoveryKeyModalState} from '@renderer/js/components/save-recovery-key/redux/types';
import {
  SET_RECOVERY_KEY_MODAL_STATE,
  GET_RECOVERY_KEY,
  SAVE_RECOVERY_KEY,
  SET_RECOVERY_KEY_SAVED_FLAG,
} from './constants';

export const setRecoveryKeyModalState = (payload: Partial<TSaveRecoveryKeyModalState>) => ({
  type: SET_RECOVERY_KEY_MODAL_STATE,
  payload,
});

export const getRecoveryKey = () => ({
  type: GET_RECOVERY_KEY,
  payload: {},
});

export const saveRecoveryKey = () => ({
  type: SAVE_RECOVERY_KEY,
  payload: {},
});

export const setRecoveryKeySavedFlag = () => ({
  type: SET_RECOVERY_KEY_SAVED_FLAG,
  payload: {},
});
