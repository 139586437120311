import * as Sentry from '@sentry/electron';
import {logger} from '@common/compatibility/logger';

export function startCrashReporter() {
  if (TARGET === 'Debug' || SENTRY_DSN.length === 0 || SENTRY_RELEASE.length === 0) {
    return logger.warn('Crash reporter initialization skipped.');
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    release: SENTRY_RELEASE,
    beforeSend: event => {
      if (event.user) {
        delete event.user;
      }
      return event;
    },
  });
}
