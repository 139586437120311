import {createStore, applyMiddleware, Middleware} from 'redux';
import {createLogger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import {initServices} from '@figleafteam/store';
import messenger from '@renderer/js/api/communication/messenger';
import {history} from '@renderer/js/router/history';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {rootReducer} from './rootReducer';
import {rootSaga} from './rootSaga';
import {persistPpiStore} from '../components/ppi-funnel/redux/persist-store-middleware';
import {addOnMessageHandlers} from '../api/communication/onMessageHandler';
import {handleDeepLink} from '../api/communication/deepLinkHandler';

// TODO: remove timeout
setTimeout(() => {
  initServices({messenger, history, notificationService: notificationService});
}, 0);

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger({
  collapsed: true,
});

const middlewares: Array<Middleware<any, any, any>> = [sagaMiddleware, persistPpiStore];

if (TARGET === 'Debug') {
  middlewares.push(loggerMiddleware);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export type StoreType = typeof store;

sagaMiddleware.run(rootSaga);

addOnMessageHandlers(store, handleDeepLink);
