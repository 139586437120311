import {TSettingsInfoState, TPromoCodeModalState} from '@renderer/js/components/settings/redux/types';
import {
  GET_BROWSERS_REPORT,
  SET_BROWSERS_REPORT,
  OPEN_WEB_EXTENSION_STORE,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  CHANGE_ANALYTIC_STATUS,
  SET_ANALYTIC_STATUS,
  IMPORT_ACCOUNTS,
  IMPORT_ACCOUNTS_PPI,
  GET_NOTIFICATIONS_STATUS,
  SET_NOTIFICATIONS_STATUS,
  GET_USER_LOGIN,
  SET_USER_LOGIN,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_MODAL_STATE,
  CHANGE_NOTIFICATIONS_STATUS,
  LOCK_USER,
  SET_UPDATE_INFO,
  GET_IS_UPDATE_AVAILABLE,
  PERFORM_UPDATE,
  SET_INTERNET_CONNECTION_STATUS,
  CHECK_INTERNET_CONNNETION_STATUS,
  SET_IS_NEED_TO_CHECK_INTERNET_CONNECTION_STATUS,
  SET_NO_INTERNET_NOTIFICATION_OPEN_STATE,
  OPEN_SUPPORT_LINK,
  CHANGE_PROMO_CODE_MODAL,
  APPLY_PROMO_CODE,
  OPEN_BUY_NOW,
} from './constants';

export const getBrowsersReport = () => ({
  type: GET_BROWSERS_REPORT,
  payload: {},
});

export const setBrowsersReport = (installedBrowsers: BrowsersReport, supportedBrowsers: Array<Browsers>) => ({
  type: SET_BROWSERS_REPORT,
  payload: {
    installedBrowsers,
    supportedBrowsers,
  },
});

export const openWebExtensionStore = (browser: Browsers) => ({
  type: OPEN_WEB_EXTENSION_STORE,
  payload: {
    browser,
  },
});

export const getUserProfileAction = () => ({
  type: GET_USER_PROFILE,
  payload: {},
});

export const updateUserProfile = (userProfile: UserProfile) => ({
  type: UPDATE_USER_PROFILE,
  payload: {
    userProfile,
  },
});

export const changeAnalyticStatus = () => ({
  type: CHANGE_ANALYTIC_STATUS,
  payload: {},
});

export const setAnalyticStatus = (isEnabled: boolean) => ({
  type: SET_ANALYTIC_STATUS,
  payload: {
    isEnabled,
  },
});

export const importAccounts = (browsers: Array<Browsers>) => ({
  type: IMPORT_ACCOUNTS,
  payload: {
    browsers,
  },
});

export const importAccountsPPI = (browsers: Array<Browsers>) => ({
  type: IMPORT_ACCOUNTS_PPI,
  payload: {
    browsers,
  },
});

export const getNotificationsStatusAction = () => ({
  type: GET_NOTIFICATIONS_STATUS,
  payload: {},
});

export const changeNotificationsStatus = (notificationsStatus: TSettingsInfoState['notificationsStatus']) => ({
  type: CHANGE_NOTIFICATIONS_STATUS,
  payload: {
    notificationsStatus,
  },
});

export const setNotificationsStatus = (notificationsStatus: TSettingsInfoState['notificationsStatus']) => ({
  type: SET_NOTIFICATIONS_STATUS,
  payload: {
    notificationsStatus,
  },
});

export const getUserLogin = () => ({
  type: GET_USER_LOGIN,
  payload: {},
});

export const setUserLogin = (payload: {login: string}) => ({
  type: SET_USER_LOGIN,
  payload,
});

export const changePassword = () => ({
  type: CHANGE_PASSWORD,
  payload: {},
});

export const changePasswordModalState = (
  payload: Partial<{
    isOpen: boolean;
    password: string;
    rePassword: string;
    passwordErrorText: string;
    rePasswordErrorText: string;
  }>,
) => ({
  type: CHANGE_PASSWORD_MODAL_STATE,
  payload,
});

export const lockUser = () => ({
  type: LOCK_USER,
  payload: {},
});

export const setUpdateInfo = (updateInfo: Partial<TSettingsInfoState['updateInfo']>) => ({
  type: SET_UPDATE_INFO,
  payload: {
    updateInfo,
  },
});

export const getIsUpdateAvailable = () => ({
  type: GET_IS_UPDATE_AVAILABLE,
  payload: {},
});

export const performUpdate = () => ({
  type: PERFORM_UPDATE,
  payload: {},
});

export const setInternetConnectionStatus = (isInternetConnected: boolean) => ({
  type: SET_INTERNET_CONNECTION_STATUS,
  payload: {
    isInternetConnected,
  },
});

export const checkInternetConnectionStatus = () => ({
  type: CHECK_INTERNET_CONNNETION_STATUS,
  payload: {},
});

export const setIsNeedToCheckInternetConnectionStatus = (isNeedToCheckInternetStatus: boolean) => ({
  type: SET_IS_NEED_TO_CHECK_INTERNET_CONNECTION_STATUS,
  payload: {
    isNeedToCheckInternetStatus,
  },
});

export const setNoInternetNotificationOpenState = (isOpen: boolean) => ({
  type: SET_NO_INTERNET_NOTIFICATION_OPEN_STATE,
  payload: {
    isOpen,
  },
});

export const openSupportLink = (issueType: 'deleteAccount' | 'default' = 'default') => ({
  type: OPEN_SUPPORT_LINK,
  payload: {
    issueType,
  },
});

export const changePromoCodeModalAction = (payload: Partial<TPromoCodeModalState>) => ({
  type: CHANGE_PROMO_CODE_MODAL,
  payload,
});

export const applyPromoCodeAction = () => ({
  type: APPLY_PROMO_CODE,
  payload: {},
});

export const openBuyNow = (campaign = 'direct', set = 'default') => ({
  type: OPEN_BUY_NOW,
  payload: {
    campaign,
    set,
  },
});
