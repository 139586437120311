import {call, select, takeEvery} from 'redux-saga/effects';
import {sendTelemetryEvent} from '@figleafteam/api-communication';
import {SEND_TELEMETRY_EVENT} from './constants';
import {TEventsDefaultConfig, TSendTelemetryEventAction} from './types';
import {selectEventsConfig} from './selectors';

export function* sendTelemetryEventSaga(action: TSendTelemetryEventAction) {
  const {productVersion, eventContext}: TEventsDefaultConfig = yield select(selectEventsConfig);
  const {event, immediately} = action.payload;

  try {
    yield call(sendTelemetryEvent, {...event, productVersion, context: eventContext}, immediately);
  } catch (e) {
    console.error(e);
  }
}

export function* eventsWatcher() {
  yield takeEvery(SEND_TELEMETRY_EVENT, sendTelemetryEventSaga);
}
