import React, {FC, ReactNode, useEffect} from 'react';
import {useTranslation, Trans} from 'react-i18next';

import {ButtonComponent, HeadingComponent} from '@figleafteam/components';
import './_header.scss';

import MaskEmailImage from '@renderer/images/illustrations/onboarding/vaults/mask-email-image.webp';
import MaskEmailImage2x from '@renderer/images/illustrations/onboarding/vaults/mask-email-image@2x.webp';
import MaskEmailImage3x from '@renderer/images/illustrations/onboarding/vaults/mask-email-image@3x.webp';

import ZeroKnowledgeImage from '@renderer/images/illustrations/onboarding/vaults/zero-knowledge-image.webp';
import ZeroKnowledgeImage2x from '@renderer/images/illustrations/onboarding/vaults/zero-knowledge-image@2x.webp';
import ZeroKnowledgeImage3x from '@renderer/images/illustrations/onboarding/vaults/zero-knowledge-image@3x.webp';

import AccessYourDataImage from '@renderer/images/illustrations/onboarding/vaults/access-your-data-image.webp';
import AccessYourDataImage2x from '@renderer/images/illustrations/onboarding/vaults/access-your-data-image@2x.webp';
import AccessYourDataImage3x from '@renderer/images/illustrations/onboarding/vaults/access-your-data-image@3x.webp';

import {EVENTS} from '@common/events';
import {isImportAccountsFromBrowser} from '@renderer/js/utils/is-import-accounts-from-browser';
import {DetailsContainer} from '@renderer/js/components/details/components/details-component/details-control.container';
import {HeaderProps} from './header.types';

export const HeaderComponent: FC<HeaderProps> = props => {
  const {t} = useTranslation();

  useEffect(() => {
    props.sendTelemetryEvent({name: EVENTS.VAULT.VAULT_SCREEN_SHOW, source: 'app'});
    if (!props.browsersReport) {
      props.getBrowsersReport();
    }
    return () => {
      props.resetDetailsState();
    };
  }, []);

  const onboardingSteps = [
    {
      title: t('Keep your data safe'),
      description: (
        <Trans i18nKey="Keep your account logins, passwords and emails in our secure storage...">
          Keep your account logins, passwords and emails in our secure storage. With our &quot;Zero Knowledge&quot;
          promise, <strong>no one can access your data, not even us.</strong>
        </Trans>
      ),
      img: (
        <img
          src={ZeroKnowledgeImage}
          srcSet={`${ZeroKnowledgeImage2x} 2x, ${ZeroKnowledgeImage3x} 3x`}
          alt="Keep your Data Safe"
        />
      ),
    },
    {
      title: t('Mask your email'),
      description: (
        <Trans i18nKey="Generate a unique Masked Email that will be connected to your real email...">
          Generate a unique Masked Email that will be connected to your real email. Use it when signing up for new
          accounts or newsletters. If your Masked Email gets leaked or sold for any reason, your{' '}
          <strong>real email remains protected from abuse</strong>.
        </Trans>
      ),
      img: <img src={MaskEmailImage} srcSet={`${MaskEmailImage2x} 2x, ${MaskEmailImage3x} 3x`} alt="Mask your email" />,
    },
    {
      title: t('Access your data in a snap'),
      description: (
        <Trans i18nKey="Once you add accounts to your Vault...">
          Once you add accounts to your Vault, add a FigLeaf extension to your browser to quickly and securely{' '}
          <strong>sign in to your accounts with a single click</strong>. Available for Chrome, FireFox and Edge.
        </Trans>
      ),
      img: (
        <img
          src={AccessYourDataImage}
          srcSet={`${AccessYourDataImage2x} 2x, ${AccessYourDataImage3x} 3x`}
          alt="Access Your Data in a snap"
        />
      ),
    },
  ];

  const isInstalledBrowsers = isImportAccountsFromBrowser(props.browsersReport);
  const actionButton: null | ReactNode = isInstalledBrowsers ? (
    <ButtonComponent
      onClick={() => {
        props.setDetailsState({isOnboardingOpened: false});
        props.toggleImportModalOpen(true);
      }}
      text={t('Import accounts from browsers')}
      type="primary"
      fullWidth
    />
  ) : null;
  return (
    <HeadingComponent
      title={t('Vault')}
      details={
        <DetailsContainer
          onboardingModifier="vault"
          onboardingSteps={onboardingSteps}
          detailsKey="vault"
          onboardingFinishButton={!props.isAuthorized || props.accountsNumber.all > 1 ? null : actionButton}
        />
      }
    />
  );
};
