import {
  AuthState,
  TClearAuthInfoAction,
  TCreateLoginErrorsAction,
  TCreateSignUpErrorsAction,
  TLoginErrors,
  TResetLoginEmailErrorsAction,
  TResetLoginPasswordsErrorsAction,
  TResetSignUpEmailErrorsAction,
  TResetSignUpPasswordsErrorsAction,
  TSetAuthInfoAction,
  TSetResendCodeTimeAction,
  TSetConfirmAccountCodeAction,
  TSetConfirmDeviceCodeAction,
  TSignUpErrors,
  TSetAuthPopupOpenedAction,
  TSetAuthPopupContentAction,
} from '@renderer/js/components/auth/redux/types';
import {
  CLEAR_INFO,
  LOGIN_ERROR,
  RESET_LOGIN_EMAIL_VALIDATION,
  RESET_LOGIN_PASSWORD_VALIDATION,
  RESET_SIGNUP_EMAIL_VALIDATION,
  RESET_SIGNUP_PASSWORD_VALIDATION,
  SET_AUTH_DATA,
  SIGNUP_ERROR,
  SET_CONFIRM_ACCOUNT_CODE,
  SET_CONFIRM_DEVICE_CODE,
  SET_RESEND_CODE_TIME,
  SET_AUTH_POPUP_OPENED,
  SET_AUTH_POPUP_CONTENT,
  AuthPopupContents,
} from '@renderer/js/components/auth/redux/constants';
import {initialErrors as initialSignUpErrors} from '@renderer/js/components/auth/utils/getErrorSignupCredentialsValid';
import {initialErrors as initialLoginErrors} from '@renderer/js/components/auth/utils/getErrorLoginCredentialsValid';

const signUpErrors: TSignUpErrors = {...initialSignUpErrors};

const loginErrors: TLoginErrors = {...initialLoginErrors};

const initialState: AuthState = {
  signUpErrors,
  loginErrors,

  isAuthPopupOpened: false,
  authPopupContent: AuthPopupContents.SIGNUP,
  email: '',
  signupPassword: '',
  signupRePassword: '',
  loginPassword: '',
  confirmAccountCode: '',
  confirmDeviceCode: '',
  resendCodeTime: 120,
};

type Actions =
  | TClearAuthInfoAction
  | TSetAuthInfoAction
  | TCreateSignUpErrorsAction
  | TResetSignUpEmailErrorsAction
  | TResetSignUpPasswordsErrorsAction
  | TResetLoginEmailErrorsAction
  | TResetLoginPasswordsErrorsAction
  | TCreateLoginErrorsAction
  | TSetResendCodeTimeAction
  | TSetConfirmAccountCodeAction
  | TSetConfirmDeviceCodeAction
  | TSetAuthPopupOpenedAction
  | TSetAuthPopupContentAction;

export const authStateReducer = (state: AuthState = initialState, action: Actions): AuthState => {
  switch (action.type) {
    case SET_AUTH_DATA:
      return {...state, [action.payload.key]: action.payload.value};
    case CLEAR_INFO:
      return {
        ...initialState,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        signUpErrors: action.payload.signUpErrors,
      };
    case RESET_SIGNUP_EMAIL_VALIDATION:
      return {
        ...state,
        signUpErrors: {
          ...state.signUpErrors,
          emailInvalid: false,
          emailErrorText: '',
        },
      };
    case RESET_SIGNUP_PASSWORD_VALIDATION:
      return {
        ...state,
        signUpErrors: {
          ...state.signUpErrors,
          passwordInvalid: false,
          rePasswordInvalid: false,
          passwordErrorText: '',
          rePasswordErrorText: '',
        },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginErrors: action.payload.loginErrors,
      };
    case RESET_LOGIN_EMAIL_VALIDATION:
      return {
        ...state,
        loginErrors: {
          ...state.loginErrors,
          emailInvalid: false,
          emailErrorText: '',
        },
      };
    case RESET_LOGIN_PASSWORD_VALIDATION:
      return {
        ...state,
        loginErrors: {
          ...state.loginErrors,
          passwordInvalid: false,
          passwordErrorText: '',
        },
      };
    case SET_RESEND_CODE_TIME: {
      return {
        ...state,
        resendCodeTime: action.payload.seconds,
      };
    }
    case SET_CONFIRM_ACCOUNT_CODE: {
      return {
        ...state,
        confirmAccountCode: action.payload.code,
      };
    }
    case SET_CONFIRM_DEVICE_CODE: {
      return {
        ...state,
        confirmDeviceCode: action.payload.code,
      };
    }

    case SET_AUTH_POPUP_OPENED: {
      return {
        ...state,
        authPopupContent: AuthPopupContents.SIGNUP,
        isAuthPopupOpened: action.payload.isOpened,
      };
    }

    case SET_AUTH_POPUP_CONTENT:
      return {
        ...state,
        authPopupContent: action.payload.authPopupContent,
      };
    default:
      return state;
  }
};
