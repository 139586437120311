import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  getAntitrackingStatus,
  getBrowsersConfigState,
  getAntitrackingWhitelist,
} from '@renderer/js/components/private-browsing/redux/selectors';
import {getInstalledBrowsersReport, getSupportedBrowsers} from '@renderer/js/components/settings/redux/selectors';
import {getAntitrackingConfig} from '@renderer/js/components/private-browsing/redux/actions';
import {openWebExtensionStore, getBrowsersReport} from '@renderer/js/components/settings/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {AntitrackingComponent} from './antitracking.component';

const mapStateToProps = (state: AppState) => ({
  antitrackingStatus: getAntitrackingStatus(state),
  browsersReport: getInstalledBrowsersReport(state),
  supportedBrowsers: getSupportedBrowsers(state),
  browsersConfig: getBrowsersConfigState(state),
  whitelist: getAntitrackingWhitelist(state),
});
const mapDispatchToProps = {
  getBrowsersReport,
  openWebExtensionStore,
  sendTelemetryEvent,
  getAntitrackingConfig,
};

export const AntitrackingContainer = connect(mapStateToProps, mapDispatchToProps)(AntitrackingComponent);
