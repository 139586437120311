import {connect} from 'react-redux';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getVpnConnectionStatus, selectIsVpnModalOpened} from '@renderer/js/components/private-browsing/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {setIsVpnModalOpened} from '@renderer/js/components/private-browsing/redux/actions';
import {selectHiddenRisks, selectResolvedRisks} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {setResolvedRisk} from '@renderer/js/components/ppi-funnel/redux/actions';
import {VPNModalComponent} from './vpn-modal.component';

const mapStateToProps = (state: AppState) => ({
  appInfo: getAppInfo(state),
  resolvedRisks: selectResolvedRisks(state),
  hiddenRisks: selectHiddenRisks(state),
  isVpnEnabled: getVpnConnectionStatus(state),
  isModalOpened: selectIsVpnModalOpened(state),
});

const mapDispatchToProps = {
  sendTelemetryEvent,
  setResolvedRisk,
  setIsVpnModalOpened,
};

export const VPNModalContainer = connect(mapStateToProps, mapDispatchToProps)(VPNModalComponent);
