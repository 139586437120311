import {i18n} from '@figleafteam/helpers';

import {TSignUpErrors} from '@renderer/js/components/auth/redux/types';
import {isEmailInvalid} from './isEmailInvalid';
import {isWeakPassword} from './isWeakPassword';

export const initialErrors: TSignUpErrors = {
  // fields has error
  emailInvalid: false,
  passwordInvalid: false,
  rePasswordInvalid: false,
  // fields error texts
  emailErrorText: '',
  passwordErrorText: '',
  rePasswordErrorText: '',
};

export function getErrorSignupCredentialsValid(email: string, password: string, rePassword: string) {
  const errors = {...initialErrors};

  if (isEmailInvalid(email)) {
    errors.emailInvalid = true;
    errors.emailErrorText = i18n.t("Your email doesn't look right");
  }
  if (isWeakPassword(password)) {
    errors.passwordInvalid = true;
    errors.rePasswordInvalid = true;
    errors.passwordErrorText = i18n.t("Your password isn't strong enough");
    errors.rePasswordErrorText = i18n.t("Your password isn't strong enough");
  }
  if (email.length === 0) {
    errors.emailInvalid = true;
    errors.emailErrorText = i18n.t('Please fill email field');
  }
  if (password.length === 0) {
    errors.passwordInvalid = true;
    errors.passwordErrorText = i18n.t('Please fill password field');
  }
  if (rePassword.length === 0) {
    errors.rePasswordInvalid = true;
    errors.rePasswordErrorText = i18n.t('Please fill confirm password field');
  }
  if (password !== rePassword) {
    errors.rePasswordInvalid = true;
    errors.rePasswordErrorText = i18n.t("Passwords don't match");
  }

  return errors;
}
