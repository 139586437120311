import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  loginSubmit,
  setAuthInfo,
  resetLoginEmailValidationStatus,
  resetLoginPasswordsValidationStatus,
  setAuthPopupContent,
  setAuthPopupOpened,
} from '@renderer/js/components/auth/redux/actions';
import {setSavePasswordStatus} from '@renderer/js/components/unlock-user/redux/actions';
import {getEmail, getLoginPassword, getLoginErrors} from '@renderer/js/components/auth/redux/selectors';
import {getIsSavePassword} from '@renderer/js/components/unlock-user/redux/selectors';
import {setRecoveryStatus} from '@renderer/js/components/recovery/redux/actions';
import {getIsMigrationRequired} from '@renderer/js/components/enter-security-key/redux/selectors';
import {setIsMigrationRequired} from '@renderer/js/components/enter-security-key/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {openSupportLink} from '@renderer/js/components/settings/redux/actions';
import {AuthPopupLoginContentComponent} from './auth-popup-login-content.component';

const mapStateToProps = (state: AppState) => ({
  email: getEmail(state),
  loginPassword: getLoginPassword(state),
  loginErrors: getLoginErrors(state),
  savePassword: getIsSavePassword(state),
  isMigrationRequired: getIsMigrationRequired(state),
});

export const AuthPopupLoginContentContainer = connect(mapStateToProps, {
  setAuthInfo,
  loginSubmit,
  setRecoveryStatus,
  setSavePasswordStatus,
  resetLoginEmailValidationStatus,
  resetLoginPasswordsValidationStatus,
  setIsMigrationRequired,
  setAuthPopupOpened,
  setAuthPopupContent,
  sendTelemetryEvent,
  openSupportLink,
})(AuthPopupLoginContentComponent);
