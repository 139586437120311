import React, {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {DashboardWidgetComponent, IconComponent} from '@figleafteam/components';
import './_tips-widget.scss';
import {TipsWidgetProps} from '@renderer/js/components/dashboard/components/tips/tips-widget.types';
import {tips} from '../../redux/tips';

const fallbackTip = tips[0];

export const TipsWidgetComponent = memo<TipsWidgetProps>(props => {
  const {t} = useTranslation();

  useEffect(() => {
    if (!props.tip && props.isAuthorized) {
      props.getTip();
    }
  }, [props.isAuthorized]);

  return (
    <DashboardWidgetComponent
      headerIcon={<IconComponent name="book" size={24} />}
      headerTitle={t('Today’s tip')}
      isLoading={false}
    >
      <div className="tips-widget__body">
        <div className="tips-widget__body-title">{props.tip ? props.tip.title : fallbackTip.title}</div>
        <div className="tips-widget__body-text">{props.tip ? props.tip.describe : fallbackTip.describe}</div>
      </div>
    </DashboardWidgetComponent>
  );
});

TipsWidgetComponent.displayName = 'TipsWidgetComponent';
