import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalComponent} from '@figleafteam/components';
import {AuthPopupContents} from '@renderer/js/components/auth/redux/constants';
import {AuthPopupSignUpContentContainer} from '@renderer/js/components/auth/components/auth-popup-signup-content/auth-popup-signup-content.container';
import {AuthPopupLoginContentContainer} from '@renderer/js/components/auth/components/auth-popup-login-content/auth-popup-login-content.container';
import {AuthPopupConfirmAccountContainer} from '@renderer/js/components/auth/components/auth-popup-confirm-account/auth-popup-confirm-account.container';
import {AuthPopupConfirmDeviceContainer} from '@renderer/js/components/auth/components/auth-popup-confirm-device/auth-popup-confirm-device.container';
import LogoSvg from '@renderer/images/logo.svg';
import {TRIAL_LENGTH} from '@renderer/js/constants';
import {TAuthPopupProps} from './auth-popup.types';
import IconCheckedPersonPath from './images/path-file/icon-checked-person.svg';
import IconGreenBoxPath from './images/path-file/icon-green-box-opened.svg';

import './_auth-popup.scss';

export const AuthPopupComponent = ({isAuthPopupOpened, setAuthPopupOpened, authPopupContent}: TAuthPopupProps) => {
  const {t} = useTranslation();

  const AuthPopupContent = (() => {
    switch (authPopupContent) {
      case AuthPopupContents.LOGIN:
        return AuthPopupLoginContentContainer;

      case AuthPopupContents.CONFIRM_ACCOUNT:
        return AuthPopupConfirmAccountContainer;

      case AuthPopupContents.CONFIRM_DEVICE:
        return AuthPopupConfirmDeviceContainer;

      case AuthPopupContents.SIGNUP:
      default:
        return AuthPopupSignUpContentContainer;
    }
  })();

  return (
    <ModalComponent
      width={820}
      isOpen={isAuthPopupOpened}
      handleClose={() => setAuthPopupOpened(false)}
      showCloseButton
    >
      <div className="auth-popup">
        <div className="auth-popup__left">
          <div className="auth-popup__logo">
            <LogoSvg />
          </div>
          <AuthPopupContent />
        </div>
        <div className="auth-popup__right">
          <div className="auth-popup__tile-list">
            <figure className="auth-popup__tile">
              <div className="auth-popup__tile-image">
                <img src={IconCheckedPersonPath} alt="Decrease all privacy risks" />
              </div>
              <figcaption className="auth-popup__tile-title">{t('Decrease all privacy risks')}</figcaption>
            </figure>
            <figure className="auth-popup__tile">
              <div className="auth-popup__tile-image">
                <img src={IconGreenBoxPath} alt={`Start ${TRIAL_LENGTH}-day free trial`} />
              </div>
              <figcaption className="auth-popup__tile-title">
                {t('Start {{period}}-day free trial', {period: TRIAL_LENGTH})}
              </figcaption>
            </figure>
          </div>

          <div className="auth-popup__benefits">
            <p className="auth-popup__benefits-title">{t('Discover')}</p>
            <ul className="auth-popup__benefits-list">
              <li className="auth-popup__benefits-item">{t('See leaked emails and passwords')}</li>
              <li className="auth-popup__benefits-item">{t('Get real-time alerts for new data leaks')}</li>
            </ul>

            <p className="auth-popup__benefits-title">{t('Control')}</p>
            <ul className="auth-popup__benefits-list">
              <li className="auth-popup__benefits-item">{t('Connect privately with VPN')}</li>
              <li className="auth-popup__benefits-item">{t('Block advertising trackers online')}</li>
            </ul>

            <p className="auth-popup__benefits-title">{t('Privatize')}</p>
            <ul className="auth-popup__benefits-list">
              <li className="auth-popup__benefits-item">{t('Sign up privately with email masking')}</li>
              <li className="auth-popup__benefits-item">{t('Save credentials to a secure vault')}</li>
            </ul>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};
