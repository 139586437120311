import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getAccountInfo} from '@renderer/js/components/settings/redux/selectors';
import {openSupportLink} from '@renderer/js/components/settings/redux/actions';
import {
  setRecoveryKey,
  setRecoveryEmail,
  requestRecoveryCode,
  resetRecoveryKeyError,
  setRecoveryStatus,
} from '@renderer/js/components/recovery/redux/actions';
import {getRecoveryEmail, getRecoveryKey, getRecoveryKeyErrors} from '@renderer/js/components/recovery/redux/selectors';
import {EnterRecoveryKeyComponent} from './enter-recovery-key.component';

const mapStateToProps = (state: AppState) => ({
  accountInfo: getAccountInfo(state),
  email: getRecoveryEmail(state),
  recoveryKey: getRecoveryKey(state),
  recoveryKeyErrors: getRecoveryKeyErrors(state),
});
const mapDispatchToProps = {
  setRecoveryKey,
  setRecoveryEmail,
  requestRecoveryCode,
  resetRecoveryKeyError,
  openSupportLink,
  setRecoveryStatus,
};

export const EnterRecoveryKeyContainer = connect(mapStateToProps, mapDispatchToProps)(EnterRecoveryKeyComponent);
