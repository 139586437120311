import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getBrowsersReport, openWebExtensionStore} from '@renderer/js/components/settings/redux/actions';
import {getInstalledBrowsersReport} from '@renderer/js/components/settings/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {ExtensionsSettingsComponent} from './extensions-settings.component';

const mapStateToProps = (state: AppState) => ({
  browsersReport: getInstalledBrowsersReport(state),
});
const mapDispatchToProps = {
  getBrowsersReport,
  openWebExtensionStore,
  sendTelemetryEvent,
};

export const ExtensionsSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(ExtensionsSettingsComponent);
