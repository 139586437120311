import {connect} from 'react-redux';
import {setAuthPopupContent} from '@figleafteam/store';
import {setPpiFunnelState} from '@renderer/js/components/ppi-funnel/redux/actions';
import {setAuthPopupOpened} from '@renderer/js/components/auth/redux/actions';
import {AppState} from '@renderer/js/redux/rootReducer';
import {selectIsAuthorized} from '@renderer/js/components/app/redux/selectors';
import {changeAnalyticStatus} from '@renderer/js/components/settings/redux/actions';
import {getAnalyticStatus} from '@renderer/js/components/settings/redux/selectors';
import {PPIRisksScannerRunScanBlockComponent} from './ppi-risks-scanner-run-scan-block.component';

const mapStateToProps = (state: AppState) => ({
  isAuthorized: selectIsAuthorized(state),
  isAnalyticEnabled: getAnalyticStatus(state),
});

const mapDispatchToProps = {
  setPpiFunnelState,
  setAuthPopupOpened,
  setAuthPopupContent,
  changeAnalyticStatus,
};

export const PPIRisksScannerRunScanBlockContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PPIRisksScannerRunScanBlockComponent);
