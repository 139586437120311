import {AppState} from '@renderer/js/redux/rootReducer';

export const getVpnConnectionStatus = (state: AppState) => state.privateBrowsingState.vpnStatus.isVpnEnabled;

export const getCountryList = (state: AppState) => state.privateBrowsingState.vpnStatus.countryList;

export const isVpnInfoFetched = (state: AppState) => state.privateBrowsingState.vpnStatus.isVpnInfoFetched;

export const getVpnSessionStartState = (state: AppState) => state.privateBrowsingState.vpnStatus.vpnSessionStart;

export const isCountryListFetched = (state: AppState) => state.privateBrowsingState.vpnStatus.isCountryListFetched;

export const getCurrentCountryCode = (state: AppState) => state.privateBrowsingState.vpnStatus.currentCountryCode;

export const getCurrentCountryName = (state: AppState) => state.privateBrowsingState.vpnStatus.currentCountryName;

export const getCurrentCountryCodeIp = (state: AppState) => state.privateBrowsingState.vpnStatus.currentCountryIp;

export const getVpnInProgressStatus = (state: AppState) => state.privateBrowsingState.vpnStatus.isVpnStatusInProgress;

export const getVpnInProgressStatusText = (state: AppState) =>
  state.privateBrowsingState.vpnStatus.vpnInProgressStatusText;

export const getIsVpnConnectionScheduled = (state: AppState) =>
  state.privateBrowsingState.vpnStatus.isVpnConnectionScheduled;

export const getAntitrackingStatus = (state: AppState) =>
  state.privateBrowsingState.antitrackingStatus.defaultSettings.isAntiTrackingEnabled;

export const getBrowsersConfigState = (state: AppState) => state.privateBrowsingState.antitrackingStatus.browsersConfig;

export const getTrackersBlockedInBrowserState = (state: AppState) =>
  state.privateBrowsingState.antitrackingStatus.trackersBlockedInBrowser;

export const getAntitrackingWhitelist = (state: AppState) =>
  state.privateBrowsingState.antitrackingStatus.antitrackingConfig.whiteList;

export const getAntitrackingStatisticsState = (state: AppState) =>
  state.privateBrowsingState.antitrackingStatus.antitrackingStatistics;

export const selectIsVpnModalOpened = (state: AppState) => state.privateBrowsingState.vpnStatus.isModalOpened;
