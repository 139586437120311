import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {TooltipComponent} from '@figleafteam/components';
import {i18n} from '@figleafteam/helpers';

import classNames from 'classnames';
import {TPrivacyIndicatorProps} from './privacy-indicator.types';

import lowImage from './images/low.png';
import mediumImage from './images/medium.png';
import highImage from './images/high.png';

import lowGradient from './images/gradient-low.png';
import mediumGradient from './images/gradient-medium.png';
import highGradient from './images/gradient-high.png';

import './_privacy-indicator.scss';

const DATA_BY_RISKTYPE = {
  low: {
    image: lowImage,
    gradient: lowGradient,
    get state() {
      return i18n.t('Low');
    },
  },
  medium: {
    image: mediumImage,
    gradient: mediumGradient,
    get state() {
      return i18n.t('Medium');
    },
  },
  high: {
    image: highImage,
    gradient: highGradient,
    get state() {
      return i18n.t('High');
    },
  },
};

export const PrivacyIndicatorComponent: FC<TPrivacyIndicatorProps> = ({riskType, status, size = 'medium'}) => {
  const {t} = useTranslation();

  const data = DATA_BY_RISKTYPE[riskType];

  useEffect(() => {
    [lowImage, lowGradient, highImage, highGradient].map(url => {
      document.createElement('img').src = url;
    });
  }, []);

  return (
    <div className={`privacy-indicator privacy-indicator--size-${size}`}>
      <div className="privacy-indicator__content" style={{backgroundImage: `url(${data.image})`}}>
        {status === 'report' && (
          <div className="privacy-indicator__state">
            <div className="privacy-indicator__title">{data.state}</div>
            <div className="privacy-indicator__sub-title">{t('Privacy risk')}</div>
          </div>
        )}
      </div>
      <img className="privacy-indicator__gradient" src={data.gradient} />

      {status === 'report' && size !== 'small' && (
        <div className="privacy-indicator__help">
          <TooltipComponent
            text={t(
              'We scan your computer and check if your private data was exposed. Please follow our instructions to lower them and protect yourself.',
            )}
            placement="top"
            fullWidth
            modifier="dark"
          >
            {t('How it works')}
          </TooltipComponent>
        </div>
      )}
      {status !== 'report' && (
        <React.Fragment>
          <div className="privacy-indicator__progress">
            <div className="privacy-indicator__progress-dot" />
            <div
              className={classNames('privacy-indicator__progress-hand', {
                'privacy-indicator__progress-hand--move': status === 'scanning',
              })}
            />
          </div>
          <div className="privacy-indicator__text">{status === 'scanning' ? t('Analyzing...') : t('Risk score')} </div>
        </React.Fragment>
      )}
    </div>
  );
};
