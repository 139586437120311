import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  setNewPassword,
  submitNewMasterPassword,
  resetChangePasswordValidation,
  setRecoveryStatus,
} from '@renderer/js/components/recovery/redux/actions';
import {
  getNewPassword,
  getNewRePassword,
  getChangePasswordErrors,
} from '@renderer/js/components/recovery/redux/selectors';
import {ChangePasswordComponent} from './change-password.component';

const mapStateToProps = (state: AppState) => ({
  newPassword: getNewPassword(state),
  newRePassword: getNewRePassword(state),
  changePasswordErrors: getChangePasswordErrors(state),
});

export const ChangePasswordContainer = connect(mapStateToProps, {
  setNewPassword,
  submitNewMasterPassword,
  resetChangePasswordValidation,
  setRecoveryStatus,
})(ChangePasswordComponent);
