import React, {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {SideBarNotificationComponent, IconComponent} from '@figleafteam/components';
import {LicenseInfoProps} from '@renderer/js/components/monetization/components/monetization-info/monetization-info.types';
import {pluralize} from '@renderer/js/utils/pluralize';
import {EVENTS} from '@common/events';
import './_monetization-info.scss';

export const LicenseInfoComponent = memo<LicenseInfoProps>(props => {
  const {t} = useTranslation();

  useEffect(() => {
    props.getLicenseInfoAction();
  }, []);

  if (props.licenseInfo.type !== 'trial') {
    return null;
  }

  const handleOpenBuyNow = () => {
    props.sendTelemetryEvent({name: EVENTS.NAVIGATION.BUYNOW_BUTTON_CLICK, source: 'user'});
    props.openBuyNow();
  };

  return (
    <SideBarNotificationComponent>
      {!props.licenseInfo.isExpired ? (
        <div className="monetization-info">
          <span className="monetization-info__status">
            {t('Expires in {{expiration}} {{days}}', {
              expiration: props.licenseInfo.daysToExpiration,
              days: pluralize('day', props.licenseInfo.daysToExpiration),
            })}
          </span>
          <button type="button" className="monetization-info__btn btn-link" onClick={handleOpenBuyNow}>
            {t('Subscribe now')}
          </button>
        </div>
      ) : (
        <div className="monetization-info">
          <div className="monetization-info__image">
            <IconComponent name="attention-round" size={24} />
          </div>
          <span className="monetization-info__status monetization-info__status--red">
            {t('Your {{type}} is up', {type: props.licenseInfo.type === 'trial' ? t('trial') : t('subscription')})}
          </span>
          <button
            type="button"
            className="monetization-info__btn monetization-info__btn--warning btn-link"
            onClick={handleOpenBuyNow}
          >
            {t('Subscribe now')}
          </button>
        </div>
      )}
    </SideBarNotificationComponent>
  );
});

LicenseInfoComponent.displayName = 'LicenseInfoComponent';
