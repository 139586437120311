import React, {memo, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './_antitracking.scss';
import {IconComponent, ModalComponent} from '@figleafteam/components';
import {i18n} from '@figleafteam/helpers';
import {sortBrowsersReport} from '@renderer/js/utils/sort-browsers-report';
import {useAuthorization} from '@renderer/js/components/auth/hooks/use-authorization';
import {TrustedWebsitesContainer} from './components/trusted-websites/trusted-websites.container';
import {BrowserItemContainer} from './components/browser-item/browser-item.container';
import {AntitrackingProps} from './antitracking.types';

const Nbsp = () => <>{'\u00A0'}</>;

const mockBrowserReport: ReportByBrowser = {
  get title() {
    return i18n.t('Google Chrome');
  },
  version: 'mock',
  isDefault: false,
  isProcessRunning: false,
  name: 'chrome',
  isExtensionSupported: true,
  isImportSupported: false,
  extensions: [],
};

const mockedBrowsersTitle: {[key in Browsers]: string} = {
  get chrome() {
    return i18n.t('Google Chrome');
  },
  get firefox() {
    return i18n.t('Mozilla Firefox');
  },
  get edge() {
    return i18n.t('Microsoft Edge');
  },
  get brave() {
    return i18n.t('Brave');
  },
  get opera() {
    return i18n.t('Opera');
  },
  get safari() {
    return i18n.t('Safari');
  },
  get unknown() {
    return i18n.t('Browser');
  },
};

export const AntitrackingComponent = memo<AntitrackingProps>(props => {
  const {browsersReport, supportedBrowsers, browsersConfig, whitelist, getBrowsersReport} = props;
  const {isAuthorized, runIfAuthorized} = useAuthorization();
  const {t} = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (!browsersReport) {
      getBrowsersReport();
    }

    if (isAuthorized) {
      props.getAntitrackingConfig();
    }
  }, [isAuthorized]);

  const browserReports = supportedBrowsers.map(
    browserName =>
      browsersReport?.find(browserReport => browserReport.name === browserName) || {
        ...mockBrowserReport,
        name: browserName,
        title: mockedBrowsersTitle[browserName],
      },
  );

  const enabledBrowsersCount = browserReports.filter(report => {
    if (report && report.extensions.length) {
      return browsersConfig[report.name]?.isAntiTrackingEnabled;
    }
  }).length;

  return (
    <div className="antitracking">
      <div className="antitracking__header">
        <h2 className="antitracking__title">{t('Anti-Tracking')}</h2>
        <span className="antitracking__info">
          {enabledBrowsersCount ? (
            t('Enabled in {{count}} browser', {count: enabledBrowsersCount})
          ) : (
            <span className="antitracking__info--gray">{t('Disabled')}</span>
          )}
          <span className="antitracking__info--gray">
            <Nbsp />
            <Nbsp />•<Nbsp />
            <Nbsp />
          </span>
          <button
            type="button"
            className="antitracking__trusted-sites-button"
            onClick={() => runIfAuthorized(() => setIsOpenModal(true))}
          >
            {t('Trusted websites')}
            {Boolean(whitelist.length) && (
              <span className="antitracking__info--gray">
                <Nbsp />({whitelist.length})
              </span>
            )}
            <IconComponent name="arrow-right" size={20} className="antitracking__trusted-sites-button--arrow" />
          </button>
        </span>
      </div>
      <div className="antitracking__browsers">
        {browserReports.sort(sortBrowsersReport).map(browserReport => (
          <BrowserItemContainer browserReport={browserReport} key={browserReport.name} />
        ))}
      </div>
      <ModalComponent
        isOpen={isOpenModal}
        closeOnClickOutside
        handleClose={() => setIsOpenModal(false)}
        showCloseButton
        title={t('{{count}} trusted website', {count: whitelist.length})}
        titleTextAlign="left"
      >
        <TrustedWebsitesContainer handleClose={() => setIsOpenModal(false)} />
      </ModalComponent>
    </div>
  );
});

AntitrackingComponent.displayName = 'AntitrackingComponent';
