import {combineReducers} from 'redux';
import {modalWindowReducer} from '@renderer/js/components/unlock-user/redux/reducers/modal-window.reducer';
import {nextActionsReducer} from '@renderer/js/components/unlock-user/redux/reducers/next-actions.reducer';
import {unlockReducer} from '@renderer/js/components/unlock-user/redux/reducers/unlock-page-state.reducer';

export const unlockUser = combineReducers({
  modalWindow: modalWindowReducer,
  nextActions: nextActionsReducer,
  unlockData: unlockReducer,
});
