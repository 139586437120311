import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getConfirmModalState} from '@renderer/js/components/confirm-modal/redux/selectors';
import {resetConfirmModalState} from '@renderer/js/components/confirm-modal/redux/actions';
import {ConfirmModalComponent} from '@figleafteam/components';

const mapStateToProps = (state: AppState) => ({
  title: getConfirmModalState(state).title,
  text: getConfirmModalState(state).text,
  isLoadingNeeded: getConfirmModalState(state).isLoadingNeeded,
  loadingText: getConfirmModalState(state).loadingText,
  actionButtonText: getConfirmModalState(state).actionButtonText,
  actionButtonType: getConfirmModalState(state).actionButtonType,
  isOpen: getConfirmModalState(state).isOpen,
  closeButton: getConfirmModalState(state).showCloseButton,
  width: getConfirmModalState(state).width,
  buttonsWidth: getConfirmModalState(state).buttonsWidth,
  actionCallback: getConfirmModalState(state).actionCallback,
});
const mapDispatchToProps = {
  resetConfirmModalState,
};

export const ConfirmModalContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmModalComponent);
