import React, {FC} from 'react';
import CloseIcon from './images/icon-close.svg';
import './_close-button.scss';

type Props = {
  onClick: () => void;
};

export const CloseButton: FC<Props> = props => {
  return <CloseIcon onClick={props.onClick} className="close-button" />;
};
