import React from 'react';
import {InvalidCredentialsComponent} from './invalid-credentials-notification.component';

export const getInvalidCredentialsComponent = (onResetPasswordClick: () => void, onContactSupportClick: () => void) => {
  return (
    <InvalidCredentialsComponent
      onResetPasswordClick={onResetPasswordClick}
      onContactSupportClick={onContactSupportClick}
    />
  );
};
