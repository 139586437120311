export const SET_RECOVERY_STATUS = 'Recovery/SET_RECOVERY_STATUS' as const;
export const SET_RECOVERY_FLOW = 'Recovery/SET_RECOVERY_FLOW' as const;
export const SET_RECOVERY_KEY_VALUE = 'Recovery/SET_RECOVERY_KEY_VALUE' as const;
export const SET_RECOVERY_EMAIL_VALUE = 'Recovery/SET_RECOVERY_EMAIL_VALUE' as const;
export const REQUEST_RECOVERY_CODE = 'Recovery/REQUEST_RECOVERY_CODE' as const;
export const SET_RESEND_CODE_TIME = 'Recovery/SET_RESEND_CODE_TIME' as const;
export const SET_RECOVERY_KEY_ERROR = 'Recovery/SET_RECOVERY_KEY_ERROR' as const;
export const RESET_RECOVERY_KEY_ERROR = 'Recovery/RESET_RECOVERY_KEY_ERROR' as const;
export const SET_CONFIRM_RECOVERY_CODE = 'Recovery/SET_CONFIRM_RECOVERY_CODE' as const;
export const SET_NEW_PASSWORD = 'Recovery/SET_NEW_PASSWORD' as const;
export const CHANGE_PASSWORD_ERROR = 'Recovery/CHANGE_PASSWORD_ERROR' as const;
export const RESET_CHANGE_PASSWORD_VALIDATION = 'Recovery/RESET_CHANGE_PASSWORD_VALIDATION' as const;
export const SUBMIT_NEW_MASTER_PASSWORD = 'Recovery/SUBMIT_NEW_MASTER_PASSWORD' as const;
export const CLEAR_RECOVERY_INFO = 'Recovery/CLEAR_RECOVERY_INFO' as const;
