import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {performUpdate} from '@renderer/js/components/settings/redux/actions';
import {getUpdateInfo} from '@renderer/js/components/settings/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {AboutComponent} from './about.component';

const mapStateToProps = (state: AppState) => ({
  updateInfo: getUpdateInfo(state),
});

const mapDispatchToProps = {
  performUpdate,
  sendTelemetryEvent,
};

export const AboutContainer = connect(mapStateToProps, mapDispatchToProps)(AboutComponent);
