import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  requestRecoveryCode,
  setConfirmRecoveryCode,
  setRecoveryStatus,
  setResendCodeTime,
} from '@renderer/js/components/recovery/redux/actions';
import {
  getRecoveryEmail,
  getConfirmRecoveryCode,
  getResendCodeTime,
} from '@renderer/js/components/recovery/redux/selectors';
import {ConfirmRecoveryComponent} from './confirm-recovery.component';

const mapStateToProps = (state: AppState) => ({
  email: getRecoveryEmail(state),
  code: getConfirmRecoveryCode(state),
  resendTime: getResendCodeTime(state),
});

export const ConfirmRecoveryContainer = connect(mapStateToProps, {
  setConfirmRecoveryCode,
  requestRecoveryCode,
  setRecoveryStatus,
  setResendCodeTime,
})(ConfirmRecoveryComponent);
