import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  getUserLogin,
  quickScanBrowserProfiles,
  quickScan,
  updatePpiFlowStatus,
  updateMonitorProfile,
  requestMonitorConfirmationCode,
  verifyMonitorConfirmationCode,
} from '@figleafteam/api-communication';
import {selectMonitorProfileData, TMonitorProfileData, SET_MONITOR_PROFILE} from '@figleafteam/store';
import {EVENTS} from '@common/events';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {getUserProfileAction} from '@renderer/js/components/settings/redux/actions';
import {selectUserLogin} from '@renderer/js/components/settings/redux/selectors';
import {
  buildAccountHijackingCategory,
  buildHomeTitleTheftCategory,
  buildIdentityExposureCategory,
} from '@renderer/js/components/ppi-funnel/helpers/categories';
import {getParsedData} from '@renderer/js/components/ppi-funnel/helpers/parser';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {
  TParsedDataKeys,
  TRiskCategoriesState,
  RiskTypes,
  TDeleteEmailFromReportAction,
  TPpiLeaksState,
  TParsedDataState,
  TSendMonitorConfirmationCodeAction,
  TSubmitMonitorConfirmaionCodeAction,
  TAddPpiEmailToMonitorAction,
} from './types';
import {
  ADD_PPI_EMAIL_TO_MONITOR,
  DELETE_EMAIL_FROM_REPORT,
  GET_VERIFIRED_EMAILS,
  QUICK_SCAN_PROFILES,
  SEND_MONITOR_CONFIRMATION_CODE,
  SET_PPI_FLOW_FINISHED,
  SUBMIT_MONITOR_CONFIRMATION_CODE,
  GET_FULL_REPORT,
} from './constants';
import {
  setPpiFunnelState,
  setRiskCategories,
  setPpiLeaksState,
  sendMonitorConfirmationCode,
  getVerifiedEmails,
  getFullReport,
} from './actions';
import {selectRawLeaks, selectIsInitialScanFinished, selectPpiLeaksState} from './selectors';

function* updateCategories(parsedData: TParsedDataState) {
  const isInitialScanFinished: boolean = yield select(selectIsInitialScanFinished);
  const parsedDataKeys = Object.keys(parsedData) as Array<TParsedDataKeys>;
  let exposedItemsCount = 0;
  parsedDataKeys.forEach(key => (exposedItemsCount += parsedData[key].length));
  const newRiskCategories: TRiskCategoriesState = {
    identityExposure: buildIdentityExposureCategory(parsedData),
    accountHijacking: buildAccountHijackingCategory(parsedData),
    homeTitleTheft: buildHomeTitleTheftCategory(parsedData),
  };

  const riskLevelMap = {
    low: 0,
    medium: 0,
    high: 0,
  };

  Object.values(newRiskCategories).forEach(category => {
    riskLevelMap[category.riskType] = riskLevelMap[category.riskType] ? riskLevelMap[category.riskType]++ : 1;
  });

  let globalRiskType: RiskTypes = RiskTypes.medium;
  if (riskLevelMap.high) {
    globalRiskType = RiskTypes.high;
  }
  const exposedCategoriesCount = riskLevelMap.high + riskLevelMap.medium;

  if (!isInitialScanFinished) {
    yield put(
      sendTelemetryEvent({
        name: EVENTS.PPI.INITIAL_SCAN_FINISHED,
        source: 'app',
        payload: {
          globalRiskType,
          exposedItemsCount,
          exposedCategoriesCount,
          identityExposureRiskType: newRiskCategories.identityExposure.riskType,
          accountHijackingRiskType: newRiskCategories.accountHijacking.riskType,
          homeTitleTheftRiskType: newRiskCategories.homeTitleTheft.riskType,
        },
      }),
    );
    yield put(setPpiFunnelState({isInitialScanFinished: true}));
  }

  yield put(setRiskCategories(newRiskCategories));

  yield put(
    setPpiFunnelState({
      isLoading: false,
      globalRiskType,
      exposedItemsCount,
      exposedCategoriesCount,
      isDataFetched: true,
    }),
  );
}

function* quickScanSaga() {
  yield put(
    setPpiFunnelState({
      isLoading: true,
    }),
  );

  try {
    const {allBrowsersProfiles, leaks}: TQuickScanResponsePayload = yield call(quickScanBrowserProfiles);

    yield put(
      sendTelemetryEvent({
        name: EVENTS.PPI.INITIAL_SCAN_START,
        source: 'app',
        payload: {emails: Object.keys(leaks).length},
      }),
    );

    const profilesWithLeaks = Object.keys(leaks).filter(email => leaks[email]);

    const profilesToVerify = profilesWithLeaks.map(email => ({
      email,
      browser: allBrowsersProfiles.find(profile => profile.email === email)?.browser || 'unknown',
    }));

    yield put(
      setPpiLeaksState({
        rawLeaksReport: leaks,
        profiles: profilesWithLeaks,
        profilesToVerify,
      }),
    );

    yield put(getVerifiedEmails());

    const parsedData = getParsedData(leaks, Object.keys(leaks));
    yield call(updateCategories, parsedData);
  } catch (error) {
    console.error('error: ', error);
    yield put(
      sendTelemetryEvent({
        name: EVENTS.PPI.INITIAL_SCAN_ERROR,
        source: 'app',
        payload: {
          error,
        },
      }),
    );
  }

  yield put(
    setPpiFunnelState({
      isLoading: false,
    }),
  );
}

function* setPpiFlowFinishedSaga() {
  try {
    yield call(updatePpiFlowStatus, true);
    yield put(getUserProfileAction());
  } catch (error) {
    console.error(error);
  }
}

function* getVerifiedEmailsSaga() {
  const verifiedEmails = new Set<string>();
  const {profilesToVerify}: TPpiLeaksState = yield select(selectPpiLeaksState);

  try {
    let login: string | null = yield select(selectUserLogin);
    if (!login) {
      const userLoginPayload: TGetUserLoginResponse['payload'] = yield call(getUserLogin);
      login = userLoginPayload.login;
    }
    verifiedEmails.add(login);

    const monitorProfile: TMonitorProfileData = yield select(selectMonitorProfileData);
    monitorProfile.items.forEach(item => {
      if (item.groupName === 'email' && item.context?.isConfirmed && item.value) {
        verifiedEmails.add(item.value);
      }
    });

    yield put(
      setPpiLeaksState({
        profilesToVerify: profilesToVerify.filter(profile => !verifiedEmails.has(profile.email)),
        currentEmailToVerify: 0,
      }),
    );
  } catch (error) {
    console.error(error);
  }
}

function* addPpiEmailToMonitorSaga(action: TAddPpiEmailToMonitorAction) {
  const {email} = action.payload;
  const monitorProfile: TMonitorProfileData = yield select(selectMonitorProfileData);

  if (monitorProfile.items.find(monitorItem => monitorItem.value === email)) {
    yield put(sendMonitorConfirmationCode(email));
    yield put(setPpiLeaksState({isVerifyModalOpen: true, isAddEmailModalOpen: false}));
    return;
  }

  try {
    yield call(updateMonitorProfile, [
      {
        value: email,
        groupName: 'email',
        type: 'email',
      },
    ]);
    yield put(sendMonitorConfirmationCode(email));
    yield put(setPpiLeaksState({isVerifyModalOpen: true, isAddEmailModalOpen: false}));
  } catch (e) {
    console.error(e);
    yield put(setPpiLeaksState({isAddEmailModalOpen: false}));
  }
}

function* sendMonitorConfirmaionCodeSaga(action: TSendMonitorConfirmationCodeAction) {
  const {email} = action.payload;

  try {
    const {status}: TRequestMonitorConfirmationCodeResponse['payload'] = yield call(requestMonitorConfirmationCode, {
      email,
    });

    if (status !== 'confirmed') {
      return;
    }

    const {currentEmailToVerify, profilesToVerify, emailsToRescan}: TPpiLeaksState = yield select(selectPpiLeaksState);
    if (currentEmailToVerify + 1 < profilesToVerify.length) {
      yield put(
        setPpiLeaksState({
          currentEmailToVerify: currentEmailToVerify + 1,
          isVerifyInProgress: false,
          isVerifyModalOpen: false,
          isAddEmailModalOpen: true,
          emailsToRescan: [...emailsToRescan, email],
        }),
      );
      return;
    }

    yield put(getFullReport());
  } catch (error) {
    console.error(error);
    yield put(setPpiLeaksState({isVerifyModalOpen: false}));
    notificationService.showDefaultError(error.payload.message);
  }
}

function* sumbitMonitorConfirmaionCodeSaga(action: TSubmitMonitorConfirmaionCodeAction) {
  const {currentEmailToVerify, profilesToVerify, emailsToRescan}: TPpiLeaksState = yield select(selectPpiLeaksState);
  const {code, email} = action.payload;
  yield put(setPpiLeaksState({isVerifyInProgress: true}));
  try {
    yield call(verifyMonitorConfirmationCode, {email, code});
    yield put(
      setPpiLeaksState({
        emailsToRescan: [...emailsToRescan, email],
      }),
    );

    if (currentEmailToVerify + 1 < profilesToVerify.length) {
      yield put(
        setPpiLeaksState({
          currentEmailToVerify: currentEmailToVerify + 1,
          isVerifyInProgress: false,
          isVerifyModalOpen: false,
          isAddEmailModalOpen: true,
        }),
      );
      return;
    }

    yield put(getFullReport());
  } catch (error) {
    yield put(setPpiLeaksState({isVerifyInProgress: false}));
    console.error(error);
    notificationService.showDefaultError(error.payload.message);
  }
}

function* deleteEmailFromReportSaga(action: TDeleteEmailFromReportAction) {
  const {profiles, profilesToVerify, currentEmailToVerify}: TPpiLeaksState = yield select(selectPpiLeaksState);
  const leaks: TQuickScanResponsePayload['leaks'] = yield select(selectRawLeaks);
  const newEmailList = profiles.filter(profile => profile !== action.payload.email);
  const parsedData = getParsedData(leaks, newEmailList);

  if (currentEmailToVerify + 1 < profilesToVerify.length) {
    yield put(
      setPpiLeaksState({
        profiles: newEmailList,
        profilesToVerify: profilesToVerify.filter(profile => profile.email !== action.payload.email),
        isDeleteEmailModalOpen: false,
        isAddEmailModalOpen: true,
        isVerifyModalOpen: false,
      }),
    );
    yield call(updateCategories, parsedData);
    return;
  }

  yield put(getFullReport());
  yield call(updateCategories, parsedData);
  yield put(
    setPpiLeaksState({
      currentEmailToVerify: 0,
      profiles: newEmailList,
      profilesToVerify: profilesToVerify.filter(profile => profile.email !== action.payload.email),
      isDeleteEmailModalOpen: false,
      isAddEmailModalOpen: false,
      isVerifyModalOpen: false,
    }),
  );
}

function* getFullReportSaga() {
  const {emailsToRescan}: TPpiLeaksState = yield select(selectPpiLeaksState);
  yield put(
    setPpiLeaksState({
      isAddEmailModalOpen: false,
      isVerifyModalOpen: false,
      isDeleteEmailModalOpen: false,
    }),
  );

  try {
    if (!emailsToRescan.length) {
      return;
    }
    const {leaks}: TQuickScanResponse['payload'] = yield call(quickScan, emailsToRescan);

    const profilesWithLeaks = Object.keys(leaks).filter(email => leaks[email]);

    yield put(
      setPpiLeaksState({
        rawLeaksReport: leaks,
        profiles: profilesWithLeaks,
      }),
    );

    const parsedData = getParsedData(leaks, Object.keys(leaks));
    yield call(updateCategories, parsedData);
  } catch (error) {
    console.error(error);
  }
}

function* updateVerifiedEmailsSaga() {
  const {isAddEmailModalOpen, isVerifyModalOpen}: TPpiLeaksState = yield select(selectPpiLeaksState);

  if (!isAddEmailModalOpen && !isVerifyModalOpen) {
    yield put(getVerifiedEmails());
  }
}

export function* quickScanSagaWatcher() {
  yield takeLatest(QUICK_SCAN_PROFILES, quickScanSaga);
  yield takeLatest(SET_PPI_FLOW_FINISHED, setPpiFlowFinishedSaga);
  yield takeLatest(DELETE_EMAIL_FROM_REPORT, deleteEmailFromReportSaga);
  yield takeLatest(ADD_PPI_EMAIL_TO_MONITOR, addPpiEmailToMonitorSaga);
  yield takeLatest(SEND_MONITOR_CONFIRMATION_CODE, sendMonitorConfirmaionCodeSaga);
  yield takeLatest(SUBMIT_MONITOR_CONFIRMATION_CODE, sumbitMonitorConfirmaionCodeSaga);
  yield takeLatest(GET_VERIFIRED_EMAILS, getVerifiedEmailsSaga);
  yield takeLatest(GET_FULL_REPORT, getFullReportSaga);
  yield takeLatest(SET_MONITOR_PROFILE, updateVerifiedEmailsSaga);
}
