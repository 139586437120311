export const randomArrayIndex = (arr: Array<any>): number => Math.floor(Math.random() * arr.length);

const defaultTransformer = (val: any) => val;
export const range = (from: number, to: number, transformer = defaultTransformer) => {
  const length = to - from + 1;

  return Array.from({length}, (_, index) => transformer(from + index));
};

export function compareObjectsByProperty(property: string) {
  return function (a: Record<string, any>, b: Record<string, any>) {
    if (a[property] > b[property]) {
      return 1;
    }
    if (a[property] < b[property]) {
      return -1;
    }
    return 0;
  };
}

export function checkCardNumberByMoonAlgorithm(value: string) {
  if (!value || /[^0-9-\s]+/.test(value)) {
    return false;
  }

  let nCheck = 0;
  let nDigit = 0;
  let bEven = false;
  const cardNumber = value.replace(/\D/g, '');

  for (let n = cardNumber.length - 1; n >= 0; n--) {
    const cDigit = cardNumber.charAt(n);
    nDigit = parseInt(cDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 == 0;
}
