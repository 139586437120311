import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {selectAccountHijacking, selectResolvedRisks} from '@renderer/js/components/ppi-funnel/redux/selectors';

import {PPIRisksReportHackedAccountsComponent} from './ppi-report-hacked-accounts.component';

const mapStateToProps = (state: AppState) => ({
  accountHijacking: selectAccountHijacking(state),
  resolvedRisks: selectResolvedRisks(state),
});

const mapDispatchToProps = {};

export const PPIRisksReportHackedAccountsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PPIRisksReportHackedAccountsComponent);
