import React, {FC, FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, InputPasswordComponent, InputComponent} from '@figleafteam/components';
import {AuthOnboardingContainer} from '@renderer/js/components/auth-onboarding/auth-onboarding.container';
import {ForgetKeyModalComponent} from '@renderer/js/components/recovery/components/forget-key-modal/forget-key-modal.component';

import {EnterSecurityKeyProps} from './enter-security-key.types';

export const EnterSecurityKeyComponent: FC<EnterSecurityKeyProps> = props => {
  const {t} = useTranslation();
  const [isShownModalForgotSecKey, setShownModalForgotSecKey] = useState(false);
  useEffect(() => {
    if (!props.accountInfo.login) {
      props.getUserLogin();
    }
  }, []);
  const onSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    if ((!props.password && props.appInfo && props.appInfo.lockStatus) || !props.securityKey) {
      return;
    }
    props.submitSecurityKey(props.securityKey, props.password);
  };
  const handleLogout = () => props.logout();

  const handleOpenModal = () => setShownModalForgotSecKey(true);
  const handleCloseModal = () => setShownModalForgotSecKey(false);
  const handleChangeKey = (ev: React.ChangeEvent<HTMLInputElement>) => props.setSecurityKey(ev.target.value);
  const handleChangePassword = (ev: React.ChangeEvent<HTMLInputElement>) => props.setPassword(ev.target.value);

  return (
    <AuthOnboardingContainer>
      <div className="auth auth--wide">
        <div className="auth__container">
          <h1 className="auth__title">{t('Enter your {{partner}} Security Key', {partner: 'FigLeaf'})}</h1>
          <p className="auth__text">
            {t(
              'It’s time to find that long key, that you’ve saved or written down after sign up. Without this key, {{partner}} cannot decrypt your data and log in to your account.',
              {partner: 'FigLeaf'},
            )}
          </p>
          <form className="auth__form" onSubmit={onSubmit}>
            {props.appInfo && props.appInfo.lockStatus && (
              <div className="auth__form-input">
                <InputPasswordComponent
                  onChange={handleChangePassword}
                  value={props.password || ''}
                  name="password"
                  type="password"
                  placeholder={t('Password')}
                />
              </div>
            )}
            <div className="auth__form-input">
              <InputComponent
                onChange={handleChangeKey}
                value={props.securityKey}
                name="security-key"
                type="text"
                placeholder={t('Your Security Key')}
                autoFocus
              />
            </div>
            <div className="auth__form-link">
              <ButtonComponent
                type="link"
                buttonType="button"
                onClick={handleOpenModal}
                text={t('Cannot find your Security key?')}
              />
            </div>
            <div className="auth__buttons">
              <ButtonComponent
                fullWidth
                disabled={(!props.password && props.appInfo && props.appInfo.lockStatus) || !props.securityKey}
                buttonType="submit"
                type="primary"
                text={t('Submit')}
              />
            </div>
          </form>
        </div>

        <div className="auth__bottom-text-wrapper">
          <p className="auth__bottom-text">
            {t('You’re logged in as “{{login}}”.', {login: props.accountInfo.login})}{' '}
            <ButtonComponent type="link" buttonType="button" text={t('Use other account')} onClick={handleLogout} />
          </p>
        </div>
        <ForgetKeyModalComponent
          keyType={t('Security')}
          isOpen={isShownModalForgotSecKey}
          handleClose={handleCloseModal}
          openSupportClick={() => props.openSupportLink()}
        />
      </div>
    </AuthOnboardingContainer>
  );
};
