import {i18n} from '@figleafteam/helpers';

import {TLoginErrors} from '@renderer/js/components/auth/redux/types';
import {isEmailInvalid} from './isEmailInvalid';

export const initialErrors: TLoginErrors = {
  // fields has error
  emailInvalid: false,
  passwordInvalid: false,
  // fields error texts
  emailErrorText: '',
  passwordErrorText: '',
};

export function getErrorLoginCredentialsValid(email: string, password: string) {
  const errors = {...initialErrors};

  if (isEmailInvalid(email)) {
    errors.emailInvalid = true;
    errors.emailErrorText = i18n.t("Your email doesn't look right");
  }
  if (email.length === 0) {
    errors.emailInvalid = true;
    errors.emailErrorText = i18n.t('Please fill email field');
  }
  if (password.length === 0) {
    errors.passwordInvalid = true;
    errors.passwordErrorText = i18n.t('Please fill password field');
  }

  return errors;
}
