import React from 'react';
import {
  ModalComponent,
  ButtonComponent,
  BrowserIconComponent,
  IconComponent,
  TooltipComponent,
  ButtonsGroup,
} from '@figleafteam/components';
import {TPPIEmailsListModalProps} from './ppi-add-email-modal.types';
import './_ppi-add-email-modal.scss';

import MailImage from './images/mail.svg';

const browsersTitleDict: {[key in Browsers]: string} = {
  chrome: 'Google Chrome',
  firefox: 'Mozilla Firefox',
  edge: 'Microsoft Edge',
  brave: 'Brave',
  opera: 'Opera',
  safari: 'Safari',
  unknown: 'Browser',
};

export const PPIEmailsListModalComponent: React.FC<TPPIEmailsListModalProps> = ({
  profilesToVerify,
  currentEmailToVerify,
  isModalOpen,
  setPpiLeaksState,
  addPpiEmailToMonitor,
  getVerifiedEmails,
  deleteEmailFromReport,
  getFullReport,
}) => {
  if (!isModalOpen) {
    return null;
  }
  const handleClose = () => {
    getFullReport();
    getVerifiedEmails();
  };

  const {email, browser} = profilesToVerify[currentEmailToVerify];
  const handleAddEmail = () => {
    addPpiEmailToMonitor(email);
  };

  const handleRemoveEmail = () => {
    deleteEmailFromReport(email);
  };

  const handleSkip = () => {
    if (currentEmailToVerify + 1 < profilesToVerify.length) {
      return setPpiLeaksState({currentEmailToVerify: currentEmailToVerify + 1});
    }

    getFullReport();
  };

  return (
    <ModalComponent closeOnClickOutside showCloseButton isOpen handleClose={handleClose}>
      <div className="ppi-add-email">
        <div className="ppi-add-email__image">
          <MailImage />
        </div>
        {/* use here emails to verify */}
        <p className="ppi-add-email__counter">{`Email ${currentEmailToVerify + 1}/${profilesToVerify.length}`}</p>
        <p className="ppi-add-email__text">Confirm email ownership to see exposed data</p>
        <h2 className="ppi-add-email__title">{email}</h2>
        <div className="ppi-add-email__browser">
          <div className="ppi-add-email__browser-icon">
            <BrowserIconComponent id="chrome" autoSize />
          </div>{' '}
          Imported from: {browsersTitleDict[browser]}
          <TooltipComponent
            text="Email addresses were identified by scanning your PC and internet browsers"
            modifier="dark"
          >
            <div className="ppi-add-email__info-icon">
              <IconComponent name="info" size={20} />
            </div>
          </TooltipComponent>
        </div>
        <div className="ppi-add-email__button-wrapper">
          <ButtonsGroup
            buttonsWidth="200"
            buttonsOffset="sm"
            buttons={[
              {add: <ButtonComponent fullWidth onClick={handleAddEmail} text="Verify this email" type="primary" />},
              {
                remove: (
                  <ButtonComponent fullWidth onClick={handleRemoveEmail} text="It's not mine" type="empty-bordered" />
                ),
              },
            ]}
          />
          <ButtonComponent onClick={handleSkip} text="Skip" className="ppi-add-email__skip-button" type="link" />
        </div>
      </div>
    </ModalComponent>
  );
};
