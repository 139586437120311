import {connect} from 'react-redux';

import {AppState} from '@renderer/js/redux/rootReducer';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {selectRiskCategories} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {PPIRisksReportComponent} from './ppi-risks-report.component';

const mapStateToProps = (state: AppState) => ({
  riskCategories: selectRiskCategories(state),
  appInfo: getAppInfo(state),
});

const mapDispatchToProps = {
  sendTelemetryEvent,
};

export const PPIRisksReportContainer = connect(mapStateToProps, mapDispatchToProps)(PPIRisksReportComponent);
