import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getInstalledBrowsersReport} from '@renderer/js/components/settings/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {openWebExtensionStore} from '@renderer/js/components/settings/redux/actions';
import {selectIsOnboardingOpened, selectIsExtensionsModalOpened} from '@renderer/js/components/details/redux/selectors';
import {setDetailsState} from '@renderer/js/components/details/redux/actions';
import {DetailsComponent} from './details-control.component';

const mapStateToProps = (state: AppState) => ({
  browsersReport: getInstalledBrowsersReport(state),
  isOnboardingOpened: selectIsOnboardingOpened(state),
  isExtensionsModalOpened: selectIsExtensionsModalOpened(state),
});
const mapDispatchToProps = {
  sendTelemetryEvent,
  openWebExtensionStore,
  setDetailsState,
};

export const DetailsContainer = connect(mapStateToProps, mapDispatchToProps)(DetailsComponent);
