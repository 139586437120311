import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  setConfirmAccountCode,
  requestConfirmAccountCode,
  setResendCodeTime,
} from '@renderer/js/components/auth/redux/actions';
import {getEmail, getConfirmAccountCode, getResendCodeTime} from '@renderer/js/components/auth/redux/selectors';
import {ConfirmAccountComponent} from './confirm-account.component';

const mapStateToProps = (state: AppState) => ({
  email: getEmail(state),
  code: getConfirmAccountCode(state),
  resendTime: getResendCodeTime(state),
});

export const ConfirmAccountContainer = connect(mapStateToProps, {
  setConfirmAccountCode,
  requestConfirmAccountCode,
  setResendCodeTime,
})(ConfirmAccountComponent);
