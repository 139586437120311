import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {IconComponent} from '@figleafteam/components';
import {TSocialProfileTypes} from '@renderer/js/components/ppi-funnel/redux/types';
import {PPIRisksReportSectionComponent} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-section/ppi-risks-report-section.component';
import {PPIRisksReportRiskCategoryContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-risk-category/ppi-risks-report-risk-category.container';
import FacebookIcon from './images/facebook.svg';
import GoogleIcon from './images/google.svg';
import TwitterIcon from './images/twitter.svg';
import YoutubeIcon from './images/youtube.svg';
import InstagramIcon from './images/instagram.svg';
import YahooIcon from './images/yahoo.svg';
import SkypeIcon from './images/skype.svg';
import RedditIcon from './images/reddit.svg';
import StackoverflowIcon from './images/stackoverflow.svg';
import PinterestIcon from './images/pinterest.svg';
import LinkedinIcon from './images/linkedin.svg';
import {TPPIRisksReportIdentityExposureProps} from './ppi-risks-report-identity-exposure.types';
import {getRecommendedActionItem} from '../ppi-risks-report-risk-category/ppi-risks-report-risk-category.component';

const socialIconsMap: {[key in TSocialProfileTypes]: React.ReactNode} = {
  facebook: <FacebookIcon />,
  twitter: <TwitterIcon />,
  google: <GoogleIcon />,
  youtube: <YoutubeIcon />,
  instagram: <InstagramIcon />,
  yahoo: <YahooIcon />,
  skype: <SkypeIcon />,
  reddit: <RedditIcon />,
  stackoverflow: <StackoverflowIcon />,
  pinterest: <PinterestIcon />,
  linkedin: <LinkedinIcon />,
};

export const PPIRisksReportIdentityExposureComponent: FC<TPPIRisksReportIdentityExposureProps> = ({
  identityExposure,
  resolvedRisks,
}) => {
  const {t} = useTranslation();

  const isExtensionsResolved = resolvedRisks.includes('add-extensions');
  const isMonitorResolved = resolvedRisks.includes('monitoring-email');

  return (
    <>
      {(!isExtensionsResolved || !isMonitorResolved) && (
        <PPIRisksReportSectionComponent
          title={t('Why am I at risk?')}
          description={t(
            'Our scan discovered these online records associated with your email address. 3rd parties use your Discovered Records, your email address, and website trackers to target you with ads.',
          )}
        >
          <PPIRisksReportRiskCategoryContainer
            title={t('General info')}
            icon={<IconComponent name="user-round" size={24} />}
            items={identityExposure.items.generalInfo.data}
          />
          <PPIRisksReportRiskCategoryContainer
            title={t('IDs')}
            icon={<IconComponent name="id" size={24} />}
            items={identityExposure.items.ids.data}
          />
          <PPIRisksReportRiskCategoryContainer
            title={t('Phones')}
            icon={<IconComponent name="phone" size={24} />}
            items={identityExposure.items.phones.data}
          />
          <PPIRisksReportRiskCategoryContainer
            title={t('Social profiles')}
            icon={<IconComponent name="link" size={24} />}
            items={identityExposure.items.socialProfiles.data.map(item => {
              return {...item, icon: socialIconsMap[item.socialType as TSocialProfileTypes]};
            })}
          />
        </PPIRisksReportSectionComponent>
      )}
      <PPIRisksReportSectionComponent
        title={t('Recommended protection')}
        description={t('We recommend using following tools to decrease this risk')}
      >
        <PPIRisksReportRiskCategoryContainer
          title={t('Anti-tracking')}
          icon={<IconComponent name="eye-closed" size={24} />}
          items={[getRecommendedActionItem(isExtensionsResolved)]}
        />
        <PPIRisksReportRiskCategoryContainer
          title={t('Breach monitoring')}
          icon={<IconComponent name="dark-web" size={24} />}
          items={[getRecommendedActionItem(isMonitorResolved)]}
        />
      </PPIRisksReportSectionComponent>
    </>
  );
};
