import {
  TAppInfoState,
  TGetAppInfoAction,
  TSetAppInfoAction,
  TSetAppLoadingStatusAction,
} from '@renderer/js/components/app/redux/types';
import {SET_APP_INFO, SET_APP_LOADING_STATUS} from '@renderer/js/components/app/redux/constants';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';

const initialState: TAppInfoState = {
  appInfo: null,
  isLoading: true,
};

type TActionsType = TGetAppInfoAction | TSetAppInfoAction | TSetAppLoadingStatusAction | TLogOutSuccessAction;

export const appInfoReducer = (state = initialState, action: TActionsType): TAppInfoState => {
  switch (action.type) {
    case SET_APP_INFO:
    case SET_APP_LOADING_STATUS: {
      return {...state, ...action.payload};
    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
