import {
  SET_RECOVERY_STATUS,
  SET_RECOVERY_EMAIL_VALUE,
  SET_RECOVERY_KEY_VALUE,
  SET_RECOVERY_KEY_ERROR,
  RESET_RECOVERY_KEY_ERROR,
  REQUEST_RECOVERY_CODE,
  SET_RESEND_CODE_TIME,
  SET_CONFIRM_RECOVERY_CODE,
  SET_NEW_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  RESET_CHANGE_PASSWORD_VALIDATION,
  SUBMIT_NEW_MASTER_PASSWORD,
  CLEAR_RECOVERY_INFO,
} from '@renderer/js/components/recovery/redux/constants';
import {TRecoveryKeyErrors, TChangePasswordErrors} from '@renderer/js/components/recovery/redux/types';

export const setRecoveryStatus = (recoveryStatus: boolean) => ({
  type: SET_RECOVERY_STATUS,
  payload: {
    recoveryStatus,
  },
});

export const setRecoveryEmail = (email: string) => ({
  type: SET_RECOVERY_EMAIL_VALUE,
  payload: {
    email,
  },
});

export const setRecoveryKey = (recoveryKey: string) => ({
  type: SET_RECOVERY_KEY_VALUE,
  payload: {
    recoveryKey,
  },
});

export const requestRecoveryCode = (email: string, key?: string) => ({
  type: REQUEST_RECOVERY_CODE,
  payload: {
    email,
    key,
  },
});

export const setResendCodeTime = (seconds: number) => ({
  type: SET_RESEND_CODE_TIME,
  payload: {
    seconds,
  },
});

export const setRecoveryKeyError = (errors: TRecoveryKeyErrors) => ({
  type: SET_RECOVERY_KEY_ERROR,
  payload: {
    errors,
  },
});

export const resetRecoveryKeyError = () => ({
  type: RESET_RECOVERY_KEY_ERROR,
  payload: {},
});

export const setConfirmRecoveryCode = (code: string) => ({
  type: SET_CONFIRM_RECOVERY_CODE,
  payload: {
    code,
  },
});

export const clearRecoveryInfo = () => ({
  type: CLEAR_RECOVERY_INFO,
  payload: {},
});

export const setNewPassword = (key: 'newPassword' | 'newRePassword', value: string) => ({
  type: SET_NEW_PASSWORD,
  payload: {
    key,
    value,
  },
});

export const createChangePasswordError = (changePasswordErrors: TChangePasswordErrors) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: {
    changePasswordErrors,
  },
});

export const resetChangePasswordValidation = () => ({
  type: RESET_CHANGE_PASSWORD_VALIDATION,
  payload: {},
});

export const submitNewMasterPassword = (newPassword: string, newRePassword: string) => ({
  type: SUBMIT_NEW_MASTER_PASSWORD,
  payload: {
    newPassword,
    newRePassword,
  },
});
