import React, {memo, useState} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {ButtonComponent, InputPasswordComponent} from '@figleafteam/components';
import {checkPasswordCriteria} from '@renderer/js/components/auth/utils/checkPasswordCriteria';
import {getFromState, history, HISTORY_STATE_FROM} from '@renderer/js/router/history';

import {ChangePasswordProps} from './change-password.types';
import '@renderer/js/components/auth/_password-drop-down-hint.scss';

export const ChangePasswordComponent = memo<ChangePasswordProps>(props => {
  const from = getFromState();
  const {t} = useTranslation();
  const [isPasswordHintShown, setPasswordHintShown] = useState(false);
  const handleFieldChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const {value, name} = ev.target;
    props.setNewPassword(name as 'newPassword' | 'newRePassword', value);
    props.resetChangePasswordValidation();
  };
  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    props.submitNewMasterPassword(props.newPassword, props.newRePassword);
  };
  const goBackHandler = () => {
    if (from === HISTORY_STATE_FROM.UNLOCK) {
      history.push('/lock');
    } else {
      history.push('/login');
    }
  };

  const errorsConfig = {
    newPassword: {
      error: props.changePasswordErrors.newPasswordInvalid,
      bottomText: props.changePasswordErrors.newPasswordErrorText,
    },
    newRePassword: {
      error: props.changePasswordErrors.newRePasswordInvalid,
      bottomText: props.changePasswordErrors.newRePasswordErrorText,
    },
  };

  const liveChecks = checkPasswordCriteria(props.newPassword);

  const getHintItemClass = (property: keyof ReturnType<typeof checkPasswordCriteria>) => {
    return liveChecks[property]
      ? 'password-drop-down-hint__item'
      : 'password-drop-down-hint__item password-drop-down-hint__item--valid';
  };

  return (
    <div className="auth">
      <div className="auth__container">
        <h1 className="auth__title auth__title--recovery">
          {from === HISTORY_STATE_FROM.MIGRATION
            ? t('Important: Change your password to apply a better account recovery experience')
            : t('Choose a new password')}
        </h1>
        <p className="auth__text">
          {from === HISTORY_STATE_FROM.MIGRATION ? (
            <Trans i18nKey="From now on you have an option to reset your forgotten...">
              From now on you have an option to reset your forgotten FigLeaf account password using your Recovery Key
              (ex. Security Key).
              <br />
              <br />
              In order to apply these changes, you need to create a new account password. Sorry for the inconvinience!
            </Trans>
          ) : (
            t(
              'Please, take into account that you’ll be logged out on all other devices, that have {{partner}} app installed.',
              {partner: 'FigLeaf'},
            )
          )}
        </p>
        <form className="auth__form" onSubmit={handleSubmit}>
          <div className="auth__form-input">
            <InputPasswordComponent
              onChange={handleFieldChange}
              onFocusChange={setPasswordHintShown}
              value={props.newPassword}
              name="newPassword"
              type="password"
              placeholder={t('New password')}
              {...errorsConfig.newPassword}
            />
            {isPasswordHintShown && (liveChecks.digit || liveChecks.mixedLetterCase || liveChecks.passwordLength) && (
              <div className="password-drop-down-hint">
                <div className="password-drop-down-hint__inner">
                  <h6 className="password-drop-down-hint__title">{t('Password requirements:')}</h6>
                  <ul className="password-drop-down-hint__list">
                    <li className={getHintItemClass('passwordLength')}>{t('At least 8 characters')}</li>
                    <li className={getHintItemClass('mixedLetterCase')}>{t('Mixture of upper & lowercase')}</li>
                    <li className={getHintItemClass('digit')}>{t('At least 1 digit')}</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="auth__form-input">
            <InputPasswordComponent
              onChange={handleFieldChange}
              value={props.newRePassword}
              name="newRePassword"
              type="password"
              placeholder={t('Confirm new password')}
              {...errorsConfig.newRePassword}
            />
          </div>
          <div className="auth__buttons auth__buttons--indent-top">
            <ButtonComponent
              fullWidth
              type="primary"
              buttonType="submit"
              text={from === HISTORY_STATE_FROM.MIGRATION ? t('Save password') : t('Save')}
            />
          </div>
        </form>
      </div>
      <div className="auth__bottom-text-wrapper">
        <p className="auth__bottom-text">
          {!from && (
            <>
              {t('Remember you password?')}{' '}
              <ButtonComponent type="link" buttonType="button" text={t('Go back to login')} onClick={goBackHandler} />
            </>
          )}
          {from === HISTORY_STATE_FROM.UNLOCK && (
            <>
              {t('Remember you password?')}{' '}
              <ButtonComponent
                type="link"
                buttonType="button"
                text={t('Go back to lock screen')}
                onClick={goBackHandler}
              />
            </>
          )}
        </p>
      </div>
    </div>
  );
});

ChangePasswordComponent.displayName = 'ChangePasswordComponent';
