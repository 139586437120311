import React, {FC} from 'react';

import {MonitorElectron} from '@figleafteam/monitor-electron';

import messenger from '@renderer/js/api/communication/messenger';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {setPaywallModalState} from '@renderer/js/components/paywall-modal/redux/actions';

import {openURL} from '@common/compatibility/openURL';

import './_monitor.scss';

export const MonitorComponent: FC = () => {
  return (
    <section className="monitor">
      <MonitorElectron
        basename="/monitoring"
        messenger={messenger}
        notificationService={notificationService}
        openUrl={openURL}
        openPaywall={() => setPaywallModalState({isOpen: true})}
      />
    </section>
  );
};
