import React, {FC} from 'react';
import classNames from 'classnames';

import {IconComponent, Spinner} from '@figleafteam/components';
import {TPPIRisksScannerProgressItemProps} from './ppi-risks-scanner-progress-item.types';
import './_ppi-risks-scanner-progress-item.scss';

export const PPIRisksScannerProgressItem: FC<TPPIRisksScannerProgressItemProps> = ({state, children}) => {
  return (
    <div className={`ppi-progress-item ppi-progress-item`}>
      <div
        className={classNames('ppi-progress-item__marker', {
          'ppi-progress-item__marker--state-done': state === 'done',
          'ppi-progress-item__marker--state-progress': state === 'progress',
          'ppi-progress-item__marker--state-waiting': state === 'waiting',
        })}
      >
        {state === 'progress' && <Spinner />}
        {state === 'done' && <IconComponent name="check" size={24} />}
      </div>
      <div
        className={classNames('ppi-progress-item__content', {
          'ppi-progress-item__content--state-done': state === 'done',
          'ppi-progress-item__content--state-progress': state === 'progress',
          'ppi-progress-item__content--state-waiting': state === 'waiting',
        })}
      >
        {children}
      </div>
    </div>
  );
};
