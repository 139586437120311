import React, {FC, useEffect} from 'react';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {EVENTS} from '@common/events';
import {MainProps} from './main.types';

export const MainComponent: FC<MainProps> = props => {
  useEffect(() => {
    props.getIsUpdateAvailable();
  }, []);

  useEffect(() => {
    if (
      props.appInfo &&
      props.appInfo.isAuthorized &&
      !props.appInfo.isRecoveryKeySaved &&
      !props.isRisksScannerOpened
    ) {
      props.setRecoveryKeyModalState({isOpen: true});
    }
  }, [props.isRisksScannerOpened]);

  useEffect(() => {
    if (props.updateInfo.isUpdateAvailable && !props.updateInfo.isUpdateNotificationShown) {
      props.setUpdateInfo({isUpdateNotificationShown: true});
      notificationService.showUpdateAvailableNotification(() => {
        props.sendTelemetryEvent({name: EVENTS.NOTIFICATION.UPDATE_APP_BUTTON_CLICK, source: 'user'});
        props.performUpdate();
      });
    }
  }, [props.updateInfo]);

  return <>{props.children}</>;
};
