import React, {FC, useEffect, useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {ButtonComponent, IconComponent} from '@figleafteam/components';

import {TDecreaseRiskItemProps} from './decrease-risk-item.types';

import './_decrease-risk-item.scss';

export const DecreaseRiskItemComponent: FC<TDecreaseRiskItemProps> = ({
  title,
  skipText,
  resolveDuration,
  resolveText,
  items,
  onClick,
  onResolve,
  isResolved,
  isHidden,
  onHidden,
  onSkip,
}) => {
  const {t} = useTranslation();
  const $item = useRef<HTMLElement>(null);
  const onResolveCallback = useCallback(() => {
    if (!$item.current) {
      return;
    }

    const marginBottom = parseFloat(window.getComputedStyle($item.current).marginBottom);
    const height = $item.current.offsetHeight;

    requestAnimationFrame(() => {
      if (!$item.current?.style) {
        return;
      }

      $item.current.addEventListener('transitionend', () => onResolve && onResolve(), {once: true});

      $item.current.style.marginTop = `-${height + marginBottom}px`;
      $item.current.style.transform = 'translateY(100%)';
      $item.current.style.opacity = '0';
    });
  }, []);

  useEffect(() => {
    if (!isHidden) {
      return;
    }
    if (onHidden) {
      onHidden();
    }

    onResolveCallback();
  }, [isHidden]);

  return (
    <section className="decrease-risk-item" ref={$item}>
      <header className="decrease-risk-item__header">
        <h3 className="decrease-risk-item__title">{title}</h3>
      </header>
      <article className="decrease-risk-item__main">
        <ul className="decrease-risk-item__items">
          {items.map(item => (
            <li className="decrease-risk-item__item" key={item.text}>
              <div style={{backgroundColor: item.color}} className="decrease-risk-item__item-icon">
                {item.icon}
              </div>
              {item.text}
            </li>
          ))}
        </ul>
        <div className="decrease-risk-item__controls">
          {!isResolved && (
            <React.Fragment>
              <div className="decrease-risk-item__resolve">
                <ButtonComponent
                  type="primary"
                  text={resolveText}
                  afterComponent={<span className="decrease-risk-item__duration">({resolveDuration})</span>}
                  fullWidth
                  onClick={onClick}
                />
              </div>
              <div className="decrease-risk-item__resolved">
                <ButtonComponent
                  type="link"
                  text={skipText}
                  onClick={() => {
                    if (onSkip) {
                      onSkip();
                    }
                    onResolveCallback();
                  }}
                />
              </div>
            </React.Fragment>
          )}
          {isResolved && (
            <div className="decrease-risk-item__completed">
              {t('Completed')}
              <div className="decrease-risk-item__completed-icon">
                <IconComponent name="check" size={24} />
              </div>
            </div>
          )}
        </div>
      </article>
    </section>
  );
};
