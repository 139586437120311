import {AppState} from '@renderer/js/redux/rootReducer';

export const getAuthState = (state: AppState) => state.authState;

export const getEmail = (state: AppState) => state.authState.email;

export const getSignupPassword = (state: AppState) => state.authState.signupPassword;

export const getSignupRePassword = (state: AppState) => state.authState.signupRePassword;

export const getSignUpErrors = (state: AppState) => state.authState.signUpErrors;

export const getLoginPassword = (state: AppState) => state.authState.loginPassword;

export const getLoginErrors = (state: AppState) => state.authState.loginErrors;

export const getConfirmAccountCode = (state: AppState) => state.authState.confirmAccountCode;

export const getConfirmDeviceCode = (state: AppState) => state.authState.confirmDeviceCode;

export const getResendCodeTime = (state: AppState) => state.authState.resendCodeTime;

export const selectAuthPopupOpened = (state: AppState) => state.authState.isAuthPopupOpened;

export const selectAuthPopupContent = (state: AppState) => state.authState.authPopupContent;
