import {startCrashReporter} from '@common/CrashReporter';
import {initLogger} from '@common/compatibility/logger';
initLogger();
startCrashReporter();

import '@renderer/styles/style.scss';
import React from 'react';
import {render} from 'react-dom';
import {App} from '@renderer/js/App';

document.addEventListener('auxclick', event => {
  event.preventDefault();
});

document.addEventListener('click', event => {
  if (event.altKey || event.ctrlKey || event.shiftKey) {
    event.preventDefault();
  }
});

render(<App />, document.getElementById('app'));
