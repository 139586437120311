import React, {Suspense} from 'react';
import {I18nextProvider} from 'react-i18next';
import {GlobalSpinnerContainer} from '@figleafteam/vault-components';
import {i18n} from '@figleafteam/helpers';
import {ToastContainer} from 'react-toastify';
import {AppRouter} from '@renderer/js/router/AppRouter';
import {Router} from 'react-router';
import {history} from '@renderer/js/router/history';
import {Provider} from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import '@renderer/styles/style.scss';
import {store} from '@renderer/js/redux/store';
import {TitleBar} from '@renderer/js/components/title-bar/title-bar.component';
import {AppContainer} from '@renderer/js/components/app/app.container';
import {MainContainer} from '@renderer/js/main.container';
import {PaywallModalContainer} from '@renderer/js/components/paywall-modal/paywall-modal.container';
import {WinWindowControlsComponent} from '@renderer/js/components/win-window-controls/win-window-controls.component';
import {ConfirmModalContainer} from '@renderer/js/components/confirm-modal/confirm-modal.container';
import {AuthPopupContainer} from '@renderer/js/components/auth/components/auth-popup/auth-popup.container';
import {ModalInstallExtensionsContainer} from '@renderer/js/components/details/components/modal-install-extensions/modal-install-extensions.container';
import {VPNModalContainer} from '@renderer/js/components/private-browsing/components/ppi-vpn-modal/vpn-modal.container';

import localesEN from '@renderer/locales/en.locale.json';

i18n.addResources('en', 'translation', localesEN);

export const App = () => (
  <>
    <ToastContainer limit={5} className="fl-toastify-container" bodyClassName="fl-toastify__body" />
    <TitleBar />
    <Provider store={store}>
      <Suspense fallback={null}>
        <I18nextProvider i18n={i18n}>
          <WinWindowControlsComponent mode="light" />
          {/* app wrapper gets app info and shows loader */}

          <AppContainer>
            <Router history={history}>
              <MainContainer>
                <AppRouter />

                {/* modals */}
                <ConfirmModalContainer />
                <ModalInstallExtensionsContainer />
                <VPNModalContainer />
                <PaywallModalContainer />
                <AuthPopupContainer />
              </MainContainer>
            </Router>
          </AppContainer>
          <GlobalSpinnerContainer />
        </I18nextProvider>
      </Suspense>
    </Provider>
  </>
);
