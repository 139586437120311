import React, {FC} from 'react';
import {Route, Redirect} from 'react-router';
import {HISTORY_STATE_FROM} from '@renderer/js/router/history';

import {SaveRecoveryKeyContainer} from '@renderer/js/components/save-recovery-key/save-recovery-key.container';

import {TPublicRouteProps} from './public-route.types';

export const PublicRoute: FC<TPublicRouteProps> = ({appInfo, children, ...route}) => {
  if (!appInfo) {
    return null;
  }

  return (
    <Route
      {...route}
      render={() => {
        if (appInfo.lockStatus) {
          return <Redirect to="/lock" />;
        }

        if (!appInfo.isAuthorized) {
          // TODO put this condition into enabled features
          // return <Redirect to={route.isPpiFunnelShown ? '/login' : '/ppi'} />;
        }

        if (appInfo.isMigrationRequired) {
          return (
            <Redirect
              to={{
                pathname: appInfo?.hasSecurityKey ? '/recovery/password' : '/migration',
                state: {from: HISTORY_STATE_FROM.MIGRATION},
              }}
            />
          );
        }

        return (
          <React.Fragment>
            {children}
            <SaveRecoveryKeyContainer />
          </React.Fragment>
        );
      }}
    />
  );
};
