import React, {FC} from 'react';
import './_vault.scss';
import {HeaderContainer} from '@renderer/js/components/vault/components/header/header.container';
import {openURL} from '@common/compatibility/openURL';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {VaultElectron} from '@figleafteam/vault-electron';

export const VaultComponent: FC = () => {
  return (
    <section className="vault-container">
      <div className="vault-container__header">
        <HeaderContainer />
      </div>
      <div className="vault-container__wrapper">
        <VaultElectron
          baseName="vault"
          theme="FL"
          notificationService={notificationService}
          openUrl={openURL}
          features={{
            isMaskedCardEnable: ENABLED_FEATURES.UiFeatures.CreditCardMasking,
          }}
        />
      </div>
    </section>
  );
};
