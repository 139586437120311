import {all, fork} from 'redux-saga/effects';
import {monitorWatcher, vaultWatcher, monetizationWatcher} from '@figleafteam/store';
import {authSagaWatcher} from '@renderer/js/components/auth/redux/sagas';
import {privateBrowsingWatcher} from '@renderer/js/components/private-browsing/redux/sagas';
import {unlockUserWatcher} from '@renderer/js/components/unlock-user/redux/sagas';
import {settingsWatcher} from '@renderer/js/components/settings/redux/sagas';
import {enterSecurityKeyWatcher} from '@renderer/js/components/enter-security-key/redux/sagas';
import {recoverySagaWatcher} from '@renderer/js/components/recovery/redux/sagas';
import {dashboardWatcher} from '@renderer/js/components/dashboard/redux/sagas';
import {eventsWatcher} from '@renderer/js/events/redux/sagas';
import {saveRecoveryKeyModalWatcher} from '@renderer/js/components/save-recovery-key/redux/sagas';
import {appWatcher} from '@renderer/js/components/app/redux/sagas';
import {quickScanSagaWatcher} from '@renderer/js/components/ppi-funnel/redux/sagas';

export function* rootSaga() {
  yield all([
    fork(monitorWatcher),
    fork(appWatcher),
    fork(authSagaWatcher),
    fork(vaultWatcher),
    fork(privateBrowsingWatcher),
    fork(monetizationWatcher),
    fork(unlockUserWatcher),
    fork(settingsWatcher),
    fork(enterSecurityKeyWatcher),
    fork(recoverySagaWatcher),
    fork(dashboardWatcher),
    fork(eventsWatcher),
    fork(saveRecoveryKeyModalWatcher),
    fork(quickScanSagaWatcher),
  ]);
}
