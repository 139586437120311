import {call, put, takeEvery} from 'redux-saga/effects';
import {getSettingValue, setSettingValue} from '@figleafteam/api-communication';
import {GET_TIP, TIP_STORAGE_NAME} from '@renderer/js/components/dashboard/redux/constants';
import {Tip, TipFromStore} from '@renderer/js/components/dashboard/redux/types';
import {setTip} from '@renderer/js/components/dashboard/redux/actions';
import {tips} from '@renderer/js/components/dashboard/redux/tips';

function getStubTip(index: number) {
  return tips[index];
}

function generateTipIndex(): number {
  return Math.floor(Math.random() * tips.length);
}

function getDateNow() {
  return new Date(Date.now()).getDate().toString();
}

function createTip(): TipFromStore {
  return {
    index: generateTipIndex(),
    date: getDateNow(),
  };
}

export function* getTipSaga() {
  try {
    const tipFromStoreResponse: {[TIP_STORAGE_NAME]: string} = yield call(getSettingValue, TIP_STORAGE_NAME);
    const tipFromStore: TipFromStore = tipFromStoreResponse[TIP_STORAGE_NAME]
      ? JSON.parse(tipFromStoreResponse[TIP_STORAGE_NAME])
      : null;

    const isOldTip = !tipFromStore || tipFromStore?.date !== getDateNow() || tipFromStore.index >= tips.length;
    if (isOldTip) {
      const tipForStore = createTip();
      yield call(setSettingValue, TIP_STORAGE_NAME, JSON.stringify(tipForStore));
      yield put(setTip(getStubTip(tipForStore.index)));
      return;
    }

    const tip: Tip = getStubTip(tipFromStore.index);
    yield put(setTip(tip));
  } catch (e) {
    console.error(e);
    return;
  }
}

export function* dashboardWatcher() {
  yield takeEvery(GET_TIP, getTipSaga);
}
