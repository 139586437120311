import {TDetailsState, TResetDetailsStateAction, TSetDetailsStateAction} from '../types';
import {RESET_DETAILS_STATE, SET_DETAILS_STATE} from '../constants';

const initialState: TDetailsState = {
  isExtensionsModalOpened: false,
  isOnboardingOpened: false,
};

type TActions = TSetDetailsStateAction | TResetDetailsStateAction;

export const detailsStateReducer = (state = initialState, action: TActions): TDetailsState => {
  switch (action.type) {
    case SET_DETAILS_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_DETAILS_STATE:
      return initialState;
    default:
      return state;
  }
};
