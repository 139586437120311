import React, {useCallback, memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, InputPasswordComponent, InputComponent} from '@figleafteam/components';
import {openURL} from '@common/compatibility/openURL';
import {checkPasswordCriteria} from '@renderer/js/components/auth/utils/checkPasswordCriteria';
import {history} from '@renderer/js/router/history';

import '@renderer/js/components/auth/_password-drop-down-hint.scss';
import {SignupProps} from './signup.types';

type InputKeys = 'email' | 'signupPassword' | 'signupRePassword';

export const SignUpComponent = memo<SignupProps>(props => {
  const {t} = useTranslation();
  const [isPasswordHintShown, setPasswordHintShown] = useState(false);
  const handleFieldChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = ev.target;
    props.setAuthInfo(name as InputKeys, value);

    if (name === 'email') {
      props.resetSignupEmailValidationStatus();
    } else {
      props.resetSignupPasswordsValidationStatus();
    }
  }, []);

  const handleSubmit = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    props.signupSubmit();
  }, []);

  const goToLogIn = useCallback(() => {
    history.push('/login');
  }, []);

  const openEula = useCallback(() => {
    openURL('https://figleaf.com/eula/');
  }, []);

  const openPolicy = useCallback(() => {
    openURL('https://figleaf.com/privacy-policy/');
  }, []);

  const errorsConfig = {
    email: {
      error: props.signUpErrors.emailInvalid,
      bottomText: props.signUpErrors.emailErrorText,
    },
    password: {
      error: props.signUpErrors.passwordInvalid,
      bottomText: props.signUpErrors.passwordErrorText,
    },
    rePassword: {
      error: props.signUpErrors.rePasswordInvalid,
      bottomText: props.signUpErrors.rePasswordErrorText,
    },
  };

  const liveChecks = checkPasswordCriteria(props.signupPassword);

  const getHintItemClass = (property: keyof ReturnType<typeof checkPasswordCriteria>) => {
    return liveChecks[property]
      ? 'password-drop-down-hint__item'
      : 'password-drop-down-hint__item password-drop-down-hint__item--valid';
  };

  return (
    <div className="auth">
      <div className="auth__container">
        <h1 className="auth__title">{t('Create account')}</h1>
        <form className="auth__form" onSubmit={handleSubmit}>
          <div className="auth__form-input">
            <InputComponent
              value={props.email}
              onChange={handleFieldChange}
              name="email"
              type="text"
              placeholder={t('Email')}
              {...errorsConfig.email}
            />
          </div>
          <div className="auth__form-input">
            <InputPasswordComponent
              onChange={handleFieldChange}
              onFocusChange={setPasswordHintShown}
              value={props.signupPassword}
              name="signupPassword"
              type="password"
              placeholder={t('Password')}
              {...errorsConfig.password}
            />
            {isPasswordHintShown && (liveChecks.digit || liveChecks.mixedLetterCase || liveChecks.passwordLength) && (
              <div className="password-drop-down-hint">
                <div className="password-drop-down-hint__inner">
                  <h6 className="password-drop-down-hint__title">{t('Password requirements:')}</h6>
                  <ul className="password-drop-down-hint__list">
                    <li className={getHintItemClass('passwordLength')}>{t('At least 8 characters')}</li>
                    <li className={getHintItemClass('mixedLetterCase')}>{t('Mixture of upper & lowercase')}</li>
                    <li className={getHintItemClass('digit')}>{t('At least 1 digit')}</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="auth__form-input">
            <InputPasswordComponent
              onChange={handleFieldChange}
              value={props.signupRePassword}
              name="signupRePassword"
              type="password"
              placeholder={t('Confirm password')}
              {...errorsConfig.rePassword}
            />
          </div>
          <p className="auth__form-text">
            {t('By clicking the “Create account” button below, you agree to the')}{' '}
            <span onClick={openEula} role="button" className="auth__link">
              {t('{{partner}} Software License and Terms of Service', {partner: 'Figleaf'})}
            </span>{' '}
            {t('and acknowledge the')}{' '}
            <span onClick={openPolicy} role="button" className="auth__link">
              {t('Privacy Policy')}
            </span>
          </p>
          <div className="auth__buttons">
            <ButtonComponent fullWidth buttonType="submit" type="primary" text="Create account" />
          </div>
        </form>
      </div>
      <div className="auth__bottom-text-wrapper">
        <p className="auth__bottom-text">
          {t('Already use {{partner}}?', {partner: 'FigLeaf'})}
          <ButtonComponent onClick={goToLogIn} buttonType="button" text="Log In" type="link" />
        </p>
      </div>
    </div>
  );
});

SignUpComponent.displayName = 'SignUpComponent';
