import React, {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ButtonComponent,
  ModalComponent,
  ButtonsGroup,
  ImportantCopyPlaceholderComponent,
} from '@figleafteam/components';
import {EVENTS} from '@common/events';
import IconKey64 from '@renderer/images/icon-key-64.svg';
import IconExclamation from '@renderer/images/icon-exclamation-big.svg';

import {SaveRecoveryKeyProps} from './save-recovery-key.types';
import './_modal-save-key.scss';

export const SaveRecoveryKeyComponent = memo<SaveRecoveryKeyProps>(props => {
  const {t} = useTranslation();

  useEffect(() => {
    if (props.recoveryKeyModalState.isOpen) {
      props.getRecoveryKey();
      props.sendTelemetryEvent({name: EVENTS.RECOVERY_KEY_POPUP.SHOW, source: 'app'});
    }
  }, [props.recoveryKeyModalState.isOpen]);

  const recoveryKeySaveCallback = () => {
    props.setRecoveryKeySavedFlag();
  };

  const closeRecoveryKeyHintModal = () => {
    props.setRecoveryKeyModalState({isOpen: false, key: '', isNewRecoveryKey: false});
  };

  return (
    <ModalComponent
      width={750}
      showCloseButton
      standardBodyWrap
      autoFocus
      isOpen={props.recoveryKeyModalState.isOpen}
      handleClose={() => {
        closeRecoveryKeyHintModal();
        props.sendTelemetryEvent({name: EVENTS.RECOVERY_KEY_POPUP.CLOSE_BUTTON_CLICK, source: 'user'});
      }}
    >
      <div className="modal-save-key">
        <div className="modal-save-key__image">
          {!props.recoveryKeyModalState.isPPI && <IconKey64 />}
          {props.recoveryKeyModalState.isPPI && <IconExclamation />}
        </div>
        {!props.recoveryKeyModalState.isPPI && (
          <h2 className="modal-save-key__title">
            {t('Important: Save your')}{' '}
            {props.recoveryKeyModalState.isNewRecoveryKey ? (
              <span className="modal-save-key__title--green">{t('new Recovery Key')}</span>
            ) : (
              t('Recovery Key')
            )}
            <br />
          </h2>
        )}
        {props.recoveryKeyModalState.isPPI && (
          <h2 className="modal-save-key__title">
            {t('Save Recovery Key to guarantee your access to the saved passwords')}
          </h2>
        )}
        {!props.recoveryKeyModalState.isPPI && (
          <h3 className="modal-save-key__subtitle">
            {t('Without it you can`t recover your account if you forget password')}
          </h3>
        )}
        {props.recoveryKeyModalState.isPPI && (
          <p className="modal-save-key__text">
            {t('Without it you can`t recover your FigLeaf account password and decrypt saved data')}
          </p>
        )}
        {!props.recoveryKeyModalState.isPPI && (
          <p className="modal-save-key__text">
            {props.recoveryKeyModalState.isNewRecoveryKey
              ? t(
                  'Please, update the old Recovery Key with a new one. The old key will no longer help you in case you forgot your password. Please, update the old Recovery Key with a new one.',
                )
              : t(
                  'We encrypt the account with your Password, so we cannot access any data you store with us to fulfill our "Zero Knowledge" promise to you. The Recovery Key is the only way you may decrypt your data in case you forget the Password. Please save it and keep in a safe place.',
                )}
          </p>
        )}
        <ImportantCopyPlaceholderComponent text={props.recoveryKeyModalState.key} />
        <div className="modal-save-key__buttons">
          <ButtonsGroup
            buttonsWidth="full"
            buttons={[
              {
                writeKey: (
                  <ButtonComponent
                    text={t('I wrote it down')}
                    fullWidth
                    onClick={() => {
                      closeRecoveryKeyHintModal();
                      recoveryKeySaveCallback();
                      props.sendTelemetryEvent({name: EVENTS.RECOVERY_KEY_POPUP.SKIP_BUTTON_CLICK, source: 'user'});
                    }}
                    type="empty-bordered"
                  />
                ),
              },
              {
                saveKey: (
                  <ButtonComponent
                    text={t('Save key as PDF')}
                    fullWidth
                    onClick={() => {
                      props.saveRecoveryKey();
                      closeRecoveryKeyHintModal();
                      recoveryKeySaveCallback();
                      props.sendTelemetryEvent({name: EVENTS.RECOVERY_KEY_POPUP.SAVE_BUTTON_CLICK, source: 'user'});
                    }}
                    type="primary"
                    disabled={ENABLED_FEATURES.Platform === 'web'}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </ModalComponent>
  );
});

SaveRecoveryKeyComponent.displayName = 'SaveRecoveryKeyComponent';
