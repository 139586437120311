import {SEND_TELEMETRY_EVENT} from './constants';

export const sendTelemetryEvent = (
  event: {name: string; source: 'user' | 'app'; payload?: Record<string, unknown>},
  immediately?: boolean,
) => ({
  type: SEND_TELEMETRY_EVENT,
  payload: {
    event,
    immediately,
  },
});
