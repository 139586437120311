import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {PPIFinalModalComponent} from './ppi-final-modal.component';

const mapStateToProps = (state: AppState) => ({
  appInfo: getAppInfo(state),
});

const mapDispatchToProps = {
  sendTelemetryEvent,
};

export const PPIFinalModalContainer = connect(mapStateToProps, mapDispatchToProps)(PPIFinalModalComponent);
