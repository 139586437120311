import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, ConfirmationFormComponent} from '@figleafteam/components';
import {history} from '@renderer/js/router/history';

import {ConfirmAccountProps} from './confirm-account.types';
import '@renderer/js/components/auth/_confirm.scss';

export const ConfirmAccountComponent = memo<ConfirmAccountProps>(props => {
  const {t} = useTranslation();
  const requestConfirmationCode = () => {
    props.requestConfirmAccountCode(props.email);
  };

  const goBackToSignup = () => {
    history.push('/signup');
  };

  return (
    <div className="confirm">
      <div className="confirm__container">
        <ConfirmationFormComponent
          title={t('Verify email to activate your account')}
          email={props.email}
          code={props.code}
          resendTimer={props.resendTime}
          updateSecondsLeft={props.setResendCodeTime}
          handleResendConfirmationCode={requestConfirmationCode}
          setConfirmationCode={props.setConfirmAccountCode}
        />
        <div className="confirm__bottom-text-wrapper">
          <p className="confirm__bottom-text">{t('Don’t forget to check your spam folder.')}</p>
          <p className="confirm__bottom-text">
            {t('Wrong email?')}{' '}
            <ButtonComponent buttonType="button" onClick={goBackToSignup} text={t('Enter Another')} type="link" />
          </p>
        </div>
      </div>
    </div>
  );
});

ConfirmAccountComponent.displayName = 'ConfirmAccountComponent';
