import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {IconComponent} from '@figleafteam/components';
import {PPIRisksReportRiskCategoryContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-risk-category/ppi-risks-report-risk-category.container';
import {PPIRisksReportSectionComponent} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-section/ppi-risks-report-section.component';
import {TPPIRisksReportExposedLocationsProps} from './ppi-risks-report-exposed-locations.types';
import {getRecommendedActionItem} from '../ppi-risks-report-risk-category/ppi-risks-report-risk-category.component';

export const PPIRisksReportExposedLocationsComponent: FC<TPPIRisksReportExposedLocationsProps> = ({
  homeTitleTheft,
  resolvedRisks,
}) => {
  const {t} = useTranslation();
  const isVPNResolved = resolvedRisks.includes('vpn');

  return (
    <>
      {!isVPNResolved && (
        <PPIRisksReportSectionComponent
          title={t('Why am I at risk?')}
          description={t(
            'Advertisers, greedy companies, and websites collect your IP address to identify you, your physical location, and often then use this information to sell you more stuff. We recommend hiding your IP address by using a VPN (virtual private network) connection, every time you access the internet.',
          )}
        >
          <PPIRisksReportRiskCategoryContainer
            title={t('Locations')}
            icon={<IconComponent name="location" size={24} />}
            items={homeTitleTheft.items.locations.data}
          />
          <PPIRisksReportRiskCategoryContainer
            title={t('IP addresses')}
            icon={<IconComponent name="router" size={24} />}
            items={homeTitleTheft.items.ips.data}
          />
        </PPIRisksReportSectionComponent>
      )}
      <PPIRisksReportSectionComponent
        title={t('Recommended protection')}
        description={t('We recommend using following tools to decrease this risk')}
      >
        <PPIRisksReportRiskCategoryContainer
          title={t('VPN')}
          icon={<IconComponent name="location" size={24} />}
          items={[getRecommendedActionItem(isVPNResolved)]}
        />
      </PPIRisksReportSectionComponent>
    </>
  );
};
