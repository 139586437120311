import React from 'react';
import {Switch} from 'react-router';
import {VaultComponent} from '@renderer/js/components/vault/vault.component';
import {SettingsContainer} from '@renderer/js/components/settings/settings.container';
import {SideBarContainer} from '@renderer/js/components/side-bar/side-bar.container';
import {PrivateBrowsingContainer} from '@renderer/js/components/private-browsing/private-browsing.container';
import {DashboardContainer} from '@renderer/js/components/dashboard/dashboard.container';
import {MonitorComponent} from '@renderer/js/components/monitor/monitor.component';
import {PPIDecreaseRisksPageContainer} from '@renderer/js/components/ppi-funnel/components/ppi-decrease-risks-page/ppi-decrease-risks-page.container';

import {PublicRouteContainer} from '@renderer/js/components/public-route/public-route.container';

export const RouterWithSideBar = () => (
  <Switch>
    <PublicRouteContainer path="/dashboard/ppi">
      <SideBarContainer>
        <PPIDecreaseRisksPageContainer />
      </SideBarContainer>
    </PublicRouteContainer>
    <PublicRouteContainer exact path="/dashboard">
      <SideBarContainer>
        <DashboardContainer />
      </SideBarContainer>
    </PublicRouteContainer>
    <PublicRouteContainer path="/monitoring">
      <SideBarContainer>
        <MonitorComponent />
      </SideBarContainer>
    </PublicRouteContainer>
    <PublicRouteContainer path="/vault">
      <SideBarContainer>
        <VaultComponent />
      </SideBarContainer>
    </PublicRouteContainer>
    <PublicRouteContainer exact path="/browse">
      <SideBarContainer>
        <PrivateBrowsingContainer />
      </SideBarContainer>
    </PublicRouteContainer>
    <PublicRouteContainer path="/settings">
      <SideBarContainer>
        <SettingsContainer />
      </SideBarContainer>
    </PublicRouteContainer>
    <PublicRouteContainer exact path="/help">
      <SideBarContainer>help</SideBarContainer>
    </PublicRouteContainer>
  </Switch>
);
