import {useEffect} from 'react';

import {history} from '@renderer/js/router/history';

import {TAppInfoState} from '@renderer/js/components/app/redux/types';

export const useCheckAuth = (appInfo: TAppInfoState['appInfo']) => {
  useEffect(() => {
    if (!appInfo || !appInfo.isAuthorized || appInfo.lockStatus || appInfo.isMigrationRequired) {
      return;
    }

    history.push('/');
  }, [appInfo]);
};
