import React, {memo, useEffect} from 'react';

import {EVENTS} from '@common/events';
import {ModalComponent} from '@figleafteam/components';
import {VpnContainer} from '@renderer/js/components/private-browsing/components/vpn/vpn.container';
import {VPNModalProps} from './vpn-modal.types';
import './_vpn-modal.scss';

export const VPNModalComponent = memo<VPNModalProps>(
  ({sendTelemetryEvent, isVpnEnabled, setIsVpnModalOpened, isModalOpened}) => {
    useEffect(() => {
      if (isModalOpened) {
        sendTelemetryEvent({name: EVENTS.PPI.DECREASE_RISK_PAGE_SHOW_VPN_MODAL, source: 'app'});
      }
    }, [isModalOpened]);

    useEffect(() => {
      setIsVpnModalOpened(false);
    }, [isVpnEnabled]);

    return (
      <ModalComponent
        width={640}
        isOpen={isModalOpened}
        handleClose={() => setIsVpnModalOpened(false)}
        showCloseButton
        closeOnClickOutside
      >
        <section className="vpn-modal">
          <VpnContainer isModal />
        </section>
      </ModalComponent>
    );
  },
);

VPNModalComponent.displayName = 'VPNModalComponent';
