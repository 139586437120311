import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getInstalledBrowsersReport} from '@renderer/js/components/settings/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {openWebExtensionStore} from '@renderer/js/components/settings/redux/actions';
import {selectIsExtensionsModalOpened} from '@renderer/js/components/details/redux/selectors';
import {setDetailsState} from '@renderer/js/components/details/redux/actions';
import {ModalInstallExtensionsComponent} from './modal-install-extensions.component';

const mapStateToProps = (state: AppState) => ({
  browsersReport: getInstalledBrowsersReport(state),
  isModalOpen: selectIsExtensionsModalOpened(state),
});
const mapDispatchToProps = {
  sendTelemetryEvent,
  openWebExtensionStore,
  setDetailsState,
};

export const ModalInstallExtensionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalInstallExtensionsComponent);
