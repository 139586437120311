import React, {FC, useRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {TabsComponent, IconComponent} from '@figleafteam/components';

import {PPIRisksReportTabComponent} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-tab/ppi-risks-report-tab.component';

import {PPIRisksReportHackedAccountsContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-hacked-accounts/ppi-report-hacked-accounts.container';
import {PPIRisksReportIdentityExposureContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-identity-exposure/ppi-risks-report-identity-exposure.container';
import {PPIRisksReportExposedLocationsContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-exposed-locations/ppi-risks-report-exposed-locations.container';

import {EVENTS} from '@common/events';

import AccountHackingIcon from './images/account-hacking.svg';
import PrivateDataSellingIcon from './images/private-data-selling.svg';
import UnsecuredNetworkUsageIcon from './images/unsecured-network-usage.svg';

import {TRisksReportProps} from './ppi-risks-report.types';

import './_ppi-risks-report.scss';

type TabKey = 'hacked-accounts' | 'identity-exposure' | 'exposed-locations';

const EVENTS_MAP: {[key in TabKey]: string} = {
  'hacked-accounts': EVENTS.PPI.SELECT_RISK_REPORT_SELECT_HACKED_ACCOUNTS,
  'identity-exposure': EVENTS.PPI.SELECT_RISK_REPORT_SELECT_IDENTITY_EXPOSURE,
  'exposed-locations': EVENTS.PPI.SELECT_RISK_REPORT_SELECT_EXPOSED_LOCATIONS,
};

export const PPIRisksReportComponent: FC<TRisksReportProps> = ({
  riskCategories,
  scannerBodyRef,
  sendTelemetryEvent,
}) => {
  const {t} = useTranslation();

  const [activeTab, setActiveTab] = useState<TabKey>('hacked-accounts');
  const $scrollable = useRef<HTMLDivElement>(null);

  const handleTabChange = (tab: TabKey) => {
    setActiveTab(tab);
    sendTelemetryEvent({name: EVENTS_MAP[tab], source: 'user'});
  };

  useEffect(() => {
    if (!$scrollable.current) {
      return;
    }

    const onScroll = (e: Event) => {
      if (!e.target) {
        return;
      }

      const target = e.target as HTMLDivElement;

      requestAnimationFrame(() => {
        const percent = target.scrollTop / (target.scrollHeight - target.offsetHeight);
        scannerBodyRef.current?.style.setProperty('--scroll', Number.isNaN(percent) ? '1' : String(percent));
      });
    };

    $scrollable.current.addEventListener('scroll', onScroll);

    return () => {
      $scrollable.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="risks-report__scrollable" ref={$scrollable}>
      <div className="risks-report__tabs">
        <TabsComponent
          items={[
            {
              key: 'hacked-accounts',
              text: (
                <PPIRisksReportTabComponent
                  icon={<IconComponent name="globe" size={24} />}
                  risk={riskCategories.accountHijacking.riskType}
                  title={t('Accounts takeover')}
                />
              ),
            },
            {
              key: 'identity-exposure',
              text: (
                <PPIRisksReportTabComponent
                  icon={<IconComponent name="user-round" size={24} />}
                  risk={riskCategories.identityExposure.riskType}
                  title={t('Identity exposure')}
                />
              ),
            },
            {
              key: 'exposed-locations',
              text: (
                <PPIRisksReportTabComponent
                  icon={<IconComponent name="location" size={24} />}
                  risk={riskCategories.homeTitleTheft.riskType}
                  title={t('Exposed locations')}
                />
              ),
            },
          ]}
          align="center"
          activeTab={activeTab}
          onChange={tab => handleTabChange(tab as TabKey)}
        />
      </div>

      <article className="risks-report__main">
        {activeTab === 'hacked-accounts' && <PPIRisksReportHackedAccountsContainer />}
        {activeTab === 'identity-exposure' && <PPIRisksReportIdentityExposureContainer />}
        {activeTab === 'exposed-locations' && <PPIRisksReportExposedLocationsContainer />}
      </article>

      <footer className="risks-report__footer">
        <h3 className="risks-report__description-title">{t('How did you get this data?')}</h3>
        <div className="risks-report__description-text">
          {t(
            'First of all, we didn&apos;t hack you and didn&apos;t spy on you (probably one of the few who doesn&apos;t). We match your emails and phone numbers against the largest leaked databases to see if any of it was exposed.',
          )}
        </div>

        <h4 className="risks-report__expose-title">{t('There is several ways your private data can be exposed:')}</h4>
        <ul className="risks-report__expose-list">
          <li className="risks-report__expose-item">
            <div className="risks-report__expose-icon">
              <AccountHackingIcon />
            </div>
            <div className="risks-report__expose-data">
              <div className="risks-report__expose-item-title">{t('Account hacking')}</div>
              <div className="risks-report__expose-item-text">
                {t(
                  'When a site that has collected your data was hacked all private data stored with it becomes hacker&apos;s asset',
                )}
              </div>
            </div>
          </li>
          <li className="risks-report__expose-item">
            <div className="risks-report__expose-icon">
              <PrivateDataSellingIcon />
            </div>
            <div className="risks-report__expose-data">
              <div className="risks-report__expose-item-title">{t('Private data selling')}</div>
              <div className="risks-report__expose-item-text">
                {t(
                  'Some of the websites even sell your data to third-parties (and say this publicly in their Privacy Policy)',
                )}
              </div>
            </div>
          </li>
          <li className="risks-report__expose-item">
            <div className="risks-report__expose-icon">
              <UnsecuredNetworkUsageIcon />
            </div>
            <div className="risks-report__expose-data">
              <div className="risks-report__expose-item-title">{t('Unsecured network usage')}</div>
              <div className="risks-report__expose-item-text">
                {t(
                  'When you connect to a public Wi-Fi without using a VPN everything you browse and type might be interjected',
                )}
              </div>
            </div>
          </li>
        </ul>
      </footer>
    </div>
  );
};
