export function isWeakPassword(password: string) {
  const regex = /^(?=.*([A-Z]){1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,}$/;

  // at least 1 uppercase letter
  // at least 1 lowercase letter
  // at least 1 special character
  // at least 1 number
  // minimal length is 8

  return !regex.test(password);
}
