import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, ConfirmationFormComponent} from '@figleafteam/components';
import {history, getFromState, HISTORY_STATE_FROM} from '@renderer/js/router/history';

import {ConfirmRecoveryProps} from './confirm-recovery.types';

import '@renderer/js/components/auth/_confirm.scss';

export const ConfirmRecoveryComponent = memo<ConfirmRecoveryProps>(props => {
  const from = getFromState();
  const {t} = useTranslation();

  const requestConfirmationCode = () => {
    props.requestRecoveryCode(props.email);
  };
  const goBackHandler = () => {
    history.goBack();
  };

  return (
    <div className="confirm">
      <div className="confirm__container">
        <ConfirmationFormComponent
          title={t('Verify a password change')}
          email={props.email}
          code={props.code}
          resendTimer={props.resendTime}
          updateSecondsLeft={props.setResendCodeTime}
          handleResendConfirmationCode={requestConfirmationCode}
          setConfirmationCode={props.setConfirmRecoveryCode}
        />
        <div className="confirm__bottom-text-wrapper confirm__bottom-text-wrapper--center">
          <p className="confirm__bottom-text">
            {t('Remember you password?')}{' '}
            {from === HISTORY_STATE_FROM.UNLOCK ? (
              <ButtonComponent
                type="link"
                buttonType="button"
                text={t('Go back to lock screen')}
                onClick={goBackHandler}
              />
            ) : (
              <ButtonComponent type="link" buttonType="button" text={t('Go back to login')} onClick={goBackHandler} />
            )}
          </p>
        </div>
      </div>
    </div>
  );
});

ConfirmRecoveryComponent.displayName = 'ConfirmRecoveryComponent';
