import React, {FC} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import {IconComponent} from '@figleafteam/components';
import {TRisksControlProps} from './risks-control.types';
import {PPIRisksScannerContainer} from '../ppi-risks-scanner/ppi-risks-scanner.container';

import './_risks-control.scss';

export const RisksControlComponent: FC<TRisksControlProps> = ({
  exposedRiskCategoriesCount,
  isRisksScannerOpened,
  scannerStep,
  setPpiFunnelState,
}) => {
  const {t} = useTranslation();

  const className = classNames('risks-control', {
    'risks-control--color-red': scannerStep === 'report' && exposedRiskCategoriesCount >= 3,
    'risks-control--active': isRisksScannerOpened,
  });

  return (
    <React.Fragment>
      <button className={className} type="button" onClick={() => setPpiFunnelState({isRisksScannerOpened: true})}>
        <span className="risks-control__bell">
          <IconComponent name="bell" size={24} />
        </span>
        <span className="risks-control__text">
          {scannerStep === 'report'
            ? t('{{count}} privacy risks found', {count: exposedRiskCategoriesCount})
            : t('Unknown risk level')}
        </span>
        <span className="risks-control__arrow">
          <IconComponent name="arrow-right" size={20} />
        </span>
      </button>
      {isRisksScannerOpened && <PPIRisksScannerContainer />}
    </React.Fragment>
  );
};
