import React, {FC, useState, useEffect} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, ButtonIconComponent, IconComponent} from '@figleafteam/components';
import {KEYBOARD_KEYS} from '@renderer/js/constants/keyboard-keys';
import {EVENTS} from '@common/events';

import {Props} from '@renderer/js/components/details/components/onboarding/onboarding.types';
import './_onboarding.scss';

export const OnboardingComponent: FC<Props> = props => {
  const {t} = useTranslation();

  useEffect(() => {
    if (props.detailsKey === 'monitoring') {
      props.sendTelemetryEvent({name: EVENTS.MONITOR.ONBOARDING_SHOW, source: 'app'});
    }
    if (props.detailsKey === 'controls') {
      props.sendTelemetryEvent({name: EVENTS.CONTROLS.ONBOARDING_SHOW, source: 'app'});
    }
    if (props.detailsKey === 'vault') {
      props.sendTelemetryEvent({name: EVENTS.VAULT.ONBOARDING_SHOW, source: 'app'});
    }
  }, []);
  const [onboardingStep, setOnboardingStep] = useState<number>(0);

  const classList = classNames('onboarding', {'onboarding--vault': props.modifier === 'vault'});

  const steps = props.steps.map((step, index) => {
    const isLastStep = index === props.steps.length - 1;
    return (
      <div key={step.title} className="onboarding__step">
        <div className="onboarding__step-content">
          <div className="onboarding__step-text">
            <h3 className="onboarding__step-title">{step.title}</h3>
            <p className="onboarding__step-description">{step.description}</p>
          </div>
          {isLastStep ? (
            props.onboardingFinishButton && props.isAuthorized ? (
              props.onboardingFinishButton
            ) : (
              <ButtonComponent
                onClick={() => {
                  if (props.detailsKey === 'monitoring') {
                    props.sendTelemetryEvent({name: EVENTS.MONITOR.ONBOARDING_FINISH_BUTTON_CLICK, source: 'user'});
                  }
                  if (props.detailsKey === 'controls') {
                    props.sendTelemetryEvent({name: EVENTS.CONTROLS.ONBOARDING_FINISH_BUTTON_CLICK, source: 'user'});
                  }
                  if (props.detailsKey === 'vault') {
                    props.sendTelemetryEvent({name: EVENTS.VAULT.ONBOARDING_FINISH_BUTTON_CLICK, source: 'user'});
                  }
                  props.closeOnboarding();
                }}
                text={t('Okay, got it!')}
                type="primary"
                fullWidth
              />
            )
          ) : (
            <ButtonComponent
              onClick={() => {
                if (props.detailsKey === 'monitoring') {
                  props.sendTelemetryEvent({name: EVENTS.MONITOR.ONBOARDING_NEXT_BUTTON_CLICK, source: 'user'});
                }
                if (props.detailsKey === 'controls') {
                  props.sendTelemetryEvent({name: EVENTS.CONTROLS.ONBOARDING_NEXT_BUTTON_CLICK, source: 'user'});
                }
                if (props.detailsKey === 'vault') {
                  props.sendTelemetryEvent({name: EVENTS.VAULT.ONBOARDING_NEXT_BUTTON_CLICK, source: 'user'});
                }
                setOnboardingStep(index + 1);
              }}
              text={t('Next')}
              type="primary"
              fullWidth
            />
          )}
        </div>
        <div className="onboarding__step-image">{step.img}</div>
      </div>
    );
  });

  return (
    <div className={classList}>
      <div className="onboarding__arrow" />
      <div className="onboarding__wrapper">
        <div className="onboarding__inner">
          <div className="onboarding__header">
            <nav className="onboarding__nav">
              {props.steps.length > 1 &&
                props.steps.map((step, i) => {
                  return (
                    <div
                      key={step.title}
                      role="button"
                      tabIndex={0}
                      onClick={() => setOnboardingStep(i)}
                      onKeyPress={(ev: React.KeyboardEvent) => {
                        if (ev.key === KEYBOARD_KEYS.enter) {
                          setOnboardingStep(i);
                        }
                      }}
                      className={classNames('onboarding__nav-item', {
                        'onboarding__nav-item--active': onboardingStep === i,
                      })}
                    >
                      {step.title}
                    </div>
                  );
                })}
            </nav>
            <div className="onboarding__close-button">
              <ButtonIconComponent
                onClick={() => {
                  if (props.detailsKey === 'monitoring') {
                    props.sendTelemetryEvent({name: EVENTS.MONITOR.ONBOARDING_CLOSE_BUTTON_CLICK, source: 'user'});
                  }
                  if (props.detailsKey === 'controls') {
                    props.sendTelemetryEvent({name: EVENTS.CONTROLS.ONBOARDING_CLOSE_BUTTON_CLICK, source: 'user'});
                  }
                  if (props.detailsKey === 'vault') {
                    props.sendTelemetryEvent({name: EVENTS.VAULT.ONBOARDING_CLOSE_BUTTON_CLICK, source: 'user'});
                  }
                  props.closeOnboarding();
                }}
              >
                <IconComponent name="close-small" size={24} />
              </ButtonIconComponent>
            </div>
          </div>
          <div className="onboarding__content">{steps[onboardingStep]}</div>
        </div>
      </div>
    </div>
  );
};
