/* istanbul ignore file */
import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {RouterWithSideBar} from '@renderer/js/router/router-with-side-bar';

import {SignUpContainer} from '@renderer/js/components/auth/components/signup/signup.container';
import {LoginContainer} from '@renderer/js/components/auth/components/login/login.container';
import {ConfirmAccountContainer} from '@renderer/js/components/auth/components/confirm-account/confirm-account.container';
import {ConfirmDeviceContainer} from '@renderer/js/components/auth/components/confirm-device/confirm-device.container';
import {AuthOnboardingContainer} from '@renderer/js/components/auth-onboarding/auth-onboarding.container';
import {UnlockPageContainer} from '@renderer/js/components/unlock-user/unlock-page.container';

import {RecoveryWrapperContainer} from '@renderer/js/components/recovery/components/recovery-wrapper/recovery-wrapper.container';
import {EnterRecoveryKeyContainer} from '@renderer/js/components/recovery/components/enter-recovery-key/enter-recovery-key.container';
import {ConfirmRecoveryContainer} from '@renderer/js/components/recovery/components/confirm-recovery/confirm-recovery.container';
import {ChangePasswordContainer} from '@renderer/js/components/recovery/components/change-password/change-password.container';

import {EnterSecurityKeyContainer} from '@renderer/js/components/enter-security-key/enter-security-key.container';

import {PPIMonetizationPageContainer} from '@renderer/js/components/ppi-funnel/components/ppi-monetization-page/ppi-monetization-page.container';

export const AppRouter = () => (
  <Switch>
    <Route path="/login" exact>
      <AuthOnboardingContainer>
        <LoginContainer />
      </AuthOnboardingContainer>
    </Route>
    <Route path="/signup/confirm-account" exact>
      <AuthOnboardingContainer>
        <ConfirmAccountContainer />
      </AuthOnboardingContainer>
    </Route>
    <Route path="/signup/confirm-device" exact>
      <AuthOnboardingContainer>
        <ConfirmDeviceContainer />
      </AuthOnboardingContainer>
    </Route>
    <Route path="/signup" exact>
      <AuthOnboardingContainer>
        <SignUpContainer />
      </AuthOnboardingContainer>
    </Route>
    <Route path="/lock" exact>
      <UnlockPageContainer />
    </Route>
    <Route path="/recovery/confirm" exact>
      <RecoveryWrapperContainer>
        <ConfirmRecoveryContainer />
      </RecoveryWrapperContainer>
    </Route>
    <Route path="/recovery/password" exact>
      <RecoveryWrapperContainer>
        <ChangePasswordContainer />
      </RecoveryWrapperContainer>
    </Route>
    <Route path="/recovery" exact>
      <RecoveryWrapperContainer>
        <EnterRecoveryKeyContainer />
      </RecoveryWrapperContainer>
    </Route>
    <Route path="/migration">
      <EnterSecurityKeyContainer />
    </Route>
    <Route path="/ppi/monetization" exact>
      <PPIMonetizationPageContainer />
    </Route>
    <Route path="/" exact>
      <Redirect to="/dashboard" />
    </Route>
    <RouterWithSideBar />
  </Switch>
);
