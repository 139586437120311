import {AppState} from '@renderer/js/redux/rootReducer';

export const getRecoveryStatus = (state: AppState) => state.recoveryState.recoveryStatus;

export const getRecoveryEmail = (state: AppState) => state.recoveryState.email;

export const getRecoveryKey = (state: AppState) => state.recoveryState.recoveryKey;

export const getResendCodeTime = (state: AppState) => state.recoveryState.resendCodeTime;

export const getRecoveryKeyErrors = (state: AppState) => state.recoveryState.recoveryKeyErrors;

export const getConfirmRecoveryCode = (state: AppState) => state.recoveryState.confirmRecoveryCode;

export const getNewPassword = (state: AppState) => state.recoveryState.newPassword;

export const getNewRePassword = (state: AppState) => state.recoveryState.newRePassword;

export const getChangePasswordErrors = (state: AppState) => state.recoveryState.changePasswordErrors;
