import {i18n} from '@figleafteam/helpers';

import {
  AuthOnboardingState,
  ItemIds,
  TChangeOnboardingItemOpenStateAction,
} from '@renderer/js/components/auth-onboarding/redux/types';
import {CHANGE_ONBOARDING_ITEM_OPEN_STATE} from '@renderer/js/components/auth-onboarding/redux/constants';
import {EVENTS} from '@common/events';

const initialState: AuthOnboardingState = {
  orderList: ['breaches', 'browse', 'mask'],
  list: {
    breaches: {
      id: 'breaches',
      get title() {
        return i18n.t('Discover exposed data');
      },
      icon: 'IconSearchBigGreen',
      isOpen: false,
      onClickEvent: EVENTS.WELCOME_SCREEN.DISCOVER_EXPOSED_DATA_ITEM_CLICK,
    },
    browse: {
      id: 'browse',
      get title() {
        return i18n.t('Control identity exposure');
      },
      icon: 'IconBrowseBigGreen',
      isOpen: false,
      onClickEvent: EVENTS.WELCOME_SCREEN.CONTROL_IDENTITY_ITEM_CLICK,
    },
    mask: {
      id: 'mask',
      get title() {
        return i18n.t('Secure and mask your data');
      },
      icon: 'IconLockBigGreen',
      isOpen: false,
      onClickEvent: EVENTS.WELCOME_SCREEN.SECURE_MASK_DATA_ITEM_CLICK,
    },
  },
  subList: {
    get breaches() {
      return [
        i18n.t('Deep web scanning of your email address'),
        i18n.t('See if your accounts have ever been breached'),
        i18n.t('Get alerts if we find your personal info in data leaks'),
      ];
    },
    get browse() {
      return [
        i18n.t('Eliminate advertising trackers in browsers'),
        i18n.t('Hide your location and encrypt traffic'),
        i18n.t('Make it hard for sites to spy on you'),
      ];
    },
    get mask() {
      return [
        i18n.t('Keep your private data in a secure storage'),
        i18n.t('Generate masked emails to signup for new accounts'),
        i18n.t('Access your data in a snap in your browser'),
      ];
    },
  },
};

type TActions = TChangeOnboardingItemOpenStateAction;

export const authOnboardingReducer = (state = initialState, action: TActions): AuthOnboardingState => {
  switch (action.type) {
    case CHANGE_ONBOARDING_ITEM_OPEN_STATE: {
      const key: ItemIds = Object.keys(action.payload)[0] as ItemIds;
      const value = Object.values(action.payload)[0];
      return {
        ...state,
        list: {
          ...state.list,
          [key]: {
            ...state.list[key],
            isOpen: value,
          },
        },
      };
    }
    default:
      return state;
  }
};
