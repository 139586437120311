import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getRecoveryKeyModalState} from '@renderer/js/components/save-recovery-key/redux/selectors';
import {
  setRecoveryKeyModalState,
  saveRecoveryKey,
  getRecoveryKey,
  setRecoveryKeySavedFlag,
} from '@renderer/js/components/save-recovery-key/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {SaveRecoveryKeyComponent} from './save-recovery-key.component';

const mapStateToProps = (state: AppState) => ({
  recoveryKeyModalState: getRecoveryKeyModalState(state),
});

const mapDispatchToProps = {
  getRecoveryKey,
  setRecoveryKeyModalState,
  saveRecoveryKey,
  sendTelemetryEvent,
  setRecoveryKeySavedFlag,
};

export const SaveRecoveryKeyContainer = connect(mapStateToProps, mapDispatchToProps)(SaveRecoveryKeyComponent);
