import {GET_APP_INFO, SET_APP_INFO, SET_APP_LOADING_STATUS} from './constants';

export const getAppInfoAction = () => ({
  type: GET_APP_INFO,
  payload: {},
});

export const setAppInfo = (appInfo: TAppInfoPayload) => ({
  type: SET_APP_INFO,
  payload: {
    appInfo,
  },
});

export const setAppLoadingStatus = (isLoading: boolean) => ({
  type: SET_APP_LOADING_STATUS,
  payload: {
    isLoading,
  },
});
