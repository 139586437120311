import {combineReducers} from 'redux';
import {featuresBaseNameState, global, monitorReducer, vaultState, monetization} from '@figleafteam/store';
import {appInfoState} from '@renderer/js/components/app/redux/reducers';
import {authState} from '@renderer/js/components/auth/redux/reducers';
import {privateBrowsingState} from '@renderer/js/components/private-browsing/redux/reducers';
import {unlockUser} from '@renderer/js/components/unlock-user/redux/reducers';
import {settingsState} from '@renderer/js/components/settings/redux/reducers';
import {enterSecurityKeyState} from '@renderer/js/components/enter-security-key/redux/reducers';
import {confirmModal} from '@renderer/js/components/confirm-modal/redux/reducers';
import {authOnboarding} from '@renderer/js/components/auth-onboarding/redux/reducers';
import {ppi} from '@renderer/js/components/ppi-funnel/redux/reducers';
import {dashboardState} from '@renderer/js/components/dashboard/redux/reducers';
import {paywallModal} from '@renderer/js/components/paywall-modal/redux/reducers';
import {saveRecoveryKeyModalState} from '@renderer/js/components/save-recovery-key/redux/reducers';
import {recoveryState} from '@renderer/js/components/recovery/redux/reducers';
import {detailsState} from '@renderer/js/components/details/redux/reducers';
import {eventsDefaultState} from '@renderer/js/events/redux/reducer';

export const rootReducer = combineReducers({
  appInfoState,
  authState,
  vaultState,
  privateBrowsingState,
  monetization,
  unlockUser,
  settingsState,
  enterSecurityKeyState,
  confirmModal,
  authOnboarding,
  ppi,
  dashboardState,
  paywallModal,
  saveRecoveryKeyModalState,
  recoveryState,
  monitor: monitorReducer,
  global,
  detailsState,
  featuresBaseNameState,
  eventsDefaultState,
});

export type AppState = ReturnType<typeof rootReducer>;
