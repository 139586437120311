import React, {FC, useEffect, useState} from 'react';
import {useTranslation, Trans} from 'react-i18next';

import {getFromState, history, HISTORY_STATE_FROM} from '@renderer/js/router/history';
import {ButtonComponent, ButtonIconComponent, IconComponent} from '@figleafteam/components';
import {i18n} from '@figleafteam/helpers';
import {PrivacyIndicatorComponent} from '@renderer/js/components/ppi-funnel/components/ppi-privacy-indicator/privacy-indicator.component';
import {RiskTypes} from '@renderer/js/components/ppi-funnel/redux/types';
import {EVENTS} from '@common/events';
import {TPPIMonetizationPageProps} from './ppi-monetization-page.types';
import VpnImage from './images/vpn.svg';
import BreachesImage from './images/breaches.svg';
import VaultImage from './images/vault.svg';
import TrackersImage from './images/trackers.svg';
import StarsImage from './images/stars.svg';
import LeftIcon from './images/left.svg';
import RightIcon from './images/right.svg';
import './_ppi-monetization-page.scss';

const features = [
  {
    image: <BreachesImage />,
    get text() {
      return i18n.t('Get breach alerts for all your emails');
    },
  },
  {
    image: <VaultImage />,
    get text() {
      return i18n.t('Save your data in a secure Vault');
    },
  },
  {
    image: <TrackersImage />,
    text: (
      <Trans i18nKey="Eliminate ad-trackers ad-trackers">
        <span>
          Eliminate
          <br />
          ad-trackers&nbsp;ad-trackers
        </span>
      </Trans>
    ),
  },
  {
    image: <VpnImage />,
    get text() {
      return i18n.t('Connect privately with VPN');
    },
  },
];

const reviews = [
  {
    get author() {
      return i18n.t('Jacoby M.');
    },
    get text() {
      return i18n.t(
        "Thanks to FigLeaf, it's never been easier to protect your online privacy. Its easy-to-use interface walks you through the process and helps you quickly handle your privacy.",
      );
    },
  },
  {
    get author() {
      return i18n.t('Sarah B.');
    },
    get text() {
      return i18n.t(
        'I sleep much better knowing that FigLeaf is protecting my account. Some of the dark web breaches they found were absolutely scary.',
      );
    },
  },
  {
    get author() {
      return i18n.t('Joe R.');
    },
    get text() {
      return i18n.t(
        "A simple and powerful app that makes me feel safer using different internet services with regard to my personal data. It's like my own privacy assistant, I tell it what it needs to do and then forget about it.",
      );
    },
  },
  {
    get author() {
      return i18n.t('Christiana G.');
    },
    get text() {
      return i18n.t(
        'This amazing application has saved me hours. I feel much more secure regarding my online privacy with this tool!',
      );
    },
  },
  {
    get author() {
      return i18n.t('Kristopher W.');
    },
    get text() {
      return i18n.t(
        "FigLeaf is an incredible product. Privacy is a fundamental human right, one that we trade constantly online. FigLeaf works behind the scenes to claw some of that privacy back is a simple, friendly way. I can't recommend it enough!",
      );
    },
  },
];

export const PPIMonetizationPageComponent: FC<TPPIMonetizationPageProps> = ({
  appInfo,
  globalRiskType,
  isAllRisksResolved,
  openBuyNow,
  sendTelemetryEvent,
}) => {
  const [selectedReview, setSelectedReview] = useState(0);
  const from = getFromState();
  const {t} = useTranslation();

  useEffect(() => {
    sendTelemetryEvent({
      name: isAllRisksResolved ? EVENTS.PPI.MONETIZATION_PAGE_OPEN_LOW_RISK : EVENTS.PPI.MONETIZATION_PAGE_OPEN,
      source: 'app',
    });
  }, []);

  useEffect(() => {
    if (appInfo && appInfo.licenseInfo.type !== 'trial') {
      history.push('/dashboard');
    }
  }, [appInfo]);

  const pageRisk = isAllRisksResolved ? RiskTypes.low : globalRiskType;

  return (
    <section className="ppi-monetization-page">
      <div className="ppi-monetization-page__content">
        <div className="ppi-monetization-page__back-button-wrapper">
          <ButtonIconComponent
            size="auto"
            onClick={() => {
              if (isAllRisksResolved) {
                history.push('/dashboard');
              } else {
                history.goBack();
              }
            }}
          >
            <IconComponent name="back" size={24} />
          </ButtonIconComponent>
        </div>
        <div className="ppi-monetization-page__risk-indicator">
          <PrivacyIndicatorComponent riskType={pageRisk} size="small" status="report" />
        </div>
        <h1 className="ppi-monetization-page__title">
          {pageRisk === RiskTypes.low
            ? t('Activate {{partner}} to always keep your privacy risks low', {partner: 'FigLeaf'})
            : t('Activate {{partner}} and decrease your privacy risks', {partner: 'FigLeaf'})}
        </h1>
        <p className="ppi-monetization-page__description">
          {t('{{partner}} has everything you need to take back control of your privacy', {partner: 'FigLeaf'})}
        </p>
        <ul className="ppi-monetization-page__features">
          {features.map((item, i) => (
            <li key={i} className="ppi-monetization-page__feature">
              <span>{item.image}</span>
              <span className="ppi-monetization-page__feature-description">{item.text}</span>
            </li>
          ))}
        </ul>
        <div className="ppi-monetization-page__activate-button-wrapper">
          <ButtonComponent
            fullWidth
            buttonType="button"
            type="primary-green"
            text={t('Activate {{partner}} Premium', {partner: 'FigLeaf'})}
            onClick={() => {
              sendTelemetryEvent({
                name: isAllRisksResolved
                  ? EVENTS.PPI.MONETIZATION_PAGE_CLICK_ACTIVATE_LOW_RISK
                  : EVENTS.PPI.MONETIZATION_PAGE_CLICK_ACTIVATE,
                source: 'user',
              });
              openBuyNow();
            }}
          />
        </div>
        <div className="ppi-monetization-page__continue-button-wrapper">
          <ButtonComponent
            onClick={() => {
              sendTelemetryEvent({
                name: EVENTS.PPI.MONETIZATION_PAGE_CLICK_CONTINUE_TRIAL,
                source: 'user',
              });
              history.push({pathname: from === HISTORY_STATE_FROM.REPORT ? '/dashboard/ppi' : '/dashboard'});
            }}
            buttonType="button"
            text={t('Continue with free trial')}
            type="link"
          />
        </div>
      </div>
      <div className="ppi-monetization-page__reviews">
        <div className="ppi-monetization-page__reviews-button-wrapper">
          <ButtonIconComponent
            size="auto"
            onClick={() => {
              if (selectedReview === 0) {
                setSelectedReview(reviews.length - 1);
              } else {
                setSelectedReview(selectedReview - 1);
              }
            }}
          >
            <LeftIcon />
          </ButtonIconComponent>
        </div>
        <div className="ppi-monetization-page__reviews-list">
          {reviews.map((review, i) => (
            <div
              key={i}
              className={`ppi-monetization-page__review${
                i === selectedReview ? ' ppi-monetization-page__review--active' : ''
              }`}
            >
              <span className="ppi-monetization-page__review-text">{review.text}</span>
              <div className="ppi-monetization-page__review-details">
                <StarsImage />
                <span className="ppi-monetization-page__review-separator">|</span>
                <span className="ppi-monetization-page__review-author">{review.author}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="ppi-monetization-page__reviews-button-wrapper">
          <ButtonIconComponent
            size="auto"
            onClick={() => {
              if (selectedReview === reviews.length - 1) {
                setSelectedReview(0);
              } else {
                setSelectedReview(selectedReview + 1);
              }
            }}
          >
            <RightIcon />
          </ButtonIconComponent>
        </div>
      </div>
    </section>
  );
};
