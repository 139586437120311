import {AppState} from '@renderer/js/redux/rootReducer';

export const getState = (state: AppState) => state.settingsState;

export const selectUserLogin = (state: AppState) => state.settingsState.accountInfo.login;

export const getInstalledBrowsersReport = (state: AppState) => getState(state).browsersReport.installedBrowsers;

export const getSupportedBrowsers = (state: AppState) => getState(state).browsersReport.supportedBrowsers;

export const getAnalyticStatus = (state: AppState) => getState(state).settingsInfo.isAnalyticEnabled;

export const getNotificationsStatus = (state: AppState) => getState(state).settingsInfo.notificationsStatus;

export const getAccountInfo = (state: AppState) => getState(state).accountInfo;

export const getUserProfile = (state: AppState) => getState(state).userProfile;

export const getUpdateInfo = (state: AppState) => getState(state).settingsInfo.updateInfo;

export const getIsInternetConnected = (state: AppState) => getState(state).settingsInfo.isInternetConnected;

export const getIsNeedToCheckInternetConnection = (state: AppState) =>
  getState(state).settingsInfo.isNeedToCheckInternetStatus;

export const getPromoCodeModalState = (state: AppState) => getState(state).promoCodeModal;

export const selectIsPpiFlowFinished = (state: AppState) =>
  Boolean(
    getState(state).userProfile.profile && getState(state).userProfile.profile?.client_settings?.is_ppi_flow_finished,
  );
