import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, BrowserIconComponent, IconComponent} from '@figleafteam/components';
import {EVENTS} from '@common/events';

import {sortBrowsersReport} from '@renderer/js/utils/sort-browsers-report';

import {ExtensionsSettingsComponentProps} from './extensions-settings.types';
import './_extensions-settings.scss';

export const ExtensionsSettingsComponent = (props: ExtensionsSettingsComponentProps) => {
  const {t} = useTranslation();

  useEffect(() => {
    props.getBrowsersReport();
  }, []);

  const browsersStatus = props.browsersReport
    ? props.browsersReport
        .filter(browser => browser.isExtensionSupported)
        .sort(sortBrowsersReport)
        .map(browser => {
          const extensionStatus = browser.extensions.length ? (
            <div className="extensions__status">
              {browser.extensions[0].isEnabled ? (
                <>
                  <span className="extensions__status-icon">
                    <IconComponent name="check" size={20} />
                  </span>
                  {t('Active')}
                </>
              ) : (
                t('Turned off')
              )}
            </div>
          ) : (
            <ButtonComponent
              onClick={() => {
                if (browser.name === 'chrome') {
                  props.sendTelemetryEvent({
                    name: EVENTS.SETTINGS.INSTALL_CHROME_EXTENSION_BUTTON_CLICK,
                    source: 'user',
                  });
                }
                if (browser.name === 'firefox') {
                  props.sendTelemetryEvent({
                    name: EVENTS.SETTINGS.INSTALL_FIREFOX_EXTENSION_BUTTON_CLICK,
                    source: 'user',
                  });
                }
                if (browser.name === 'edge') {
                  props.sendTelemetryEvent({
                    name: EVENTS.SETTINGS.INSTALL_MICROSOFT_EDGE_EXTENSION_BUTTON_CLICK,
                    source: 'user',
                  });
                }
                props.openWebExtensionStore(browser.name);
              }}
              beforeComponent={<IconComponent name="plus" size={24} />}
              type="empty-radius"
              text={t('Add extension')}
            />
          );

          return (
            <div key={browser.name} className="extensions__browser-item">
              <div className="extensions__browser-title">
                <div className="extensions__browser-logo">
                  <BrowserIconComponent id={browser.name} />
                </div>
                <div className="extensions__browser-name">{browser.title}</div>
              </div>
              {extensionStatus}
            </div>
          );
        })
    : null;

  return (
    <div className="extensions">
      <h3 className="extensions__title">{t('{{partner}} works in these browsers:', {partner: 'FigLeaf'})}</h3>
      <div className="extensions__browsers">{browsersStatus}</div>
    </div>
  );
};
