import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, ButtonsGroup, ModalComponent, InputComponent} from '@figleafteam/components';
import {EnterPromoCodeModalProps} from './enter-promo-code-modal.types';

import './_enter-promo-code-modal.scss';

export const EnterPromoCodeComponent = memo<EnterPromoCodeModalProps>(props => {
  const {promoCodeModalState, changePromoCodeModalAction, applyPromoCodeAction} = props;
  const {isOpen, code, isLoading} = promoCodeModalState;
  const {t} = useTranslation();

  const handleClose = () => {
    changePromoCodeModalAction({
      isOpen: false,
      code: '',
      isLoading: false,
    });
  };
  const handleChangeCode = (ev: React.ChangeEvent<HTMLInputElement>) => {
    changePromoCodeModalAction({
      code: ev.target.value,
    });
  };
  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    applyPromoCodeAction();
  };
  return (
    <ModalComponent
      width={450}
      isOpen={isOpen}
      title={t('Enter promo code')}
      titleTextAlign="left"
      showCloseButton
      standardBodyWrap
      handleClose={handleClose}
    >
      <div className="enter-promocode-modal">
        <form className="enter-promocode-modal__form" onSubmit={handleSubmit}>
          <div className="enter-promocode-modal__input">
            <InputComponent
              onChange={handleChangeCode}
              value={code}
              name="promo-code"
              type="text"
              placeholder={t('Your promo code')}
              autoFocus
            />
          </div>
          <div className="enter-promocode-modal__buttons">
            <ButtonsGroup
              buttonsWidth="124"
              buttons={[
                {
                  cancel: (
                    <ButtonComponent
                      fullWidth
                      disabled={isLoading}
                      text={t('Cancel')}
                      type="empty-bordered"
                      buttonType="button"
                      onClick={handleClose}
                    />
                  ),
                },
                {
                  submit: (
                    <ButtonComponent
                      fullWidth
                      disabled={isLoading || !code}
                      text={isLoading ? t('Applying...') : t('Apply')}
                      type="primary"
                      buttonType="submit"
                    />
                  ),
                },
              ]}
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
});

EnterPromoCodeComponent.displayName = 'EnterPromoCodeComponent';
