import {
  RESET_PAYWALL_MODAL_STATE,
  SET_PAYWALL_MODAL_STATE,
} from '@renderer/js/components/paywall-modal/redux/constants';
import {TPaywallModalState} from '@renderer/js/components/paywall-modal/redux/types';

export const setPaywallModalState = (payload: Partial<TPaywallModalState>) => ({
  type: SET_PAYWALL_MODAL_STATE,
  payload,
});

export const resetPaywallModalState = () => ({
  type: RESET_PAYWALL_MODAL_STATE,
  payload: {},
});
