import {takeEvery, call, put} from 'redux-saga/effects';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {history, HISTORY_STATE_FROM} from '@renderer/js/router/history';
import {submitSecurityKey} from '@figleafteam/api-communication';
import {SUBMIT_SECURITY_KEY} from './constants';
import {setPassword, setSecurityKey} from './actions';
import {TSubmitSecurityKeyAction} from './types';

function* submitSecurityKeySaga(action: TSubmitSecurityKeyAction) {
  try {
    yield call(submitSecurityKey, btoa(action.payload.key), action.payload.password && btoa(action.payload.password));
    yield put(setSecurityKey(''));
    yield put(setPassword(''));

    history.push({
      pathname: '/recovery/password',
      state: {from: HISTORY_STATE_FROM.MIGRATION},
    });
  } catch (error) {
    notificationService.sendSecretKeyError();
    console.error(error);
  }
}

export function* enterSecurityKeyWatcher() {
  yield takeEvery(SUBMIT_SECURITY_KEY, submitSecurityKeySaga);
}
