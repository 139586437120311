import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {IconComponent} from '@figleafteam/components';
import {Props} from './new-breaches-notification.types';
import './_new-breaches-notification.scss';

export const NewBreachesNotificationComponent: FC<Props> = props => {
  const {t} = useTranslation();

  let notificationTitle = t('No new breaches added.');
  if (props.breachesCount) {
    notificationTitle = t('{{count}} new breaches added.', {count: props.breachesCount});
  }

  return (
    <div className="new-breaches-notification">
      <span className="new-breaches-notification__icon">
        <IconComponent name={props.breachesCount ? 'attention-round' : 'check'} size={24} />
      </span>
      <h6 className="new-breaches-notification__title">{notificationTitle}</h6>
      <span className="new-breaches-notification__text">
        {props.breachesCount ? t('Take a look!') : t('Way to go!')}
      </span>
    </div>
  );
};
