import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getPaywallModalState} from '@renderer/js/components/paywall-modal/redux/selectors';
import {resetPaywallModalState, setPaywallModalState} from '@renderer/js/components/paywall-modal/redux/actions';
import {openBuyNow} from '@renderer/js/components/settings/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {PaywallModalComponent} from './paywall-modal.component';

const mapStateToProps = (state: AppState) => ({
  paywallModalState: getPaywallModalState(state),
});
const mapDispatchToProps = {
  setPaywallModalState,
  resetPaywallModalState,
  openBuyNow,
  sendTelemetryEvent,
};

export const PaywallModalContainer = connect(mapStateToProps, mapDispatchToProps)(PaywallModalComponent);
