import {connect} from 'react-redux';
import {getLicenseInfoAction, isLicenseExpired} from '@figleafteam/store';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  getVpnConnectionStatus,
  getCurrentCountryCode,
  getCurrentCountryName,
  getCurrentCountryCodeIp,
  isVpnInfoFetched,
  isCountryListFetched,
  getBrowsersConfigState,
  getAntitrackingStatisticsState,
} from '@renderer/js/components/private-browsing/redux/selectors';
import {getInstalledBrowsersReport} from '@renderer/js/components/settings/redux/selectors';
import {
  getVpnConfigAction,
  getVpnInfoAction,
  getBrowserConfig,
  getAntitrackingStatistics,
} from '@renderer/js/components/private-browsing/redux/actions';
import {getBrowsersReport} from '@renderer/js/components/settings/redux/actions';
import {setPaywallModalState} from '@renderer/js/components/paywall-modal/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {setDetailsState} from '@renderer/js/components/details/redux/actions';
import {BrowseWidgetComponent} from './browse-widget.component';

const mapStateToProps = (state: AppState) => ({
  isVpnInfoFetched: isVpnInfoFetched(state),
  isCountryListFetched: isCountryListFetched(state),
  isVpnEnabled: getVpnConnectionStatus(state),
  currentCountryCode: getCurrentCountryCode(state),
  currentCountryName: getCurrentCountryName(state),
  currentCountryIp: getCurrentCountryCodeIp(state),
  browsersConfig: getBrowsersConfigState(state),
  browsersReport: getInstalledBrowsersReport(state),
  isLicenseExpired: isLicenseExpired(state),
  antitrackingStatistics: getAntitrackingStatisticsState(state),
});
const mapDispatchToProps = {
  getVpnConfig: getVpnConfigAction,
  getVpnInfo: getVpnInfoAction,
  getBrowsersReport,
  setDetailsState,
  getLicenseInfoAction,
  setPaywallModalState,
  sendTelemetryEvent,
  getBrowserConfig,
  getAntitrackingStatistics,
};

export const BrowseWidgetContainer = connect(mapStateToProps, mapDispatchToProps)(BrowseWidgetComponent);
