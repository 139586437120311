import React from 'react';
import {useTranslation} from 'react-i18next';

import {SideBarNotificationComponent, IconComponent} from '@figleafteam/components';
import {AuthorizationStatusProps} from './authorization-status.types';
import './_authorization-status.scss';

export const AuthorizationStatusComponent: React.FC<AuthorizationStatusProps> = ({
  isAuthorized,
  setAuthPopupOpened,
}) => {
  const {t} = useTranslation();

  if (isAuthorized) {
    return null;
  }

  return (
    <SideBarNotificationComponent>
      <div className="authorization-status">
        <div className="authorization-status__image">
          <IconComponent name="user-round" size={24} />
        </div>
        <h5 className="authorization-status__title">{t('Not activated')}</h5>
        <p className="authorization-status__text">{t('Sign up to activate your fully functional trial')}</p>

        <button
          className="authorization-status__button btn-link"
          type="button"
          onClick={() => setAuthPopupOpened(true)}
        >
          {t('Activate now')}
        </button>
      </div>
    </SideBarNotificationComponent>
  );
};
