import React, {memo} from 'react';
import {useTranslation, Trans} from 'react-i18next';

import {ButtonComponent, BrowserIconComponent, ModalComponent, IconComponent} from '@figleafteam/components';
import {sortBrowsersReport} from '@renderer/js/utils/sort-browsers-report';
import {EVENTS} from '@common/events';
import {ModalInstallExtensionsProps} from './modal-install-extensions.types';
import './_modal-install-extensions.scss';

export const ModalInstallExtensionsComponent = memo<ModalInstallExtensionsProps>(
  ({isModalOpen, setDetailsState, browsersReport, sendTelemetryEvent, openWebExtensionStore}) => {
    const {t} = useTranslation();

    const browsers = browsersReport
      ? browsersReport
          .filter(browser => browser.isExtensionSupported)
          .sort(sortBrowsersReport)
          .map(browser => {
            const extensionStatus = browser.extensions.length ? (
              <div className="extensions__status">
                {browser.extensions[0].isEnabled ? (
                  <>
                    <span className="extensions__status-icon">
                      <IconComponent name="check" size={20} />
                    </span>
                    {t('Active')}
                  </>
                ) : (
                  t('Turned off')
                )}
              </div>
            ) : (
              <ButtonComponent
                onClick={() => {
                  if (browser.name === 'chrome') {
                    sendTelemetryEvent({
                      name: EVENTS.ADD_BROWSER_EXTENSION_POPUP.INSTALL_CHROME_EXTENSION_BUTTON_CLICK,
                      source: 'user',
                    });
                  }
                  if (browser.name === 'firefox') {
                    sendTelemetryEvent({
                      name: EVENTS.ADD_BROWSER_EXTENSION_POPUP.INSTALL_FIREFOX_EXTENSION_BUTTON_CLICK,
                      source: 'user',
                    });
                  }
                  if (browser.name === 'edge') {
                    sendTelemetryEvent({
                      name: EVENTS.ADD_BROWSER_EXTENSION_POPUP.INSTALL_MICROSOFT_EDGE_EXTENSION_BUTTON_CLICK,
                      source: 'user',
                    });
                  }
                  openWebExtensionStore(browser.name);
                }}
                beforeComponent={<IconComponent name="plus" size={24} />}
                type="empty-radius"
                text={t('Add extension')}
              />
            );
            return (
              <div key={browser.name} className="extensions-modal__browser-row">
                <div className="extensions-modal__browser-item">
                  <div className="extensions-modal__browser-logo">
                    <BrowserIconComponent id={browser.name} />
                  </div>
                  <h6 className="extensions-modal__browser-name">{browser.title}</h6>
                </div>
                {extensionStatus}
              </div>
            );
          })
      : null;
    return (
      <ModalComponent
        isOpen={isModalOpen}
        closeOnClickOutside
        showCloseButton
        autoFocus
        title={t('Add FigLeaf to your browsers')}
        titleTextAlign="left"
        standardBodyWrap
        handleClose={() => setDetailsState({isExtensionsModalOpened: false})}
      >
        <div className="extensions-modal__content">
          <p className="extensions-modal__text">
            <Trans i18nKey="Be aware of breached websites, block advertising trackers, control VPN connection, autofill data you store with FigLeaf and more.">
              Be aware of breached websites, block advertising trackers, control
              <br /> VPN connection, autofill data you store with FigLeaf and more.
            </Trans>
          </p>
          {browsers}
        </div>
      </ModalComponent>
    );
  },
);

ModalInstallExtensionsComponent.displayName = 'ModalImportAccountsComponent';
