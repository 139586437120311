const browsersRenderingOrder = {
  chrome: 1,
  firefox: 2,
  edge: 3,
  brave: 4,
  opera: 5,
  safari: 6,
  unknown: 7,
};

export const sortBrowsersReport = (reportA: ReportByBrowser, reportB: ReportByBrowser) => {
  return browsersRenderingOrder[reportA.name] - browsersRenderingOrder[reportB.name];
};
