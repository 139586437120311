import React, {FC, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import {IconComponent} from '@figleafteam/components';
import {TPPIDecreaseRisksCompletedProps} from './ppi-decrease-risks-completed.types';
import './_ppi-decrease-risks-completed.scss';

export const PPIDecreaseRisksCompletedComponent: FC<TPPIDecreaseRisksCompletedProps> = ({children}) => {
  const {t} = useTranslation();
  const [isOpened, setOpened] = useState(false);
  const childCount = React.Children.count(children);
  const className = classNames('decrease-risk-completed', {
    'decrease-risk-completed--opened': isOpened,
  });

  if (!childCount) {
    return null;
  }

  return (
    <section className={className}>
      <header className="decrease-risk-completed__header">
        <button className="decrease-risk-completed__button" type="button" onClick={() => setOpened(!isOpened)}>
          <span className="decrease-risk-completed__button-icon">
            <IconComponent name="arrow-down" size={24} />
          </span>
          {t('Show completed {{count}}', {count: childCount})}
        </button>
      </header>
      <article className="decrease-risk-completed__main">{children}</article>
    </section>
  );
};
