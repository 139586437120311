import {i18n} from '@figleafteam/helpers';

import {TRecoveryKeyErrors} from '@renderer/js/components/recovery/redux/types';
import {isEmailInvalid} from '@renderer/js/components/auth/utils/isEmailInvalid';

export const initialErrors: TRecoveryKeyErrors = {
  emailInvalid: false,
  emailErrorText: '',
};

export function validateRecoveryKey(email: string) {
  const errors = {...initialErrors};

  if (isEmailInvalid(email)) {
    errors.emailInvalid = true;
    errors.emailErrorText = i18n.t("Your email doesn't look right");
  }
  if (email.length === 0) {
    errors.emailInvalid = true;
    errors.emailErrorText = i18n.t('Please fill email field');
  }

  return errors;
}
