import React, {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {ButtonComponent, BrowserIconComponent, SelectComponent, IconComponent} from '@figleafteam/components';
import {i18n} from '@figleafteam/helpers';
import {EVENTS} from '@common/events';
import {useAuthorization} from '@renderer/js/components/auth/hooks/use-authorization';
import {BrowserItemProps} from './browser-item.types';
import NotAllowedIcon from './images/not-allowed.svg';
import './_browser-item.scss';

const shortBrowserTitle: {[key in Browsers]: string} = {
  get chrome() {
    return i18n.t('Google Chrome');
  },
  get firefox() {
    return i18n.t('Mozilla Firefox');
  },
  get edge() {
    return i18n.t('Microsoft Edge');
  },
  get brave() {
    return i18n.t('Brave');
  },
  get opera() {
    return i18n.t('Opera');
  },
  get safari() {
    return i18n.t('Safari');
  },
  get unknown() {
    return i18n.t('Browser');
  },
};

export const formatTrackersAmount = (n: number) => n.toLocaleString('en');

export const BrowserItemComponent = memo<BrowserItemProps>(props => {
  const {
    browserReport,
    browsersConfig,
    trackersBlockedInBrowser,
    getTrackersBlockedInBrowser,
    openWebExtensionStore,
    sendTelemetryEvent,
    getBrowserConfig,
    updateBrowserConfig,
  } = props;
  const browserName = browserReport.name;
  const {isAuthorized, runIfAuthorized} = useAuthorization();
  const {t} = useTranslation();

  const isAntitrackingEnabled = Boolean(browsersConfig[browserName]?.isAntiTrackingEnabled);

  useEffect(() => {
    if (!isAuthorized) {
      return;
    }

    if (!browsersConfig || !browsersConfig[browserName]) {
      getBrowserConfig(browserName);
    }

    if (!trackersBlockedInBrowser || !trackersBlockedInBrowser[browserName]) {
      getTrackersBlockedInBrowser(browserName);
    }
  }, [isAuthorized]);

  const isBrowserInstalled = browserReport.version !== 'mock';

  const isExtensionInstalled = Boolean(browserReport.extensions.length);
  const handleSwitcherChange = () => {
    if (!isAntitrackingEnabled) {
      sendTelemetryEvent({name: EVENTS.CONTROLS.ANTITRACKING_ENABLE, source: 'user'});
    } else {
      sendTelemetryEvent({name: EVENTS.CONTROLS.ANTITRACKING_DISABLE, source: 'user'});
    }

    updateBrowserConfig({
      isAntiTrackingEnabled: !isAntitrackingEnabled,
      domain: browserReport.name,
    });
  };

  const isSwitcherDisabled = !isBrowserInstalled || !isExtensionInstalled;
  const isSwitcherChecked = isSwitcherDisabled ? false : isAntitrackingEnabled;

  const getClassesArray = (className: string) => {
    const classesArray = [className];
    if (!isExtensionInstalled || !isSwitcherChecked) {
      classesArray.push(`${className}--disabled`);
    }
    !isBrowserInstalled && classesArray.push(`${className}--not-installed`);
    return classesArray;
  };

  let statusComponent = (
    <p className={getClassesArray('browser-item__counter').join(' ')}>
      <span className="browser-item__counter-number">
        {formatTrackersAmount(trackersBlockedInBrowser[browserName] || 0)}
      </span>
      <span className="browser-item__counter-text">
        {t('tracker {{count}} blocked', {count: trackersBlockedInBrowser[browserName] || 0})}
      </span>
    </p>
  );

  if (!isBrowserInstalled) {
    statusComponent = (
      <span className="browser-item__browser-not-installed">
        <NotAllowedIcon />
        {t('Browser not installed')}
      </span>
    );
  } else if (!isExtensionInstalled) {
    statusComponent = (
      <div className="browser-item__install-extension-wrapper">
        <ButtonComponent
          onClick={() =>
            runIfAuthorized(() => {
              const clickEventMap: {[key in Browsers]?: string} = {
                chrome: EVENTS.CONTROLS.INSTALL_CHROME_EXTENSION_BUTTON_CLICK,
                firefox: EVENTS.CONTROLS.INSTALL_FIREFOX_EXTENSION_BUTTON_CLICK,
                edge: EVENTS.CONTROLS.INSTALL_MICROSOFT_EDGE_EXTENSION_BUTTON_CLICK,
                // uncomment this when need handle it
                // brave: undefined,
                // opera: undefined,
                // safari: undefined,
                // unknown: undefined,
              };
              const clickEvent = clickEventMap[browserReport.name];
              if (clickEvent) {
                sendTelemetryEvent({name: clickEvent, source: 'user'});
              }
              openWebExtensionStore(browserReport.name);
            })
          }
          beforeComponent={<IconComponent name="plus" size={24} />}
          type="empty-radius"
          text={t('Add extension')}
        >
          {t('Add extension')}
        </ButtonComponent>
      </div>
    );
  }

  return (
    <div key={browserReport.name} className="browser-item">
      <div className="browser-item__name-wrapper">
        <div className={getClassesArray('browser-item__browser-icon').join(' ')}>
          <BrowserIconComponent id={browserReport.name} />
        </div>
        <p className={getClassesArray('browser-item__name').join(' ')}>{browserReport.title}</p>
      </div>
      <SelectComponent
        theme="antitracker"
        disabled={isSwitcherDisabled}
        items={[
          {
            id: '',
            label: isAntitrackingEnabled
              ? t('Disable Anti-Tracking in {{browser}}', {browser: shortBrowserTitle[browserReport.name]})
              : t('Enable Anti-Tracking in {{browser}}', {browser: shortBrowserTitle[browserReport.name]}),
          },
        ]}
        onSelect={() => runIfAuthorized(() => handleSwitcherChange())}
        selectedItem={isAntitrackingEnabled ? t('Tracking protection enabled') : t('Tracking protection disabled')}
        iconLeft={
          <div
            className={classNames('browser-item__circle', {
              'browser-item__circle--enabled': isSwitcherChecked,
              'browser-item__circle--disabled': !isSwitcherChecked,
            })}
          />
        }
      />
      {statusComponent}
    </div>
  );
});

BrowserItemComponent.displayName = 'BrowserItemComponent';
