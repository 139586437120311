import {history} from '@renderer/js/router/history';
import {setRecoveryKeyModalState} from '@renderer/js/components/save-recovery-key/redux/actions';
import {toggleImportModalOpen} from '@figleafteam/store';
import {setRecoveryStatus} from '@renderer/js/components/recovery/redux/actions';
import {setIsMigrationRequired} from '@renderer/js/components/enter-security-key/redux/actions';
import {openBuyNow} from '@renderer/js/components/settings/redux/actions';
import {setPpiFunnelState} from '@renderer/js/components/ppi-funnel/redux/actions';
import {setAuthPopupOpened} from '@renderer/js/components/auth/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {StoreType} from '@renderer/js/redux/store';
import {logger} from '@common/compatibility/logger';

enum DeepLink {
  MainPage = 'figleaf:///',
  Monitor = 'figleaf:monitor_page',
  BreachedHistory = 'figleaf:breach_history_page', // legacy, copy of monitor
  Vault = 'figleaf:vault_page',
  AccountsList = 'figleaf:accounts_list', // legacy, copy of vault
  Controls = 'figleaf:controls_page',
  Settings = 'figleaf:settings_page',
  AccountInfo = 'figleaf:my_account_page',
  SaveRecoveryKey = 'figleaf:save_recovery_key',
  ImportAccounts = 'figleaf:import_accounts',
  PasswordRecovery = 'figleaf:password_recovery',
  StartMigration = 'figleaf:start_migration',
  Buynow = 'figleaf:buynow',
  SignUp = 'figleaf:signup',
  AutoScan = 'figleaf:auto_scan',
}

function handleDeepLinkArgs(link: string, store: StoreType) {
  logger.info('Handle deeplink: ' + link);
  const telemetryName = link
    .split('&')
    .find(arg => arg.startsWith('telemetry:'))
    ?.split('telemetry:')[1];

  if (telemetryName) {
    logger.info('SendStat ' + telemetryName);
    store.dispatch(sendTelemetryEvent({name: telemetryName, source: 'user'}));
  } else {
    logger.error('Fail to parse telemetry args in deeplink');
  }
}

export function handleDeepLink(link: string, store: StoreType) {
  switch (true) {
    case link.includes(DeepLink.Monitor):
    case link.includes(DeepLink.BreachedHistory):
      return history.push('/monitoring');
    case link.includes(DeepLink.Vault):
    case link.includes(DeepLink.AccountsList):
      return history.push('/vault');
    case link.includes(DeepLink.Controls):
      return history.push('/browse');
    case link.includes(DeepLink.Settings):
      return history.push('/settings');
    case link.includes(DeepLink.AccountInfo):
      return history.push('/settings/account');
    case link.includes(DeepLink.SaveRecoveryKey):
      history.push('/settings/account');
      store.dispatch(setRecoveryKeyModalState({isOpen: true}));
      break;
    case link.includes(DeepLink.ImportAccounts):
      history.push('/vault');
      store.dispatch(toggleImportModalOpen(true));
      break;
    case link.includes(DeepLink.PasswordRecovery):
      return store.dispatch(setRecoveryStatus(true));
    case link.includes(DeepLink.StartMigration):
      store.dispatch(setIsMigrationRequired(true));
      history.push('/login');
      break;
    case link.includes(DeepLink.Buynow):
      try {
        const url = new URL(link);
        const parametersObject = new URLSearchParams(url.search);
        const campaign = parametersObject.get('campaign') || undefined; // cause UrlSearchParams return null if no value
        const set = parametersObject.get('set') || undefined; // cause UrlSearchParams return null if no value
        store.dispatch(openBuyNow(campaign, set));
      } catch (error) {
        console.error(error);
      }
      break;
    case link.includes(DeepLink.SignUp):
      handleDeepLinkArgs(link, store);
      return store.dispatch(setAuthPopupOpened(true));
    case link.includes(DeepLink.AutoScan):
      handleDeepLinkArgs(link, store);
      if (store.getState().ppi.funnel.scannerStep === 'initial') {
        return store.dispatch(
          setPpiFunnelState({scannerStep: 'scanning', isPpiFunnelShown: true, isRisksScannerOpened: true}),
        );
      }
      return store.dispatch(setPpiFunnelState({isRisksScannerOpened: true}));
    case link.includes(DeepLink.MainPage):
    default:
      return history.push('/');
  }
}

export type HandleDeepLink = typeof handleDeepLink;
