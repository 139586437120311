import React, {memo, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, InputPasswordComponent, InputComponent} from '@figleafteam/components';
import {i18n} from '@figleafteam/helpers';
import {openURL} from '@common/compatibility/openURL';
import {checkPasswordCriteria} from '@renderer/js/components/auth/utils/checkPasswordCriteria';
import {history} from '@renderer/js/router/history';
import {AuthPopupContents} from '@renderer/js/components/auth/redux/constants';
import {EVENTS} from '@common/events';

import {TAuthPopupSignupContentProps} from './auth-popup-signup-content.types';
import '../auth-popup/_auth-popup-content.scss';

type InputKeys = 'email' | 'signupPassword' | 'signupRePassword';

const TITLES = {
  get default() {
    return i18n.t('Sign up to activate FigLeaf');
  },
  get vault() {
    return i18n.t('Sign up to activate Vault');
  },
  get monitor() {
    return i18n.t('Sign up to activate Monitor');
  },
  get controls() {
    return i18n.t('Sign up to activate VPN & Anti-Tracking');
  },
  get report() {
    return i18n.t('Sign up to access full report');
  },
};

export const AuthPopupSignupContentComponent = memo<TAuthPopupSignupContentProps>(props => {
  const [isPasswordHintShown, setPasswordHintShown] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    props.sendTelemetryEvent({name: EVENTS.PPI.AUTH_OPEN_SIGNUP_MODAL, source: 'app'});
  }, []);

  const handleFieldChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = ev.target;
    props.setAuthInfo(name as InputKeys, value);

    if (name === 'email') {
      props.resetSignupEmailValidationStatus();
    } else {
      props.resetSignupPasswordsValidationStatus();
    }
  }, []);

  const handleSubmit = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    props.signupSubmit({fromPopup: true});
  }, []);

  const goToLogIn = useCallback(() => {
    props.setAuthPopupContent(AuthPopupContents.LOGIN);
  }, [props.setAuthPopupContent]);

  const openEula = useCallback(() => {
    openURL('https://figleaf.com/eula/');
  }, []);

  const openPolicy = useCallback(() => {
    openURL('https://figleaf.com/privacy-policy/');
  }, []);

  const errorsConfig = {
    email: {
      error: props.signUpErrors.emailInvalid,
      bottomText: props.signUpErrors.emailErrorText,
    },
    password: {
      error: props.signUpErrors.passwordInvalid,
      bottomText: props.signUpErrors.passwordErrorText,
    },
    rePassword: {
      error: props.signUpErrors.rePasswordInvalid,
      bottomText: props.signUpErrors.rePasswordErrorText,
    },
  };

  const liveChecks = checkPasswordCriteria(props.signupPassword);

  const getHintItemClass = (property: keyof ReturnType<typeof checkPasswordCriteria>) => {
    return liveChecks[property]
      ? 'password-drop-down-hint__item'
      : 'password-drop-down-hint__item password-drop-down-hint__item--valid';
  };

  let title = TITLES.default;
  switch (history.location.pathname) {
    case '/monitoring': {
      title = TITLES.monitor;
      break;
    }
    case '/browse': {
      title = TITLES.controls;
      break;
    }
    case '/vault': {
      title = TITLES.vault;
      break;
    }

    case '/dashboard':
    case '/': {
      if (props.isRisksScannerOpened) {
        title = TITLES.report;
      }
      break;
    }
    default: {
      title = TITLES.default;
    }
  }

  return (
    <div className="auth-popup-content">
      <div className="auth-popup-content__container">
        <h1 className="auth-popup-content__title">{title}</h1>
        <form className="auth-popup-content__form" onSubmit={handleSubmit}>
          <div className="auth-popup-content__form-input">
            <InputComponent
              value={props.email}
              onChange={handleFieldChange}
              name="email"
              type="text"
              placeholder={t('Email')}
              {...errorsConfig.email}
            />
          </div>
          <div className="auth-popup-content__form-input">
            <InputPasswordComponent
              onChange={handleFieldChange}
              onFocusChange={setPasswordHintShown}
              value={props.signupPassword}
              name="signupPassword"
              type="password"
              placeholder={t('Password')}
              {...errorsConfig.password}
            />
            {isPasswordHintShown && (liveChecks.digit || liveChecks.mixedLetterCase || liveChecks.passwordLength) && (
              <div className="password-drop-down-hint">
                <div className="password-drop-down-hint__inner">
                  <h6 className="password-drop-down-hint__title">{t('Password requirements:')}</h6>
                  <ul className="password-drop-down-hint__list">
                    <li className={getHintItemClass('passwordLength')}>{t('At least 8 characters')}</li>
                    <li className={getHintItemClass('mixedLetterCase')}>{t('Mixture of upper & lowercase')}</li>
                    <li className={getHintItemClass('digit')}>{t('At least 1 digit')}</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="auth-popup-content__form-input">
            <InputPasswordComponent
              onChange={handleFieldChange}
              value={props.signupRePassword}
              name="signupRePassword"
              type="password"
              placeholder={t('Confirm password')}
              {...errorsConfig.rePassword}
            />
          </div>
          <p className="auth-popup-content__form-text">
            {t('By clicking the “Sign up” button below, you agree to the {{partner}}', {partner: 'Figleaf'})}{' '}
            <span onClick={openEula} role="button" className="auth-popup-content__link">
              {t('Software License and Terms of Service')}
            </span>{' '}
            {t('and')}{' '}
            <span onClick={openPolicy} role="button" className="auth-popup-content__link">
              {t('Privacy Policy')}
            </span>
          </p>
          <div className="auth-popup-content__buttons">
            <ButtonComponent fullWidth buttonType="submit" type="primary-green" text={t('Sign up')} />
          </div>
        </form>
      </div>
      <div className="auth-popup-content__bottom-text-wrapper">
        <p className="auth-popup-content__bottom-text">
          {t('Already use {{partner}}?', {partner: 'FigLeaf'})}{' '}
          <ButtonComponent onClick={goToLogIn} buttonType="button" text={t('Log In')} type="link" />
        </p>
      </div>
    </div>
  );
});

AuthPopupSignupContentComponent.displayName = 'AuthPopupSignupContentComponent';
