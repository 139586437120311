import {i18n} from '@figleafteam/helpers';

import {
  TAccountHijackingCategory,
  TDataItem,
  THomeTitleTheftCategory,
  TIdentityExposureCategory,
  RiskTypes,
} from '@renderer/js/components/ppi-funnel/redux/types';

export const buildAccountHijackingCategory = (parsedData: {
  accounts?: Array<TDataItem>;
  logins?: Array<TDataItem>;
  passwords?: Array<TDataItem>;
  securityQuestions?: Array<TDataItem>;
}): TAccountHijackingCategory => {
  let riskType: RiskTypes = RiskTypes.medium;
  if (parsedData.passwords?.length || parsedData.securityQuestions?.length) {
    riskType = RiskTypes.high;
  } else if (parsedData.logins?.length || parsedData.passwords?.length) {
    riskType = RiskTypes.medium;
  }
  return {
    riskType,
    items: {
      accounts: {
        get name() {
          return i18n.t('Account');
        },
        data: parsedData.accounts || [],
      },
      logins: {
        get name() {
          return i18n.t('Login');
        },
        data: parsedData.logins || [],
      },
      passwords: {
        get name() {
          return i18n.t('Password');
        },
        data: parsedData.passwords || [],
      },
      securityQuestions: {
        get name() {
          return i18n.t('Security question');
        },
        data: parsedData.securityQuestions || [],
      },
    },
  };
};

export const buildHomeTitleTheftCategory = (parsedData: {
  locations?: Array<TDataItem>;
  ipAddresses?: Array<TDataItem>;
}): THomeTitleTheftCategory => {
  let riskType: RiskTypes = RiskTypes.medium;
  if (parsedData.locations?.length) {
    riskType = RiskTypes.high;
  } else if (parsedData.ipAddresses?.length) {
    riskType = RiskTypes.medium;
  }
  return {
    riskType,
    items: {
      locations: {
        get name() {
          return i18n.t('Location');
        },
        data: parsedData.locations || [],
      },
      ips: {
        get name() {
          return i18n.t('IP address');
        },
        data: parsedData.ipAddresses || [],
      },
    },
  };
};

export const buildIdentityExposureCategory = (parsedData: {
  generalInfo?: Array<TDataItem>;
  ids?: Array<TDataItem>;
  phones?: Array<TDataItem>;
  socialProfiles?: Array<TDataItem>;
}): TIdentityExposureCategory => {
  let riskType: RiskTypes = RiskTypes.medium;
  if (
    parsedData.ids?.length ||
    parsedData.generalInfo?.length ||
    parsedData.phones?.length ||
    parsedData.socialProfiles?.length
  ) {
    riskType = RiskTypes.high;
  }

  return {
    riskType,
    items: {
      generalInfo: {
        get name() {
          return i18n.t('General Info');
        },
        data: parsedData.generalInfo || [],
      },
      ids: {
        get name() {
          return i18n.t('ID');
        },
        data: parsedData.ids || [],
      },
      phones: {
        get name() {
          return i18n.t('Phone');
        },
        data: parsedData.phones || [],
      },
      socialProfiles: {
        get name() {
          return i18n.t('Social profile');
        },
        data: parsedData.socialProfiles || [],
      },
    },
  };
};
