import {call, put, takeEvery, takeLatest, select} from 'redux-saga/effects';
import {
  openWebExtensionStore,
  getBrowsersReport,
  changeAnalyticStatus,
  getUserProfile,
  importAccounts,
  getSettingValue,
  setSettingValue,
  getUserLogin,
  lockUser,
  getIsUpdateAvailable,
  performUpdate,
  ping,
  applyPromoCode,
  getBuyNowLink,
} from '@figleafteam/api-communication';
import {i18n} from '@figleafteam/helpers';
import {setVaultListIsFetchedStatus, getLicenseInfoAction, getLicenseInfo} from '@figleafteam/store';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {
  setBrowsersReport,
  updateUserProfile,
  setUserLogin,
  setAnalyticStatus,
  changePasswordModalState,
  setNotificationsStatus,
  setUpdateInfo,
  setInternetConnectionStatus,
  setIsNeedToCheckInternetConnectionStatus,
  changePromoCodeModalAction,
} from '@renderer/js/components/settings/redux/actions';
import {hideRiskItem} from '@renderer/js/components/ppi-funnel/redux/actions';
import {setRecoveryKeyModalState} from '@renderer/js/components/save-recovery-key/redux/actions';
import {unlockCheckAndHandle} from '@renderer/js/components/unlock-user/redux/sagas';
import {setModalVisible, setNextActions} from '@renderer/js/components/unlock-user/redux/actions';
import {history} from '@renderer/js/router/history';
import {openURL} from '@common/compatibility/openURL';
import {SUPPORT_PAGE} from '@renderer/js/constants/site-pages';
import {setAppLoadingStatus} from '@renderer/js/components/app/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {EVENTS} from '@common/events';
import {
  TImportAccountsAction,
  TChangeNotificationStatusAction,
  TOpenWebExtensionStoreAction,
  TGetUserLoginAction,
  TPromoCodeModalState,
  TOpenSupportLinkAction,
  TOpenBuyNowAction,
} from './types';
import {getAnalyticStatus, getPromoCodeModalState} from './selectors';
import {
  OPEN_WEB_EXTENSION_STORE,
  GET_BROWSERS_REPORT,
  CHANGE_ANALYTIC_STATUS,
  GET_USER_PROFILE,
  IMPORT_ACCOUNTS,
  IMPORT_ACCOUNTS_PPI,
  GET_USER_LOGIN,
  CHANGE_PASSWORD,
  GET_NOTIFICATIONS_STATUS,
  NOTIFICATIONS_STATUS,
  CHANGE_NOTIFICATIONS_STATUS,
  LOCK_USER,
  GET_IS_UPDATE_AVAILABLE,
  PERFORM_UPDATE,
  CHECK_INTERNET_CONNNETION_STATUS,
  OPEN_SUPPORT_LINK,
  APPLY_PROMO_CODE,
  OPEN_BUY_NOW,
} from './constants';

export function* openWebExtensionStoreSaga(action: TOpenWebExtensionStoreAction) {
  try {
    yield call(openWebExtensionStore, action.payload.browser);
  } catch (e) {
    notificationService.showDefaultError(i18n.t('Register FigLeaf extension failure'));
    console.error('err', e);
    yield unlockCheckAndHandle(e, action);
  }
}

export function* getBrowsersReportSaga() {
  try {
    const response: TGetBrowsersReportResponse['payload'] = yield call(getBrowsersReport);
    yield put(setBrowsersReport(response.installedBrowsers, response.supportedBrowsers));
  } catch (e) {
    console.error('err', e);
  }
}

export function* changeAnalyticStatusSaga() {
  try {
    const isAnalyticEnabled: boolean = yield select(getAnalyticStatus);
    yield put(setAnalyticStatus(!isAnalyticEnabled)); // update status immediately
    const profile: UserProfile = yield call(changeAnalyticStatus, !isAnalyticEnabled);
    yield put(setAnalyticStatus(profile.consent_share_anonymous_statistics === 1));
    yield put(updateUserProfile(profile));
  } catch (e) {
    console.error(e);
  }
}

export function* getUserProfileSaga() {
  try {
    const response: UserProfile = yield call(getUserProfile);
    yield put(updateUserProfile(response));
    yield put(setAnalyticStatus(response.consent_share_anonymous_statistics === 1));
  } catch (e) {
    console.error(e);
  }
}

export function* importAccountsSaga(action: TImportAccountsAction) {
  try {
    // Run loader in vault
    yield put(setVaultListIsFetchedStatus(false));
    history.push('/vault');
    yield call(importAccounts, action.payload.browsers);
    yield put(setVaultListIsFetchedStatus(true));
    yield put(sendTelemetryEvent({name: EVENTS.VAULT.IMPORT_ACCOUNTS_SUCCESS, source: 'app'}));
  } catch (e) {
    // Remove loader in vault list if error occur
    yield put(setVaultListIsFetchedStatus(true));
    yield unlockCheckAndHandle(e, action);
    console.error(e);
  }
}

export function* importAccountsPPISaga(action: TImportAccountsAction) {
  try {
    yield call(importAccounts, action.payload.browsers);
    yield put(hideRiskItem({id: 'import-accounts'}));
    yield put(setRecoveryKeyModalState({isOpen: true, isPPI: true}));
  } catch (e) {
    // Remove loader in vault list if error occur
    yield put(setVaultListIsFetchedStatus(true));
    yield unlockCheckAndHandle(e, action);
    console.error(e);
  }
}

export function* getNotificationsStatusSaga() {
  try {
    const notificationsStatusResponse: {[NOTIFICATIONS_STATUS]: string} = yield call(
      getSettingValue,
      NOTIFICATIONS_STATUS,
    );
    if (notificationsStatusResponse[NOTIFICATIONS_STATUS]) {
      yield put(setNotificationsStatus(JSON.parse(notificationsStatusResponse[NOTIFICATIONS_STATUS])));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* changeNotificationsStatusSaga(action: TChangeNotificationStatusAction) {
  try {
    yield call(setSettingValue, NOTIFICATIONS_STATUS, JSON.stringify(action.payload.notificationsStatus));
    yield put(setNotificationsStatus(action.payload.notificationsStatus));
  } catch (e) {
    console.error(e);
  }
}

export function* getUserLoginSaga(action: TGetUserLoginAction) {
  try {
    const response: TGetUserLoginResponse['payload'] = yield call(getUserLogin);
    yield put(setUserLogin(response));
  } catch (e) {
    yield unlockCheckAndHandle(e, action);
  }
}

function* changePasswordSaga() {
  yield put(
    setNextActions({
      nextAction: changePasswordModalState({
        isOpen: true,
        password: '',
        rePassword: '',
        passwordErrorText: '',
        rePasswordErrorText: '',
      }),
    }),
  );
  yield put(setModalVisible(true));
}

function* lockUserSaga() {
  yield put(setAppLoadingStatus(true));

  try {
    yield call(lockUser);
  } catch (e) {
    console.error(e);
  }
}

function* getIsUpdateAvailableSaga() {
  try {
    const {isUpdateAvailable}: {isUpdateAvailable: boolean} = yield call(getIsUpdateAvailable);
    if (isUpdateAvailable) {
      yield put(setUpdateInfo({isUpdateAvailable}));
    }
  } catch (e) {
    console.error(e);
  }
}

function* performUpdateSaga() {
  try {
    yield call(performUpdate);
  } catch (e) {
    console.error(e);
    if (e.payload && e.payload.message) {
      notificationService.showDefaultError(e.payload.message);
    }
  }
}

function* checkInternetConnectionSaga() {
  yield put(setIsNeedToCheckInternetConnectionStatus(true));
  try {
    const response: TPingResponse = yield call(ping);
    if (response.payload.result) {
      notificationService.clearAllToasts();
      yield put(setInternetConnectionStatus(true));
    }
  } catch (e) {
    console.error(e);
  }
  yield put(setIsNeedToCheckInternetConnectionStatus(false));
}

function* openSupportLinkSaga(action: TOpenSupportLinkAction) {
  const {type} = yield select(getLicenseInfo);
  const queryString = `appVersion=${VERSION}&appState=${type}&issueType=${action.payload.issueType}&distribution=${ENABLED_FEATURES.DistributionChanel}`;

  try {
    yield call(openURL, `${SUPPORT_PAGE}?${window.btoa(queryString)}`);
  } catch (err) {
    console.error(err);
    yield call(openURL, SUPPORT_PAGE);
  }
}

export function* applyPromoCodeSaga() {
  yield put(
    changePromoCodeModalAction({
      isLoading: true,
    }),
  );
  const state: TPromoCodeModalState = yield select(getPromoCodeModalState);
  try {
    yield call(applyPromoCode, state.code);
    yield put(getLicenseInfoAction());
    yield put(
      changePromoCodeModalAction({
        code: '',
        isOpen: false,
        isLoading: false,
      }),
    );
  } catch (e) {
    yield put(
      changePromoCodeModalAction({
        isLoading: false,
      }),
    );
    notificationService.applyPromoCodeError(e?.payload?.message);
  }
}

export function* openBuyNowSaga(action: TOpenBuyNowAction) {
  const {campaign, set} = action.payload;
  const parameters = `campaign=${campaign}&set=${set}`;
  try {
    const link: string = yield call(getBuyNowLink);
    yield call(openURL, `${link}&${parameters}`);
    yield put(
      sendTelemetryEvent({
        name: EVENTS.MONETIZATIOM.OPEN_BUYNOW_URL,
        source: 'user',
        payload: {campaign, set},
      }),
    );
  } catch (err) {
    console.error(err);
  }
}

export function* settingsWatcher() {
  yield takeEvery(OPEN_WEB_EXTENSION_STORE, openWebExtensionStoreSaga);
  yield takeLatest(GET_BROWSERS_REPORT, getBrowsersReportSaga);
  yield takeEvery(CHANGE_ANALYTIC_STATUS, changeAnalyticStatusSaga);
  yield takeEvery(GET_USER_PROFILE, getUserProfileSaga);
  yield takeEvery(IMPORT_ACCOUNTS, importAccountsSaga);
  yield takeEvery(IMPORT_ACCOUNTS_PPI, importAccountsPPISaga);
  yield takeEvery(GET_NOTIFICATIONS_STATUS, getNotificationsStatusSaga);
  yield takeEvery(CHANGE_NOTIFICATIONS_STATUS, changeNotificationsStatusSaga);
  yield takeEvery(GET_USER_LOGIN, getUserLoginSaga);
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(LOCK_USER, lockUserSaga);
  yield takeLatest(GET_IS_UPDATE_AVAILABLE, getIsUpdateAvailableSaga);
  yield takeLatest(PERFORM_UPDATE, performUpdateSaga);
  yield takeLatest(CHECK_INTERNET_CONNNETION_STATUS, checkInternetConnectionSaga);
  yield takeLatest(OPEN_SUPPORT_LINK, openSupportLinkSaga);
  yield takeLatest(APPLY_PROMO_CODE, applyPromoCodeSaga);
  yield takeLatest(OPEN_BUY_NOW, openBuyNowSaga);
}
