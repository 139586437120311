import React, {FC, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router';
import {useTranslation} from 'react-i18next';

import {HeadingComponent} from '@figleafteam/components';
import {AdvancedSettingsContainer} from '@renderer/js/components/settings/components/advanced-settings/advanced-settings.container';
import {AboutContainer} from '@renderer/js/components/settings/components/about/about.container';
import {AccountSettingsContainer} from '@renderer/js/components/settings/components/account-settings/account-settings.container';
import {EVENTS} from '@common/events';
import {EnterPromoCodeContainer} from '@renderer/js/components/settings/components/enter-promo-code-modal/enter-promo-code-modal.container';
import {SettingsProps} from './settings.types';
import {ExtensionsSettingsContainer} from './components/extensions-settings/extensions-settings.container';
import './_settings.scss';
import './_settings-item.scss';

export const SettingsComponent: FC<SettingsProps> = props => {
  const {t} = useTranslation();

  useEffect(() => {
    props.sendTelemetryEvent({name: EVENTS.SETTINGS.SETTINGS_SCREEN_SHOW, source: 'app'});
  }, []);

  const match = useRouteMatch();
  const settingsUrl = match.url;
  const links = [
    {url: `${settingsUrl}/account`, title: t('Account')},
    {url: `${settingsUrl}/extensions`, title: t('Extensions')},
    {url: `${settingsUrl}/advanced`, title: t('Advanced')},
    {url: `${settingsUrl}/about`, title: t('About')},
  ];
  return (
    <section className="settings">
      <div className="settings__header">
        <HeadingComponent title={t('Settings')} links={links} Link={NavLink} />
      </div>
      <div className="settings__inner">
        <Switch>
          <Redirect exact from={`${settingsUrl}`} to={`${settingsUrl}/account`} />
          <Route path={`${settingsUrl}/account`}>
            <AccountSettingsContainer />
          </Route>
          <Route path={`${settingsUrl}/extensions`}>
            <ExtensionsSettingsContainer />
          </Route>
          <Route path={`${settingsUrl}/advanced`}>
            <AdvancedSettingsContainer />
          </Route>
          <Route path={`${settingsUrl}/about`}>
            <AboutContainer />
          </Route>
        </Switch>
      </div>
      <EnterPromoCodeContainer />
    </section>
  );
};
