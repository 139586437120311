import {call, put, takeEvery, select} from 'redux-saga/effects';
import {
  getVpnInfo,
  getVpnConfig,
  installDriver,
  enableVpn,
  disableVpn,
  getDomainConfig,
  updateDefaultSettings,
  getTrackersBlockedInBrowser,
  getAntitrackingConfig,
  getAntitrackingStatistics,
  updateDomainConfig,
  clearAntitrackingWhiteList,
  getSettingValue,
} from '@figleafteam/api-communication';
import {
  setVpnInfo,
  setCountryList,
  setBrowserConfig,
  setTrackersBlockedInBrowser,
  setAntitrackingConfig,
  setVpnSessionStart,
  setAntitrackingStatistics,
  getBrowserConfig,
} from '@renderer/js/components/private-browsing/redux/actions';
import {
  GET_VPN_INFO,
  GET_VPN_CONFIG,
  REQUEST_ENABLE_VPN_CONNECTION,
  REQUEST_DISABLE_VPN_CONNECTION,
  GET_BROWSER_CONFIG,
  UPDATE_BROWSER_CONFIG,
  GET_TRACKERS_BLOCKED_IN_BROWSER,
  GET_ANTITRACKING_CONFIG,
  UPDATE_DOMAIN_CONFIG,
  CLEAR_ANTITRACKING_WHITELIST,
  VPN_SESSION_START,
  GET_VPN_SESSION_START,
  GET_ANTITRACKING_STATISTICS,
  SET_VPN_INFO,
} from '@renderer/js/components/private-browsing/redux/constants';
import {
  TGetBrowserConfigAction,
  TGetTrackersBlockedInBrowserAction,
  TSetVpnInfoAction,
  TUpdateBrowserConfigAction,
  TUpdateDomainAction,
} from '@renderer/js/components/private-browsing/redux/types';
import {getCurrentCountryCode} from '@renderer/js/components/private-browsing/redux/selectors';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {EVENTS} from '@common/events';
import {selectHiddenRisks, selectResolvedRisks} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {TRiskToDecrease} from '@renderer/js/components/ppi-funnel/redux/types';
import {setResolvedRisk} from '@renderer/js/components/ppi-funnel/redux/actions';

export function* getVpnInfoSaga() {
  try {
    const info: TVpnInfoPayload = yield call(getVpnInfo);
    const vpnInfo =
      info.state === 'connected'
        ? {
            isVpnFeatureAvailable: true,
            isVpnEnabled: true,
            currentCountryCode: info.countryCode?.toLowerCase(),
            currentCountryName: info.countryName,
            currentCountryIp: info.ipAddress,
          }
        : {isVpnEnabled: false, isVpnFeatureAvailable: true};
    yield put(setVpnInfo(vpnInfo));
  } catch (error) {
    if (error.payload && error.payload.message) {
      yield put(
        sendTelemetryEvent({
          name: EVENTS.CONTROLS.VPN_ERROR,
          source: 'app',
          payload: {error},
        }),
      );
    }
    yield put(setVpnInfo({currentCountryCode: 'us', currentCountryName: 'United States'}));
    console.error(error);
    yield put(
      setVpnInfo({
        isVpnEnabled: false,
      }),
    );
  }
}

export function* getCountryListSaga() {
  const currentCountryCode: string | null = yield select(getCurrentCountryCode);
  if (currentCountryCode === null) {
    yield put(setVpnInfo({currentCountryCode: 'us', currentCountryName: 'United States'}));
  }

  try {
    const config: {countries: TCountryMetaData[]} = yield call(getVpnConfig);
    if (config.countries.length) {
      yield put(setCountryList(config.countries));
    } else {
      throw new Error('No available countries for connection');
    }
  } catch (error) {
    if (error.payload && error.payload.message) {
      yield put(
        sendTelemetryEvent({
          name: EVENTS.CONTROLS.VPN_ERROR,
          source: 'app',
          payload: {error},
        }),
      );
    }
    console.error('err', error);
    yield put(setCountryList([]));
    yield put(
      setVpnInfo({
        isVpnEnabled: false,
      }),
    );
  }
}

function* installDriverAndEnableVpn(countryCode: string) {
  try {
    yield call(installDriver);
    yield call(enableVpn, countryCode);
  } catch (error) {
    if (error.payload && error.payload.message) {
      yield put(
        sendTelemetryEvent({
          name: EVENTS.CONTROLS.VPN_ERROR,
          source: 'app',
          payload: {error},
        }),
      );
      notificationService.showDefaultError(error.payload.message);
    }
    console.error('err', error);
    yield put(
      setVpnInfo({
        isVpnEnabled: false,
        isVpnStatusInProgress: false,
      }),
    );
  }
}

export function* enableVpnSaga() {
  const currentCountryCode: string = yield select(getCurrentCountryCode);
  if (currentCountryCode === null) {
    return;
  }
  try {
    yield call(enableVpn, currentCountryCode);
  } catch (error) {
    if (error.payload && error.payload.statusCode === 0) {
      yield installDriverAndEnableVpn(currentCountryCode);
    } else {
      if (error.payload && error.payload.message) {
        yield put(
          sendTelemetryEvent({
            name: EVENTS.CONTROLS.VPN_ERROR,
            source: 'app',
            payload: {error},
          }),
        );
        notificationService.showDefaultError(error.payload.message);
      }
      console.error('err', error);
      yield put(
        setVpnInfo({
          isVpnEnabled: false,
          isVpnStatusInProgress: false,
        }),
      );
    }
  }
}

export function* disableVpnSaga() {
  try {
    yield call(disableVpn);
  } catch (error) {
    console.error('err', error);
    if (error.payload && error.payload.message) {
      yield put(
        sendTelemetryEvent({
          name: EVENTS.CONTROLS.VPN_ERROR,
          source: 'app',
          payload: {error},
        }),
      );
      notificationService.showDefaultError(error.payload.message);
    }
    yield put(
      setVpnInfo({
        isVpnEnabled: false,
        isVpnStatusInProgress: false,
      }),
    );
  }
}

export function* getBrowserConfigSaga(action: TGetBrowserConfigAction) {
  try {
    const browserConfig: {payload: TDomainConfig} = yield call(getDomainConfig, action.payload.browserName);
    yield put(setBrowserConfig({browserConfig: browserConfig.payload, browserName: action.payload.browserName}));
  } catch (e) {
    console.error('err', e);
  }
}

export function* updateBrowserConfigSaga(action: TUpdateBrowserConfigAction) {
  if (!action.payload.domain) {
    throw new Error('Update config should have browser name');
  }
  try {
    yield call(updateDefaultSettings, action.payload);
    yield put(getBrowserConfig(action.payload.domain));
  } catch (e) {
    console.error('err', e);
  }
}

export function* getTrackersBlockedInBrowserSaga(action: TGetTrackersBlockedInBrowserAction) {
  try {
    const blockedTrackersInBrowserResponse: {payload: {blocked: number}} = yield call(
      getTrackersBlockedInBrowser,
      action.payload.browserName,
    );
    yield put(
      setTrackersBlockedInBrowser({
        browserName: action.payload.browserName,
        amountBlocked: blockedTrackersInBrowserResponse.payload.blocked,
      }),
    );
  } catch (e) {
    console.error('err', e);
  }
}

export function* getAntitrackingConfigSaga() {
  try {
    const antitrackingConfigResponse: {payload: TAntitrackingConfig} = yield call(getAntitrackingConfig);

    const isBrowserConfig = (value: string) => !/[^a-zA-Z]/.test(value); // browser config's domain contains only letters

    yield put(
      setAntitrackingConfig({
        ...antitrackingConfigResponse.payload,
        whiteList: antitrackingConfigResponse.payload.whiteList.filter(
          (item?: string) => item && !isBrowserConfig(item),
        ),
      }),
    );
  } catch (e) {
    console.error('err', e);
  }
}

export function* updateDomainConfigSaga(action: TUpdateDomainAction) {
  try {
    yield call(updateDomainConfig, action.payload);
  } catch (e) {
    console.error('err', e);
  }
}

function* clearAntitrackingWhiteListSaga() {
  try {
    yield call(clearAntitrackingWhiteList);
  } catch (e) {
    console.error(e);
    if (e.payload && e.payload.message) {
      notificationService.showDefaultError(e.payload.message);
    }
  }
}

export function* getVpnSessionStartSaga() {
  try {
    const sessionStartFromStoreResponse: {[VPN_SESSION_START]: string} = yield call(getSettingValue, VPN_SESSION_START);
    if (sessionStartFromStoreResponse && sessionStartFromStoreResponse[VPN_SESSION_START]) {
      yield put(setVpnSessionStart(sessionStartFromStoreResponse[VPN_SESSION_START]));
    }
  } catch (e) {
    console.error(e);
    return;
  }
}

export function* getAntitrackingStatisticsSaga() {
  try {
    const antitrackingStatisticsResponse: TAntitrackingStatistics = yield call(getAntitrackingStatistics);
    yield put(setAntitrackingStatistics(antitrackingStatisticsResponse));
  } catch (e) {
    console.error(e);
    return;
  }
}

export function* handleVpnInfoChange(action: TSetVpnInfoAction) {
  if (!action.payload.isVpnEnabled) {
    return;
  }
  const resolvedRisks: Array<TRiskToDecrease> = yield select(selectResolvedRisks);
  const hiddenRisks: Array<TRiskToDecrease> = yield select(selectHiddenRisks);
  const isVpnResolved = [...resolvedRisks, ...hiddenRisks].includes('vpn');
  if (!isVpnResolved) {
    yield put(setResolvedRisk({id: 'vpn'}));
  }
}

export function* privateBrowsingWatcher() {
  yield takeEvery(GET_VPN_INFO, getVpnInfoSaga);
  yield takeEvery(GET_VPN_CONFIG, getCountryListSaga);
  yield takeEvery(REQUEST_ENABLE_VPN_CONNECTION, enableVpnSaga);
  yield takeEvery(REQUEST_DISABLE_VPN_CONNECTION, disableVpnSaga);
  yield takeEvery(GET_VPN_SESSION_START, getVpnSessionStartSaga);
  yield takeEvery(GET_BROWSER_CONFIG, getBrowserConfigSaga);
  yield takeEvery(UPDATE_BROWSER_CONFIG, updateBrowserConfigSaga);
  yield takeEvery(UPDATE_DOMAIN_CONFIG, updateDomainConfigSaga);
  yield takeEvery(GET_TRACKERS_BLOCKED_IN_BROWSER, getTrackersBlockedInBrowserSaga);
  yield takeEvery(GET_ANTITRACKING_CONFIG, getAntitrackingConfigSaga);
  yield takeEvery(CLEAR_ANTITRACKING_WHITELIST, clearAntitrackingWhiteListSaga);
  yield takeEvery(GET_ANTITRACKING_STATISTICS, getAntitrackingStatisticsSaga);
  yield takeEvery(SET_VPN_INFO, handleVpnInfoChange);
}
