import {AppState} from '@renderer/js/redux/rootReducer';
import {connect} from 'react-redux';
import {getTip} from '@renderer/js/components/dashboard/redux/actions';
import {TipsWidgetComponent} from '@renderer/js/components/dashboard/components/tips/tips-widget.component';
import {getTipSelector} from '@renderer/js/components/dashboard/redux/selectors';
import {selectIsAuthorized} from '@renderer/js/components/app/redux/selectors';

const mapStateToProps = (state: AppState) => ({
  tip: getTipSelector(state),
  isAuthorized: selectIsAuthorized(state),
});

const mapDispatchToProps = {
  getTip,
};

export const TipsWidgetContainer = connect(mapStateToProps, mapDispatchToProps)(TipsWidgetComponent);
