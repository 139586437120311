import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getAccountInfo} from '@renderer/js/components/settings/redux/selectors';
import {getUserLogin, openSupportLink} from '@renderer/js/components/settings/redux/actions';
import {logout} from '@renderer/js/components/auth/redux/actions';
import {setSecurityKey, setPassword, submitSecurityKey} from '@renderer/js/components/enter-security-key/redux/actions';
import {getPassword, getSecurityKey} from '@renderer/js/components/enter-security-key/redux/selectors';
import {setRecoveryStatus} from '@renderer/js/components/recovery/redux/actions';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {EnterSecurityKeyComponent} from './enter-security-key.component';

const mapStateToProps = (state: AppState) => ({
  securityKey: getSecurityKey(state),
  password: getPassword(state),
  accountInfo: getAccountInfo(state),
  appInfo: getAppInfo(state),
});
const mapDispatchToProps = {
  getUserLogin,
  logout,
  setSecurityKey,
  setPassword,
  submitSecurityKey,
  setRecoveryStatus,
  openSupportLink,
};

export const EnterSecurityKeyContainer = connect(mapStateToProps, mapDispatchToProps)(EnterSecurityKeyComponent);
