import React, {FC, useState, useEffect} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, ButtonIconComponent, DropDownComponent, IconComponent} from '@figleafteam/components';
import {OnboardingComponent} from '@renderer/js/components/details/components/onboarding/onboarding.component';
import {EVENTS} from '@common/events';
import {useAuthorization} from '@renderer/js/components/auth/hooks/use-authorization';
import './_details.scss';
import {DetailsProps} from './details-control.types';

export const DetailsComponent: FC<DetailsProps> = props => {
  const {t} = useTranslation();
  const {isAuthorized, runIfAuthorized} = useAuthorization();
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [isDetailsDropdownOpen, showHideDetailsDropdown] = useState<boolean>(false);
  useEffect(() => {
    setIsFirstRender(true);
    if (isFirstRender) {
      return;
    }
    if (props.isExtensionsModalOpened) {
      props.sendTelemetryEvent({name: EVENTS.ADD_BROWSER_EXTENSION_POPUP.SHOW, source: 'app'});
    } else {
      props.sendTelemetryEvent({name: EVENTS.ADD_BROWSER_EXTENSION_POPUP.CLOSE, source: 'app'});
    }
  }, [props.isExtensionsModalOpened]);

  const isExtensionInstalled =
    props.browsersReport &&
    props.browsersReport.filter(browser => browser.isExtensionSupported).some(browser => browser.extensions.length);

  const detailsDropdownItems = [
    {
      id: 'install-extensions',
      component: (
        <ButtonComponent
          text={t('Browser Extensions')}
          type="empty"
          fullWidth
          size="xs"
          textAlign="left"
          beforeComponent={
            <div
              className={classNames('details__globe-icon-wrapper', {
                'details__globe-icon-wrapper--notification': !isExtensionInstalled,
              })}
            >
              <IconComponent name="globe" size={24} className="details__globe-icon" />
            </div>
          }
          onClick={() =>
            runIfAuthorized(() => {
              if (props.detailsKey === 'monitoring') {
                props.sendTelemetryEvent({name: EVENTS.MONITOR.DETAILS_BROWSER_EXTENSION_ITEM_CLICK, source: 'user'});
              }
              if (props.detailsKey === 'controls') {
                props.sendTelemetryEvent({name: EVENTS.CONTROLS.DETAILS_BROWSER_EXTENSION_ITEM_CLICK, source: 'user'});
              }
              if (props.detailsKey === 'vault') {
                props.sendTelemetryEvent({name: EVENTS.VAULT.DETAILS_BROWSER_EXTENSION_ITEM_CLICK, source: 'user'});
              }
              props.setDetailsState({isExtensionsModalOpened: true});
            })
          }
        />
      ),
    },
    {
      id: 'onboarding',
      component: (
        <ButtonComponent
          text={t('Show tutorial')}
          type="empty"
          fullWidth
          size="xs"
          textAlign="left"
          beforeComponent={<IconComponent name="info" size={24} />}
          onClick={() => {
            if (props.detailsKey === 'monitoring') {
              props.sendTelemetryEvent({name: EVENTS.MONITOR.DETAILS_SHOW_TUTORIAL_ITEM_CLICK, source: 'user'});
            }
            if (props.detailsKey === 'controls') {
              props.sendTelemetryEvent({name: EVENTS.CONTROLS.DETAILS_SHOW_TUTORIAL_ITEM_CLICK, source: 'user'});
            }
            if (props.detailsKey === 'vault') {
              props.sendTelemetryEvent({name: EVENTS.VAULT.DETAILS_SHOW_TUTORIAL_ITEM_CLICK, source: 'user'});
            }
            props.setDetailsState({isOnboardingOpened: true});
          }}
        />
      ),
    },
  ];

  return (
    <div className="details__wrapper">
      <div className="details__button">
        <ButtonIconComponent
          size="sm"
          modifier="white"
          inheritColor
          onClick={() => {
            if (props.detailsKey === 'monitoring') {
              props.sendTelemetryEvent({name: EVENTS.MONITOR.DETAILS_BUTTON_CLICK, source: 'user'});
            }
            if (props.detailsKey === 'controls') {
              props.sendTelemetryEvent({name: EVENTS.CONTROLS.DETAILS_BUTTON_CLICK, source: 'user'});
            }
            if (props.detailsKey === 'vault') {
              props.sendTelemetryEvent({name: EVENTS.VAULT.DETAILS_BUTTON_CLICK, source: 'user'});
            }
            if (props.isOnboardingOpened) {
              props.setDetailsState({isOnboardingOpened: false});
            }
            showHideDetailsDropdown(true);
          }}
        >
          {
            <div
              className={classNames('details__button-icon', {
                'details__button-icon--notification': !isExtensionInstalled,
              })}
            >
              <IconComponent name="info" size={20} />
            </div>
          }
        </ButtonIconComponent>
      </div>
      {isDetailsDropdownOpen ? (
        <DropDownComponent
          modifier="vault-details"
          closeDropdown={() => showHideDetailsDropdown(false)}
          items={detailsDropdownItems}
        />
      ) : null}
      {props.isOnboardingOpened ? (
        <OnboardingComponent
          modifier={props.onboardingModifier}
          steps={props.onboardingSteps}
          closeOnboarding={() => props.setDetailsState({isOnboardingOpened: false})}
          onboardingFinishButton={props.onboardingFinishButton}
          detailsKey={props.detailsKey}
          sendTelemetryEvent={props.sendTelemetryEvent}
          isAuthorized={isAuthorized}
        />
      ) : null}
    </div>
  );
};
