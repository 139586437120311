import React, {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {ButtonComponent, ConfirmationFormComponent} from '@figleafteam/components';
import {AuthPopupContents} from '@renderer/js/components/auth/redux/constants';
import {history} from '@renderer/js/router/history';
import {EVENTS} from '@common/events';
import '@renderer/js/components/auth/_confirm.scss';

import {AuthPopupConfirmDeviceProps} from './auth-popup-confirm-device.types';

export const AuthPopupConfirmDeviceComponent = memo<AuthPopupConfirmDeviceProps>(props => {
  const {appInfo} = props;
  const {t} = useTranslation();

  const requestConfirmationCode = () => {
    props.requestConfirmDeviceCode(props.email);
  };

  useEffect(() => {
    props.sendTelemetryEvent({name: EVENTS.PPI.AUTH_OPEN_CONFIRM_DEVICE_MODAL, source: 'app'});
  }, []);

  useEffect(() => {
    if (appInfo && appInfo.isAuthorized && appInfo.isMigrationRequired) {
      history.push('/migration');
    }
  }, [appInfo]);

  const goBackToLogin = () => {
    props.setAuthPopupContent(AuthPopupContents.LOGIN);
  };

  return (
    <div className="confirm confirm--auth-popup">
      <div className="confirm__container">
        <ConfirmationFormComponent
          title={t('Verify adding a new device')}
          email={props.email}
          code={props.code}
          resendTimer={props.resendTime}
          updateSecondsLeft={props.setResendCodeTime}
          handleResendConfirmationCode={requestConfirmationCode}
          setConfirmationCode={props.setConfirmDeviceCode}
        />
        <div className="confirm__bottom-text-wrapper">
          <p className="confirm__bottom-text">{t('Don’t forget to check your spam folder.')}</p>
          <p className="confirm__bottom-text">
            {t('Wrong email?')}{' '}
            <ButtonComponent onClick={goBackToLogin} text={t('Enter Another')} buttonType="button" type="link" />
          </p>
        </div>
      </div>
    </div>
  );
});

AuthPopupConfirmDeviceComponent.displayName = 'ConfirmDeviceComponent';
