import {connect} from 'react-redux';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {SettingsComponent} from './settings.component';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  sendTelemetryEvent,
};

export const SettingsContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsComponent);
