import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, ModalImportAccountsComponent, SwitcherComponent, IconComponent} from '@figleafteam/components';
import {EVENTS} from '@common/events';
import {isImportAccountsFromBrowser} from '@renderer/js/utils/is-import-accounts-from-browser';

import {AdvancedSettingsComponentProps} from './advanced-settings.types';

export const AdvancedSettingsComponent: React.FC<AdvancedSettingsComponentProps> = ({
  userProfileState,
  browsersReport,
  isAnalyticEnabled,
  notificationsStatus,
  importAccounts,
  getUserProfileAction,
  getBrowsersReport,
  changeNotificationsStatus,
  getNotificationsStatusAction,
  changeAnalyticStatus,
  sendTelemetryEvent,
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    if (!userProfileState.profile) {
      getUserProfileAction();
    }
    getBrowsersReport();
    getNotificationsStatusAction();
  }, []);
  const [isImportModalOpen, showHideModal] = useState<boolean>(false);

  const isInstalledBrowsers = isImportAccountsFromBrowser(browsersReport);

  const hadnleChangeShareStatistic = () => {
    if (!isAnalyticEnabled) {
      sendTelemetryEvent({name: EVENTS.SETTINGS.SHARE_STATISTIC_BUTTON_CLICK, source: 'user'}, true);
    } else {
      sendTelemetryEvent({name: EVENTS.SETTINGS.NOT_SHARE_STATISTIC_BUTTON_CLICK, source: 'user'}, true);
    }
    changeAnalyticStatus();
  };

  const handleImport = () => {
    sendTelemetryEvent({name: EVENTS.SETTINGS.IMPORT_BUTTON_CLICK, source: 'user'});
    showHideModal(true);
  };

  return (
    <div className="settings-section">
      {isInstalledBrowsers && (
        <div className="settings-item">
          <div className="settings-item__content">
            <div className="settings-item__title-wrapper">
              <span className="settings-item__icon">
                <IconComponent name="import" size={24} />
              </span>
              <h3 className="settings-item__title">{t('Import')}</h3>
            </div>
            <div className="settings-item__data">
              <span className="settings-item__text">
                {t('Upload usernames and passwords from browsers or password managers')}
              </span>
            </div>
          </div>
          <div className="settings-item__buttons">
            <ButtonComponent
              type="empty-bordered"
              fullWidth
              disabled={!isInstalledBrowsers}
              text={t('Import')}
              onClick={handleImport}
            />
          </div>
        </div>
      )}
      <div className="settings-item">
        <div className="settings-item__content">
          <div className="settings-item__title-wrapper">
            <span className="settings-item__icon">
              <IconComponent name="analytics" size={24} />
            </span>
            <h3 className="settings-item__title">{t('Analytics')}</h3>
          </div>
          <div className="settings-item__data settings-item__data--switcher">
            <div className="settings-item__switcher-wrapper">
              <SwitcherComponent isChecked={isAnalyticEnabled} onChange={hadnleChangeShareStatistic} />
            </div>
            <span className="settings-item__text">{t('Share anonymous usage statistics')}</span>
          </div>
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__content">
          <div className="settings-item__title-wrapper">
            <span className="settings-item__icon">
              <IconComponent name="bell" size={24} />
            </span>
            <h3 className="settings-item__title">{t('Notifications')}</h3>
          </div>
          <div className="settings-item__data settings-item__data--switcher">
            <div className="settings-item__switcher-wrapper">
              <SwitcherComponent
                isChecked={notificationsStatus.breaches}
                onChange={() =>
                  changeNotificationsStatus({
                    ...notificationsStatus,
                    breaches: !notificationsStatus.breaches,
                  })
                }
              />
            </div>
            <span className="settings-item__text">{t('Send me alerts about recent data breaches')}</span>
          </div>
          <div className="settings-item__data settings-item__data--switcher">
            <div className="settings-item__switcher-wrapper">
              <SwitcherComponent
                isChecked={notificationsStatus.subsctioption}
                onChange={() =>
                  changeNotificationsStatus({
                    ...notificationsStatus,
                    subsctioption: !notificationsStatus.subsctioption,
                  })
                }
              />
            </div>
            <span className="settings-item__text">{t('Send me subscription expiration reminders')}</span>
          </div>
          <div className="settings-item__data settings-item__data--switcher">
            <div className="settings-item__switcher-wrapper">
              <SwitcherComponent
                isChecked={notificationsStatus.actions}
                onChange={() =>
                  changeNotificationsStatus({
                    ...notificationsStatus,
                    actions: !notificationsStatus.actions,
                  })
                }
              />
            </div>
            <span className="settings-item__text">{t('Send me notifications about FigLeaf actions')}</span>
          </div>
        </div>
      </div>
      <ModalImportAccountsComponent
        isImportModalOpen={isImportModalOpen}
        showHideModal={showHideModal}
        browsersReport={browsersReport}
        importAccounts={importAccounts}
      />
    </div>
  );
};
