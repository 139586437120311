import {connect} from 'react-redux';
import {getLicenseInfo, isPremium} from '@figleafteam/store';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {openSupportLink} from '@renderer/js/components/settings/redux/actions';
import {AppState} from '@renderer/js/redux/rootReducer';
import {selectIsPpiFlowFinished} from '@renderer/js/components/settings/redux/selectors';
import {selectRisksScannerStep} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {setAuthPopupOpened} from '@renderer/js/components/auth/redux/actions';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {SideBarComponent} from '@renderer/js/components/side-bar/side-bar.component';

const mapStateToProps = (state: AppState) => ({
  appInfo: getAppInfo(state),
  riskScannerStep: selectRisksScannerStep(state),
  licenseInfo: getLicenseInfo(state),
  isPremium: isPremium(state),
  isPpiFlowFinished: selectIsPpiFlowFinished(state),
});

const mapDispatchToProps = {
  sendTelemetryEvent,
  openSupportLink,
  setAuthPopupOpened,
};

export const SideBarContainer = connect(mapStateToProps, mapDispatchToProps)(SideBarComponent);
