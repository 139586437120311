import React, {useState} from 'react';
import {ModalComponent, ConfirmationFormComponent, ButtonComponent} from '@figleafteam/components';
import {TPPIVerifyEmailsModalProps} from './ppi-verify-email-modal.types';
import './_ppi-verify-email-modal.scss';

import SendImage from './images/send.svg';

export const PPIVerifyEmailModalComponent: React.FC<TPPIVerifyEmailsModalProps> = ({
  ppiLeaksState,
  setPpiLeaksState,
  sendMonitorConfirmationCode,
  submitMonitorConfirmationCode,
  getVerifiedEmails,
  getFullReport,
}) => {
  const {currentEmailToVerify, isVerifyModalOpen, isVerifyInProgress} = ppiLeaksState;
  if (!isVerifyModalOpen) {
    return null;
  }
  const [confirmationCode, setConfirmationCode] = useState('');
  const {email} = ppiLeaksState.profilesToVerify[currentEmailToVerify];

  const handleConfirmationCodeChange = (code: string) => {
    if (code.length === 6) {
      submitMonitorConfirmationCode(email, code);
    }
    setConfirmationCode(code);
  };

  const handleClose = () => {
    getFullReport();
    getVerifiedEmails();
  };

  const resendConfirmationCode = () => {
    sendMonitorConfirmationCode(email);
  };

  const handleDeleteEmailFromReport = () => {
    setPpiLeaksState({
      isVerifyModalOpen: false,
      isDeleteEmailModalOpen: true,
      nextModal: 'verify',
    });
  };

  const verifyLater = () => {
    setPpiLeaksState({
      isAddEmailModalOpen: true,
      isVerifyModalOpen: false,
      currentEmailToVerify: currentEmailToVerify + 1,
    });
  };

  const verifySteps = [
    `Check ${email || 'your email'}`,
    'Open verification email to get the code (may need to check Spam folder)',
    'Enter code below',
  ];

  return (
    <ModalComponent width={620} isOpen showCloseButton handleClose={handleClose}>
      <div className="verify-email-modal">
        <div className="verify-email-modal__image">
          <SendImage />
        </div>
        <ConfirmationFormComponent
          title={email}
          email={email}
          code={confirmationCode}
          isLoading={isVerifyInProgress}
          resendTimer={120}
          customSteps={verifySteps}
          handleResendConfirmationCode={resendConfirmationCode}
          setConfirmationCode={handleConfirmationCodeChange}
        />
        <div className="verify-email-modal__footer">
          <div className="verify-email-modal__footer-col">
            <p className="verify-email-modal__footer-text">If you don&apos;t own this email -</p>
            <ButtonComponent
              type="link"
              className="verify-email-modal__footer-link"
              text="Delete it"
              onClick={handleDeleteEmailFromReport}
            />
          </div>
          <div className="verify-email-modal__footer-col">
            <ButtonComponent
              type="link"
              text="Verify email later"
              className="verify-email-modal__footer-link"
              onClick={verifyLater}
            />
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};
