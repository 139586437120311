import {
  quickScanProfiles,
  setPpiFunnelState,
  setRiskCategories,
  setResolvedRisk,
  hideRiskItem,
  setPpiFlowFinished,
  setPpiLeaksState,
  getVerifiedEmails,
  deleteEmailFromReport,
  addPpiEmailToMonitor,
  verifyNextEmail,
  sendMonitorConfirmationCode,
  submitMonitorConfirmationCode,
  getFullReport,
} from './actions';

export type TParsedDataKeys =
  | 'names'
  | 'gender'
  | 'language'
  | 'accounts'
  | 'phones'
  | 'socialProfiles'
  | 'logins'
  | 'passwords'
  | 'ipAddresses'
  | 'locations'
  | 'ids'
  | 'securityQuestions'
  | 'generalInfo';

export type TSocialProfileTypes =
  | 'facebook'
  | 'google'
  | 'youtube'
  | 'instagram'
  | 'yahoo'
  | 'skype'
  | 'reddit'
  | 'stackoverflow'
  | 'pinterest'
  | 'twitter'
  | 'linkedin';

export type TDataItem = {
  value: string;
  hidden?: boolean;
  type?: string;
  socialType?: TSocialProfileTypes;
};

export type TParsedDataState = {
  [key in TParsedDataKeys]: Array<TDataItem>;
};

export enum RiskTypes {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export type TIdentityExposureCategory = {
  riskType: RiskTypes;
  items: {
    generalInfo: {
      name: string;
      data: Array<TDataItem>;
    };
    ids: {
      name: string;
      data: Array<TDataItem>;
    };
    phones: {
      name: string;
      data: Array<TDataItem>;
    };
    socialProfiles: {
      name: string;
      data: Array<TDataItem>;
    };
  };
};

export type TAccountHijackingCategory = {
  riskType: RiskTypes;
  items: {
    passwords: {
      name: string;
      data: Array<TDataItem>;
    };
    accounts: {
      name: string;
      data: Array<TDataItem>;
    };
    logins: {
      name: string;
      data: Array<TDataItem>;
    };
    securityQuestions: {
      name: string;
      data: Array<TDataItem>;
    };
  };
};

export type THomeTitleTheftCategory = {
  riskType: RiskTypes;
  items: {
    ips: {
      name: string;
      data: Array<TDataItem>;
    };
    locations: {
      name: string;
      data: Array<TDataItem>;
    };
  };
};

export type TRiskCategoriesState = {
  identityExposure: TIdentityExposureCategory;
  accountHijacking: TAccountHijackingCategory;
  homeTitleTheft: THomeTitleTheftCategory;
};

export type TScannerStep = 'initial' | 'scanning' | 'report';

export type TRiskToDecrease = 'monitoring-email' | 'import-accounts' | 'vpn' | 'add-extensions';

export type TResolveRiskPayload = {
  id: TRiskToDecrease;
};

export type TDecreaseRisksState = {
  resolved: TRiskToDecrease[];
  hidden: TRiskToDecrease[];
};

export type TPpiFunnelState = {
  isLoading: boolean;
  isDataFetched: boolean;
  isInitialScanFinished: boolean;
  isPpiFunnelShown: boolean;
  globalRiskType: RiskTypes;
  exposedItemsCount: number;
  exposedCategoriesCount: number;
  isAllRisksResolved: boolean;
  isRisksScannerOpened: boolean;
  riskCategories: TRiskCategoriesState;
  decreaseRisks: TDecreaseRisksState;
  scannerStep: TScannerStep;
};

export type TPpiLeaksState = {
  isAddEmailModalOpen: boolean;
  isVerifyModalOpen: boolean;
  isVerifyInProgress: boolean;
  isDeleteEmailModalOpen: boolean;
  currentEmailToVerify: number;
  emailsToRescan: string[];
  profilesToVerify: {
    email: string;
    browser: Browsers;
  }[];
  profiles: string[];
  rawLeaksReport: TQuickScanResponsePayload['leaks'] | null;
  nextModal: 'add' | 'verify';
};

export type TQuickScanProfilesActionCreator = typeof quickScanProfiles;
export type TQuickScanProfilesAction = ReturnType<TQuickScanProfilesActionCreator>;

export type TSetRiskCategoriesActionCreator = typeof setRiskCategories;
export type TSetRiskCategoriesAction = ReturnType<TSetRiskCategoriesActionCreator>;

export type TSetPpiFunnelStateActionCreator = typeof setPpiFunnelState;
export type TSetPpiFunnelStateAction = ReturnType<TSetPpiFunnelStateActionCreator>;

export type TSetResolvedRiskActionCreator = typeof setResolvedRisk;
export type TSetResolvedRiskAction = ReturnType<TSetResolvedRiskActionCreator>;

export type THideRiskItemActionCreator = typeof hideRiskItem;
export type THideRiskItemAction = ReturnType<THideRiskItemActionCreator>;

export type TSetPpiFlowFinishedActionCreator = typeof setPpiFlowFinished;
export type TSetPpiFlowFinishedAction = ReturnType<TSetPpiFlowFinishedActionCreator>;

export type TSetPpiLeaksStateActionCreator = typeof setPpiLeaksState;
export type TSetPpiLeaksStateAction = ReturnType<TSetPpiLeaksStateActionCreator>;

export type TGetVerifiedEmailsActionCreator = typeof getVerifiedEmails;
export type TGetVerifiedEmailsAction = ReturnType<TGetVerifiedEmailsActionCreator>;

export type TDeleteEmailFromReportActionCreator = typeof deleteEmailFromReport;
export type TDeleteEmailFromReportAction = ReturnType<TDeleteEmailFromReportActionCreator>;

export type TAddPpiEmailToMonitorActionCreator = typeof addPpiEmailToMonitor;
export type TAddPpiEmailToMonitorAction = ReturnType<TAddPpiEmailToMonitorActionCreator>;

export type TVerifyNextEmailActionCreator = typeof verifyNextEmail;
export type TVerifyNextEmailAction = ReturnType<TVerifyNextEmailActionCreator>;

export type TSendMonitorConfirmationCodeActionCreator = typeof sendMonitorConfirmationCode;
export type TSendMonitorConfirmationCodeAction = ReturnType<TSendMonitorConfirmationCodeActionCreator>;

export type TSubmitMonitorConfirmaionCodeActionCreator = typeof submitMonitorConfirmationCode;
export type TSubmitMonitorConfirmaionCodeAction = ReturnType<TSubmitMonitorConfirmaionCodeActionCreator>;

export type TGetFullReportActionCreator = typeof getFullReport;
export type TGetFullReportAction = ReturnType<TGetFullReportActionCreator>;
