import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getPromoCodeModalState} from '@renderer/js/components/settings/redux/selectors';
import {applyPromoCodeAction, changePromoCodeModalAction} from '@renderer/js/components/settings/redux/actions';
import {EnterPromoCodeComponent} from './enter-promo-code-modal.component';

const mapStateToProps = (state: AppState) => ({
  promoCodeModalState: getPromoCodeModalState(state),
});
const mapDispatchToProps = {
  changePromoCodeModalAction,
  applyPromoCodeAction,
};

export const EnterPromoCodeContainer = connect(mapStateToProps, mapDispatchToProps)(EnterPromoCodeComponent);
