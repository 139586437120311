import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {selectPpiLeaksState} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {
  setPpiLeaksState,
  sendMonitorConfirmationCode,
  submitMonitorConfirmationCode,
  getVerifiedEmails,
  getFullReport,
} from '@renderer/js/components/ppi-funnel/redux/actions';
import {PPIVerifyEmailModalComponent} from './ppi-verify-email-modal.component';

const mapStateToProps = (state: AppState) => ({
  ppiLeaksState: selectPpiLeaksState(state),
});

const mapDispatchToProps = {
  setPpiLeaksState,
  sendMonitorConfirmationCode,
  submitMonitorConfirmationCode,
  getVerifiedEmails,
  getFullReport,
};

export const PPIVerifyEmailModalContainer = connect(mapStateToProps, mapDispatchToProps)(PPIVerifyEmailModalComponent);
