import React, {FC} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import {i18n} from '@figleafteam/helpers';
import {IconComponent} from '@figleafteam/components';
import {TPPIRisksReportRiskCategoryProps} from './ppi-risks-report-risk-category.types';

import './_ppi-risks-report-risk-category.scss';

export const getRecommendedActionItem = (isResolved: boolean) => ({
  value: isResolved ? i18n.t('Protected') : i18n.t('Status unknown'),
  icon: (
    <div
      className={classNames('report-risk-category__recommended-action-icon', {
        'report-risk-category__recommended-action-icon--resolved': isResolved,
      })}
    >
      <IconComponent name={isResolved ? 'check-round' : 'help'} size={24} />
    </div>
  ),
});

export const PPIRisksReportRiskCategoryComponent: FC<TPPIRisksReportRiskCategoryProps> = ({
  icon,
  title,
  items,
  appInfo,
  profilesToVerify,
  setPpiLeaksState,
  setAuthPopupOpened,
}) => {
  const {t} = useTranslation();

  return (
    <div className="report-risk-category">
      <div className="report-risk-category__icon">{icon}</div>
      <div className="report-risk-category__title">{title}</div>
      <ul className="report-risk-category__items">
        {items.length ? (
          items.map((item, index) => (
            <li
              className={classNames('report-risk-category__item', {
                'report-risk-category__item--blurred': !appInfo?.isAuthorized && index !== 0,
                'report-risk-category__item--clickable': appInfo?.isAuthorized && item.hidden,
              })}
              key={item.value}
            >
              <div
                {...(item.hidden &&
                  Boolean(profilesToVerify.length) && {
                    onClick: () => {
                      if (appInfo?.isAuthorized) {
                        setPpiLeaksState({isAddEmailModalOpen: true});
                        return;
                      }

                      setAuthPopupOpened(true);
                    },
                    role: 'button',
                  })}
                className="report-risk-category__item-main"
              >
                <div className="report-risk-category__item-content">
                  {item.label && <div className="report-risk-category__item-label">{item.label}</div>}
                  <div className="report-risk-category__item-value">
                    {item.icon && <div className="report-risk-category__item-icon">{item.icon}</div>}
                    {item.value}
                  </div>
                </div>
                {item.hidden && (
                  <div className="report-risk-category__item-eye">
                    <IconComponent name="eye" size={24} />
                  </div>
                )}
              </div>
            </li>
          ))
        ) : (
          <li className="report-risk-category__item report-risk-category__item-empty">
            {t('No exposed data was found. Well done!')}
          </li>
        )}
      </ul>
    </div>
  );
};
