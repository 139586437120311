import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {selectIdentityExposure, selectResolvedRisks} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {PPIRisksReportIdentityExposureComponent} from './ppi-risks-report-identity-exposure.component';

const mapStateToProps = (state: AppState) => ({
  identityExposure: selectIdentityExposure(state),
  resolvedRisks: selectResolvedRisks(state),
});

const mapDispatchToProps = {};

export const PPIRisksReportIdentityExposureContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PPIRisksReportIdentityExposureComponent);
