import {setSettingValue} from '@figleafteam/api-communication';
import {getVaultList, getMonitorData, getMonitorProfile, setLicenseInfo} from '@figleafteam/store';
import {
  setDefaultSettings,
  setVpnInfo,
  getAntitrackingConfig,
  setVpnSessionStart,
  getAntitrackingStatistics,
  getTrackersBlockedInBrowser,
  getBrowserConfig,
} from '@renderer/js/components/private-browsing/redux/actions';
import {
  getBrowsersReport,
  setInternetConnectionStatus,
  setNoInternetNotificationOpenState,
  setUpdateInfo,
} from '@renderer/js/components/settings/redux/actions';
import {setAppInfo, getAppInfoAction} from '@renderer/js/components/app/redux/actions';
import {TVpnInfoType} from '@renderer/js/components/private-browsing/redux/types';
import {VPN_SESSION_START} from '@renderer/js/components/private-browsing/redux/constants';
import {ERROR_STATUS_CODES} from '@common/communication/constants';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {StoreType} from '@renderer/js/redux/store';
import {logger} from '@common/compatibility/logger';
import messenger from './messenger';
import {HandleDeepLink} from './deepLinkHandler';

export const addOnMessageHandlers = (store: StoreType, handleDeepLink: HandleDeepLink) => {
  messenger.onMessage((message: TAllMessages) => {
    logger.debug('==== getMessage ====', message);

    switch (message.type) {
      case 'VpnInfoChanged': {
        const state = message.payload.state;
        const vpnInfo: Partial<TVpnInfoType> = {
          isVpnEnabled: state === 'connected',
          isVpnStatusInProgress: state === 'connecting' || state === 'disconnecting',
        };

        if (state === 'connected') {
          vpnInfo.currentCountryCode = message.payload.countryCode?.toLowerCase();
          vpnInfo.currentCountryName = message.payload.countryName;
          vpnInfo.currentCountryIp = message.payload.ipAddress;

          const vpnSessionStartString = new Date().toString();
          setSettingValue(VPN_SESSION_START, vpnSessionStartString);
          store.dispatch(setVpnSessionStart(vpnSessionStartString));
        } else {
          store.dispatch(setVpnSessionStart(null));
        }

        return store.dispatch(setVpnInfo(vpnInfo));
      }
      case 'ConnectionAddedNotification':
      case 'ConnectionRemovedNotification':
        // TODO: received not updated report | will be fixed in service
        return setTimeout(() => {
          store.dispatch(getBrowsersReport());
        }, 2000);
      case 'VaultChanged':
        return store.dispatch(getVaultList());
      case 'MonitorAlertsChanged':
        store.dispatch(getMonitorData());
        store.dispatch(getMonitorProfile());
        break;
      case 'AntiTrackingDidFilterRequestArray':
        if ((message as TAntiTrackingDidFilterRequestArrayEvent).payload.clientType) {
          store.dispatch(
            getTrackersBlockedInBrowser((message as TAntiTrackingDidFilterRequestArrayEvent).payload.clientType),
          );
          return store.dispatch(getAntitrackingStatistics());
        }
        break;

      case 'DefaultSettingsChanged': {
        // TODO: Rename DefaultSettingsChanged(Request|Response|Error) to ChangeDefaultSettings(Request|Response|Error)
        if (Object.keys(message.payload).length === 0) {
          break;
        }
        const {payload} = message as TDefaultSettingsChangedEvent;

        if (payload.domain) {
          store.dispatch(getBrowserConfig(payload.domain));
        }
        return store.dispatch(setDefaultSettings(payload));
      }
      case 'ChangeAppInfo': {
        store.dispatch(setAppInfo(message.payload));
        const licenseInfo = message.payload.licenseInfo;

        if (licenseInfo && !licenseInfo.isExpired) {
          store.dispatch(getMonitorData());
          store.dispatch(getMonitorProfile());
        }

        return store.dispatch(setLicenseInfo({...licenseInfo, cancelAtPeriodEnd: licenseInfo.cancel_at_period_end}));
      }
      case 'UpdateAvailable': {
        return store.dispatch(setUpdateInfo({isUpdateAvailable: true, isUpdateNotificationShown: false}));
      }
      case 'HandleDeepLink': {
        return handleDeepLink(message.payload.link, store);
      }
      case 'AntiTrackingWhitelistDidUpdate':
        return store.dispatch(getAntitrackingConfig());
      case 'ConnectorStateChanged':
        if (message.payload.state === 'connected') {
          return store.dispatch(getAppInfoAction());
        }
        break;
      default: {
        break;
      }
    }
  });

  messenger.onErrorMessage(message => {
    if (message.payload?.statusCode === ERROR_STATUS_CODES.Network) {
      const {isNoInternetNotificationOpen} = store.getState().settingsState.settingsInfo;
      if (isNoInternetNotificationOpen) {
        return;
      }
      store.dispatch(setInternetConnectionStatus(false));
      store.dispatch(setNoInternetNotificationOpenState(true));
      notificationService.addCleanUpFunction('resetNoInternetNotification', () => {
        store.dispatch(setNoInternetNotificationOpenState(false));
      });
      notificationService.showNoInternetError(message.payload.message, {
        onClose: () => {
          store.dispatch(setNoInternetNotificationOpenState(false));
        },
      });
    }
  });
};
