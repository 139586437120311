import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import './_win-window-controls.scss';
import {closeWindow, maximizeWindow, minimizeWindow} from '@common/compatibility/windowControls';
import {getPlatform} from '@common/compatibility/getPlatform';
import IconClose from './images/icon-close.svg';
import IconMaximize from './images/icon-maximize.svg';
import IconMinimize from './images/icon-minimize.svg';
import {Props} from './win-window-controls.types';

export const WinWindowControlsComponent: FC<Props> = props => {
  const {t} = useTranslation();

  if (getPlatform() !== 'WINDOWS') {
    return null;
  }
  const handleClose = () => closeWindow();
  const handleMaximize = () => maximizeWindow();
  const handleMinimize = () => minimizeWindow();
  let className = 'win-window-controls';
  if (props.mode === 'light') {
    className += ' win-window-controls--dark';
  }
  return (
    <div className={className}>
      <button
        type="button"
        onClick={handleMinimize}
        className="win-window-controls__minimize"
        title={t('Minimize app')}
      >
        <IconMinimize />
      </button>
      <button
        type="button"
        onClick={handleMaximize}
        className="win-window-controls__maximize"
        title={t('Maximize app')}
      >
        <IconMaximize />
      </button>
      <button type="button" onClick={handleClose} className="win-window-controls__close" title={t('Close app')}>
        <IconClose />
      </button>
    </div>
  );
};
