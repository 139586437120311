import {
  RecoveryState,
  TClearRecoveryInfoAction,
  TSetRecoveryStatusAction,
  TSetRecoveryKeyAction,
  TSetRecoveryEmailAction,
  TSetRecoveryKeyErrorAction,
  TResetRecoveryKeyErrorAction,
  TSetResendCodeTimeAction,
  TSetConfirmRecoveryCodeAction,
  TSetNewPasswordAction,
  TCreateChangePasswordErrorAction,
  TResetChangePasswordValidationAction,
  TChangePasswordErrors,
  TRecoveryKeyErrors,
} from '@renderer/js/components/recovery/redux/types';
import {
  SET_RECOVERY_STATUS,
  SET_RECOVERY_KEY_VALUE,
  SET_RECOVERY_EMAIL_VALUE,
  SET_RECOVERY_KEY_ERROR,
  RESET_RECOVERY_KEY_ERROR,
  SET_CONFIRM_RECOVERY_CODE,
  SET_NEW_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  RESET_CHANGE_PASSWORD_VALIDATION,
  CLEAR_RECOVERY_INFO,
  SET_RESEND_CODE_TIME,
} from '@renderer/js/components/recovery/redux/constants';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {initialErrors as initialChangePasswordErrors} from '@renderer/js/components/recovery/utils/validateChangePassword';
import {initialErrors as initialRecoveryKeyErrors} from '@renderer/js/components/recovery/utils/validateRecoveryKey';

const changePasswordErrors: TChangePasswordErrors = {...initialChangePasswordErrors};
const recoveryKeyErrors: TRecoveryKeyErrors = {...initialRecoveryKeyErrors};

const initialState: RecoveryState = {
  recoveryStatus: false,

  email: '',
  recoveryKey: '',
  confirmRecoveryCode: '',
  newPassword: '',
  newRePassword: '',
  resendCodeTime: 120,

  recoveryKeyErrors,
  changePasswordErrors,
};

type Actions =
  | TClearRecoveryInfoAction
  | TSetRecoveryStatusAction
  | TSetRecoveryKeyAction
  | TSetRecoveryEmailAction
  | TSetRecoveryKeyErrorAction
  | TResetRecoveryKeyErrorAction
  | TSetResendCodeTimeAction
  | TSetConfirmRecoveryCodeAction
  | TSetNewPasswordAction
  | TCreateChangePasswordErrorAction
  | TResetChangePasswordValidationAction
  | TLogOutSuccessAction;

export const recoveryStateReducer = (state: RecoveryState = initialState, action: Actions): RecoveryState => {
  switch (action.type) {
    case SET_RECOVERY_STATUS:
    case SET_RECOVERY_KEY_VALUE:
    case SET_RECOVERY_EMAIL_VALUE:
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case SET_RECOVERY_KEY_ERROR: {
      return {
        ...state,
        recoveryKeyErrors: action.payload.errors,
      };
    }
    case RESET_RECOVERY_KEY_ERROR: {
      return {
        ...state,
        recoveryKeyErrors: initialState.recoveryKeyErrors,
      };
    }
    case SET_RESEND_CODE_TIME: {
      return {
        ...state,
        resendCodeTime: action.payload.seconds,
      };
    }
    case SET_CONFIRM_RECOVERY_CODE: {
      return {
        ...state,
        confirmRecoveryCode: action.payload.code,
      };
    }
    case SET_NEW_PASSWORD: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case RESET_CHANGE_PASSWORD_VALIDATION: {
      return {
        ...state,
        changePasswordErrors: initialChangePasswordErrors,
      };
    }
    case CLEAR_RECOVERY_INFO:
      return initialState;
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
