import {SET_IS_MIGRATION_REQUIRED, SET_PASSWORD, SET_SECURITY_KEY_VALUE, SUBMIT_SECURITY_KEY} from './constants';

export const setIsMigrationRequired = (isMigrationRequired: boolean) => ({
  type: SET_IS_MIGRATION_REQUIRED,
  payload: {
    isMigrationRequired,
  },
});

export const setSecurityKey = (key: string) => ({
  type: SET_SECURITY_KEY_VALUE,
  payload: {
    key,
  },
});

export const setPassword = (password: string) => ({
  type: SET_PASSWORD,
  payload: {
    password,
  },
});

export const submitSecurityKey = (key: string, password?: string) => ({
  type: SUBMIT_SECURITY_KEY,
  payload: {
    key,
    password,
  },
});
