import {connect} from 'react-redux';
import {getLicenseInfoAction, isLicenseExpired} from '@figleafteam/store';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  getVpnConnectionStatus,
  getCountryList,
  getCurrentCountryCode,
  getCurrentCountryName,
  getCurrentCountryCodeIp,
  getVpnInProgressStatus,
  getVpnInProgressStatusText,
  getIsVpnConnectionScheduled,
  isVpnInfoFetched,
  isCountryListFetched,
  getVpnSessionStartState,
} from '@renderer/js/components/private-browsing/redux/selectors';
import {
  getVpnConfigAction,
  getVpnInfoAction,
  enableVpn,
  disableVpn,
  scheduleVpnConnection,
  setCurrentCountry,
  getVpnSessionStart,
} from '@renderer/js/components/private-browsing/redux/actions';
import {setPaywallModalState} from '@renderer/js/components/paywall-modal/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {setAuthPopupOpened} from '@renderer/js/components/auth/redux/actions';
import {VpnComponent} from './vpn.component';

const mapStateToProps = (state: AppState) => ({
  isVpnInfoFetched: isVpnInfoFetched(state),
  isCountryListFetched: isCountryListFetched(state),
  isVpnEnabled: getVpnConnectionStatus(state),
  isVpnStatusInProgress: getVpnInProgressStatus(state),
  vpnInProgressStatusText: getVpnInProgressStatusText(state),
  isVpnConnectionScheduled: getIsVpnConnectionScheduled(state),
  countryList: getCountryList(state),
  currentCountryCode: getCurrentCountryCode(state),
  currentCountryName: getCurrentCountryName(state),
  currentCountryIp: getCurrentCountryCodeIp(state),
  isLicenseExpired: isLicenseExpired(state),
  vpnSessionStart: getVpnSessionStartState(state),
});
const mapDispatchToProps = {
  getVpnConfig: getVpnConfigAction,
  getVpnInfo: getVpnInfoAction,
  enableVpn,
  disableVpn,
  scheduleVpnConnection,
  setCurrentCountry,
  getLicenseInfoAction,
  setPaywallModalState,
  sendTelemetryEvent,
  getVpnSessionStart,
  setAuthPopupOpened,
};

export const VpnContainer = connect(mapStateToProps, mapDispatchToProps)(VpnComponent);
