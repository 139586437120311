import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';
import {TSendTelemetryEventAction} from '@renderer/js/events/redux/types';
import {
  SET_ANALYTIC_STATUS,
  SET_NOTIFICATIONS_STATUS,
  SET_UPDATE_INFO,
  SET_INTERNET_CONNECTION_STATUS,
  SET_IS_NEED_TO_CHECK_INTERNET_CONNECTION_STATUS,
  SET_NO_INTERNET_NOTIFICATION_OPEN_STATE,
} from '../constants';
import {
  TSettingsInfoState,
  TSetAnalyticStatusAction,
  TSetNotificationStatusAction,
  TSetUpdateInfoAction,
  TPerformUpdateAction,
  TSetNoInternetNotificationOpenStateAction,
  TSetIsNeedToCheckInternetConnectionStatusAction,
  TSetInternetConnectionStatusAction,
  TOpenSupportLinkAction,
} from '../types';

const initialState: TSettingsInfoState = {
  isAnalyticEnabled: false,
  notificationsStatus: {
    breaches: false,
    subsctioption: false,
    actions: false,
  },
  updateInfo: {
    isUpdateNotificationShown: false,
    isUpdateAvailable: false,
    version: '',
  },
  isNeedToCheckInternetStatus: false,
  isInternetConnected: true,
  isNoInternetNotificationOpen: false,
};

type TActionsType =
  | TOpenSupportLinkAction
  | TSetAnalyticStatusAction
  | TSetNotificationStatusAction
  | TSetUpdateInfoAction
  | TPerformUpdateAction
  | TSendTelemetryEventAction
  | TSetInternetConnectionStatusAction
  | TSetIsNeedToCheckInternetConnectionStatusAction
  | TSetNoInternetNotificationOpenStateAction
  | TLogOutSuccessAction;

export const settingsInfoReducer = (state = initialState, action: TActionsType): TSettingsInfoState => {
  switch (action.type) {
    case SET_ANALYTIC_STATUS:
      return {
        ...state,
        isAnalyticEnabled: action.payload.isEnabled,
      };
    case SET_NOTIFICATIONS_STATUS:
    case SET_INTERNET_CONNECTION_STATUS:
    case SET_IS_NEED_TO_CHECK_INTERNET_CONNECTION_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_UPDATE_INFO: {
      return {
        ...state,
        updateInfo: {...state.updateInfo, ...action.payload.updateInfo},
      };
    }
    case SET_NO_INTERNET_NOTIFICATION_OPEN_STATE: {
      return {
        ...state,
        isNoInternetNotificationOpen: action.payload.isOpen,
      };
    }
    case LOGOUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};
