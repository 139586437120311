import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  requestRecoveryCode,
  confirmRecoveryCode,
  finishMigration,
  finishRecovery,
} from '@figleafteam/api-communication';
import {i18n} from '@figleafteam/helpers';
import {
  REQUEST_RECOVERY_CODE,
  SET_CONFIRM_RECOVERY_CODE,
  SET_RECOVERY_STATUS,
  SUBMIT_NEW_MASTER_PASSWORD,
} from '@renderer/js/components/recovery/redux/constants';
import {
  clearRecoveryInfo,
  createChangePasswordError,
  setConfirmRecoveryCode,
  setRecoveryEmail,
  setRecoveryKeyError,
  setRecoveryStatus,
  setResendCodeTime,
} from '@renderer/js/components/recovery/redux/actions';
import {
  TRequestRecoveryCodeAction,
  TSetConfirmRecoveryCodeAction,
  TSetRecoveryStatusAction,
  TSubmitNewMasterPasswordAction,
} from '@renderer/js/components/recovery/redux/types';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {validateChangePassword} from '@renderer/js/components/recovery/utils/validateChangePassword';
import {validateRecoveryKey} from '@renderer/js/components/recovery/utils/validateRecoveryKey';
import {getRecoveryEmail} from '@renderer/js/components/recovery/redux/selectors';
import {setAppLoadingStatus} from '@renderer/js/components/app/redux/actions';
import {getAccountInfo} from '@renderer/js/components/settings/redux/selectors';
import {TAccountInfoState} from '@renderer/js/components/settings/redux/types';
import {getEmail} from '@renderer/js/components/auth/redux/selectors';
import {setRecoveryKeyModalState} from '@renderer/js/components/save-recovery-key/redux/actions';
import {DEFAULT_VALUE_FOR_TIMER} from '@renderer/js/constants';
import {getFromState, history, HISTORY_STATE_FROM} from '@renderer/js/router/history';

function* setRecoveryStatusSaga(action: TSetRecoveryStatusAction) {
  if (action.payload.recoveryStatus) {
    const accountInfo: TAccountInfoState = yield select(getAccountInfo);
    if (accountInfo.login) {
      yield put(setRecoveryEmail(accountInfo.login));
      return;
    }

    const email: string = yield select(getEmail);
    if (email.length) {
      yield put(setRecoveryEmail(email));
      return;
    }
  } else {
    yield put(clearRecoveryInfo());
  }
}

function* requestConfirmRecoveryCodeSaga(action: TRequestRecoveryCodeAction) {
  const errors = validateRecoveryKey(action.payload.email);
  if (errors.emailInvalid) {
    yield put(setRecoveryKeyError(errors));
    return;
  }

  notificationService.clearAllToasts();

  try {
    yield put(setAppLoadingStatus(true));
    // Hardcode 2 minutes till backend will return 'retryAfter' field
    yield put(setResendCodeTime(DEFAULT_VALUE_FOR_TIMER));
    yield call(requestRecoveryCode, action.payload.email, action.payload.key && btoa(action.payload.key));
    history.push('/recovery/confirm');
  } catch (error) {
    notificationService.showDefaultError(i18n.t('Invalid email or recovery key'));
    console.error(error);
  }
  yield put(setAppLoadingStatus(false));
}

export function* handleConfirmRecoveryCodeSaga(action: TSetConfirmRecoveryCodeAction) {
  if (action.payload.code.length !== 6) {
    return null;
  }

  notificationService.clearAllToasts();
  const email: string = yield select(getRecoveryEmail);

  try {
    yield put(setAppLoadingStatus(true));
    yield call(confirmRecoveryCode, email, action.payload.code);
    history.push('/recovery/password');
  } catch (error) {
    console.error(error);
    notificationService.showAuthNotificationError(error.payload.message);
  }

  yield put(setAppLoadingStatus(false));
  yield put(setConfirmRecoveryCode(''));
}

function* submitNewPasswordSaga(action: TSubmitNewMasterPasswordAction) {
  const errors = validateChangePassword(action.payload.newPassword, action.payload.newRePassword);
  if (errors.newPasswordInvalid || errors.newRePasswordInvalid) {
    yield put(createChangePasswordError(errors));
    return;
  }
  const email: string = yield select(getRecoveryEmail);
  const from = getFromState();

  try {
    yield put(setAppLoadingStatus(true));
    if (from === HISTORY_STATE_FROM.MIGRATION) {
      yield call(finishMigration, btoa(action.payload.newPassword));
    } else {
      yield call(finishRecovery, email, btoa(action.payload.newPassword));
      yield put(setRecoveryKeyModalState({isNewRecoveryKey: true}));
    }
    // After success password change confirmation service will send 'ChangeAppInfo' message to electron
    // this will dispatch 'setAppInfo' action and set appLoaderStatus to false
    yield put(setRecoveryStatus(false));
  } catch (error) {
    console.error(error);
    yield put(setAppLoadingStatus(false));
    notificationService.changePasswordError(error?.payload?.message);
  }
}

export function* recoverySagaWatcher() {
  yield takeLatest(SET_RECOVERY_STATUS, setRecoveryStatusSaga);
  yield takeLatest(REQUEST_RECOVERY_CODE, requestConfirmRecoveryCodeSaga);
  yield takeLatest(SET_CONFIRM_RECOVERY_CODE, handleConfirmRecoveryCodeSaga);
  yield takeLatest(SUBMIT_NEW_MASTER_PASSWORD, submitNewPasswordSaga);
}
