import {
  TSecurityKeyState,
  TSetIsMigrationRequiredAction,
  TSetPasswordAction,
  TSetSecurityKeyAction,
} from '@renderer/js/components/enter-security-key/redux/types';
import {
  SET_IS_MIGRATION_REQUIRED,
  SET_PASSWORD,
  SET_SECURITY_KEY_VALUE,
} from '@renderer/js/components/enter-security-key/redux/constants';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';

const initialState: TSecurityKeyState = {
  isMigrationRequired: false,
  key: '',
  password: undefined,
};

type TActionsType = TSetIsMigrationRequiredAction | TSetSecurityKeyAction | TSetPasswordAction | TLogOutSuccessAction;

export const securityKeyReducer = (state = initialState, action: TActionsType): TSecurityKeyState => {
  switch (action.type) {
    case SET_SECURITY_KEY_VALUE:
    case SET_PASSWORD:
    case SET_IS_MIGRATION_REQUIRED:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
