import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getAntitrackingWhitelist} from '@renderer/js/components/private-browsing/redux/selectors';
import {clearAntitrackingWhiteList, updateDomainConfig} from '@renderer/js/components/private-browsing/redux/actions';
import {setConfirmModalState} from '@renderer/js/components/confirm-modal/redux/actions';
import {TrustedWebsitesComponent} from './trusted-websites.component';

const mapStateToProps = (state: AppState) => ({
  whitelist: getAntitrackingWhitelist(state),
});
const mapDispatchToProps = {
  updateDomainConfig,
  clearAntitrackingWhiteList,
  setConfirmModalState,
};

export const TrustedWebsitesContainer = connect(mapStateToProps, mapDispatchToProps)(TrustedWebsitesComponent);
