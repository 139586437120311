import {connect} from 'react-redux';

import {AppState} from '@renderer/js/redux/rootReducer';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {setAuthPopupOpened} from '@renderer/js/components/auth/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {
  setPpiFunnelState,
  setPpiLeaksState,
  getVerifiedEmails,
  deleteEmailFromReport,
} from '@renderer/js/components/ppi-funnel/redux/actions';
import {
  selectGlobalRiskType,
  selectRisksScannerStep,
  selectPpiLeaksState,
} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {PPIRisksScannerComponent} from './ppi-risks-scanner.component';

const mapStateToProps = (state: AppState) => ({
  scannerStep: selectRisksScannerStep(state),
  globalRiskType: selectGlobalRiskType(state),
  appInfo: getAppInfo(state),
  ppiLeaksState: selectPpiLeaksState(state),
});

const mapDispatchToProps = {
  setPpiFunnelState,
  setPpiLeaksState,
  setAuthPopupOpened,
  sendTelemetryEvent,
  getVerifiedEmails,
  deleteEmailFromReport,
};

export const PPIRisksScannerContainer = connect(mapStateToProps, mapDispatchToProps)(PPIRisksScannerComponent);
