export const GET_BROWSERS_REPORT = 'Settings/GET_BROWSERS_REPORT' as const;
export const SET_BROWSERS_REPORT = 'Settings/SET_BROWSERS_REPORT' as const;
export const OPEN_WEB_EXTENSION_STORE = 'Settings/OPEN_WEB_EXTENSION_STORE' as const;
export const GET_USER_PROFILE = 'Settings/GET_USER_PROFILE' as const;
export const UPDATE_USER_PROFILE = 'Settings/UPDATE_USER_PROFILE' as const;
export const CHANGE_ANALYTIC_STATUS = 'Settings/CHANGE_ANALYTIC_STATUS' as const;
export const SET_ANALYTIC_STATUS = 'Settings/SET_ANALYTIC_STATUS' as const;
export const IMPORT_ACCOUNTS = 'Settings/IMPORT_ACCOUNTS' as const;
export const IMPORT_ACCOUNTS_PPI = 'Settings/IMPORT_ACCOUNTS_PPI' as const;
export const GET_NOTIFICATIONS_STATUS = 'Settings/GET_NOTIFICATIONS_STATUS' as const;
export const CHANGE_NOTIFICATIONS_STATUS = 'Settings/CHANGE_NOTIFICATIONS_STATUS' as const;
export const SET_NOTIFICATIONS_STATUS = 'Settings/SET_NOTIFICATIONS_STATUS' as const;
export const GET_USER_LOGIN = 'Settings/GET_USER_LOGIN' as const;
export const SET_USER_LOGIN = 'Settings/SET_USER_LOGIN' as const;
export const CHANGE_PASSWORD = 'Settings/CHANGE_PASSWORD' as const;
export const CHANGE_PASSWORD_MODAL_STATE = 'Settings/CHANGE_PASSWORD_MODAL_STATE' as const;
export const LOCK_USER = 'Settings/LOCK_USER' as const;
export const SET_UPDATE_INFO = 'Settings/SET_UPDATE_INFO' as const;
export const GET_IS_UPDATE_AVAILABLE = 'Settings/GET_IS_UPDATE_AVAILABLE' as const;
export const PERFORM_UPDATE = 'Settings/PERFORM_UPDATE' as const;
export const SET_INTERNET_CONNECTION_STATUS = 'Settings/SET_INTERNET_CONNECTION_STATUS' as const;
export const CHECK_INTERNET_CONNNETION_STATUS = 'Settings/CHECK_INTERNET_CONNNETION_STATUS' as const;
export const SET_IS_NEED_TO_CHECK_INTERNET_CONNECTION_STATUS =
  'Settings/SET_IS_NEED_TO_CHECK_INTERNET_CONNECTION_STATUS' as const;
export const OPEN_SUPPORT_LINK = 'Settings/OPEN_SUPPORT_LINK' as const;
export const SET_NO_INTERNET_NOTIFICATION_OPEN_STATE = 'Settings/SET_NO_INTERNET_NOTIFICATION_OPEN_STATE' as const;

// Key for store notification status in background service
export const NOTIFICATIONS_STATUS = 'Settings/NOTIFICATIONS_STATUS' as const;

export const CHANGE_PROMO_CODE_MODAL = 'Settings/CHANGE_PROMO_CODE_MODAL' as const;
export const APPLY_PROMO_CODE = 'Settings/APPLY_PROMO_CODE' as const;
export const OPEN_BUY_NOW = 'Settings/OPEN_BUY_NOW' as const;
