import React, {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, ConfirmationFormComponent} from '@figleafteam/components';
import {AuthPopupContents} from '@renderer/js/components/auth/redux/constants';
import {EVENTS} from '@common/events';

import '@renderer/js/components/auth/_confirm.scss';

import {AuthPopupConfirmAccountProps} from './auth-popup-confirm-account.types';

export const AuthPopupConfirmAccountComponent = memo<AuthPopupConfirmAccountProps>(props => {
  const {t} = useTranslation();

  const requestConfirmationCode = () => {
    props.requestConfirmAccountCode(props.email);
  };

  useEffect(() => {
    props.sendTelemetryEvent({name: EVENTS.PPI.AUTH_OPEN_CONFIRM_ACCOUNT_MODAL, source: 'app'});
  }, []);

  const goBackToSignup = () => {
    props.setAuthPopupContent(AuthPopupContents.SIGNUP);
  };

  return (
    <div className="confirm confirm--auth-popup">
      <div className="confirm__container">
        <ConfirmationFormComponent
          title={t('Verify email to activate your account')}
          email={props.email}
          code={props.code}
          resendTimer={props.resendTime}
          updateSecondsLeft={props.setResendCodeTime}
          handleResendConfirmationCode={requestConfirmationCode}
          setConfirmationCode={props.setConfirmAccountCode}
        />
        <div className="confirm__bottom-text-wrapper">
          <p className="confirm__bottom-text">{t('Don’t forget to check your spam folder.')}</p>
          <p className="confirm__bottom-text">
            {t('Wrong email?')}{' '}
            <ButtonComponent buttonType="button" onClick={goBackToSignup} text={t('Enter Another')} type="link" />
          </p>
        </div>
      </div>
    </div>
  );
});

AuthPopupConfirmAccountComponent.displayName = 'ConfirmAccountComponent';
