import React from 'react';
import {useTranslation} from 'react-i18next';

import {TimerComponent, SideBarNotificationComponent, Spinner, IconComponent} from '@figleafteam/components';
import {InternetStatusProps} from './internet-status.types';
import './_internet-status.scss';

export const InternetStatusComponent: React.FC<InternetStatusProps> = props => {
  const {t} = useTranslation();

  if (props.isInternetConnected) {
    return null;
  }

  return (
    <SideBarNotificationComponent>
      <div className="internet-connection">
        <div className="internet-connection__image">
          <IconComponent name="attention-round" size={24} />
        </div>
        <h5 className="internet-connection__title">{t('No Internet')}</h5>
        {props.isNeedToCheckInternetConnection ? (
          <>
            <p className="internet-connection__text">{t('Reconnecting...')}</p>
            <Spinner color="white" />
          </>
        ) : (
          <>
            <p className="internet-connection__text">
              {t('Reconnect in')}{' '}
              <TimerComponent seconds={30} secondsOnly onTimeOut={props.checkInternetConnectionStatus} /> {t('sec...')}
            </p>
            <button
              className="internet-connection__button btn-link"
              type="button"
              onClick={props.checkInternetConnectionStatus}
            >
              {t('Try now')}
            </button>
          </>
        )}
      </div>
    </SideBarNotificationComponent>
  );
};
