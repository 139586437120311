import {useTranslation} from 'react-i18next';

import {IconComponent} from '@figleafteam/components';
import React, {FC} from 'react';
import {Props} from './update-available-notification.types';
import './_update-available-notification.scss';

export const UpdateAvailableNotificationComponent: FC<Props> = props => {
  const {t} = useTranslation();

  return (
    <div className="update-available-notification">
      <span className="update-available-notification__icon">
        <IconComponent name="refresh" size={24} />
      </span>
      <h6 className="update-available-notification__title">
        {t('New {{partner}} version is available!', {partner: 'FigLeaf'})}
      </h6>
      <button className="btn update-available-notification__button" type="button" onClick={props.onClick}>
        {t('Update now')}
      </button>
    </div>
  );
};
