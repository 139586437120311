export const ELECTRON_COMMUNICATION_CHANNEL = 'electron-communication-channel';

export enum ERROR_STATUS_CODES {
  UnknownRequest,
  Network,
  UserMissing,
  UserLocked,
  FeatureNotSupported,
  WrongPassword,
  SecretKeyMissing, // deprecated
  BadRequest,
  DeviceNotConfirmed,
}
