import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getUnlockPassword, getIsSavePassword} from '@renderer/js/components/unlock-user/redux/selectors';
import {setUnlockPassword, unlockUser, setSavePasswordStatus} from '@renderer/js/components/unlock-user/redux/actions';
import {logout} from '@renderer/js/components/auth/redux/actions';
import {getAccountInfo} from '@renderer/js/components/settings/redux/selectors';
import {getUserLogin, openSupportLink} from '@renderer/js/components/settings/redux/actions';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {setIsMigrationRequired} from '@renderer/js/components/enter-security-key/redux/actions';
import {UnlockPageComponent} from './unlock-page.component';

const mapStateToProps = (state: AppState) => ({
  password: getUnlockPassword(state),
  savePassword: getIsSavePassword(state),
  accountInfo: getAccountInfo(state),
  appInfo: getAppInfo(state),
});
const mapDispatchToProps = {
  setIsMigrationRequired,
  setUnlockPassword,
  unlockUser,
  setSavePasswordStatus,
  getUserLogin,
  openSupportLink,
  logout,
};

export const UnlockPageContainer = connect(mapStateToProps, mapDispatchToProps)(UnlockPageComponent);
