export const SET_AUTH_DATA = 'Auth/SET_AUTH_DATA' as const;
export const SIGNUP_SUBMIT = 'Auth/SIGNUP_SUBMIT' as const;
export const SIGNUP_ERROR = 'Auth/SIGNUP_ERROR' as const;
export const RESET_SIGNUP_EMAIL_VALIDATION = 'Auth/RESET_SIGNUP_EMAIL_VALIDATION' as const;
export const RESET_SIGNUP_PASSWORD_VALIDATION = 'Auth/RESET_SIGNUP_PASSWORD_VALIDATION' as const;
export const LOGIN_SUBMIT = 'Auth/LOGIN_SUBMIT' as const;
export const LOGIN_ERROR = 'Auth/LOGIN_ERROR' as const;
export const RESET_LOGIN_EMAIL_VALIDATION = 'Auth/RESET_LOGIN_EMAIL_VALIDATION' as const;
export const RESET_LOGIN_PASSWORD_VALIDATION = 'Auth/RESET_LOGIN_PASSWORD_VALIDATION' as const;
export const CLEAR_INFO = 'Auth/CLEAR_INFO' as const;
export const REQUEST_CONFIRM_DEVICE_CODE = 'Auth/REQUEST_CONFIRM_DEVICE_CODE' as const;
export const REQUEST_CONFIRM_ACCOUNT_CODE = 'Auth/REQUEST_CONFIRM_ACCOUNT_CODE' as const;
export const SET_RESEND_CODE_TIME = 'Auth/SET_RESEND_CODE_TIME' as const;
export const SET_CONFIRM_ACCOUNT_CODE = 'Auth/SET_CONFIRM_ACCOUNT_CODE' as const;
export const SET_CONFIRM_DEVICE_CODE = 'Auth/SET_CONFIRM_DEVICE_CODE' as const;
export const SET_AUTH_POPUP_OPENED = 'Auth/SET_AUTH_POPUP_OPENED' as const;
export const SET_AUTH_POPUP_CONTENT = 'Auth/SET_AUTH_POPUP_CONTENT' as const;
export const LOGOUT = 'Auth/LOGOUT' as const;
export const LOGOUT_SUCCESS = 'Auth/LOGOUT_SUCCESS' as const;
export const LOGOUT_ERROR = 'Auth/LOGOUT_ERROR' as const;
export const HANDLE_USER_PROFILE_AND_CHOSE_SECURITY_KEY_STATE =
  'Auth/HANDLE_USER_PROFILE_AND_CHOSE_SECURITY_KEY_STATE' as const;

export enum AuthPopupContents {
  SIGNUP,
  LOGIN,
  CONFIRM_ACCOUNT,
  CONFIRM_DEVICE,
}
