import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {IconComponent} from '@figleafteam/components';

import {PPIRisksReportSectionComponent} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-section/ppi-risks-report-section.component';
import {PPIRisksReportRiskCategoryContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-risk-category/ppi-risks-report-risk-category.container';

import {TReportHackedAccountsProps} from './report-hacked-accounts.types';
import {getRecommendedActionItem} from '../ppi-risks-report-risk-category/ppi-risks-report-risk-category.component';

export const PPIRisksReportHackedAccountsComponent: FC<TReportHackedAccountsProps> = ({
  accountHijacking,
  resolvedRisks,
}) => {
  const {t} = useTranslation();

  const isVaultResolved = resolvedRisks.includes('import-accounts');
  const isMonitorResolved = resolvedRisks.includes('monitoring-email');

  return (
    <>
      {(!isVaultResolved || !isMonitorResolved) && (
        <PPIRisksReportSectionComponent
          title={t('Why am I at risk?')}
          description={t(
            'You have several vulnerable accounts. Hackers who obtain the login credentials for one of your digital accounts may access that account or others. When personal data is exposed, it can be used to access your bank accounts, open credit, or even file taxes in your name.',
          )}
        >
          <PPIRisksReportRiskCategoryContainer
            title={t('Passwords')}
            icon={<IconComponent name="lock" size={24} />}
            items={accountHijacking.items.passwords.data}
          />
          <PPIRisksReportRiskCategoryContainer
            title={t('Accounts')}
            icon={<IconComponent name="globe" size={24} />}
            items={accountHijacking.items.accounts.data}
          />
          <PPIRisksReportRiskCategoryContainer
            title={t('Logins')}
            icon={<IconComponent name="mail" size={24} />}
            items={accountHijacking.items.logins.data}
          />
          <PPIRisksReportRiskCategoryContainer
            title={t('Security questions')}
            icon={<IconComponent name="help" size={24} />}
            items={accountHijacking.items.securityQuestions.data}
          />
        </PPIRisksReportSectionComponent>
      )}
      <PPIRisksReportSectionComponent
        title={t('Recommended protection')}
        description={t('We recommend using following tools to decrease this risk')}
      >
        <PPIRisksReportRiskCategoryContainer
          title={t('Password secure storage')}
          icon={<IconComponent name="lock" size={24} />}
          items={[getRecommendedActionItem(isVaultResolved)]}
        />
        <PPIRisksReportRiskCategoryContainer
          title={t('Breach monitoring')}
          icon={<IconComponent name="dark-web" size={24} />}
          items={[getRecommendedActionItem(isMonitorResolved)]}
        />
      </PPIRisksReportSectionComponent>
    </>
  );
};
