import {
  TAccountInfoState,
  TChangePasswordModalAction,
  TOpenBuyNowAction,
  TSetUserLoginAction,
} from '@renderer/js/components/settings/redux/types';
import {CHANGE_PASSWORD_MODAL_STATE, SET_USER_LOGIN} from '@renderer/js/components/settings/redux/constants';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';

const initialState: TAccountInfoState = {
  login: null,
  changePasswordModal: {
    isOpen: false,
    password: '',
    rePassword: '',
    passwordErrorText: '',
    rePasswordErrorText: '',
  },
};

type TActionsType = TSetUserLoginAction | TChangePasswordModalAction | TLogOutSuccessAction | TOpenBuyNowAction;

export const accountInfoReducer = (
  state: TAccountInfoState = initialState,
  action: TActionsType,
): TAccountInfoState => {
  switch (action.type) {
    case SET_USER_LOGIN:
      return {...state, ...action.payload};
    case CHANGE_PASSWORD_MODAL_STATE:
      return {
        ...state,
        changePasswordModal: {
          ...state.changePasswordModal,
          ...action.payload,
        },
      };
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
