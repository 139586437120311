import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {AuthPopupContents} from '@figleafteam/store';
import {i18n} from '@figleafteam/helpers';
import {ButtonComponent} from '@figleafteam/components';
import {openURL} from '@common/compatibility/openURL';
import DiscoverAccounsIcon from './images/discover-accounts.svg';
import UncoverRecordsIcon from './images/uncover-records.svg';
import IdentifyInfoIcon from './images/identify-info.svg';
import './_ppi-risks-scanner-run-scan-block.scss';
import {PPIRisksScannerRunScanProps} from './ppi-risks-scanner-run-scan-block.types';

const featureItems = [
  {
    icon: <UncoverRecordsIcon />,
    get text() {
      return i18n.t('Uncover records associated with your email address');
    },
  },
  {
    icon: <IdentifyInfoIcon />,
    get text() {
      return i18n.t('Identify what personal info has been compromised');
    },
  },
  {
    icon: <DiscoverAccounsIcon />,
    get text() {
      return i18n.t('Discover your vulnerable and at-risk accounts');
    },
  },
];

export const PPIRisksScannerRunScanBlockComponent: FC<PPIRisksScannerRunScanProps> = ({
  setPpiFunnelState,
  setAuthPopupOpened,
  changeAnalyticStatus,
  setAuthPopupContent,
  isAuthorized,
  isAnalyticEnabled,
}) => {
  const {t} = useTranslation();

  return (
    <section className="run-scan-block">
      <div className="run-scan-block__content">
        <h1 className="run-scan-block__title">{t('Get your free privacy risks report')}</h1>
        <p className="run-scan-block__subtitle">{t('It`s time to check the privacy of your personal information')}</p>
        <ul className="run-scan-block__list">
          {featureItems.map(item => (
            <li className="run-scan-block__item" key={item.text}>
              <div className="run-scan-block__item-icon">{item.icon}</div>
              <span className="run-scan-block__item-text">{item.text}</span>
            </li>
          ))}
        </ul>
        <div className="run-scan-block__cta-wrapper">
          <ButtonComponent
            type="primary-green"
            text={t('Run my free privacy scan')}
            fullWidth
            onClick={() => {
              if (!isAnalyticEnabled) {
                changeAnalyticStatus();
              }
              setPpiFunnelState({scannerStep: 'scanning'});
            }}
          />
          {!isAuthorized && (
            <span className="run-scan-block__already-use">
              {t('Already use {{partner}}?', {partner: 'FigLeaf'})}{' '}
              <ButtonComponent
                text={t('Log In')}
                type="link"
                fontWeight="semi-bold"
                onClick={() => {
                  setAuthPopupOpened(true);
                  setAuthPopupContent(AuthPopupContents.LOGIN);
                }}
              />
            </span>
          )}
        </div>
      </div>
      <span className="run-scan-block__consent">
        {t('By clicking &#39;Run my free privacy scan&#39;, you agree to the Aura FigLeaf')}{' '}
        <a className="run-scan-block__consent-link" onClick={() => openURL('https://figleaf.com/terms-of-service/')}>
          {t('Terms of Service')}
        </a>{' '}
        {t('and')}{' '}
        <a className="run-scan-block__consent-link" onClick={() => openURL('https://aura.com/legal/privacy-policy')}>
          {t('Privacy Policy')}
        </a>
        .
      </span>
    </section>
  );
};
