import React from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, ModalComponent} from '@figleafteam/components';
import IconKey64 from '@renderer/images/icon-key-64.svg';

import {Props} from './forget-key-modal.types';
import './_forget-key.scss';

export const ForgetKeyModalComponent: React.FC<Props> = ({keyType, isOpen, handleClose, openSupportClick}) => {
  const {t} = useTranslation();

  return (
    <ModalComponent width={520} autoFocus closeOnClickOutside showCloseButton isOpen={isOpen} handleClose={handleClose}>
      <div className="forget-key-modal">
        <div className="forget-key-modal__image">
          <IconKey64 />
        </div>
        <h3 className="forget-key-modal__title">{t('Can’t find your {{keyType}} Key?', {keyType})}</h3>
        <h4 className="forget-key-modal__subtitle">{t('Here are possible places you can find it:')}</h4>
        <ul className="forget-key-modal__list">
          <li className="forget-key-modal__list-item">{t('On your computer as a PDF file, if you’ve saved it.')}</li>
          <li className="forget-key-modal__list-item">{t('In FigLeaf settings on other device.')}</li>
        </ul>
        <h4 className="forget-key-modal__subtitle">{t('Still can’t find it?')}</h4>
        <p className="forget-key-modal__text">
          {t(
            '{{partner}} provides the highest standard of data protection, and doesn’t save any of your info on its servers. Therefore, the {{keyType}} Key can’t be restored or provided. Contact support to help reset your account.',
            {keyType, partner: 'FigLeaf'},
          )}
        </p>
        <ButtonComponent
          type="link"
          text={t('Contact support to reset account')}
          buttonType="button"
          onClick={openSupportClick}
        />
      </div>
    </ModalComponent>
  );
};
