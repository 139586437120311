import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {IconComponent} from '@figleafteam/components';
import {MaskedCardWidgetProps} from '@renderer/js/components/vault/components/masked-card-widget/masked-card-widget.types';
import './_masked-card-widget.scss';
import {KEYBOARD_KEYS} from '@renderer/js/constants/keyboard-keys';
import {history} from '@renderer/js/router/history';
import MaskedCard from './img/masked-card-widget.png';
import MaskedCard2x from './img/masked-card-widget@2x.png';

export const MaskedCardWidgetComponent = memo<MaskedCardWidgetProps>(props => {
  const {t} = useTranslation();

  return (
    <section
      tabIndex={0}
      className="masked-card-widget"
      role="button"
      onKeyPress={(ev: React.KeyboardEvent) => {
        if (ev.key === KEYBOARD_KEYS.enter) {
          history.push(`/vault/view-masked-card/${props.maskedCardId}`);
        }
      }}
      onClick={() => {
        history.push(`/vault/view-masked-card/${props.maskedCardId}`);
      }}
    >
      <div className="masked-card-widget__image">
        <img src={MaskedCard} srcSet={`${MaskedCard2x} 2x`} alt="Masked card image" />
      </div>

      <p className="masked-card-widget__text">{t('Pay privately.')}</p>
      <p className="masked-card-widget__button">
        <span>{t('Activate Masked Card')}</span>
        <IconComponent name="arrow-right" size={24} className="masked-card-widget__arrow" />
      </p>
    </section>
  );
});

MaskedCardWidgetComponent.displayName = 'MaskedCardWidgetComponent';
