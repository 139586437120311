import {AnyAction} from '@renderer/js/global-types/AnyAction';
import {TClearNextActionsAction, TSetNextActionsAction} from '@renderer/js/components/unlock-user/redux/types';
import {CLEAR_NEXT_ACTION, SET_NEXT_ACTION} from '@renderer/js/components/unlock-user/redux/constants';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';

const initialState: Array<AnyAction> = [];

type TActionsType = TSetNextActionsAction | TClearNextActionsAction | TLogOutSuccessAction;

export const nextActionsReducer = (state = initialState, action: TActionsType): Array<AnyAction> => {
  switch (action.type) {
    case SET_NEXT_ACTION:
      return [...state, action.payload.nextAction];
    case LOGOUT_SUCCESS:
    case CLEAR_NEXT_ACTION:
      return [];
    default:
      return state;
  }
};
