import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {checkInternetConnectionStatus} from '@renderer/js/components/settings/redux/actions';
import {
  getIsInternetConnected,
  getIsNeedToCheckInternetConnection,
} from '@renderer/js/components/settings/redux/selectors';
import {InternetStatusComponent} from './internet-status.component';

const mapStateToProps = (state: AppState) => ({
  isInternetConnected: getIsInternetConnected(state),
  isNeedToCheckInternetConnection: getIsNeedToCheckInternetConnection(state),
});

const mapDispatchToProps = {
  checkInternetConnectionStatus,
};

export const InternetStatusContainer = connect(mapStateToProps, mapDispatchToProps)(InternetStatusComponent);
