import {AppState} from '@renderer/js/redux/rootReducer';
import {RiskTypes} from './types';
import {STEPS_TO_RESOLVE_ITEMS_LENGTH} from '../components/ppi-decrease-risks-page/ppi-decrease-risks-page.component';

export const selectGlobalRiskType = (state: AppState) => {
  let globalRiskType = state.ppi.funnel.globalRiskType;
  const resolved = state.ppi.funnel.decreaseRisks.resolved;
  const resolvePercent = (100 * resolved.length) / STEPS_TO_RESOLVE_ITEMS_LENGTH;
  if (resolvePercent === 100) {
    globalRiskType = RiskTypes.low;
  } else if (resolvePercent >= 50) {
    globalRiskType = RiskTypes.medium;
  }
  return globalRiskType;
};
export const selectRiskCategories = (state: AppState) => {
  const riskCategories = state.ppi.funnel.riskCategories;
  const resolved = new Set([...state.ppi.funnel.decreaseRisks.resolved]);
  if (resolved.has('vpn')) {
    riskCategories.homeTitleTheft.riskType = RiskTypes.low;
  }
  if (resolved.has('monitoring-email') && resolved.has('add-extensions')) {
    riskCategories.identityExposure.riskType = RiskTypes.low;
  }
  if (resolved.has('monitoring-email') && resolved.has('import-accounts')) {
    riskCategories.accountHijacking.riskType = RiskTypes.low;
  }
  return riskCategories;
};
export const selectIdentityExposure = (state: AppState) => state.ppi.funnel.riskCategories.identityExposure;
export const selectHomeTitleTheft = (state: AppState) => state.ppi.funnel.riskCategories.homeTitleTheft;
export const selectAccountHijacking = (state: AppState) => state.ppi.funnel.riskCategories.accountHijacking;
export const selectIsLoading = (state: AppState) => state.ppi.funnel.isLoading;
export const selectIsDataFetched = (state: AppState) => state.ppi.funnel.isDataFetched;
export const selectRisksScannerStep = (state: AppState) => state.ppi.funnel.scannerStep;
export const selectIsPpiFunnelShown = (state: AppState) => state.ppi.funnel.isPpiFunnelShown;
export const selectExposedItemsCount = (state: AppState) => state.ppi.funnel.exposedItemsCount;
export const selectExposedCategoriesCount = (state: AppState) => {
  const riskCategories = selectRiskCategories(state);
  return Object.values(riskCategories).filter(item => item.riskType !== RiskTypes.low).length;
};
export const selectIsAllRisksResolved = (state: AppState) => state.ppi.funnel.isAllRisksResolved;
export const selectisRisksScannerOpened = (state: AppState) => state.ppi.funnel.isRisksScannerOpened;
export const selectResolvedRisks = (state: AppState) => state.ppi.funnel.decreaseRisks.resolved;
export const selectHiddenRisks = (state: AppState) => state.ppi.funnel.decreaseRisks.hidden;
export const selectIsInitialScanFinished = (state: AppState) => state.ppi.funnel.isInitialScanFinished;
export const selectPpiLeaksState = (state: AppState) => state.ppi.leaks;
export const selectRawLeaks = (state: AppState) => state.ppi.leaks.rawLeaksReport;
export const selectPpiProfilesToVerify = (state: AppState) => state.ppi.leaks.profilesToVerify;
export const selectIsAddEmailModalOpen = (state: AppState) => state.ppi.leaks.isAddEmailModalOpen;
export const selectCurrentEmailToVerify = (state: AppState) => state.ppi.leaks.currentEmailToVerify;
