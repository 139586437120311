import {i18n} from '@figleafteam/helpers';

import {
  TConfirmModalState,
  TResetConfirmModalStateAction,
  TSetConfirmModalStateAction,
} from '@renderer/js/components/confirm-modal/redux/types';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';
import {
  RESET_CONFIRM_MODAL_STATE,
  SET_CONFIRM_MODAL_STATE,
} from '@renderer/js/components/confirm-modal/redux/constants';

const initialState: TConfirmModalState = {
  get title() {
    return i18n.t('Please confirm your action');
  },
  text: '',
  isLoadingNeeded: false,
  get loadingText() {
    return i18n.t('Loading...');
  },
  get actionButtonText() {
    return i18n.t('Ok');
  },
  isOpen: false,
  showCloseButton: true,
  width: 500,
  actionCallback: () => {
    return;
  },
};

type TActions = TSetConfirmModalStateAction | TResetConfirmModalStateAction | TLogOutSuccessAction;

export const confirmModalStateReducer = (state = initialState, action: TActions): TConfirmModalState => {
  switch (action.type) {
    case SET_CONFIRM_MODAL_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT_SUCCESS:
    case RESET_CONFIRM_MODAL_STATE:
      return initialState;
    default:
      return state;
  }
};
