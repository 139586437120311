import {TConfirmModalState} from '@renderer/js/components/confirm-modal/redux/types';
import {
  RESET_CONFIRM_MODAL_STATE,
  SET_CONFIRM_MODAL_STATE,
} from '@renderer/js/components/confirm-modal/redux/constants';

export const setConfirmModalState = (payload: Partial<TConfirmModalState>) => ({
  type: SET_CONFIRM_MODAL_STATE,
  payload,
});

export const resetConfirmModalState = () => ({
  type: RESET_CONFIRM_MODAL_STATE,
  payload: {},
});
