import {TChangePromoCodeModalAction, TPromoCodeModalState} from '@renderer/js/components/settings/redux/types';
import {CHANGE_PROMO_CODE_MODAL} from '@renderer/js/components/settings/redux/constants';

const initialState: TPromoCodeModalState = {
  isOpen: false,
  code: '',
  isLoading: false,
};

type TActionsType = TChangePromoCodeModalAction;

export const promoCodeModalReducer = (
  state: TPromoCodeModalState = initialState,
  action: TActionsType,
): TPromoCodeModalState => {
  switch (action.type) {
    case CHANGE_PROMO_CODE_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
