import React, {FC} from 'react';

import {PPIRisksReportRiskIconComponent} from '@renderer/js/components/ppi-funnel/components/ppi-risks-report-risk-icon/ppi-risks-report-risk-icon.component';
import {TPPIRisksReportTabProps} from './ppi-risks-report-tab.types';
import './_ppi-risks-report-tab.scss';

export const PPIRisksReportTabComponent: FC<TPPIRisksReportTabProps> = ({risk, icon, title}) => {
  return (
    <div className={`risks-report-tab risks-report-tab--risk-${risk}`}>
      <div className="risks-report-tab__icon">
        <PPIRisksReportRiskIconComponent icon={icon} risk={risk} />
      </div>
      <div className="risks-report-tab__content">
        <div className="risks-report-tab__title">{title}</div>
        <div className="risks-report-tab__risk">{risk} risk</div>
      </div>
    </div>
  );
};
