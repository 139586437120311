import React, {FC} from 'react';
import {TPPIRisksReportSectionProps} from './ppi-risks-report-section.types';

import './_ppi-risks-report-section.scss';

export const PPIRisksReportSectionComponent: FC<TPPIRisksReportSectionProps> = ({title, description, children}) => {
  return (
    <>
      <section className="risks-report-section">
        <header className="risks-report-section__header">
          <h3 className="risks-report-section__title">{title}</h3>
          <div className="risks-report-section__description">{description}</div>
        </header>
        <article className="risks-report-section__main">{children}</article>
      </section>
    </>
  );
};
