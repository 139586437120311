import {TParsedDataKeys, TParsedDataState} from '@renderer/js/components/ppi-funnel/redux/types';

const unknownSites = new Set(['n/a', 'na', 'n/a/', 'unknown']);

export const getParsedData = (leaks: TQuickScanResponsePayload['leaks'], emails: string[]) => {
  const result: TParsedDataState = {
    names: [],
    gender: [],
    language: [],
    accounts: [],
    phones: [],
    socialProfiles: [],
    logins: [],
    passwords: [],
    ipAddresses: [],
    locations: [],
    ids: [],
    securityQuestions: [],
    generalInfo: [],
  };

  if (emails.length) {
    emails.forEach(email => {
      leaks[email]?.forEach(leakItem => {
        leakItem.assets.forEach(asset => {
          if (asset.email || asset.username) {
            if (leakItem.site && !unknownSites.has(leakItem.site)) {
              result.accounts.push({value: leakItem.site});
            }
          }

          // social
          asset.social_facebook?.forEach(value => result.socialProfiles.push({value, socialType: 'facebook'}));
          asset.social_google?.forEach(value => result.socialProfiles.push({value, socialType: 'google'}));
          asset.social_youtube?.forEach(value => result.socialProfiles.push({value, socialType: 'youtube'}));
          asset.social_instagram?.forEach(value => result.socialProfiles.push({value, socialType: 'instagram'}));
          asset.social_yahoo?.forEach(value => result.socialProfiles.push({value, socialType: 'yahoo'}));
          asset.social_skype?.forEach(value => result.socialProfiles.push({value, socialType: 'skype'}));
          asset.social_reddit?.forEach(value => result.socialProfiles.push({value, socialType: 'reddit'}));
          asset.social_pinterest?.forEach(value => result.socialProfiles.push({value, socialType: 'pinterest'}));
          asset.social_twitter?.forEach(value => result.socialProfiles.push({value, socialType: 'twitter'}));
          asset.social_linkedin?.forEach(value => result.socialProfiles.push({value, socialType: 'linkedin'}));
          asset.social_stackoverflow?.forEach(value =>
            result.socialProfiles.push({value, socialType: 'stackoverflow'}),
          );
          // end social

          asset.ip_addresses?.forEach(value => result.ipAddresses.push({value}));

          const dataForPush = {
            logins: [{value: asset.email}, {value: asset.username}],
            phones: [{value: asset.phone, hidden: true}],
            securityQuestions: [{value: asset.account_secret_question, hidden: true}],
            passwords: [{value: asset.password_plaintext, hidden: true}],
            names: [{value: asset.full_name}],

            // locations
            locations: [{value: asset.address_1}, {value: asset.address_2}],

            // ids
            ids: [
              {value: asset.social_security_number, hidden: true, type: 'SSN'},
              {value: asset.national_id, hidden: true, type: 'Passport'},
              {value: asset.taxid, hidden: true, type: 'Tax ID'},
            ],
          };

          Object.entries(dataForPush).forEach(([key, values]) => {
            values.forEach((val: {value?: string}) => {
              if (val && val.value) {
                result[key as TParsedDataKeys].push(val as {value: string; hidden: boolean});
              }
            });
          });
        });
      });
    });
  }

  Object.keys(result).forEach(key => {
    result[key as TParsedDataKeys] = Array.from(
      new Set(result[key as TParsedDataKeys].map(item => JSON.stringify(item))),
    )
      .map(item => JSON.parse(item))
      .sort(
        (a, b) => b.value.length - a.value.length, // to have consistent first items
      );
  });

  result.names.length && result.generalInfo.push(result.names[0]);
  result.gender.length && result.generalInfo.push(result.gender[0]);
  result.language.length && result.generalInfo.push(result.language[0]);

  return result;
};
