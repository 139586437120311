import {takeLatest, call, put, select} from 'redux-saga/effects';
import {unlockUser} from '@figleafteam/api-communication';
import {UNLOCK_USER} from '@renderer/js/components/unlock-user/redux/constants';
import {
  getNextActions,
  getUnlockPassword,
  getIsSavePassword,
} from '@renderer/js/components/unlock-user/redux/selectors';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {
  clearNextActions,
  resetModal,
  setUnlockPassword,
  setModalVisible,
  setNextActions,
  resetLockedState,
} from '@renderer/js/components/unlock-user/redux/actions';
import {AnyAction} from '@renderer/js/global-types/AnyAction';
import {setAppLoadingStatus} from '@renderer/js/components/app/redux/actions';
import {ERROR_STATUS_CODES} from '@common/communication/constants';

import {history} from '@renderer/js/router/history';

export function* unlockCheckAndHandle(err: any, action: AnyAction) {
  if (err && err.status === 1 && err.payload && err.payload.statusCode === ERROR_STATUS_CODES.UserLocked) {
    yield put(setNextActions({nextAction: action}));
    yield put(setModalVisible(true));
  }
}

export function* unlockUserSaga() {
  const password: string = yield select(getUnlockPassword);
  const savePassword: boolean = yield select(getIsSavePassword);
  try {
    yield put(setAppLoadingStatus(true));
    yield call(unlockUser, btoa(password), savePassword);
    // After success unlock service will send 'ChangeAppInfo' message to electron
    // this will dispatch 'setAppInfo' action and set appLoaderStatus to false
    yield put(resetModal());
    yield put(resetLockedState());
    const nextActionsList: Array<AnyAction> = yield select(getNextActions);
    for (const action of nextActionsList) {
      yield put(action);
    }
    yield put(clearNextActions());

    history.push('/');
  } catch (e) {
    console.error(e);
    yield put(setAppLoadingStatus(false));
    yield put(setUnlockPassword(''));
    notificationService.unlockUnSuccess(e?.payload?.message);
  }
}

export function* unlockUserWatcher() {
  yield takeLatest(UNLOCK_USER, unlockUserSaga);
}
