import {
  TUnlockState,
  TSetUnlockPasswordAction,
  TSetSavePasswordStatusAction,
  TResetLockedStateAction,
} from '@renderer/js/components/unlock-user/redux/types';
import {
  SET_PASSWORD,
  SET_SAVE_PASSWORD_STATUS,
  RESET_LOCKED_STATE,
} from '@renderer/js/components/unlock-user/redux/constants';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';

const initialState: TUnlockState = {
  password: '',
  savePassword: false,
};

type TActionsType =
  | TSetUnlockPasswordAction
  | TSetSavePasswordStatusAction
  | TResetLockedStateAction
  | TLogOutSuccessAction;

export const unlockReducer = (state: TUnlockState = initialState, action: TActionsType): TUnlockState => {
  switch (action.type) {
    case SET_PASSWORD:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SAVE_PASSWORD_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_LOCKED_STATE:
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
