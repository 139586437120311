import {i18n} from '@figleafteam/helpers';

import {TChangePasswordErrors} from '@renderer/js/components/recovery/redux/types';
import {isWeakPassword} from '@renderer/js/components/auth/utils/isWeakPassword';

export const initialErrors: TChangePasswordErrors = {
  // fields has error
  newPasswordInvalid: false,
  newRePasswordInvalid: false,
  // fields error texts
  newPasswordErrorText: '',
  newRePasswordErrorText: '',
};

export function validateChangePassword(newPassword: string, newRePassword: string) {
  const errors = {...initialErrors};

  if (isWeakPassword(newPassword)) {
    errors.newPasswordInvalid = true;
    errors.newRePasswordInvalid = true;
    errors.newPasswordErrorText = i18n.t("Your password isn't strong enough");
    errors.newRePasswordErrorText = i18n.t("Your password isn't strong enough");
  }

  if (newPassword.length === 0) {
    errors.newPasswordInvalid = true;
    errors.newPasswordErrorText = i18n.t('Please fill new password field');
  }

  if (newRePassword.length === 0) {
    errors.newRePasswordInvalid = true;
    errors.newRePasswordErrorText = i18n.t('Please fill confirm password field');
  }

  if (newPassword !== newRePassword) {
    errors.newRePasswordInvalid = true;
    errors.newRePasswordErrorText = i18n.t("Passwords don't match");
  }

  return errors;
}
