import {toast, ToastOptions} from 'react-toastify';
import {i18n} from '@figleafteam/helpers';
import {ReactNode} from 'react';
import {getNewBreachesComponent} from './components/new-breaches-notification';
import {getUpdatevailableComponent} from './components/update-available-notification';
import {getDefaultErrorComponent} from './components/default-error-notification';
import {getCloseButtonComponent} from './components/close-button';
import {getInvalidCredentialsComponent} from './components/invalid-credentilas-notification';

class NotificationService {
  private cleanUpFunctions = new Map<string, () => void>();

  private showErrorNotification(message: string | ReactNode, options: ToastOptions = {}): void {
    const customId = Math.random().toString();

    const closeButton = getCloseButtonComponent(() => {
      toast.dismiss(customId);
    });

    toast(message, {
      toastId: customId,
      type: 'error',
      hideProgressBar: true,
      draggable: true,
      className: 'fl-toastify fl-toastify--error',
      draggablePercent: 60,
      position: 'top-center',
      autoClose: 3000,
      delay: 300,
      closeButton,
      ...options,
    });
  }

  // TODO make this private after fix types in @figleafteam/store
  // This method shouldn't call directrly
  showSuccessNotification(message: string | ReactNode, options: ToastOptions = {}): void {
    const customId = Math.random().toString();

    const closeButton = getCloseButtonComponent(() => {
      toast.dismiss(customId);
    });

    toast(message, {
      toastId: customId,
      type: 'success',
      hideProgressBar: true,
      draggable: true,
      className: 'fl-toastify fl-toastify--success',
      draggablePercent: 60,
      position: 'top-center',
      autoClose: 3000,
      delay: 300,
      closeButton,
      ...options,
    });
  }

  addCleanUpFunction(key: string, func: () => void): void {
    this.cleanUpFunctions.set(key, func);
  }

  clearAllToasts(): void {
    this.cleanUpFunctions.forEach(func => func());
    this.cleanUpFunctions.clear();
    toast.dismiss();
  }

  showDefaultError(message: string | ReactNode = i18n.t('Oops, something went wrong'), options: ToastOptions = {}) {
    const errorNotification = getDefaultErrorComponent(message);
    this.showErrorNotification(errorNotification, options);
  }

  // custom notifications
  sendSecretKeyError(message: string = i18n.t('Check secret key error')) {
    const errorNotification = getDefaultErrorComponent(message);
    this.showErrorNotification(errorNotification);
  }

  sendPasswordHintError(message: string = i18n.t('Send password to email error')) {
    const errorNotification = getDefaultErrorComponent(message);
    this.showErrorNotification(errorNotification);
  }

  showAuthNotificationError(message: string = i18n.t('Authorization error. Please try again later.')) {
    const errorNotification = getDefaultErrorComponent(message);
    this.showErrorNotification(errorNotification);
  }

  showInvalidCredentialsError(onResetPasswordClick: () => void, onContactSupportClick: () => void) {
    const invalidCredentialsError = getInvalidCredentialsComponent(onResetPasswordClick, onContactSupportClick);
    this.showDefaultError(invalidCredentialsError, {autoClose: false, closeOnClick: false});
  }

  unlockUnSuccess(message = i18n.t('Unlock error. Try again.')) {
    const errorNotification = getDefaultErrorComponent(message);
    this.showErrorNotification(errorNotification);
  }

  changePasswordError(message = i18n.t('Change password unsuccessful, please try later')) {
    const errorNotification = getDefaultErrorComponent(message);
    this.showErrorNotification(errorNotification, {autoClose: false});
  }

  showDefaultErrorWithoutAutoClose(message = i18n.t('Oops, something went wrong')) {
    const errorNotification = getDefaultErrorComponent(message);
    this.showErrorNotification(errorNotification, {autoClose: false});
  }

  showNoInternetError(message = i18n.t('No internet connection'), options: ToastOptions = {}) {
    const errorNotification = getDefaultErrorComponent(message);
    this.showErrorNotification(errorNotification, {autoClose: false, ...options});
  }

  showNewBreachesNotification(breachesCount: number) {
    const NewBreachesComponent = getNewBreachesComponent(breachesCount);
    if (breachesCount) {
      this.showErrorNotification(NewBreachesComponent, {
        className: 'fl-toastify fl-toastify--error',
        hideProgressBar: false,
        autoClose: 5000,
      });
    } else {
      this.showSuccessNotification(NewBreachesComponent, {
        className: 'fl-toastify fl-toastify--success',
        hideProgressBar: false,
        autoClose: 5000,
      });
    }
  }

  showUpdateAvailableNotification(onClick: () => void) {
    const UpdateAvailableComponent = getUpdatevailableComponent(onClick);
    this.showSuccessNotification(UpdateAvailableComponent, {
      className: 'fl-toastify fl-toastify--success',
      hideProgressBar: true,
      autoClose: false,
      closeOnClick: true,
    });
  }

  deleteBreachInfoError(message = i18n.t('Deleting breach unknown error. Please try again later.')) {
    this.showErrorNotification(message);
  }

  applyPromoCodeError(message = i18n.t('Apply promo code error')) {
    this.showErrorNotification(message);
  }
}

export const notificationService = new NotificationService();
