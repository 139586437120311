import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getUpdateInfo} from '@renderer/js/components/settings/redux/selectors';
import {getIsUpdateAvailable, performUpdate, setUpdateInfo} from '@renderer/js/components/settings/redux/actions';
import {setRecoveryKeyModalState} from '@renderer/js/components/save-recovery-key/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {MainComponent} from '@renderer/js/main.component';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {selectisRisksScannerOpened} from './components/ppi-funnel/redux/selectors';

const mapStateToProps = (state: AppState) => ({
  updateInfo: getUpdateInfo(state),
  appInfo: getAppInfo(state),
  isRisksScannerOpened: selectisRisksScannerOpened(state),
});

const mapDispatchToProps = {
  setRecoveryKeyModalState,
  getIsUpdateAvailable,
  performUpdate,
  sendTelemetryEvent,
  setUpdateInfo,
};

export const MainContainer = connect(mapStateToProps, mapDispatchToProps)(MainComponent);
