import {connect} from 'react-redux';

import {AppState} from '@renderer/js/redux/rootReducer';
import {setAuthPopupOpened} from '@renderer/js/components/auth/redux/actions';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {selectPpiProfilesToVerify} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {setPpiLeaksState} from '@renderer/js/components/ppi-funnel/redux/actions';
import {PPIRisksReportRiskCategoryComponent} from './ppi-risks-report-risk-category.component';

const mapStateToProps = (state: AppState) => ({
  appInfo: getAppInfo(state),
  profilesToVerify: selectPpiProfilesToVerify(state),
});

const mapDispatchToProps = {
  setPpiLeaksState,
  setAuthPopupOpened,
};

export const PPIRisksReportRiskCategoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PPIRisksReportRiskCategoryComponent);
