import {
  TSetRecoveryKeyModalStateAction,
  TSaveRecoveryKeyModalState,
} from '@renderer/js/components/save-recovery-key/redux/types';
import {SET_RECOVERY_KEY_MODAL_STATE} from '@renderer/js/components/save-recovery-key/redux/constants';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';

const initialState: TSaveRecoveryKeyModalState = {
  isOpen: false,
  key: '',
  isNewRecoveryKey: false,
  isPPI: false,
};

type TActionsType = TSetRecoveryKeyModalStateAction | TLogOutSuccessAction;

export const saveRecoveryKeyModalReducer = (
  state: TSaveRecoveryKeyModalState = initialState,
  action: TActionsType,
): TSaveRecoveryKeyModalState => {
  switch (action.type) {
    case SET_RECOVERY_KEY_MODAL_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
