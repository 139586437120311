import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {AuthOnboardingComponent} from '@renderer/js/components/auth-onboarding/auth-onboarding.component';
import {getAuthOnboardingState} from '@renderer/js/components/auth-onboarding/redux/selectors';
import {changeOnboardingItemOpenState} from '@renderer/js/components/auth-onboarding/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {
  selectExposedCategoriesCount,
  selectExposedItemsCount,
  selectGlobalRiskType,
} from '@renderer/js/components/ppi-funnel/redux/selectors';

const mapStateToProps = (state: AppState) => ({
  authOnboardingState: getAuthOnboardingState(state),
  appInfo: getAppInfo(state),
  globalRiskType: selectGlobalRiskType(state),
  exposedItemsCount: selectExposedItemsCount(state),
  exposedCategoriesCount: selectExposedCategoriesCount(state),
});
const mapDispatchToProps = {
  changeOnboardingItemOpenState,
  sendTelemetryEvent,
};

export const AuthOnboardingContainer = connect(mapStateToProps, mapDispatchToProps)(AuthOnboardingComponent);
