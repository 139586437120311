import {call, put, takeLatest} from 'redux-saga/effects';
import {getRecoveryKey, setSettingValue} from '@figleafteam/api-communication';
import {
  GET_RECOVERY_KEY,
  SAVE_RECOVERY_KEY,
  SET_RECOVERY_KEY_SAVED_FLAG,
} from '@renderer/js/components/save-recovery-key/redux/constants';
import {saveRecoveryKey} from '@common/compatibility/saveRecoveryKey';
import {setRecoveryKeyModalState} from '@renderer/js/components/save-recovery-key/redux/actions';
import {TGetRecoveryKeyAction, TSaveRecoveryKeyAction} from '@renderer/js/components/save-recovery-key/redux/types';
import {unlockCheckAndHandle} from '@renderer/js/components/unlock-user/redux/sagas';
import {getAppInfoAction} from '@renderer/js/components/app/redux/actions';

export function* getRecoveryKeySaga(action: TGetRecoveryKeyAction) {
  try {
    const response: {recoveryKey: string} = yield call(getRecoveryKey);
    yield put(
      setRecoveryKeyModalState({
        key: atob(response.recoveryKey),
      }),
    );
  } catch (e) {
    console.error(e);
    yield unlockCheckAndHandle(e, action);
  }
}

export function* saveRecoveryKeySaga(action: TSaveRecoveryKeyAction) {
  try {
    const success: boolean = yield call(saveRecoveryKey);
    if (success) {
      yield put(setRecoveryKeyModalState({isOpen: false}));
    }
  } catch (e) {
    console.error(e);
    yield unlockCheckAndHandle(e, action);
  }
}

export function* setRecoveryKeySavedFlagSaga() {
  try {
    yield call(setSettingValue, 'isRecoveryKeySaved', 'true');
    yield put(getAppInfoAction()); // to update isRecoveryKeySaved value in appInfo
  } catch (err) {
    console.error(err);
  }
}

export function* saveRecoveryKeyModalWatcher() {
  yield takeLatest(GET_RECOVERY_KEY, getRecoveryKeySaga);
  yield takeLatest(SAVE_RECOVERY_KEY, saveRecoveryKeySaga);
  yield takeLatest(SET_RECOVERY_KEY_SAVED_FLAG, setRecoveryKeySavedFlagSaga);
}
