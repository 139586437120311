export abstract class ConnectorBase implements Connector {
  messageHandler?: (message: string) => void;
  stateChangeHandler?: (newState: ConnectorState) => void;

  get state(): ConnectorState {
    return this._state;
  }

  set state(newState: ConnectorState) {
    if (this._state != newState) {
      this._state = newState;
      this.stateChangeHandler?.(newState);

      if (newState == 'connected') {
        this.sendPendingMessages();
      }
    }
  }

  private _state: ConnectorState = 'disconnected';
  private _pendingMessages: Array<string> = [];

  reconnect() {
    this.disconnect();
    this.connect();
  }

  abstract connect(): void;
  abstract disconnect(): void;

  sendMessageReliably(message: string) {
    if (this.state == 'connected') {
      this.sendMessage(message);
    } else {
      this._pendingMessages.push(message);
    }
  }

  abstract sendMessage(message: string): void;

  private sendPendingMessages(): void {
    for (const message of this._pendingMessages) {
      this.sendMessage(message);
    }
    this._pendingMessages = [];
  }
}
