import React, {memo, useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {useTranslation, Trans} from 'react-i18next';

import {ModalImportAccountsComponent, IconComponent} from '@figleafteam/components';
import {i18n} from '@figleafteam/helpers';
import {MonitorAddEmailContainer} from '@figleafteam/monitor-electron';
import {history} from '@renderer/js/router/history';
import {TRiskToDecrease} from '@renderer/js/components/ppi-funnel/redux/types';
import {EVENTS} from '@common/events';

import {DecreaseRiskItemComponent} from '@renderer/js/components/ppi-funnel/components/ppi-decrease-risks-item/decrease-risk-item.component';
import {PrivacyIndicatorComponent} from '@renderer/js/components/ppi-funnel/components/ppi-privacy-indicator/privacy-indicator.component';
import {PPIDecreaseRisksCompletedComponent} from '@renderer/js/components/ppi-funnel/components/ppi-decrease-risks-completed/ppi-decrease-risks-completed.component';

import {TPPIDecreaseRisksPageProps} from './ppi-decrease-risks-page.types';
import {PPIFinalModalContainer} from '../ppi-final-modal/ppi-final-modal.container';

import './_ppi-decrease-risks-page.scss';

const STEPS: Array<{
  title: string;
  items: Array<{
    id: TRiskToDecrease;
    title: string;
    resolveText: string;
    resolveDuration: string;
    skipText: string;
    clickResolveEvent: string;
    resolveEvent: string;
    skipEvent: string;
    items: Array<{
      icon: React.ReactElement;
      color: string;
      text: string;
    }>;
  }>;
}> = [
  {
    get title() {
      return i18n.t('Decrease risk of accounts takeovers');
    },
    items: [
      {
        id: 'import-accounts',
        get title() {
          return i18n.t('Safeguard passwords and cards with Vault');
        },
        get resolveText() {
          return i18n.t('Import passwords');
        },
        get resolveDuration() {
          return i18n.t('30 sec');
        },
        get skipText() {
          return i18n.t('I already use a password manager');
        },
        resolveEvent: EVENTS.PPI.DECREASE_RISK_PAGE_VAULT_RESOLVE,
        clickResolveEvent: EVENTS.PPI.DECREASE_RISK_PAGE_VAULT_CLICK_RESOLVE,
        skipEvent: EVENTS.PPI.DECREASE_RISK_PAGE_VAULT_SKIP,
        items: [
          {
            icon: <IconComponent name="user-round" size={24} />,
            color: '#9FD982',
            get text() {
              return i18n.t('Save and auto-fill unlimited logins and passwords');
            },
          },
          {
            icon: <IconComponent name="masked-email" size={24} />,
            color: '#C3D350',
            get text() {
              return i18n.t('Hide your personal email using email masking');
            },
          },
        ],
      },
      {
        id: 'monitoring-email',
        get title() {
          return i18n.t('Monitor unlimited emails for breaches');
        },
        get resolveText() {
          return i18n.t('Add email to Monitor');
        },
        get resolveDuration() {
          return i18n.t('2 min');
        },
        get skipText() {
          return i18n.t('I already monitor my email');
        },
        resolveEvent: EVENTS.PPI.DECREASE_RISK_PAGE_MONITOR_RESOLVE,
        clickResolveEvent: EVENTS.PPI.DECREASE_RISK_PAGE_MONITOR_CLICK_RESOLVE,
        skipEvent: EVENTS.PPI.DECREASE_RISK_PAGE_MONITOR_SKIP,
        items: [
          {
            icon: <IconComponent name="list" size={24} />,
            color: '#B57AC7',
            get text() {
              return i18n.t('See leaked passwords, phones, IDs, etc.');
            },
          },
          {
            icon: <IconComponent name="bell" size={24} />,
            color: '#D971AF',
            get text() {
              return i18n.t('Get real-time alerts for new data leaks');
            },
          },
        ],
      },
    ],
  },
  {
    get title() {
      return i18n.t('Shield yourself from annoying spam and ads');
    },
    items: [
      {
        id: 'add-extensions',
        get title() {
          return i18n.t('Eliminate advertising trackers in browsers');
        },
        get resolveText() {
          return i18n.t('Add FigLeaf to browser');
        },
        get resolveDuration() {
          return i18n.t('2 min');
        },
        get skipText() {
          return i18n.t('I have another solution installed');
        },
        resolveEvent: EVENTS.PPI.DECREASE_RISK_PAGE_TRACKERS_RESOLVE,
        clickResolveEvent: EVENTS.PPI.DECREASE_RISK_PAGE_TRACKERS_CLICK_RESOLVE,
        skipEvent: EVENTS.PPI.DECREASE_RISK_PAGE_TRACKERS_SKIP,
        items: [
          {
            icon: <IconComponent name="credit-monitoring" size={24} />,
            color: '#2099FB',
            get text() {
              return i18n.t('Hide your personal email using email masking');
            },
          },
          {
            icon: <IconComponent name="eye-closed" size={24} />,
            color: '#64D0FD',
            get text() {
              return i18n.t('Make it hard for sites to spy on you');
            },
          },
        ],
      },
    ],
  },
  {
    get title() {
      return i18n.t('Keep your online activity private on any network');
    },
    items: [
      {
        id: 'vpn',
        get title() {
          return i18n.t('Hide location and encrypt connection with VPN');
        },
        get resolveText() {
          return i18n.t('Enable VPN');
        },
        get resolveDuration() {
          return i18n.t('1 min');
        },
        get skipText() {
          return i18n.t('I use another VPN solution');
        },
        resolveEvent: EVENTS.PPI.DECREASE_RISK_PAGE_VPN_RESOLVE,
        clickResolveEvent: EVENTS.PPI.DECREASE_RISK_PAGE_VPN_CLICK_RESOLVE,
        skipEvent: EVENTS.PPI.DECREASE_RISK_PAGE_VPN_SKIP,
        items: [
          {
            icon: <IconComponent name="power" size={24} />,
            color: '#7373E5',
            get text() {
              return i18n.t('Use the fastest VPN solution in the world');
            },
          },
          {
            icon: <IconComponent name="guard" size={24} />,
            color: '#5CDBDB',
            get text() {
              return i18n.t('Feel secure with encrypted traffic and no-logs policy');
            },
          },
        ],
      },
    ],
  },
];
export const STEPS_TO_RESOLVE_ITEMS_LENGTH = STEPS.reduce((target, step) => {
  return target + step.items.length;
}, 0);

export const PPIDecreaseRisksPageComponent = memo<TPPIDecreaseRisksPageProps>(
  ({
    resolved,
    hidden,
    setResolvedRisk,
    toggleImportModalOpen,
    setPpiFunnelState,
    isImportModalOpen,
    importAccountsPPI,
    browsersReport,
    getBrowsersReport,
    sendTelemetryEvent,
    hideRiskItem,
    monitorHasConfirmedEmail,
    globalRiskType,
    setDetailsState,
    setIsVpnModalOpened,
    setPpiFlowFinished,
  }) => {
    const [isFinalModalOpened, setFinalModalOpened] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
      sendTelemetryEvent({name: EVENTS.PPI.DECREASE_RISK_PAGE_OPEN, source: 'app'});
      if (!browsersReport) {
        getBrowsersReport();
      }
    }, []);

    useEffect(() => {
      if (resolved.length !== STEPS_TO_RESOLVE_ITEMS_LENGTH) {
        return;
      }

      setTimeout(() => {
        setPpiFunnelState({isAllRisksResolved: true});
        setPpiFlowFinished();
        setFinalModalOpened(true);
      }, 1000);
    }, [resolved]);

    const onItemClick = useCallback((id: TRiskToDecrease, clickEvent: string) => {
      sendTelemetryEvent({name: clickEvent, source: 'user'});

      switch (id) {
        case 'import-accounts':
          toggleImportModalOpen(true);
          break;

        case 'monitoring-email':
          history.push('ppi/email');
          break;

        case 'add-extensions':
          setDetailsState({isExtensionsModalOpened: true});
          break;

        case 'vpn':
          setIsVpnModalOpened(true);
          break;

        default:
          console.error('Unsupported id');
          break;
      }
    }, []);

    useEffect(() => {
      const isExtensionInstalled =
        browsersReport && browsersReport.some(browser => browser.isExtensionSupported && browser.extensions.length);
      if (isExtensionInstalled && ![...hidden, ...resolved].includes('add-extensions')) {
        setDetailsState({isExtensionsModalOpened: false});
        hideRiskItem({id: 'add-extensions'});
      }
    }, [browsersReport]);

    useEffect(() => {
      if (monitorHasConfirmedEmail && ![...hidden, ...resolved].includes('monitoring-email')) {
        hideRiskItem({id: 'monitoring-email'});
      }
    }, [monitorHasConfirmedEmail]);

    return (
      <section className="ppi-decrease-risks-page">
        <header className="ppi-decrease-risks-page__header">
          <div className="ppi-decrease-risks-page__header-text">
            <h1 className="ppi-decrease-risks-page__title">
              <Trans i18nKey="Decrease your privacy risks in just 3 steps">
                Decrease your privacy
                <br />
                risks in just 3 steps
              </Trans>
            </h1>
            <div className="ppi-decrease-risks-page__description">
              {t(
                'FigLeaf is the most comprehensive privacy protection available on the market that allows you to not only monitor for exposed data but also reduce the risk of its abuse.',
              )}
            </div>
          </div>
          <div className="ppi-decrease-risks-page__header-indicator">
            <PrivacyIndicatorComponent riskType={globalRiskType} status="report" />
          </div>
        </header>
        <article className="ppi-decrease-risks-page__main">
          <ul className="ppi-decrease-risks-page__steps">
            {STEPS.map((step, index) => {
              const notResolved = step.items.filter(item => !resolved.includes(item.id));
              const isGroupResolved = notResolved.length === 0;
              const titleClassName = classNames('ppi-decrease-risks-page__step-title', {
                'ppi-decrease-risks-page__step-title--completed': isGroupResolved,
              });

              return (
                <li className="ppi-decrease-risks-page__step-item" key={index}>
                  <h2 className={titleClassName}>
                    {step.title}
                    {isGroupResolved && (
                      <div className="ppi-decrease-risks-page__completed-icon">
                        <IconComponent name="check" size={24} />
                      </div>
                    )}
                  </h2>
                  <div className="ppi-decrease-risks-page__step-content">
                    {notResolved.map(item => (
                      <DecreaseRiskItemComponent
                        key={item.title}
                        isHidden={hidden.includes(item.id)}
                        onClick={() => onItemClick(item.id, item.clickResolveEvent)}
                        onResolve={() => setResolvedRisk({id: item.id})}
                        onHidden={() =>
                          sendTelemetryEvent({
                            name: item.resolveEvent,
                            source: 'app',
                          })
                        }
                        onSkip={() => {
                          sendTelemetryEvent({
                            name: item.skipEvent,
                            source: 'app',
                          });
                        }}
                        {...item}
                      />
                    ))}

                    <PPIDecreaseRisksCompletedComponent>
                      {step.items
                        .filter(item => resolved.includes(item.id))
                        .map(item => (
                          <DecreaseRiskItemComponent key={item.title} isResolved {...item} />
                        ))}
                    </PPIDecreaseRisksCompletedComponent>
                  </div>
                </li>
              );
            })}
          </ul>
        </article>

        <button className="ppi-decrease-risks-page__back" type="button" onClick={() => history.push('/dashboard')}>
          <IconComponent name="back" size={24} />
          <span className="ppi-decrease-risks-page__back-text">{t('Report')}</span>
        </button>

        <ModalImportAccountsComponent
          isImportModalOpen={isImportModalOpen}
          showHideModal={toggleImportModalOpen}
          browsersReport={browsersReport}
          importAccounts={importAccountsPPI}
        />
        <PPIFinalModalContainer isModalOpened={isFinalModalOpened} closeModal={() => setFinalModalOpened(false)} />

        <MonitorAddEmailContainer rootPath="/dashboard/ppi" />
      </section>
    );
  },
);
