import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {CSSTransition} from 'react-transition-group';

import {IconComponent} from '@figleafteam/components';
import PersonImage from '@renderer/images/illustrations/person.svg';
import IconLockBigGreen from '@renderer/images/icon-lock-big-green.svg';
import IconSearchBigGreen from '@renderer/images/icon-search-big-green.svg';
import IconBrowseBigGreen from '@renderer/images/icon-browse-big-green.svg';
import {ItemIds} from '@renderer/js/components/auth-onboarding/redux/types';
import {AuthOnboardingProps} from '@renderer/js/components/auth-onboarding/auth-onboarding.types';

import {useCheckAuth} from '@renderer/js/utils/use-check-auth';

import './_auth-onboarding.scss';

const iconMapping = {
  IconSearchBigGreen,
  IconLockBigGreen,
  IconBrowseBigGreen,
};

export const AuthOnboardingComponent: FC<AuthOnboardingProps> = props => {
  const {t} = useTranslation();

  const handleClose = (id: ItemIds) => () => {
    props.changeOnboardingItemOpenState({[id]: false});
  };
  const handleOpen = (id: ItemIds) => () => {
    props.sendTelemetryEvent({name: props.authOnboardingState.list[id].onClickEvent, source: 'user'});
    props.changeOnboardingItemOpenState({[id]: true});
  };

  useCheckAuth(props.appInfo);

  const list = props.authOnboardingState.orderList.map(id => {
    const item = props.authOnboardingState.list[id];
    const subList = props.authOnboardingState.subList[id];
    const Icon = iconMapping[item.icon];
    return (
      <li key={id} className="auth-onboarding__item">
        <div className="auth-onboarding__item-inner" role="button" onClick={handleOpen(id)}>
          <span className="auth-onboarding__item-icon">
            <Icon />
          </span>
          <span className="auth-onboarding__item-text">{item.title}</span>
          <span className="auth-onboarding__item-buttons">
            <span className="auth-onboarding__item-arrow-down">
              <IconComponent name="arrow-down" size={24} />
            </span>
          </span>
        </div>

        <CSSTransition in={item.isOpen} timeout={300} classNames={`${id}-auth-onboarding`} unmountOnExit>
          <div className="auth-onboarding__drop-down">
            <div className="auth-onboarding__item-inner">
              <span className="auth-onboarding__item-icon">
                <Icon />
              </span>
              <span className="auth-onboarding__item-text">{item.title}</span>
              <span className="auth-onboarding__item-buttons">
                <button className="auth-onboarding__item-close-button" type="button" onClick={handleClose(id)}>
                  <IconComponent name="close" size={24} />
                </button>
              </span>
            </div>
            <ul>
              {subList.map(txt => (
                <li key={txt} className="auth-onboarding__checked-list-item">
                  {txt}
                </li>
              ))}
            </ul>
          </div>
        </CSSTransition>
      </li>
    );
  });

  return (
    <div className="auth-onboarding">
      <section className="auth-onboarding__col">
        <div className="auth-onboarding__content">
          <div className="auth-onboarding__inner">
            <div className="auth-onboarding__image">
              <PersonImage />
            </div>
            <h2 className="auth-onboarding__title">
              {t('{{partner}}.', {partner: 'FigLeaf'})}
              <strong>
                <br /> {t('Control Your Privacy')}
              </strong>
            </h2>
            <ul className="auth-onboarding__list">{list}</ul>
          </div>
        </div>
      </section>
      <section className="auth-onboarding__col">{props.children}</section>
    </div>
  );
};
