import {connect} from 'react-redux';

import {AppState} from '@renderer/js/redux/rootReducer';
import {
  selectResolvedRisks,
  selectHiddenRisks,
  selectGlobalRiskType,
} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {getIsImportModalOpen, toggleImportModalOpen, selectMonitorHasConfirmedEmail} from '@figleafteam/store';
import {getInstalledBrowsersReport} from '@renderer/js/components/settings/redux/selectors';
import {
  setResolvedRisk,
  hideRiskItem,
  setPpiFunnelState,
  setPpiFlowFinished,
} from '@renderer/js/components/ppi-funnel/redux/actions';
import {
  importAccountsPPI,
  getBrowsersReport,
  openWebExtensionStore,
} from '@renderer/js/components/settings/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {setDetailsState} from '@renderer/js/components/details/redux/actions';
import {setIsVpnModalOpened} from '@renderer/js/components/private-browsing/redux/actions';

import {PPIDecreaseRisksPageComponent} from './ppi-decrease-risks-page.component';

const mapStateToProps = (state: AppState) => ({
  resolved: selectResolvedRisks(state),
  hidden: selectHiddenRisks(state),
  isImportModalOpen: getIsImportModalOpen(state),
  browsersReport: getInstalledBrowsersReport(state),
  globalRiskType: selectGlobalRiskType(state),
  monitorHasConfirmedEmail: selectMonitorHasConfirmedEmail(state),
});

const mapDispatchToProps = {
  setResolvedRisk,
  toggleImportModalOpen,
  setPpiFunnelState,
  importAccountsPPI,
  getBrowsersReport,
  hideRiskItem,
  sendTelemetryEvent,
  openWebExtensionStore,
  setDetailsState,
  setIsVpnModalOpened,
  setPpiFlowFinished,
};

export const PPIDecreaseRisksPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PPIDecreaseRisksPageComponent);
