import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';
import {SET_BROWSERS_REPORT} from '../constants';
import {TBrowsersState, TGetBrowsersReportAction, TSetBrowsersReportAction} from '../types';

const initialState: TBrowsersState = {
  installedBrowsers: null,
  supportedBrowsers: [],
};

type TActionsType = TSetBrowsersReportAction | TGetBrowsersReportAction | TLogOutSuccessAction;

export const browsersReportReducer = (state = initialState, action: TActionsType) => {
  switch (action.type) {
    case SET_BROWSERS_REPORT:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};
