import {
  QUICK_SCAN_PROFILES,
  SET_PPI_FUNNEL_STATE,
  SET_RISK_CATEGORIES,
  SET_RESOLVED_RISK,
  HIDE_RISK_ITEM,
  SET_PPI_FLOW_FINISHED,
  SET_PPI_LEAKS_STATE,
  GET_VERIFIRED_EMAILS,
  DELETE_EMAIL_FROM_REPORT,
  ADD_PPI_EMAIL_TO_MONITOR,
  VERIFY_NEXT_EMAIL,
  SEND_MONITOR_CONFIRMATION_CODE,
  SUBMIT_MONITOR_CONFIRMATION_CODE,
  GET_FULL_REPORT,
} from './constants';
import {TPpiFunnelState, TRiskCategoriesState, TResolveRiskPayload, TPpiLeaksState} from './types';

export const quickScanProfiles = () => ({
  type: QUICK_SCAN_PROFILES,
  payload: {},
});

export const setRiskCategories = (payload: TRiskCategoriesState) => ({
  type: SET_RISK_CATEGORIES,
  payload,
});

export const setPpiFunnelState = (payload: Partial<TPpiFunnelState>) => ({
  type: SET_PPI_FUNNEL_STATE,
  payload,
});

export const setResolvedRisk = (payload: TResolveRiskPayload) => ({
  type: SET_RESOLVED_RISK,
  payload,
});

export const hideRiskItem = (payload: TResolveRiskPayload) => ({
  type: HIDE_RISK_ITEM,
  payload,
});

export const setPpiFlowFinished = () => ({
  type: SET_PPI_FLOW_FINISHED,
  payload: {},
});

export const setPpiLeaksState = (payload: Partial<TPpiLeaksState>) => ({
  type: SET_PPI_LEAKS_STATE,
  payload,
});

export const getVerifiedEmails = () => ({
  type: GET_VERIFIRED_EMAILS,
  payload: {},
});

export const deleteEmailFromReport = (email: string) => ({
  type: DELETE_EMAIL_FROM_REPORT,
  payload: {
    email,
  },
});

export const addPpiEmailToMonitor = (email: string) => ({
  type: ADD_PPI_EMAIL_TO_MONITOR,
  payload: {
    email,
  },
});

export const verifyNextEmail = () => ({
  type: VERIFY_NEXT_EMAIL,
  payload: {},
});

export const sendMonitorConfirmationCode = (email: string) => ({
  type: SEND_MONITOR_CONFIRMATION_CODE,
  payload: {email},
});

export const submitMonitorConfirmationCode = (email: string, code: string) => ({
  type: SUBMIT_MONITOR_CONFIRMATION_CODE,
  payload: {
    email,
    code,
  },
});

export const getFullReport = () => ({
  type: GET_FULL_REPORT,
  payload: {},
});
