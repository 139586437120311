import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {selectIsAuthorized} from '@renderer/js/components/app/redux/selectors';
import {setAuthPopupOpened} from '@renderer/js/components/auth/redux/actions';
import {AuthorizationStatusComponent} from './authorization-status.component';

const mapStateToProps = (state: AppState) => ({
  isAuthorized: selectIsAuthorized(state),
});

const mapDispatchToProps = {
  setAuthPopupOpened,
};

export const AuthorizationStatusContainer = connect(mapStateToProps, mapDispatchToProps)(AuthorizationStatusComponent);
