import {call, put, select, takeLatest} from 'redux-saga/effects';
import {history} from '@renderer/js/router/history';
import {
  signUp,
  login,
  logout,
  requestAccountCode,
  confirmAccountCode,
  requestDeviceCode,
  confirmDeviceCode,
} from '@figleafteam/api-communication';
import {
  createLoginError,
  createSignUpError,
  logoutError,
  logoutSuccess,
  setConfirmAccountCode,
  setConfirmDeviceCode,
  requestConfirmDeviceCode,
  setResendCodeTime,
  setAuthPopupOpened,
  setAuthPopupContent,
} from '@renderer/js/components/auth/redux/actions';
import {
  TLoginSubmitAction,
  TRequestConfirmAccountCodeAction,
  TRequestConfirmDeviceCodeAction,
  TSetConfirmAccountCodeAction,
  TSetConfirmDeviceCodeAction,
  TSignupSubmitAction,
} from '@renderer/js/components/auth/redux/types';
import {getErrorSignupCredentialsValid} from '@renderer/js/components/auth/utils/getErrorSignupCredentialsValid';
import {getErrorLoginCredentialsValid} from '@renderer/js/components/auth/utils/getErrorLoginCredentialsValid';
import {notificationService} from '@renderer/js/notification-service/NotificationService';
import {getIsSavePassword} from '@renderer/js/components/unlock-user/redux/selectors';
import {EVENTS} from '@common/events';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {getAppInfoAction, setAppLoadingStatus} from '@renderer/js/components/app/redux/actions';
import {getAuthState} from '@renderer/js/components/auth/redux/selectors';
import {ERROR_STATUS_CODES} from '@common/communication/constants';
import {DEFAULT_VALUE_FOR_TIMER} from '@renderer/js/constants';
import {getIsMigrationRequired} from '@renderer/js/components/enter-security-key/redux/selectors';
import {setIsMigrationRequired} from '@renderer/js/components/enter-security-key/redux/actions';
import {setPpiFunnelState} from '@renderer/js/components/ppi-funnel/redux/actions';
import {
  LOGIN_SUBMIT,
  LOGOUT,
  REQUEST_CONFIRM_ACCOUNT_CODE,
  SET_CONFIRM_ACCOUNT_CODE,
  REQUEST_CONFIRM_DEVICE_CODE,
  SET_CONFIRM_DEVICE_CODE,
  SIGNUP_SUBMIT,
  AuthPopupContents,
} from './constants';
import {cleanUpStorage} from '../utils/cleanUp';

export function* handleSignupSubmitSaga(action: TSignupSubmitAction) {
  yield put(sendTelemetryEvent({name: EVENTS.WELCOME_SCREEN.SIGNUP_SUBMIT, source: 'user'}));
  notificationService.clearAllToasts();
  const {email, signupPassword, signupRePassword} = yield select(getAuthState);

  // validation
  const errors = getErrorSignupCredentialsValid(email, signupPassword, signupRePassword);
  if (Object.values(errors).includes(true)) {
    yield put(createSignUpError(errors));
    return;
  }

  yield put(setAppLoadingStatus(true));

  try {
    yield call(signUp, email, btoa(signupPassword));
    yield put(sendTelemetryEvent({name: EVENTS.WELCOME_SCREEN.USER_SIGNUP, source: 'app'}));

    // Hardcode 2 minutes till backend will return 'retryAfter' field
    yield put(setResendCodeTime(DEFAULT_VALUE_FOR_TIMER));

    if (action.payload.fromPopup) {
      yield put(setAuthPopupContent(AuthPopupContents.CONFIRM_ACCOUNT));
    } else {
      history.push('/signup/confirm-account');
    }
  } catch (error) {
    yield put(
      sendTelemetryEvent({
        name: EVENTS.WELCOME_SCREEN.SINGUP_ERROR,
        source: 'app',
        payload: {error},
      }),
    );
    notificationService.showAuthNotificationError(error.payload.message);
  }

  yield put(setAppLoadingStatus(false));
}

export function* handleLoginSubmitSaga(action: TLoginSubmitAction) {
  yield put(sendTelemetryEvent({name: EVENTS.WELCOME_SCREEN.LOGIN_SUBMIT, source: 'user'}));
  notificationService.clearAllToasts();
  const {email, loginPassword} = yield select(getAuthState);
  const savePassword: boolean = yield select(getIsSavePassword);
  const usePlaintextPassword: boolean = yield select(getIsMigrationRequired);

  // validation
  const errors = getErrorLoginCredentialsValid(email, loginPassword);
  if (Object.values(errors).includes(true)) {
    yield put(createLoginError(errors));
    return;
  }

  yield put(setAppLoadingStatus(true));

  try {
    yield call(login, email, btoa(loginPassword), savePassword, usePlaintextPassword);
    // After success login service will send 'ChangeAppInfo' message to electron
    // this will dispatch 'setAppInfo' action and set appLoaderStatus to false
    yield put(sendTelemetryEvent({name: EVENTS.WELCOME_SCREEN.USER_LOGIN, source: 'app'}));
    yield put(setPpiFunnelState({isPpiFunnelShown: true}));
    if (action.payload.fromPopup) {
      yield put(setAuthPopupOpened(false));
    } else {
      history.push('/');
    }
  } catch (error) {
    yield put(
      sendTelemetryEvent({
        name: EVENTS.WELCOME_SCREEN.LOGIN_ERROR,
        source: 'app',
        payload: {error},
      }),
    );
    if (error.payload && error.payload.statusCode === ERROR_STATUS_CODES.DeviceNotConfirmed) {
      yield put(requestConfirmDeviceCode(email));

      if (action.payload.fromPopup) {
        yield put(setAuthPopupContent(AuthPopupContents.CONFIRM_DEVICE));
      } else {
        history.push('/signup/confirm-device');
      }
    } else {
      const {handleContactSupport, handleResetPassword} = action.payload;
      notificationService.showInvalidCredentialsError(handleResetPassword, handleContactSupport);
      // Property isMigrationRequired seted to false on login.component unmount.
      // Need to set to true if some error occured
      if (usePlaintextPassword) {
        yield put(setIsMigrationRequired(true));
      }
    }
    yield put(setAppLoadingStatus(false));
  }
}

function* handleLogOutSaga() {
  yield put(setAppLoadingStatus(true));

  try {
    yield call(logout);
    yield put(logoutSuccess());
    cleanUpStorage();

    history.push('/');
  } catch (error) {
    yield put(logoutError());
  }

  yield put(getAppInfoAction());
}

export function* requestConfirmAccountCodeSaga(action: TRequestConfirmAccountCodeAction) {
  try {
    // Hardcode 2 minutes till backend will return 'retryAfter' field
    yield put(setResendCodeTime(DEFAULT_VALUE_FOR_TIMER));
    yield call(requestAccountCode, action.payload.email);
  } catch (error) {
    console.error(error);
    notificationService.showAuthNotificationError(error.payload.message);
  }
}

export function* handleConfirmAccountCodeSaga(action: TSetConfirmAccountCodeAction) {
  if (action.payload.code.length !== 6) {
    return null;
  }

  notificationService.clearAllToasts();
  const {email} = yield select(getAuthState);

  yield put(setAppLoadingStatus(true));

  try {
    yield call(confirmAccountCode, email, action.payload.code);
    // After success account confirmation service will send 'ChangeAppInfo' message to electron
    // this will dispatch 'setAppInfo' action and set appLoaderStatus to false
    yield put(setPpiFunnelState({isPpiFunnelShown: true}));

    yield put(setAuthPopupOpened(false));
  } catch (error) {
    yield put(setAppLoadingStatus(false));
    notificationService.showAuthNotificationError(error.payload.message);
  }

  yield put(setConfirmAccountCode(''));
}

export function* requestConfirmDeviceCodeSaga(action: TRequestConfirmDeviceCodeAction) {
  try {
    // Hardcode 2 minutes till backend will return 'retryAfter' field
    yield put(setResendCodeTime(DEFAULT_VALUE_FOR_TIMER));
    yield call(requestDeviceCode, action.payload.email);
  } catch (error) {
    console.error(error);
    notificationService.showAuthNotificationError(error.payload.message);
  }
}

export function* handleConfirmDeviceCodeSaga(action: TSetConfirmDeviceCodeAction) {
  if (action.payload.code.length !== 6) {
    return null;
  }

  notificationService.clearAllToasts();
  yield put(setAppLoadingStatus(true));
  const {email} = yield select(getAuthState);

  try {
    yield call(confirmDeviceCode, email, action.payload.code);
    // After success device confirmation service will send 'ChangeAppInfo' message to electron
    // this will dispatch 'setAppInfo' action and set appLoaderStatus to false
    yield put(setPpiFunnelState({isPpiFunnelShown: true}));
  } catch (error) {
    yield put(setAppLoadingStatus(false));
    notificationService.showAuthNotificationError(error.payload.message);
  }

  yield put(setConfirmDeviceCode(''));
}

export function* authSagaWatcher() {
  yield takeLatest(SIGNUP_SUBMIT, handleSignupSubmitSaga);
  yield takeLatest(LOGIN_SUBMIT, handleLoginSubmitSaga);
  yield takeLatest(LOGOUT, handleLogOutSaga);
  yield takeLatest(REQUEST_CONFIRM_ACCOUNT_CODE, requestConfirmAccountCodeSaga);
  yield takeLatest(SET_CONFIRM_ACCOUNT_CODE, handleConfirmAccountCodeSaga);
  yield takeLatest(REQUEST_CONFIRM_DEVICE_CODE, requestConfirmDeviceCodeSaga);
  yield takeLatest(SET_CONFIRM_DEVICE_CODE, handleConfirmDeviceCodeSaga);
}
