import {
  CLEAR_INFO,
  SET_CONFIRM_ACCOUNT_CODE,
  SET_CONFIRM_DEVICE_CODE,
  SET_AUTH_DATA,
  SIGNUP_SUBMIT,
  SIGNUP_ERROR,
  RESET_SIGNUP_EMAIL_VALIDATION,
  RESET_SIGNUP_PASSWORD_VALIDATION,
  LOGIN_SUBMIT,
  LOGIN_ERROR,
  RESET_LOGIN_EMAIL_VALIDATION,
  RESET_LOGIN_PASSWORD_VALIDATION,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SET_RESEND_CODE_TIME,
  REQUEST_CONFIRM_DEVICE_CODE,
  REQUEST_CONFIRM_ACCOUNT_CODE,
  SET_AUTH_POPUP_OPENED,
  SET_AUTH_POPUP_CONTENT,
  AuthPopupContents,
} from '@renderer/js/components/auth/redux/constants';
import {TSignUpErrors, TLoginErrors} from '@renderer/js/components/auth/redux/types';

export const setAuthInfo = (key: 'email' | 'signupPassword' | 'signupRePassword' | 'loginPassword', value: string) => ({
  type: SET_AUTH_DATA,
  payload: {
    key,
    value,
  },
});

export const setResendCodeTime = (seconds: number) => ({
  type: SET_RESEND_CODE_TIME,
  payload: {
    seconds,
  },
});

export const requestConfirmDeviceCode = (email: string) => ({
  type: REQUEST_CONFIRM_DEVICE_CODE,
  payload: {
    email,
  },
});

export const requestConfirmAccountCode = (email: string) => ({
  type: REQUEST_CONFIRM_ACCOUNT_CODE,
  payload: {
    email,
  },
});

export const setConfirmAccountCode = (code: string) => ({
  type: SET_CONFIRM_ACCOUNT_CODE,
  payload: {
    code,
  },
});

export const setConfirmDeviceCode = (code: string) => ({
  type: SET_CONFIRM_DEVICE_CODE,
  payload: {
    code,
  },
});

export const signupSubmit = (params?: {fromPopup: boolean}) => ({
  type: SIGNUP_SUBMIT,
  payload: {fromPopup: params?.fromPopup},
});

export const createSignUpError = (signUpErrors: TSignUpErrors) => ({
  type: SIGNUP_ERROR,
  payload: {
    signUpErrors,
  },
});

export const resetSignupEmailValidationStatus = () => ({
  type: RESET_SIGNUP_EMAIL_VALIDATION,
  payload: {},
});

export const resetSignupPasswordsValidationStatus = () => ({
  type: RESET_SIGNUP_PASSWORD_VALIDATION,
  payload: {},
});

export const loginSubmit = (payload: {
  fromPopup?: boolean;
  handleResetPassword: () => void;
  handleContactSupport: () => void;
}) => ({type: LOGIN_SUBMIT, payload});

export const createLoginError = (loginErrors: TLoginErrors) => ({
  type: LOGIN_ERROR,
  payload: {
    loginErrors,
  },
});

export const resetLoginEmailValidationStatus = () => ({
  type: RESET_LOGIN_EMAIL_VALIDATION,
  payload: {},
});

export const resetLoginPasswordsValidationStatus = () => ({
  type: RESET_LOGIN_PASSWORD_VALIDATION,
  payload: {},
});

export const clearAuthInfo = () => ({
  type: CLEAR_INFO,
  payload: {},
});

export const logout = () => ({
  type: LOGOUT,
  payload: {},
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
  payload: {},
});

export const logoutError = () => ({
  type: LOGOUT_ERROR,
  payload: {},
});

export const setAuthPopupOpened = (isOpened: boolean) => ({
  type: SET_AUTH_POPUP_OPENED,
  payload: {isOpened},
});

export const setAuthPopupContent = (authPopupContent: AuthPopupContents) => ({
  type: SET_AUTH_POPUP_CONTENT,
  payload: {
    authPopupContent,
  },
});
