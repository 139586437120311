import React, {FC} from 'react';
import classNames from 'classnames';

import {IconComponent} from '@figleafteam/components';
import {RiskTypes} from '@renderer/js/components/ppi-funnel/redux/types';

import {TPPIRisksReportRiskIconProps} from './ppi-risks-report-risk-icon.types';

import './_ppi-risks-report-risk-icon.scss';

export const PPIRisksReportRiskIconComponent: FC<TPPIRisksReportRiskIconProps> = ({icon, risk}) => {
  return (
    <div className="risk-icon">
      <div className="risk-icon__icon">{icon}</div>
      {risk !== RiskTypes.low && (
        <div
          className={classNames('risk-icon__error', {
            'risk-icon__error--high': risk === RiskTypes.high,
            'risk-icon__error--medium': risk === RiskTypes.medium,
          })}
        >
          <IconComponent name="attention-fill" size={20} />
        </div>
      )}
    </div>
  );
};
