import {TEventsDefaultConfig, TSendTelemetryEventAction} from '@renderer/js/events/redux/types';
import {FL_EVENT_CONTEXT} from './constants';

const initialState: TEventsDefaultConfig = {
  productVersion: VERSION,
  eventContext: FL_EVENT_CONTEXT,
};

type TActionsType = TSendTelemetryEventAction;

export const eventsDefaultState = (
  state: TEventsDefaultConfig = initialState,
  action: TActionsType,
): TEventsDefaultConfig => {
  switch (action.type) {
    default:
      return state;
  }
};
