import {
  CLEAR_NEXT_ACTION,
  RESET_MODAL,
  SET_MODAL_VISIBLE,
  SET_NEXT_ACTION,
  SET_PASSWORD,
  UNLOCK_USER,
  SET_SAVE_PASSWORD_STATUS,
  RESET_LOCKED_STATE,
} from '@renderer/js/components/unlock-user/redux/constants';
import {AnyAction} from '@renderer/js/global-types/AnyAction';

export const setModalVisible = (isVisible: boolean) => ({
  type: SET_MODAL_VISIBLE,
  payload: {
    isVisible,
  },
});

export const setUnlockPassword = (password: string) => ({
  type: SET_PASSWORD,
  payload: {
    password,
  },
});

export const setSavePasswordStatus = (savePassword: boolean) => ({
  type: SET_SAVE_PASSWORD_STATUS,
  payload: {savePassword},
});

export const resetModal = () => ({
  type: RESET_MODAL,
  payload: {},
});

export const unlockUser = () => ({
  type: UNLOCK_USER,
  payload: {},
});

export const setNextActions = (payload: {nextAction: AnyAction}) => ({
  type: SET_NEXT_ACTION,
  payload,
});

export const clearNextActions = () => ({
  type: CLEAR_NEXT_ACTION,
  payload: {},
});

export const resetLockedState = () => ({
  type: RESET_LOCKED_STATE,
  payload: {},
});
