import React, {FC} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {Props} from './invalid-credentials-notification.types';
import './_invalid-credentials-notification.scss';

export const InvalidCredentialsComponent: FC<Props> = props => {
  const {t} = useTranslation();

  return (
    <div className="invalid-credentials-notification">
      <p className="invalid-credentials-notification__text">{t('Email or password is incorrect')}</p>
      <div className="invalid-credentials-notification__buttons">
        <button
          className="btn invalid-credentials-notification__button"
          type="button"
          onClick={() => {
            toast.dismiss();
            props.onResetPasswordClick();
          }}
        >
          {t('Reset password')}
        </button>
        <button
          className="btn invalid-credentials-notification__button"
          type="button"
          onClick={() => {
            toast.dismiss();
            props.onContactSupportClick();
          }}
        >
          {t('Contact support')}
        </button>
      </div>
    </div>
  );
};
