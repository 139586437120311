import {
  TModalWindowState,
  TResetModalAction,
  TSetModalVisibleAction,
} from '@renderer/js/components/unlock-user/redux/types';
import {RESET_MODAL, SET_MODAL_VISIBLE} from '@renderer/js/components/unlock-user/redux/constants';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';

const initialState: TModalWindowState = {
  isOpen: false,
};

type TActionsType = TSetModalVisibleAction | TResetModalAction | TLogOutSuccessAction;

export const modalWindowReducer = (
  state: TModalWindowState = initialState,
  action: TActionsType,
): TModalWindowState => {
  switch (action.type) {
    case SET_MODAL_VISIBLE:
      return {
        ...state,
        isOpen: action.payload.isVisible,
      };
    case LOGOUT_SUCCESS:
    case RESET_MODAL:
      return initialState;
    default:
      return state;
  }
};
