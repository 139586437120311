import {combineReducers} from 'redux';
import {browsersReportReducer} from './reducers/browsers-report.reducer';
import {userProfileReducer} from './reducers/user-profile.reducer';
import {accountInfoReducer} from './reducers/account-info.reducer';
import {settingsInfoReducer} from './reducers/settings-info.reducer';
import {promoCodeModalReducer} from './reducers/promo-code-modal.reducer';

export const settingsState = combineReducers({
  browsersReport: browsersReportReducer,
  userProfile: userProfileReducer,
  accountInfo: accountInfoReducer,
  settingsInfo: settingsInfoReducer,
  promoCodeModal: promoCodeModalReducer,
});
