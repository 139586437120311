import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {i18n} from '@figleafteam/helpers';

import {TPPIRisksReportProgressBlockProps} from './ppi-risks-scanner-progress-block.types';
import {PPIRisksScannerProgressItem} from '../ppi-risks-scanner-progress-item/ppi-risks-scanner-progress-item.component';

import './_ppi-risks-scanner-progress-block.scss';

const PROGRESS_INTERVAL = 2.5 * 1000;

const STEPS = {
  get 0() {
    return i18n.t('Searching for privacy risks on your PC...');
  },
  get 1() {
    return i18n.t('Looking for your exposed data online...');
  },
  get 2() {
    return i18n.t('Checking data broker databases...');
  },
};

export const PPIRisksScannerProgressBlockComponent: FC<TPPIRisksReportProgressBlockProps> = ({
  isFetched,
  isLoading,
  quickScanProfiles,
  setPpiFunnelState,
}) => {
  const {t} = useTranslation();
  const [progressIndex, setProgressIndex] = useState(0);

  const incrementProgress = () => {
    setProgressIndex(currentIndex => {
      if (isLoading) {
        return currentIndex;
      } else if (currentIndex >= 2) {
        setTimeout(() => {
          setPpiFunnelState({scannerStep: 'report'});
        }, 500);
      }
      return currentIndex + 1;
    });
  };

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;
    if (isFetched) {
      // skip scanning and show stored results right away
      setProgressIndex(4);
    } else {
      quickScanProfiles();
      interval = setInterval(incrementProgress, PROGRESS_INTERVAL);
    }
    return () => {
      setPpiFunnelState({scannerStep: 'report'});
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <section className="ppi-progress-block">
      <h1 className="ppi-progress-block__title">{t('Checking your privacy')}</h1>
      <ul className="ppi-progress-block__items">
        {Object.values(STEPS).map((text, index) => {
          const done = index < progressIndex && 'done';
          const state = done || (index === progressIndex ? 'progress' : 'waiting');
          return (
            <li className="ppi-progress-block__item" key={text}>
              <PPIRisksScannerProgressItem state={state}>{text}</PPIRisksScannerProgressItem>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
