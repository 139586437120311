import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {
  selectIsDataFetched,
  selectIsLoading,
  selectRiskCategories,
} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {
  quickScanProfiles,
  setPpiFunnelState,
  setRiskCategories,
} from '@renderer/js/components/ppi-funnel/redux/actions';
import {PPIRisksScannerProgressBlockComponent} from './ppi-risks-scanner-progress-block.component';

const mapStateToProps = (state: AppState) => ({
  isFetched: selectIsDataFetched(state),
  riskCategories: selectRiskCategories(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = {
  quickScanProfiles,
  setRiskCategories,
  sendTelemetryEvent,
  setPpiFunnelState,
};

export const PPIRisksScannerProgressBlockContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PPIRisksScannerProgressBlockComponent);
