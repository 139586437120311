import React, {FC, FormEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReactInputMask, {InputState, StateSnapshot} from 'react-input-mask';
import {ButtonComponent, InputComponent} from '@figleafteam/components';
import {ForgetKeyModalComponent} from '@renderer/js/components/recovery/components/forget-key-modal/forget-key-modal.component';
import {RECOVERY_KEY_MASK} from '@renderer/js/components/recovery/constants/recovery-key-mask';
import {getFromState, history, HISTORY_STATE_FROM} from '@renderer/js/router/history';

import {EnterRecoveryKeyProps} from './enter-recovery-key.types';

export const EnterRecoveryKeyComponent: FC<EnterRecoveryKeyProps> = props => {
  const [isForgotRecoveryKeyModalShown, setForgotRecoveryKeyModalShown] = useState(false);
  const {t} = useTranslation();
  const onSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    props.requestRecoveryCode(props.email, props.recoveryKey);
  };
  const handleOpenModal = () => setForgotRecoveryKeyModalShown(true);
  const handleCloseModal = () => setForgotRecoveryKeyModalShown(false);
  const contactSupport = () => props.openSupportLink();
  const handleChangeKey = (ev: React.ChangeEvent<HTMLInputElement>) => props.setRecoveryKey(ev.target.value);
  const handleMaskedBehavior = (snapshot: StateSnapshot): InputState => {
    const {previousState, nextState} = snapshot;
    const currentValue = previousState?.value;
    const firstMaskedChar = currentValue?.indexOf('_');
    if (currentValue && firstMaskedChar && firstMaskedChar !== -1) {
      const entered = nextState?.enteredString?.toUpperCase();

      if (entered) {
        const newValue = [...currentValue];
        let i = firstMaskedChar;
        for (let j = 0; i < newValue.length && j < entered.length; i++) {
          if (newValue[i] === '_') {
            newValue[i] = entered[j++];
          } else if (newValue[i] === entered[j]) {
            // this case needed if full recovery key pasted (with dashes)
            j++;
          }
        }
        return {
          value: newValue.join(''),
          selection: {
            start: i,
            end: i,
          },
        };
      }
      const correctCarretPosition = nextState?.value.indexOf('_');
      return {
        ...nextState,
        selection: {
          start: correctCarretPosition,
          end: correctCarretPosition,
        },
      };
    }

    return {
      ...nextState,
      value: nextState.value.toUpperCase(),
    };
  };

  const handleChangeEmail = (ev: React.ChangeEvent<HTMLInputElement>) => {
    props.setRecoveryEmail(ev.target.value);
    props.resetRecoveryKeyError();
  };
  const goBackHandler = () => {
    history.goBack();
  };

  const errorsConfig = {
    email: {
      error: props.recoveryKeyErrors.emailInvalid,
      bottomText: props.recoveryKeyErrors.emailErrorText,
    },
  };

  const from = getFromState();

  return (
    <>
      <div className="auth">
        <div className="auth__container">
          <h1 className="auth__title auth__title--recovery">{t('Enter your Recovery Key')}</h1>
          <p className="auth__text">
            {t(
              'It’s time to find that long key, that you’ve saved or written down after sign up. Without this key, {{partner}} cannot decrypt your data and log in to your account.',
              {partner: 'FigLeaf'},
            )}
          </p>
          <form className="auth__form" onSubmit={onSubmit}>
            <div className="auth__form-input">
              <InputComponent
                onChange={handleChangeEmail}
                value={props.email}
                name="recovery-email"
                type="text"
                placeholder={t('Email')}
                {...errorsConfig.email}
              />
            </div>
            <div className="auth__form-input">
              <ReactInputMask
                mask={RECOVERY_KEY_MASK}
                beforeMaskedStateChange={handleMaskedBehavior}
                onChange={handleChangeKey}
                value={props.recoveryKey}
              >
                <InputComponent
                  onChange={handleChangeKey}
                  value={props.recoveryKey}
                  name="recovery-key"
                  type="recovery-code"
                  placeholder="0000-0000-0000-0000-0000-0000-0000"
                  autoFocus
                />
              </ReactInputMask>
            </div>
            <div className="auth__form-link">
              <ButtonComponent
                type="link"
                buttonType="button"
                onClick={handleOpenModal}
                text={t('Cannot find your Recovery key?')}
              />
            </div>
            <div className="auth__buttons">
              <ButtonComponent
                fullWidth
                disabled={!props.email}
                buttonType="submit"
                type="primary"
                text={t('Reset password')}
              />
            </div>
          </form>
        </div>

        <div className="auth__bottom-text-wrapper">
          <p className="auth__bottom-text">
            {t('Remember you password?')}{' '}
            {!from && (
              // Go back to login
              <ButtonComponent type="link" buttonType="button" text={t('Go back')} onClick={goBackHandler} />
            )}
            {from === HISTORY_STATE_FROM.UNLOCK && (
              // Go back to lock screen
              <ButtonComponent type="link" buttonType="button" text={t('Go back')} onClick={goBackHandler} />
            )}
          </p>
        </div>
      </div>
      <ForgetKeyModalComponent
        keyType={t('Recovery')}
        isOpen={isForgotRecoveryKeyModalShown}
        handleClose={handleCloseModal}
        openSupportClick={contactSupport}
      />
    </>
  );
};
