import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {SignUpComponent} from '@renderer/js/components/auth/components/signup/signup.component';
import {
  setAuthInfo,
  signupSubmit,
  resetSignupEmailValidationStatus,
  resetSignupPasswordsValidationStatus,
} from '@renderer/js/components/auth/redux/actions';
import {
  getEmail,
  getSignupPassword,
  getSignupRePassword,
  getSignUpErrors,
} from '@renderer/js/components/auth/redux/selectors';

const mapStateToProps = (state: AppState) => ({
  email: getEmail(state),
  signupPassword: getSignupPassword(state),
  signupRePassword: getSignupRePassword(state),
  signUpErrors: getSignUpErrors(state),
});

export const SignUpContainer = connect(mapStateToProps, {
  setAuthInfo,
  signupSubmit,
  resetSignupEmailValidationStatus,
  resetSignupPasswordsValidationStatus,
})(SignUpComponent);
