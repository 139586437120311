import {call, put, takeEvery} from 'redux-saga/effects';
import {getAppInfo} from '@figleafteam/api-communication';
import {SET_APP_INFO, GET_APP_INFO} from './constants';
import {setAppInfo, setAppLoadingStatus} from './actions';

export function* getAppInfosSaga() {
  try {
    const appInfo: TAppInfoPayload = yield call(getAppInfo);
    yield put(setAppInfo(appInfo));
  } catch (e) {
    console.error('err', e);
    yield put(setAppLoadingStatus(false));
  }
}

export function* setAppInfoSaga() {
  yield put(setAppLoadingStatus(false));
}

export function* appWatcher() {
  yield takeEvery(GET_APP_INFO, getAppInfosSaga);
  yield takeEvery(SET_APP_INFO, setAppInfoSaga);
}
