import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';
import {compareObjectsByProperty} from '@renderer/js/utils/index';
import {
  REQUEST_DISABLE_VPN_CONNECTION,
  REQUEST_ENABLE_VPN_CONNECTION,
  SCHEDULE_VPN_CONNECTION,
  SET_COUNTRY_LIST,
  SET_CURRENT_COUNTRY,
  SET_IS_VPN_MODAL_OPENED,
  SET_VPN_INFO,
  SET_VPN_SESSION_START,
} from '../constants';
import {
  TDisableVpnConnectionAction,
  TEnableVpnConnectionAction,
  TScheduleVpnConnectionAction,
  TSetCountryListAction,
  TSetCurrentCountryAction,
  TSetIsVpnModalOpenedAction,
  TSetVpnInfoAction,
  TSetVpnSessionStartAction,
  TVpnState,
} from '../types';

const initialState: TVpnState = {
  isVpnInfoFetched: false,
  isCountryListFetched: false,
  isVpnEnabled: false,
  isVpnStatusInProgress: false,
  isVpnConnectionScheduled: false,
  vpnInProgressStatusText: '',
  currentCountryCode: null,
  currentCountryName: null,
  currentCountryIp: null,
  countryList: [],
  vpnSessionStart: null,
  isModalOpened: false,
};

type TActionsType =
  | TSetVpnInfoAction
  | TSetCountryListAction
  | TEnableVpnConnectionAction
  | TDisableVpnConnectionAction
  | TScheduleVpnConnectionAction
  | TSetCurrentCountryAction
  | TLogOutSuccessAction
  | TSetVpnSessionStartAction
  | TSetIsVpnModalOpenedAction;

export const vpnReducer = (state = initialState, action: TActionsType): TVpnState => {
  switch (action.type) {
    case SET_VPN_INFO:
      return {
        ...state,
        ...action.payload,
        currentCountryName: action.payload.currentCountryName
          ? action.payload.currentCountryName
          : state.currentCountryName,
        isVpnInfoFetched: true,
      };
    case SET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload.countryList.sort(compareObjectsByProperty('countryName')),
        isCountryListFetched: true,
      };
    case REQUEST_ENABLE_VPN_CONNECTION:
      return {
        ...state,
        ...action.payload,
        vpnInProgressStatusText: 'Connecting...',
      };
    case REQUEST_DISABLE_VPN_CONNECTION:
      return {
        ...state,
        ...action.payload,
        vpnInProgressStatusText: 'Disconnecting...',
      };
    case SCHEDULE_VPN_CONNECTION:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CURRENT_COUNTRY:
      return {
        ...state,
        ...action.payload,
      };

    case SET_VPN_SESSION_START:
      return {
        ...state,
        ...action.payload,
      };
    case SET_IS_VPN_MODAL_OPENED:
      return {
        ...state,
        isModalOpened: action.payload.isOpened,
      };

    case LOGOUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};
