import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  selectPpiProfilesToVerify,
  selectIsAddEmailModalOpen,
  selectCurrentEmailToVerify,
} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {
  setPpiLeaksState,
  addPpiEmailToMonitor,
  getVerifiedEmails,
  deleteEmailFromReport,
  getFullReport,
} from '@renderer/js/components/ppi-funnel/redux/actions';
import {PPIEmailsListModalComponent} from './ppi-add-email-modal.component';

const mapStateToProps = (state: AppState) => ({
  profilesToVerify: selectPpiProfilesToVerify(state),
  isModalOpen: selectIsAddEmailModalOpen(state),
  currentEmailToVerify: selectCurrentEmailToVerify(state),
});

const mapDispatchToProps = {
  setPpiLeaksState,
  addPpiEmailToMonitor,
  getVerifiedEmails,
  deleteEmailFromReport,
  getFullReport,
};

export const PPIEmailsListModalContainer = connect(mapStateToProps, mapDispatchToProps)(PPIEmailsListModalComponent);
