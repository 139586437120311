import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  getSearchFilter,
  getIsFetched,
  getAccountsNumber,
  setSearchQuery,
  toggleImportModalOpen,
} from '@figleafteam/store';
import {getInstalledBrowsersReport} from '@renderer/js/components/settings/redux/selectors';
import {openWebExtensionStore, getBrowsersReport} from '@renderer/js/components/settings/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {selectIsAuthorized} from '@renderer/js/components/app/redux/selectors';
import {resetDetailsState, setDetailsState} from '@renderer/js/components/details/redux/actions';
import {HeaderComponent} from './header.component';

const mapStateToProps = (state: AppState) => ({
  searchQuery: getSearchFilter(state),
  isAccountsFetched: getIsFetched(state),
  accountsNumber: getAccountsNumber(state),
  browsersReport: getInstalledBrowsersReport(state),
  isAuthorized: selectIsAuthorized(state),
});

const mapDispatchToProps = {
  setSearchQuery,
  getBrowsersReport,
  openWebExtensionStore,
  setDetailsState,
  resetDetailsState,
  toggleImportModalOpen,
  sendTelemetryEvent,
};

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
