import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  setConfirmAccountCode,
  requestConfirmAccountCode,
  setResendCodeTime,
  setAuthPopupContent,
} from '@renderer/js/components/auth/redux/actions';
import {getEmail, getConfirmAccountCode, getResendCodeTime} from '@renderer/js/components/auth/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {AuthPopupConfirmAccountComponent} from './auth-popup-confirm-account.component';

const mapStateToProps = (state: AppState) => ({
  email: getEmail(state),
  code: getConfirmAccountCode(state),
  resendTime: getResendCodeTime(state),
});

export const AuthPopupConfirmAccountContainer = connect(mapStateToProps, {
  setConfirmAccountCode,
  requestConfirmAccountCode,
  setResendCodeTime,
  setAuthPopupContent,
  sendTelemetryEvent,
})(AuthPopupConfirmAccountComponent);
