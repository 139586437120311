import {connect} from 'react-redux';

import {AppState} from '@renderer/js/redux/rootReducer';
import {AuthPopupComponent} from './auth-popup.component';
import {selectAuthPopupOpened, selectAuthPopupContent} from '../../redux/selectors';
import {setAuthPopupOpened} from '../../redux/actions';

const mapStateToProps = (state: AppState) => ({
  isAuthPopupOpened: selectAuthPopupOpened(state),
  authPopupContent: selectAuthPopupContent(state),
});

const mapDispatchToProps = {
  setAuthPopupOpened,
};

export const AuthPopupContainer = connect(mapStateToProps, mapDispatchToProps)(AuthPopupComponent);
