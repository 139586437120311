import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {selectHomeTitleTheft, selectResolvedRisks} from '@renderer/js/components/ppi-funnel/redux/selectors';

import {PPIRisksReportExposedLocationsComponent} from './ppi-risks-report-exposed-locations.component';

const mapStateToProps = (state: AppState) => ({
  homeTitleTheft: selectHomeTitleTheft(state),
  resolvedRisks: selectResolvedRisks(state),
});

const mapDispatchToProps = {};

export const PPIRisksReportExposedLocationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PPIRisksReportExposedLocationsComponent);
