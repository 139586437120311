import React, {memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import psl from 'psl';
import {ButtonComponent, TooltipComponent, InputComponent, IconComponent} from '@figleafteam/components';
import iconDefaultFavicon from '@renderer/images/path-file/icon_default-favicon.svg';
import {TrustedItemsProps} from './trusted-websites.types';
import './_trusted-websites.scss';

const getHostnameFromUrl = (value: string) => {
  try {
    const parsedUrl = psl.parse(value.startsWith('http') ? new URL(value).hostname : value);
    if (!parsedUrl.error) {
      return parsedUrl.domain;
    }
  } catch {
    return null;
  }
};

export const TrustedWebsitesComponent = memo<TrustedItemsProps>(props => {
  const {whitelist, updateDomainConfig, clearAntitrackingWhiteList, handleClose, setConfirmModalState} = props;
  const [inputValue, setInputValue] = useState('');
  const [isInputValueValid, setIsInputValueValid] = useState(true);
  const {t} = useTranslation();

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (!isInputValueValid) {
      const parsedHostname = getHostnameFromUrl(value);
      setIsInputValueValid(Boolean(parsedHostname));
    }

    setInputValue(value);
  };

  const handleAddToWhiteList = () => {
    const parsedHostname = getHostnameFromUrl(inputValue);

    if (parsedHostname) {
      updateDomainConfig({
        domain: parsedHostname,
        isAntiTrackingEnabled: false,
      });
      setInputValue('');
    } else {
      setIsInputValueValid(false);
    }
  };

  return (
    <div className="trusted-websites">
      <div className="trusted-websites__header">
        <span className="trusted-websites__subtitle">
          {t('Sites, where all the trackers will be allowed')}
          {Boolean(whitelist.length) && (
            <span>
              <br />
              <button
                className="trusted-websites__delete-all"
                onClick={() => {
                  setConfirmModalState({
                    title: t('Remove all the trusted websites?'),
                    text: t('Trackers will be blocked on every website you visit'),
                    actionButtonText: t('Remove all'),
                    isOpen: true,
                    actionCallback: () => {
                      clearAntitrackingWhiteList();
                      handleClose();
                    },
                  });
                }}
                type="button"
              >
                {t('Clear Trusted websites list')}
              </button>{' '}
              {t('to block trackers everywhere.')}
            </span>
          )}
        </span>
        <div className="trusted-websites__search-form-wrapper">
          <form
            className="trusted-websites__search-form"
            onSubmit={event => {
              event.preventDefault();
              handleAddToWhiteList();
            }}
          >
            <InputComponent
              value={inputValue}
              onChange={handleInputChange}
              placeholder={t('Type or paste the website address')}
              type="text"
              name="website"
              autoFocus
              error={!isInputValueValid}
              bottomText={isInputValueValid ? '' : t('Please, enter a valid website address')}
            />
            <ButtonComponent
              type="primary"
              text="Add"
              buttonType="submit"
              disabled={!isInputValueValid || !inputValue}
            />
          </form>
        </div>
      </div>

      {whitelist.length ? (
        <div className="trusted-websites__list">
          {whitelist.map((item, index) => {
            const hostname = getHostnameFromUrl(item);
            return (
              <div className="trusted-websites__list-item" key={index}>
                <div className="trusted-websites__list-item-icon">
                  <img
                    src={hostname ? `https://api.figleaf.online/v1/image/${hostname}/96` : ''}
                    onError={(ev: any) => {
                      ev.target.src = iconDefaultFavicon;
                    }}
                    width="32"
                    alt=""
                  />
                </div>
                <span className="trusted-websites__list-item-text">{item}</span>
                <TooltipComponent text={t('Remove')} modifier="dark" placement="left">
                  <button
                    className="trusted-websites__list-item-delete"
                    onClick={() =>
                      updateDomainConfig({
                        domain: item,
                        isAntiTrackingEnabled: true,
                      })
                    }
                    type="button"
                  >
                    <IconComponent name="delete" size={24} />
                  </button>
                </TooltipComponent>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
});
TrustedWebsitesComponent.displayName = 'TrustedWebsitesComponent';
