import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';
import {
  TAntitrackingState,
  TSetDefaultSettingsAction,
  TSetBrowserConfigAction,
  TGetBrowserConfigAction,
  TGetTrackersBlockedInBrowserAction,
  TSetTrackersBlockedInBrowserAction,
  TGetAntitrackingConfigAction,
  TSetAntitrackingConfigAction,
  TGetAntitrackingStatisticsAction,
  TSetAntitrackingStatisticsAction,
} from '../types';
import {
  SET_ANTITRACKING_CONFIG,
  SET_ANTITRACKING_STATISTICS,
  SET_BROWSER_CONFIG,
  SET_DEFAULT_SETTINGS,
  SET_TRACKERS_BLOCKED_IN_BROWSER,
} from '../constants';

const initialState: TAntitrackingState = {
  isDefaultSettingsFetched: false,
  defaultSettings: {
    isAntiTrackingEnabled: false,
    isConnectionEncryptionEnabled: false,
    isAutofillEnabled: true,
    isBalumbaEnabled: true,
    isAntitrackingPageEnabled: false,
  },
  browsersConfig: {},
  trackersBlockedInBrowser: {},
  antitrackingConfig: {
    whiteList: [],
    blackList: [],
  },
  antitrackingStatistics: {
    total: 0,
    today: 0,
    week: 0,
    month: 0,
  },
};

type TActionsType =
  | TSetDefaultSettingsAction
  | TLogOutSuccessAction
  | TSetBrowserConfigAction
  | TGetBrowserConfigAction
  | TGetTrackersBlockedInBrowserAction
  | TSetTrackersBlockedInBrowserAction
  | TGetAntitrackingConfigAction
  | TSetAntitrackingConfigAction
  | TGetAntitrackingStatisticsAction
  | TSetAntitrackingStatisticsAction;

export const antitrackingReducer = (state = initialState, action: TActionsType) => {
  switch (action.type) {
    case SET_DEFAULT_SETTINGS:
      return {
        ...state,
        ...action.payload,
        isDefaultSettingsFetched: true,
      };
    case SET_BROWSER_CONFIG:
      return {
        ...state,
        browsersConfig: {
          ...state.browsersConfig,
          [action.payload.browserName]: action.payload.browserConfig,
        },
      };
    case SET_TRACKERS_BLOCKED_IN_BROWSER:
      return {
        ...state,
        trackersBlockedInBrowser: {
          ...state.trackersBlockedInBrowser,
          [action.payload.browserName]: action.payload.amountBlocked,
        },
      };

    case SET_ANTITRACKING_CONFIG:
      return {
        ...state,
        antitrackingConfig: action.payload,
      };

    case SET_ANTITRACKING_STATISTICS:
      return {
        ...state,
        antitrackingStatistics: action.payload.antitrackingStatistics,
      };

    case LOGOUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};
