import {useDispatch, useSelector} from 'react-redux';
import {selectIsAuthorized} from '@renderer/js/components/app/redux/selectors';
import {setAuthPopupOpened} from '../redux/actions';

export const useAuthorization = () => {
  const isAuthorized = useSelector(selectIsAuthorized);
  const dispatch = useDispatch();
  return {
    isAuthorized,
    runIfAuthorized: (callback: () => void) => {
      if (!isAuthorized) {
        dispatch(setAuthPopupOpened(true));
        return;
      }
      callback();
    },
  };
};
