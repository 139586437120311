import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  getUserProfileAction,
  getBrowsersReport,
  changeAnalyticStatus,
  importAccounts,
  getNotificationsStatusAction,
  changeNotificationsStatus,
} from '@renderer/js/components/settings/redux/actions';
import {
  getAnalyticStatus,
  getInstalledBrowsersReport,
  getNotificationsStatus,
  getUserProfile,
} from '@renderer/js/components/settings/redux/selectors';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {AdvancedSettingsComponent} from './advanced-settings.component';

const mapStateToProps = (state: AppState) => ({
  userProfileState: getUserProfile(state),
  isAnalyticEnabled: getAnalyticStatus(state),
  browsersReport: getInstalledBrowsersReport(state),
  notificationsStatus: getNotificationsStatus(state),
});

const mapDispatchToProps = {
  getUserProfileAction,
  getBrowsersReport,
  changeAnalyticStatus,
  importAccounts,
  getNotificationsStatusAction,
  changeNotificationsStatus,
  sendTelemetryEvent,
};

export const AdvancedSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(AdvancedSettingsComponent);
