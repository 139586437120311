import {TVpnInfoType, TCountryListData} from './types';
import {
  GET_VPN_CONFIG,
  SET_COUNTRY_LIST,
  GET_VPN_INFO,
  SET_VPN_INFO,
  REQUEST_ENABLE_VPN_CONNECTION,
  REQUEST_DISABLE_VPN_CONNECTION,
  SCHEDULE_VPN_CONNECTION,
  SET_CURRENT_COUNTRY,
  SET_DEFAULT_SETTINGS,
  GET_DEFAULT_SETTINGS,
  SET_BROWSER_CONFIG,
  GET_BROWSER_CONFIG,
  UPDATE_BROWSER_CONFIG,
  GET_TRACKERS_BLOCKED_IN_BROWSER,
  SET_TRACKERS_BLOCKED_IN_BROWSER,
  SET_ANTITRACKING_CONFIG,
  GET_ANTITRACKING_CONFIG,
  UPDATE_DOMAIN_CONFIG,
  CLEAR_ANTITRACKING_WHITELIST,
  SET_VPN_SESSION_START,
  GET_VPN_SESSION_START,
  SET_ANTITRACKING_STATISTICS,
  GET_ANTITRACKING_STATISTICS,
  SET_IS_VPN_MODAL_OPENED,
} from './constants';

export const getVpnConfigAction = () => ({
  type: GET_VPN_CONFIG,
  payload: {},
});

export const setCountryList = (countryList: TCountryListData) => ({
  type: SET_COUNTRY_LIST,
  payload: {
    countryList,
  },
});

export const getVpnInfoAction = () => ({
  type: GET_VPN_INFO,
  payload: {},
});

export const setVpnInfo = (vpnInfo: Partial<TVpnInfoType>) => ({
  type: SET_VPN_INFO,
  payload: {
    ...vpnInfo,
  },
});

export const setVpnSessionStart = (vpnSessionStart: string | null) => ({
  type: SET_VPN_SESSION_START,
  payload: {
    vpnSessionStart,
  },
});

export const setIsVpnModalOpened = (isOpened: boolean) => ({
  type: SET_IS_VPN_MODAL_OPENED,
  payload: {
    isOpened,
  },
});

export const getAntitrackingStatistics = () => ({
  type: GET_ANTITRACKING_STATISTICS,
  payload: {},
});

export const setAntitrackingStatistics = (antitrackingStatistics: TAntitrackingStatistics) => ({
  type: SET_ANTITRACKING_STATISTICS,
  payload: {
    antitrackingStatistics,
  },
});

export const getVpnSessionStart = () => ({
  type: GET_VPN_SESSION_START,
  payload: {},
});

export const enableVpn = () => ({
  type: REQUEST_ENABLE_VPN_CONNECTION,
  payload: {
    isVpnStatusInProgress: true,
    isVpnConnectionScheduled: false,
  },
});

export const disableVpn = () => ({
  type: REQUEST_DISABLE_VPN_CONNECTION,
  payload: {
    isVpnStatusInProgress: true,
  },
});

export const scheduleVpnConnection = () => ({
  type: SCHEDULE_VPN_CONNECTION,
  payload: {
    isVpnConnectionScheduled: true,
  },
});

export const setCurrentCountry = (currentCountryCode: string, currentCountryName: string) => ({
  type: SET_CURRENT_COUNTRY,
  payload: {
    currentCountryCode,
    currentCountryName,
  },
});

export const getDefaultSettings = () => ({
  type: GET_DEFAULT_SETTINGS,
  payload: {},
});

export const setDefaultSettings = (defaultSettings: TDefaultSettings) => ({
  type: SET_DEFAULT_SETTINGS,
  payload: {
    defaultSettings,
  },
});

export const getBrowserConfig = (browserName: Browsers) => ({
  type: GET_BROWSER_CONFIG,
  payload: {
    browserName,
  },
});

export const getTrackersBlockedInBrowser = (browserName: string) => ({
  type: GET_TRACKERS_BLOCKED_IN_BROWSER,
  payload: {
    browserName,
  },
});

export const setTrackersBlockedInBrowser = (payload: {browserName: string; amountBlocked: number}) => ({
  type: SET_TRACKERS_BLOCKED_IN_BROWSER,
  payload,
});

export const setBrowserConfig = (payload: {browserConfig: TDomainConfig; browserName: Browsers}) => ({
  type: SET_BROWSER_CONFIG,
  payload,
});

export const updateBrowserConfig = (
  payload: TDefaultSettings /*{browserConfig: TDomainConfig; browserName: string}*/,
) => ({
  type: UPDATE_BROWSER_CONFIG,
  payload,
});

export const getAntitrackingConfig = () => ({
  type: GET_ANTITRACKING_CONFIG,
  payload: {},
});

export const setAntitrackingConfig = (payload: TAntitrackingConfig) => ({
  type: SET_ANTITRACKING_CONFIG,
  payload,
});

export const updateDomainConfig = (payload: TDomainConfig) => ({
  type: UPDATE_DOMAIN_CONFIG,
  payload,
});

export const clearAntitrackingWhiteList = () => ({
  type: CLEAR_ANTITRACKING_WHITELIST,
  payload: {},
});
