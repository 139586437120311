import React, {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {history} from '@renderer/js/router/history';
import {EVENTS} from '@common/events';
import {ButtonComponent, ModalComponent} from '@figleafteam/components';
import TopImage from './images/top.svg';
import './_ppi-final-modal.scss';
import {PPIFinalModalProps} from './ppi-final-modal.types';

export const PPIFinalModalComponent = memo<PPIFinalModalProps>(
  ({appInfo, sendTelemetryEvent, isModalOpened, closeModal}) => {
    const {t} = useTranslation();

    const handleClose = () => {
      closeModal();
      history.push({
        pathname: appInfo?.licenseInfo.type === 'trial' ? '/ppi/monetization' : '/dashboard',
      });
    };

    useEffect(() => {
      if (isModalOpened) {
        sendTelemetryEvent({name: EVENTS.PPI.DECREASE_RISK_PAGE_SHOW_FINAL_MODAL, source: 'app'});
      }
    }, [isModalOpened]);

    return (
      <ModalComponent width={580} titleTextAlign="left" isOpen={isModalOpened} handleClose={handleClose}>
        <section className="ppi-final-modal">
          <div className="ppi-final-modal__top">
            <TopImage />
          </div>
          <h1 className="ppi-final-modal__title">{t('Well done decreasing your privacy risks!')}</h1>
          <div className="ppi-final-modal__button-wrapper">
            <ButtonComponent text={t('OK, thanks!')} type="primary" fullWidth onClick={handleClose} />
          </div>
        </section>
      </ModalComponent>
    );
  },
);

PPIFinalModalComponent.displayName = 'PPIFinalModalComponent';
