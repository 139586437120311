import React, {FC} from 'react';
import {IconComponent} from '@figleafteam/components';
import {Props} from './default-error-notification.types';
import './_default-error-notification.scss';

export const DefaultErrorNotificationComponent: FC<Props> = props => {
  return (
    <div className="default-error-notification">
      <span className="default-error-notification__icon">
        <IconComponent name="attention-round" size={24} />
      </span>
      <span className="default-error-notification__text">{props.errorMessage}</span>
    </div>
  );
};
