import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {
  selectExposedCategoriesCount,
  selectisRisksScannerOpened,
  selectRisksScannerStep,
} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {setPpiFunnelState} from '@renderer/js/components/ppi-funnel/redux/actions';
import {RisksControlComponent} from './risks-control.component';

const mapStateToProps = (state: AppState) => ({
  exposedRiskCategoriesCount: selectExposedCategoriesCount(state),
  isRisksScannerOpened: selectisRisksScannerOpened(state),
  scannerStep: selectRisksScannerStep(state),
});

const mapDispatchToProps = {
  setPpiFunnelState,
};

export const RisksControlContainer = connect(mapStateToProps, mapDispatchToProps)(RisksControlComponent);
