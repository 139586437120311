import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {AppState} from '@renderer/js/redux/rootReducer';
import {openBuyNow} from '@renderer/js/components/settings/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {selectGlobalRiskType, selectIsAllRisksResolved} from '@renderer/js/components/ppi-funnel/redux/selectors';
import {getAppInfo} from '@renderer/js/components/app/redux/selectors';
import {PPIMonetizationPageComponent} from './ppi-monetization-page.component';

const mapStateToProps = (state: AppState) => ({
  globalRiskType: selectGlobalRiskType(state),
  isAllRisksResolved: selectIsAllRisksResolved(state),
  appInfo: getAppInfo(state),
});

const mapDispatchToProps = {
  openBuyNow,
  sendTelemetryEvent,
};

export const PPIMonetizationPageContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PPIMonetizationPageComponent),
);
