import React, {FC, useEffect} from 'react';
import {Loader} from '@figleafteam/components';
import {AppProps} from './app.types';

export const AppComponent: FC<AppProps> = ({getAppInfoAction, getUserProfileAction, isLoading, children}) => {
  useEffect(() => {
    getAppInfoAction();
    getUserProfileAction();
  }, []);

  return <Loader isLoading={isLoading}>{children} </Loader>;
};
