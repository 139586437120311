import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {getLicenseInfo, getLicenseInfoAction} from '@figleafteam/store';
import {AppState} from '@renderer/js/redux/rootReducer';
import {openBuyNow} from '@renderer/js/components/settings/redux/actions';
import {LicenseInfoComponent} from '@renderer/js/components/monetization/components/monetization-info/monetization-info.component';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';

const mapStateToProps = (state: AppState) => ({
  licenseInfo: getLicenseInfo(state),
});

const mapDispatchToProps = {
  getLicenseInfoAction,
  openBuyNow,
  sendTelemetryEvent,
};

export const LicenseInfoContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LicenseInfoComponent));
