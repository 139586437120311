import React, {memo} from 'react';
import {Props} from './location-circle.types';
import './_animated-location.scss';

const coordinates: {[key: string]: {cx: number; cy: number}} = {
  ar: {
    cx: 180,
    cy: 200,
  },
  at: {
    cx: 301,
    cy: 56,
  },
  au: {
    cx: 515,
    cy: 200,
  },
  be: {
    cx: 287,
    cy: 50,
  },
  br: {
    cx: 200,
    cy: 174,
  },
  ca: {
    cx: 169,
    cy: 57,
  },
  ch: {
    cx: 294,
    cy: 58,
  },
  cy: {
    cx: 332,
    cy: 79,
  },
  cz: {
    cx: 303,
    cy: 53,
  },
  de: {
    cx: 295,
    cy: 50,
  },
  dk: {
    cx: 296,
    cy: 40,
  },
  es: {
    cx: 276,
    cy: 69,
  },
  fi: {
    cx: 312,
    cy: 32,
  },
  fr: {
    cx: 284,
    cy: 57,
  },
  hk: {
    cx: 463,
    cy: 101,
  },
  hu: {
    cx: 308,
    cy: 57,
  },
  id: {
    cx: 454,
    cy: 156,
  },
  ie: {
    cx: 269,
    cy: 47,
  },
  il: {
    cx: 337,
    cy: 86,
  },
  in: {
    cx: 402,
    cy: 94,
  },
  it: {
    cx: 299,
    cy: 65,
  },
  jp: {
    cx: 493,
    cy: 77,
  },
  mx: {
    cx: 123,
    cy: 107,
  },
  nl: {
    cx: 289,
    cy: 48,
  },
  no: {
    cx: 293,
    cy: 35,
  },
  nz: {
    cx: 544,
    cy: 218,
  },
  pl: {
    cx: 307,
    cy: 48,
  },
  pt: {
    cx: 268,
    cy: 70,
  },
  ro: {
    cx: 317,
    cy: 60,
  },
  ru: {
    cx: 322,
    cy: 35,
  },
  se: {
    cx: 303,
    cy: 33,
  },
  sg: {
    cx: 449,
    cy: 141,
  },
  th: {
    cx: 443,
    cy: 117,
  },
  tr: {
    cx: 324,
    cy: 71,
  },
  ua: {
    cx: 326,
    cy: 54,
  },
  gb: {
    cx: 278,
    cy: 47,
  },
  us: {
    cx: 133,
    cy: 70,
  },
  za: {
    cx: 324,
    cy: 193,
  },
};

export const LocationCircleComponent = memo<Props>(props => {
  if (!props.id) {
    return null;
  }

  const countryCoordinates = coordinates[props.id];

  return (
    <svg className="animated-location" viewBox="0 0 572 248" fill="none">
      <linearGradient
        gradientTransform="rotate(162)"
        id="location-center-linear"
        x1="-98.3%"
        y1="0%"
        x2="86.36%"
        y2="0%"
      >
        <stop stopColor="rgba(162, 89, 185, 0.1)" offset="-10.78%" />
        <stop stopColor="rgba(28, 136, 50, 0.1)" offset="36.57%" />
        <stop stopColor="rgba(100, 185, 138, 0.1)" offset="76.21%" />
        <stop stopColor="rgba(255, 188, 88, 0.1)" offset="113.49%" />
      </linearGradient>
      <circle
        fill="url(#location-center-linear)"
        className="animated-location__circle"
        cx={countryCoordinates ? countryCoordinates.cx : '-100'}
        cy={countryCoordinates ? countryCoordinates.cy : '-100'}
        r="4.4"
      />
      <circle
        fill="currentColor"
        className="animated-location__center"
        cx={countryCoordinates ? countryCoordinates.cx : '-100'}
        cy={countryCoordinates ? countryCoordinates.cy : '-100'}
        r="3"
      />
    </svg>
  );
});

LocationCircleComponent.displayName = 'LocationCircleComponent';
