import {connect} from 'react-redux';
import {getLicenseInfo, isLicenseExpired, getLicenseInfoAction} from '@figleafteam/store';
import {AppState} from '@renderer/js/redux/rootReducer';
import {logout} from '@renderer/js/components/auth/redux/actions';
import {getAccountInfo} from '@renderer/js/components/settings/redux/selectors';
import {
  changePassword,
  changePasswordModalState,
  getUserLogin,
  lockUser,
  changePromoCodeModalAction,
  openSupportLink,
  openBuyNow,
} from '@renderer/js/components/settings/redux/actions';
import {setConfirmModalState} from '@renderer/js/components/confirm-modal/redux/actions';
import {sendTelemetryEvent} from '@renderer/js/events/redux/actions';
import {setRecoveryKeyModalState} from '@renderer/js/components/save-recovery-key/redux/actions';
import {AccountSettingsComponent} from '@renderer/js/components/settings/components/account-settings/account-settings.component';

const mapStateToProps = (state: AppState) => ({
  accountInfo: getAccountInfo(state),
  licenseInfo: getLicenseInfo(state),
  isLicenseExpired: isLicenseExpired(state),
});

const mapDispatchToProps = {
  logout,
  getUserLogin,
  openBuyNow,
  setRecoveryKeyModalState,
  changePassword,
  changePasswordModalState,
  lockUser,
  openSupportLink,
  setConfirmModalState,
  getLicenseInfoAction,
  sendTelemetryEvent,
  changePromoCodeModalAction,
};

export const AccountSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(AccountSettingsComponent);
