import React, {memo, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, Checkbox, InputPasswordComponent, InputComponent} from '@figleafteam/components';
import {AuthPopupContents} from '@renderer/js/components/auth/redux/constants';
import {history} from '@renderer/js/router/history';
import {EVENTS} from '@common/events';

import {TAuthPopupLoginContentProps} from './auth-popup-login-content.types';
import '../auth-popup/_auth-popup-content.scss';

type InputKeys = 'email' | 'loginPassword';

export const AuthPopupLoginContentComponent = memo<TAuthPopupLoginContentProps>(props => {
  const {t} = useTranslation();

  useEffect(() => {
    props.sendTelemetryEvent({name: EVENTS.PPI.AUTH_OPEN_LOGIN_MODAL, source: 'app'});
    return () => {
      props.setIsMigrationRequired(false);
    };
  }, []);

  const handleFieldChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = ev.target;
    props.setAuthInfo(name as InputKeys, value);

    if (name === 'email') {
      props.resetLoginEmailValidationStatus();
    } else {
      props.resetLoginPasswordsValidationStatus();
    }
  }, []);

  const handleForgotPassword = () => {
    props.setAuthPopupOpened(false);
    history.push('/recovery');
  };
  const goToSignUp = () => {
    props.setAuthPopupContent(AuthPopupContents.SIGNUP);
  };
  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    props.loginSubmit({
      fromPopup: true,
      handleResetPassword: handleForgotPassword,
      handleContactSupport: props.openSupportLink,
    });
  };

  const errorsConfig = {
    email: {
      error: props.loginErrors.emailInvalid,
      bottomText: props.loginErrors.emailErrorText,
    },
    password: {
      error: props.loginErrors.passwordInvalid,
      bottomText: props.loginErrors.passwordErrorText,
    },
  };

  return (
    <div className="auth-popup-content">
      <div className="auth-popup-content__container">
        <h1 className="auth-popup-content__title">
          {props.isMigrationRequired ? t('Log in to migrate your account') : t('Log In')}
        </h1>
        <form className="auth-popup-content__form" onSubmit={handleSubmit}>
          <div className="auth-popup-content__form-input">
            <InputComponent
              onChange={handleFieldChange}
              value={props.email}
              name="email"
              type="text"
              placeholder={t('Email')}
              {...errorsConfig.email}
            />
          </div>
          <div className="auth-popup-content__form-input">
            <InputPasswordComponent
              onChange={handleFieldChange}
              value={props.loginPassword}
              name="loginPassword"
              type="password"
              placeholder={t('Password')}
              {...errorsConfig.password}
            />
          </div>
          <div className="auth-popup-content__controls">
            <label className="auth-popup-content__checkbox-wrapper">
              <Checkbox
                theme="green"
                isChecked={props.savePassword}
                onChange={() => props.setSavePasswordStatus(!props.savePassword)}
                size="sm"
              />
              <span className="auth-popup-content__checkbox-text">{t('Keep me logged in')}</span>
            </label>
            <ButtonComponent
              onClick={handleForgotPassword}
              buttonType="button"
              text={t('Forgot password?')}
              type="link"
            />
          </div>
          <div className="auth-popup-content__buttons">
            <ButtonComponent fullWidth buttonType="submit" type="primary-green" text={t('Log in')} />
          </div>
        </form>
      </div>
      <div className="auth-popup-content__bottom-text-wrapper">
        <p className="auth-popup-content__bottom-text">
          {t('Don’t have an account?')}{' '}
          <ButtonComponent onClick={goToSignUp} buttonType="button" text={t('Create one')} type="link" />
        </p>
      </div>
    </div>
  );
});

AuthPopupLoginContentComponent.displayName = 'AuthPopupLoginContentComponent';
